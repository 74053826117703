<template>
<icon-base :width="size + 'px'" :fill="color">
        <g>
            <ellipse transform="matrix(0.9239 -0.3827 0.3827 0.9239 -14.1865 52.5501)" cx="125" cy="61.935" rx="61.923" ry="61.923"/>
            <path d="M208.784,173.267c-18.437-18.719-42.876-29.028-68.819-29.028h-29.929c-25.942,0-50.383,10.309-68.819,29.028   c-18.346,18.628-28.449,43.216-28.449,69.238c0,4.132,3.35,7.482,7.482,7.482H229.75c4.132,0,7.482-3.35,7.482-7.482   C237.233,216.484,227.129,191.895,208.784,173.267z"/>
        </g>
</icon-base>
</template>

<script>
export default {
name: 'iconPersonCircle',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
    type: Number,
        default: 20
    },
        color: {
    type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>

</style>
