<template>
    <icon-base :color="color" :size="size" viewBox="0 0 56 56" class="icon-whatsapp">
        <circle id="Ellipse_56" data-name="Ellipse 56" cx="28" cy="28" r="28" fill="#e9fbf0"/>
        <g id="Icon_ionic-logo-whatsapp" data-name="Icon ionic-logo-whatsapp" transform="translate(14 12.842)">
            <path id="Icon_ionic-logo-whatsapp-2" data-name="Icon ionic-logo-whatsapp" d="M16.5,2.25A13.693,13.693,0,0,0,2.758,15.889a13.5,13.5,0,0,0,1.972,7.043L2.25,30.25l7.61-2.418A13.769,13.769,0,0,0,30.25,15.889,13.693,13.693,0,0,0,16.5,2.25Zm6.835,18.819a3.55,3.55,0,0,1-2.431,1.567c-.645.034-.663.5-4.178-1.027s-5.628-5.24-5.795-5.479a6.741,6.741,0,0,1-1.3-3.653,3.9,3.9,0,0,1,1.338-2.858,1.346,1.346,0,0,1,.952-.4c.277,0,.456-.008.661,0s.512-.043.778.665.9,2.447.984,2.624a.637.637,0,0,1,.006.611,2.387,2.387,0,0,1-.373.568c-.183.2-.386.44-.55.591-.182.167-.373.348-.181.7a10.518,10.518,0,0,0,1.861,2.484A9.59,9.59,0,0,0,17.872,19.3c.345.188.55.167.764-.058s.915-.984,1.162-1.323.479-.273.8-.146,2.01,1.035,2.355,1.222.575.283.657.431A2.887,2.887,0,0,1,23.339,21.069Z" transform="translate(-2.25 -2.25)" fill="#5dd58b"/>
        </g>
    </icon-base>
</template>

<script>
export default {
    name:'iconTwitter',
    components:{
        IconBase: () => import('@/common/components/iconBase.vue')
    },
    props:{
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>

<style scoped>
</style>

