export default {
    //address
    getAddresses: state => state.addresses,
    addressSelected: state => state.addressSelected,
    getAddressAvailableList: state => state.addressAvailableList,
    allAddressCheckList: state => state.allAddressCheckList,
    allAddressCheckSelect(state, getters, rootState, rootGetters) {
        const filter = state.allAddressCheckList.filter((address) => address.id === state.addressSelected.id)[0]; //this will always be one
        let reacheableBranches = [];
        let reacheableBranchesIds = []; //final array & temporary array containing every single Id
        let branches = rootGetters["branches/branches"]; //we need to pass all the branches
        filter.verify.map(({branch_code}) => { // the branches that we will search.
            reacheableBranchesIds.push(branch_code)
        });
        branches.map( ({id}) => {
            reacheableBranches.push({
                branch: id,
                available: reacheableBranchesIds.indexOf(id) >= 0 //if we found the index it means that it is available
            });
        });
        return reacheableBranches;
    },
    orderStatus: state => state.orderStatus,
    addressSelectedAvailable: (state) => {
        if(state.addressAvailableList?.length > 0){
            let isAvailable = state.addressAvailableList.filter((address) => {
                return address?.id === state.addressSelected?.id;
            });
            return isAvailable[0] ? isAvailable[0] : { id: 0, available: false }
        }else {
            return { id: 0, available: false };
        }
    },
    // payments
    cards: (state) => state.cards,
    invoice: (state) => state.invoice,
    quotes: (state) => state.quotes,
    changedQuotes: (state) => state.changedQuotes,
    selectedQuotation: (state) => state.selectedQuotation,
    focusQuotation: (state) => state.focusQuotation,
    openInvoiceReturnModal: (state) => state.openInvoiceReturnModal,
    selectedQuotationForReturn: (state) => state.selectedQuotationForReturn,
    carrierSelected: (state) => state.carrierSelected
}
