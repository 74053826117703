<template>
    <icon-base :color="color" :size="size" viewBox="0 0 56 56" class="icon-facebook">
        <circle id="Ellipse_56" data-name="Ellipse 56" cx="28" cy="28" r="28" fill="#ddecff"/>
        <g id="Facebook_icon" data-name="Facebook icon" transform="translate(21.5 14)">
            <path id="Facebook_icon-2" data-name="Facebook icon" d="M204.589,3627.367a.368.368,0,0,1-.367.367h-2.79a1.238,1.238,0,0,0-1.248,1.249v2.938h3.966a.367.367,0,0,1,.366.367l-.366,4.113a.363.363,0,0,1-.367.331h-3.636v13.7a.369.369,0,0,1-.367.368h-4.921a.369.369,0,0,1-.367-.368v-13.7h-2.5a.369.369,0,0,1-.367-.368v-4.112a.368.368,0,0,1,.367-.368h2.5v-3.966a5.015,5.015,0,0,1,5.032-5.03h4.737a.367.367,0,0,1,.367.366v4.114Z" transform="translate(-191.626 -3622.887)" fill="#3b589c"/>
        </g>
    </icon-base>
</template>

<script>
export default {
    name:'iconTwitter',
    components:{
        IconBase: () => import('@/common/components/iconBase.vue')
    },
    props:{
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>

<style scoped>
</style>

