<template>
    <icon-base :width="size+'px'" :fill="color" viewBox="0 0 26.865 55.158">
        <g id="Group_7502" data-name="Group 7502" transform="translate(2.049 2.118)">
            <g id="Group_7501" data-name="Group 7501" opacity="0.54">
            <g id="Group_7500" data-name="Group 7500">
                <path id="Path_3084" data-name="Path 3084" d="M680.725,1248.416a20.167,20.167,0,0,1-.994,18.284" transform="translate(-679.731 -1233.317)" fill="none" stroke="#b5c66d" stroke-linecap="round" stroke-width="3"/>
                <path id="Path_3085" data-name="Path 3085" d="M687.9,1240.933s10.8,13.944-.92,33.232" transform="translate(-680.271 -1232.76)" fill="none" stroke="#b5c66d" stroke-linecap="round" stroke-width="3"/>
                <path id="Path_3086" data-name="Path 3086" d="M694.72,1232.1s21.479,24.095-.7,50.928" transform="translate(-680.794 -1232.103)" fill="none" stroke="#b5c66d" stroke-linecap="round" stroke-width="3"/>
            </g>
            </g>
        </g>
    </icon-base>
</template>

<script>
export default {
    name: 'SoundWaveLeft',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>
