<template>
<icon-base :width="100+'%'" :fill="color" viewBox="0 0 599.99 105.129">
    <path id="Path_3079" data-name="Path 3079" d="M1009.317,1055.506s160.394,170.886,599.044,73.378" transform="translate(-1008.588 -1054.822)" fill="none" stroke="#eaca42" stroke-width="3" opacity="0.6"/>
</icon-base>
</template>

<script>
export default {
    name: 'Line2',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>
