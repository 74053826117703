<template>
    <main-modal @closeModal="$emit('closeModal')">
        <div :key="modalIntranetMFA" class="d-flex flex-column justify-content-center py-2">
            <div v-if="error" class="alert alert-danger color-light-main-red d-flex align-items-center border-0 py-2" role="alert">
                <icon-error color="var(--light-main-red)" class="me-2"/> 
                {{$t(this.error)}}.
            </div>
            <p v-else class="alert alert-warning color-light-yellow subtitle-15 d-flex align-items-center border-0 py-2" role="warning">
                <iconWarning class="me-2" />
                <b>{{ $t("auth.validate_totp") }}</b>
            </p>
            <form @submit.prevent="" autocomplete="off">
                <main-input title="auth.code" id="code" ref="code" class="mb-2" placeholder="auth.code_placeholder" @changeValue="(data)=>{code = data}" :mask="'###-###'">
                    <icon-code :size="15" color="var(--dark-gray-200)" />
                </main-input>
                <div class="d-flex justify-content-end">
                    <main-button :title="'auth.save'" @click="verify"/>
                </div>
            </form>
        </div>
    </main-modal>
</template>

<script>
import MainInput from '../../../common/components/inputs/MainInput.vue'
import MainButton from '../../../common/components/buttons/MainButton.vue';
import IconError from '../../../common/svg/iconError.vue';
import IconCode from '../../auth/assets/svg/iconCode.vue';
import iconWarning from '../../../common/svg/iconWarning.vue'
import {AuthService} from '../services/auth-service'
import MainModal from '../../../common/components/modals/MainModal.vue';
const authService = new AuthService();

export default {
    components: { 
        MainInput, 
        MainButton, 
        IconError, 
        IconCode,
        iconWarning,
        MainModal 
    },
    name: "VerifyIntranetMFA",
    data() {
        return {
            code: '',
            error: false,
            verifyEmail: 0,
            readonly: true,
            totp: null,
        }
    },
    props:{
        user:{
            type: Object
        }
    },
    methods:{
        async verify(){
            const totp = {
                username: this.user.username,
                totp: this.code.replace(/\D/g, '')
            }
            try {
                this.$store.commit("common/SET_STATE_LOADING", true)
                this.$refs.code.$v.$touch();
                if (this.$refs.code.$v.$invalid) return;

                const sendCode = await authService.validateMFAIntranet(totp);
                const username = this.user.username;
                const password = this.user.password;

                if (sendCode.valid_totp) {
                    await this.$store.dispatch('auth/login', {username, password, isVerify: true});
                    this.$emit('closeModal');
                }
            } catch (error) {
                this.$emit("incorrectCode")
                this.error = error.message
            }finally{
                this.$store.commit("common/SET_STATE_LOADING", false)
            }
        }
    }
}
</script>

<style scoped>
</style>