export default () => ({
    searchHistory: JSON.parse(localStorage.getItem('searchHistory')) || [],
    oldQuantity: null,
    quickView: false,
    valueFilters: [],
    filters: {
        q: '',
        page: 1,
        brands_selected: [],
        models_selected: [],
        prices: [],
        limit: 24,
        order_by: 'popularity',
        category: 0,
        place: 1,
        rated: null,
        only_offers: 0,
        // initiated to null to avoid overlapping on search's component filters watch 
        only_with_existence: null
    },
    singleProductsView: '',
})
