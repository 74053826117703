import { http } from "../../../common/services/node-service";
import { parseResponse } from "../../../common/helpers/responses/parser";
export class AlgoliaService {
    async algoliaClick(params) {
        try {
            const algoliaClick = await http.post('algolia-events/product-clicked-after-search', params);
            const res = parseResponse(algoliaClick.data);
            return res.data;
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else {
                throw e
            }
        }
    }
    async algoliaCart(params) {
        try {
            const algoliaAddCart = await http.post('/algolia-events/product-added-to-cart', params);
            const res = parseResponse(algoliaAddCart.data);
            return res.data;
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else {
                throw e
            }
        }
    }
    async algoliaView(params) {
        try {
            const algoliaView = await http.post('/algolia-events/product-viewed', params);
            const res = parseResponse(algoliaView.data);
            return res.data;
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else {
                throw e
            }
        }
    }
}