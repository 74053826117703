<template>
<div>
        <hr class="home-section">
        <section class="home-section">
            <div class="row mx-0">
                <div class="col-md-12 px-0 mb-4 mb-md-0">
                    <div class="section-title-container d-flex justify-content-between">
                        <h3 class="active-title px-0">{{ $t("home.products_by_category") }}</h3>
                        <div class="d-none d-sm-flex px-lg-0">       
                            <div class="swiper-button-prev-product-slider" slot="button-prev" style="margin-right: 15px;" >
                                <icon-arrow-circle :size="40" class="fill-gray-50" />
                            </div>
                            <div class="swiper-button-next-product-slider" slot="button-next">
                                <icon-arrow-circle :size="40" class="fill-gray-50" style="transform: rotate(180deg)"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 px-0">
                    <div class="row mx-0">
                        <div class="scroller-column ps-0 pe-0 pe-md-4">
                            <div class="category-selector d-flex flex-column flex-sm-row flex-md-column flex-sm-wrap flex-md-nowrap overflow-auto me-md-3 pe-md-3">
                                <div class="product-category cursor-pointer d-flex align-items-center px-4 py-2 mb-3 me-sm-3 me-md-0" :class="{'active': selected === value}" :key="name" v-for="{name, value, icon, viewBox} in categoriesENV" @click="selected = value">
                                    <component :is="icon" class="py-1" :viewBox="viewBox" color="var(--gray-1001)" />
                                    <h4 class="size-16 weight-300 color-gray-1001 mt-2 ms-3 pt-1">{{$t(('home.'+name))}}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="slider-column position-relative px-0 mt-5 mt-md-0">
                            <div class="swiper-father-container position-relative"> 
                                <swiper v-if="!notFound" class="swiper" ref="productSwiper" :options="options" :pagination="{ clickable: true }" >
                                    <swiper-slide v-for="product in products" :key="product.codarticulo" class="pt-3">
                                        <div class="card-father-container d-flex">
                                            <card-product :product="product" :skeleton="loading" @showQuickView="showQuickView" @showShare="showShare"/>
                                        </div>
                                    </swiper-slide>
                                    <div class="swiper-pagination" slot="pagination" />     
                                </swiper>
                                <without-results v-else class="mt-5" description="home.category_notFound" title="product.no_product_was_found" noButton>                
                                    <template #main-icon>
                                        <icon-no-product :size="90"/>
                                    </template>
                                    <template #button-icon>
                                        <icon-change class="fill-white me-2" :size="17" />
                                    </template>
                                </without-results>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <quick-view v-if="quickView.value" :product="quickView.product" @closeModal="quickView.value = false"/>
            <share v-if="share.value" @closeModal="share.value = false" :product="share.product"/>
        </section>
</div>
</template>
<script>
import CardProduct from '../../../../products/components/CardProduct.vue';
import { mapGetters } from 'vuex';
import { ProductService } from "@/modules/products/services/product-service.js"
import QuickView from '../../../../products/components/quick-view/QuickView.vue';
import Share from '../../../../products/components/modals/Share.vue';
import IconArrowCircle from '../../../../../common/svg/iconArrowCircle.vue';
import WithoutResults from '../../../../../common/components/modals/WithoutResults.vue';
import IconNoProduct from '../../../../products/assets/svg/iconNoProduct.vue';
import IconChange from '../../../../products/assets/svg/iconChange.vue';
const service = new ProductService();
export default {
    name: "CategorySlider",
    components: {
        QuickView ,
        CardProduct,
        IconArrow: () => import("@/common/svg/iconArrow.vue"),
        // shared icons
        IconHeadphone: () => import('@/common/svg/categories/iconHeadphone'),
        IconCable: () => import('@/common/svg/categories/iconCable'),
        IconCellphone: () => import('@/common/svg/categories/iconCellphone'),
        IconCharger: () => import('@/common/svg/categories/iconCharger'),
        IconSpeaker: () => import('@/common/svg/categories/iconSpeaker'),

        IconBattery: () => import('@/modules/home/assets/svg/Categories/iconBattery'),
        IconCamera: () => import('@/modules/home/assets/svg/Categories/iconCamera'),
        IconComputer: () => import('@/modules/home/assets/svg/Categories/iconComputer'),
        IconConfiguration: () => import('@/modules/home/assets/svg/Categories/iconConfiguration'),
        IconGlass: () => import('@/modules/home/assets/svg/Categories/iconGlass'),
        IconHandsFree: () => import('@/modules/home/assets/svg/Categories/iconHandsFree'),
        IconLightRing: () => import('@/modules/home/assets/svg/Categories/iconLightRing'),
        IconPhoneCase: () => import('@/modules/home/assets/svg/Categories/iconPhoneCase'),
        IconPowerBank: () => import('@/modules/home/assets/svg/Categories/iconPowerBank'),
        IconSIM: () => import('@/modules/home/assets/svg/Categories/iconSIM'),
        IconSecurityCamera: () => import('@/modules/home/assets/svg/Categories/iconSecurityCamera'),
        IconStorage: () => import('@/modules/home/assets/svg/Categories/iconStorage'),
        IconTablet: () => import('@/modules/home/assets/svg/Categories/iconTablet'),
        IconTools: () => import('@/modules/home/assets/svg/Categories/iconTools'),
        IconTouch: () => import('@/modules/home/assets/svg/Categories/iconTouch'),
        Share,
        WithoutResults,
        IconArrowCircle,
        IconNoProduct,
        IconChange,
    },
    data(){
        return {
            loading: true,
            notFound: false,
            quickView: { product: null, value: false },
            share: { product: null, value: false },
            products: [],
            selected: process.env.VUE_APP_VIDVIE == 1  ? 4 : 16,
            categories: [
                {
                    icon: 'IconHeadphone',
                    viewBox: "0 0 250 250",
                    value: 16,
                    name: 'headphones'
                },
                {
                    icon: 'IconCharger',
                    viewBox: "0 10 250 250",
                    value: 11,
                    name: 'chargers'
                },
                {
                    icon: 'IconCable',
                    viewBox: "0 0 250 250",
                    value: 9,
                    name: 'cables'
                },
                {
                    icon: 'IconCellphone',
                    viewBox: "0 0 250 250",
                    value: 1,
                    name: 'cellphones'
                },
                {
                    icon: 'IconSpeaker',
                    viewBox: "0 0 250 250",
                    value: 5,
                    name: 'speakers'
                },
                {
                    icon: 'IconBattery',
                    viewBox: "0 0 200 200",
                    value: 3,
                    name: 'batterys'
                },
                // {
                //     icon: 'IconSecurityCamera',
                //     viewBox: "0 0 200 200",
                //     value: 36,
                //     name: 'security_cameras'
                // },
                {
                    icon: 'IconPhoneCase',
                    viewBox: "0 0 200 200",
                    value: 12,
                    name: 'phone_cases'
                },
                {
                    icon: 'IconTouch',
                    viewBox: "0 0 200 200",
                    value: 25,
                    name: 'touch'
                },
                {
                    icon: 'IconTools',
                    viewBox: "0 0 200 200",
                    value: 31,
                    name: 'tools'
                },
                {
                    icon: 'IconStorage',
                    viewBox: "0 0 200 200",
                    value: 19,
                    name: 'storage'
                },
                {
                    icon: 'IconPowerBank',
                    viewBox: "0 0 200 200",
                    value: 40,
                    name: 'powerbank'
                },
                {
                    icon: 'IconGlass',
                    viewBox: "0 0 200 200",
                    value: 34,
                    name: 'glass'
                },
                {
                    icon: 'IconTablet',
                    viewBox: "0 0 200 200",
                    value: 33,
                    name: 'tablet'
                },
                // {
                //     icon: 'IconSIM',
                //     viewBox: "0 0 200 200",
                //     value: 27,
                //     name: 'SIM'
                // },
            ],
            categoriesUS: [
                {
                    icon: 'IconHeadphone',
                    viewBox: "0 0 250 250",
                    value: 4,
                    name: 'headphones'
                },
                {
                    icon: 'IconCharger',
                    viewBox: "0 10 250 250",
                    value: 3,
                    name: 'chargers'
                },
                {
                    icon: 'IconCable',
                    viewBox: "0 0 250 250",
                    value: 	2,
                    name: 'cables'
                },
                // {
                //     icon: 'IconCellphone',
                //     viewBox: "0 0 250 250",
                //     value: 1,
                //     name: 'cellphones'
                // },
                {
                    icon: 'IconSpeaker',
                    viewBox: "0 0 250 250",
                    value: 8,
                    name: 'speakers'
                },
                {
                    icon: 'IconBattery',
                    viewBox: "0 0 200 200",
                    value: 6,
                    name: 'batterys'
                },
                // {
                //     icon: 'IconSecurityCamera',
                //     viewBox: "0 0 200 200",
                //     value: 36,
                //     name: 'security_cameras'
                // },
                {
                    icon: 'IconPhoneCase',
                    viewBox: "0 0 200 200",
                    value: 17,
                    name: 'phone_cases'
                },
                {
                    icon: 'IconTouch',
                    viewBox: "0 0 200 200",
                    value: 21,
                    name: 'touch'
                },
                // {
                //     icon: 'IconTools',
                //     viewBox: "0 0 200 200",
                //     value: 31,
                //     name: 'tools'
                // },
                // {
                //     icon: 'IconStorage',
                //     viewBox: "0 0 200 200",
                //     value: 19,
                //     name: 'storage'
                // },
                {
                    icon: 'IconPowerBank',
                    viewBox: "0 0 200 200",
                    value: 5,
                    name: 'powerbank'
                },
                {
                    icon: 'IconGlass',
                    viewBox: "0 0 200 200",
                    value: 14,
                    name: 'glass'
                },
                // {
                //     icon: 'IconTablet',
                //     viewBox: "0 0 200 200",
                //     value: 33,
                //     name: 'tablet'
                // },
                // {
                //     icon: 'IconSIM',
                //     viewBox: "0 0 200 200",
                //     value: 27,
                //     name: 'SIM'
                // },
            ],
            options: {
                noSwiping: true,
                preloadImages: true,
                lazy: true,     
                pagination: {
                    el: '.swiper-pagination',
                    dynamicBullets: true,
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next-product-slider', 
                    prevEl: '.swiper-button-prev-product-slider'
                },
                initialSlide: 0,
                autoplay: {
                    delay: 2500,
                    stopOnLastSlide: true,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: true,
                },
                grabCursor: true,
                slidesPerView: 4,
                slidesPerColumnFill: 'row',
                slidesPerColumn: 1,
                preventClicks: true,
                breakpoints: {
                    0: {
                        slidesPerView: 1.3,
                        spaceBetween: 0,
                        slidesPerColumn: 1,
                        speed: 300
                    },
                    576: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                        slidesPerColumn: 1,
                        speed: 800,
                    },
                    // when window width is >= 768px
                    768: {
                        slidesPerView: 1,
                        slidesPerColumn: 1,
                        slidesPerGroup: 1,
                        spaceBetween: 1,
                        centeredSlides: false,
                    },
                    // when window width is >= 992px
                    992: {
                        slidesPerView: 2,
                        slidesPerGroup: 1,
                        spaceBetween: 25,
                    },
                    // when window width is >= 1200px
                    1200: {
                        slidesPerView: 3,
                        slidesPerGroup: 1,
                        spaceBetween: 0
                    },
                    1400: {
                        slidesPerView: 3,
                        slidesPerGroup: 1,
                        spaceBetween: 24
                    },
                },
            },
        }
    },
    computed: {
        ...mapGetters({
            branchSelected: "branches/branchSelected",
        }),
        categoriesENV(){
            if(process.env.VUE_APP_VIDVIE == 1){
                return this.categoriesUS
            } else {
                return this.categories
            }
        },
    },
    methods: {
        async setProducts(category){
            this.loading = true;
            let branch = this.branchSelected?.codplace || 1;
            let filterParams = { order_by: 'newest', category, limit: 10, prices: [0, 100000], gbSelected: [], place: branch, page: 1 };
            try {
                const response = await service.getProducts(filterParams);
                this.products = response.docs;
            } catch (e) {
                console.log(e);
            } finally {
                this.notFound = this.products.length < 1;
                if(this.$refs?.productSwiper)
                this.$refs.productSwiper.$swiper.slideTo(0,0)
                
                this.loading = false;
            }
        },   
        showShare(product){
            this.share.value = true
            this.share.product = product
        },
        showQuickView({product, value}){
            this.quickView.product = product;
            this.quickView.value = value;
        },
        pauseSwiper(swiper) {
            let swiperGetter = document.getElementById(swiper);
            swiperGetter.swiper.autoplay.stop();
        },
        pauseAllSwipers(){
            this.$refs.productSwiper.map((swiper) => {
                swiper.$swiper.autoplay.stop();
            })
        },
        resumeSwiper(swiper){
            let swiperGetter = document.getElementById(swiper)
            swiperGetter.swiper.autoplay.start();
        },
    },
    watch: {
        selected(val){
            this.setProducts(val)
        }
    },
    created(){
        this.setProducts(this.selected);
    },
}
</script>

<style scoped>
.active-title {
    display: inline;
    font-size: calc(1.3rem + 0.6vw);
    max-width: 100%;
    font-weight: 700;
}
.active-title:after {
    content: '';
    display: block;
    position: relative;
    height: 4px;
    border-radius: 3.125rem;
    width: 100%;
    background-color: var(--main-red);
    top: 5px !important;
}
.swiper-button-prev-product-slider.swiper-button-disabled, .swiper-button-next-product-slider.swiper-button-disabled {
    opacity: 0.35;
    cursor: no-drop !important;
    pointer-events: all !important;
}
.swiper-button-prev-product-slider.swiper-button-disabled:hover svg, .swiper-button-next-product-slider.swiper-button-disabled:hover svg {
    fill: var(--gray-100) !important;
}
.swiper-button-prev-product-slider:hover svg, .swiper-button-next-product-slider:hover svg {
    fill: var(--main-red) !important;
}
/* scroll styles */
.category-selector {
    max-height: 257px;
}
.category-selector::-webkit-scrollbar-track {
    background: var(--light-gray-50) !important;
    border-radius: 15px;
}
.product-category {
    border: 1.5px solid var(--gray-1001);
    border-radius: 12px;
    transition: var(--transition-1);
}
.product-category:hover svg {
    fill: var(--main-navy);
}
.product-category:hover h4 {
    font-weight: 600 !important;
}
.product-category svg {
    width: 45px;
    transition: var(--transition-1);
}
.product-category h4 {
    transition: var(--transition-1);
}
.product-category.active {
    border-color: var(--light-gray-100);
    background-color: var(--light-gray-100);
}
.product-category.active svg {
    fill: var(--main-red);
}
.product-category.active h4 {
    font-weight: 600 !important;
    color: var(--main-dark-navy) !important;
}

/* slider styles */
.swiper-father-container {
    left: -18px;
    width: 104.5%; 
}
.swiper, .slider-column { /* height of the general swiper*/
    height: 380px;
}
.swiper-pagination::v-deep .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
}
.swiper-pagination::v-deep :is(.swiper-pagination-bullet-active-prev-prev, .swiper-pagination-bullet-active-next-next){
    transform: scale(0.45);
}
.card-father-container {
    padding-inline: 21px;
}
/* overwritting some of the card styles */
.card-container {
    box-shadow: 3px 6px 20px #7C879329;
}
.card-container::v-deep.card-product:hover {
    transform: scale(1.075);
}
.card-container::v-deep .product-slider-card-content {
    height: 255px !important;
    padding-top: 3px;
}
.card-container::v-deep .product-desc {
    font-size: 13px !important;
    margin-top: 6px !important;
}
.card-container::v-deep .star-rating {
    margin-top: 6px !important;
}
/* responsive and default styles */
.scroller-column { 
    flex: 0 0 auto;
    width: 100%;
}
.slider-column {
    flex: 0 0 auto;
    width: 100%; 
}

@media (min-width: 576px) {
    .product-category {
        width: 46.5%;
    }
    .category-selector {
        max-height: 337px;
    }
    .scroller-column {
        flex: 0 0 auto;
        width: 100%;
    }
    .slider-column {
        flex: 0 0 auto;
        width: 100%; 
    }
    .swiper-father-container {
        left: -13px;
        width: 104.5%; 
    }
    .card-father-container {
        padding-inline: 17px;
    } 
}
@media (min-width: 768px) {
    .section-title-container {
        margin-bottom: 40px;
    }
    .scroller-column {
        flex: 0 0 auto;
        width: 50%;
    }
    .slider-column {
        flex: 0 0 auto;
        width: 50%; 
    }
    .product-category {
        width: 100%;
    }
    .swiper-father-container {
        left: -4px;
        width: 104.5%; 
    }
    .card-father-container {
        padding-inline: 42px;
    }
}
@media (min-width: 992px) {
    .active-title {
        font-size: 27px;
    }
    .scroller-column { 
        flex: 0 0 auto;
        width: 38%;
    }
    .slider-column {
        flex: 0 0 auto;
        width: 62%;
    }
    .card-father-container {
        padding-inline: 22px;
    } 
}
@media (min-width: 1200px) {
    .card-father-container {
        padding-inline: 32px;
    }  
    .scroller-column { /* space between slider and category scroll */
        flex: 0 0 auto;
        width: 28%;
    }
    .slider-column {
        flex: 0 0 auto;
        width: 72%; /* 72% */
    }
}
@media (min-width: 1400px) {
    .card-father-container {
        padding-inline: 40px;
    }
}
.card-product::v-deep > .card-inner-container > .product-slider-card-content {
    height: auto !important;
}
</style>