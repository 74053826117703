<template>
    <div class="d-flex color-picker">
        <div v-for="color in colors" :key="color" class="color-outline d-flex align-content-center align-items-center justify-content-center cursor-pointer" :style="{background: getColor(color)+'33'}" @click.prevent="change(color)">
            <div class="color d-flex align-content-center align-items-center justify-content-center" :style="{background: getColor(color)}">
                <icon-check class="check-icon" v-show="selected === color" color="#fff" :size="12" />
            </div>
        </div>
    </div>
</template>

<script>
import iconCheck from "@/common/svg/iconCheck.vue"
export default {
    name: 'ColorPicker',
    components:{
        iconCheck
    },
    data() {
        return {
            colors: [],
            selected: null,
        }
    },
    props: {
        colorsAvailable: {
            type: Array,
            default: () => {
                return ['negro','yellow','azul','gray']
            }
        },
        selectedColor: {
            type: String,
            default: 'black'
        }
    },
    methods: {
        convertColors(){
            let tempColors = this.colorsAvailable;
            tempColors.map((color,index) => {
                tempColors[index] = this.translateColor(color);
            });
            this.colors = tempColors
        },
        //translating the color (could be improved by using $i18n)
        translateColor(color){ 
            let tcolor = color.toLowerCase();
            switch (tcolor) {
                case 'negro':
                    return 'black';
                case 'blanco':
                    return 'white';
                case 'plateado':
                    return 'gray';
                case 'plata':
                    return 'gray';
                case 'verde':
                    return 'green';
                case 'rosado':
                    return 'pink';
                case 'rojo':
                    return 'red';
                case 'azul':
                    return 'blue';
                default:
                    return tcolor;
            }
        },
        getColor(color){ // setting the hex value
            switch (color) {
                case 'black':
                    return '#212E3D'
                case 'white':
                    return '#ffffff'
                case 'red':
                    return 'var(--main-red)'
                case 'yellow':
                    return '#F9B530'
                case 'blue':
                    return '#4980D5'
                case 'gray':
                    return '#929191'
                default:
                    break;
            }
        },
        change(color){
            this.$emit('color', color)
            this.selected = color;
        }
    },
    created() {
        //translating the colors
        this.convertColors();
        this.selected = this.translateColor(this.selectedColor)
    }
}
</script>

<style scoped>
.color, .color-outline {
    border-radius: 3.12rem;
}
.color-outline {
    width: 30px;
    height: 30px;
    
    transform: scale(0.98);
}
.color-outline:not(:last-child) {
    margin-right: 14px;
}
.color {
    width: 20px;
    height: 20px;
}
.check-icon{
    animation: appear 0.15s;
}
@keyframes appear {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    70% {
        opacity: 1;
        transform: scale(1.2);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
</style>
