<template>
    <button :id="id" @click="$emit('click')" :class="[{'pressed': pressed}]" class="button-secondary" :disabled="disabled"
    @focus="pressed = true" @blur="pressed = false" @mouseout="pressed = false">
        <span class="d-flex justify-content-center" >
            <div v-if="!iconRight" class="d-flex justify-content-center">
                <slot v-if="icon" iconColor/>
            </div>
            {{$t(title)}}
            <div v-if="iconRight" class="d-flex align-items-center justify-content-center">
                <slot v-if="icon" iconColor/>
            </div>
        </span>
    </button>
</template>

<script>
export default {
    name: 'SecondButton',
    props: {
        title: {
            type: String,
            required: true
        },
        id: {
            type: String
        },
        icon: {
            type: Boolean
        },
        iconColor: {
            type: String,
            default: 'var(--dark-main-navy)'
        },
        iconRight:{
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            pressed:false
        }
    },
}
</script>

<style scoped>
    button {
        background-color: transparent;
        color: var(--dark-main-navy); 

        border: 0.124rem solid;
        border-color: var(--dark-main-navy); 
        border-radius: 5px;

        padding: 0 15px;
        font-weight: 400;
        line-height: 28px;

        transition: var(--transition-1);
        font-size: 14px;
    }
    button:disabled{
        border-color: var(--gray-1001) !important;
        cursor:no-drop;
    }
    button:disabled > span{
        color: var(--gray-1001) !important;
    }
    button:disabled > span > div > svg{
        fill: var(--gray-1001) !important;
    }
    button:hover, 
    button:hover span{
        border-color: var(--light-main-red);
        color: var(--light-main-red);
    }
    .pressed {
        transform: scale(0.97);
    }
    span {
        color: var(--dark-main-navy);
        transition: var(--transition-1);
    }
    button > span > div > svg{
        transition: var(--transition-1);
    }
    button:hover > span > div > svg{
        fill: var(--light-main-red);
    }
</style>