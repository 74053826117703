<template>
    <div class="row m-0 px-5 px-lg-2 py-2 py-md-0 ms-0 ms-md-5 advertisements-3 cursor-pointer" @click="navigateTo({value: verifyUSTECH() ? 4 : 16})" v-if="getDimensions">
        <background-circle id="background-circle"/>
        <background-curve id="background-curve"/>
        <background-curve2 id="background-curve-2"/>
        <sound-wave-right1 id="sound-wave-right-1" class="d-none d-md-block"/>
        <sound-wave-right1 id="sound-wave-right-2" class="d-none d-md-block"/>
        <div class="col-md-4 mt-4 d-none d-md-flex align-items-center">
            <img loading="lazy" src="../../assets/images/advertisements/ads-3/img-1.webp" alt="img-advertisements-3-img-1" id="img-advertisements-3-img-1">
            <img loading="lazy" src="../../assets/images/advertisements/ads-3/img-2.webp" alt="img-advertisements3-img-2-" id="img-advertisements-3-img-2">
        </div>
        <div class="col-md-8 position-relative d-flex flex-column justify-content-center align-items-center content" style="z-index: 2;">
            <h1 class="mb-0">{{$t('home.ads.earphones')}}</h1>
            <h4>{{$t('home.ads.share_your')}} <b style="font-family:'Myriad Pro Bold' !important;">{{$t('home.ads.music')}}</b> {{$t('home.ads.with_your_friends')}}</h4>
            <img loading="lazy" src="../../assets/images/advertisements/ads-3/img-3.webp" alt="img-advertisements-3-img-1" class="d-block d-md-none" height="250">
            <button class="btn-advertisements-3 mt-4 mt-md-0 mb-2 mb-md-0">{{$t('home.ads.see_more')}}</button>
        </div>
        <ads-line id="ads-line"/>
        <line-2 id="ads-line-2"/>
    </div>
    <div v-else>
        <ads-03/>
    </div>
</template>

<script>
    import BackgroundCircle from '../../assets/svg/ads/ads-3/BackgroundCircle.vue'
    import BackgroundCurve from '../../assets/svg/ads/ads-3/BackgroundCurve.vue'
    import BackgroundCurve2 from '../../assets/svg/ads/ads-3/BackgroundCurve2.vue'
    import AdsLine from '../../assets/svg/ads/ads-3/Line.vue'
    import Line2 from '../../assets/svg/ads/ads-3/Line2.vue'
    import SoundWaveRight1 from '../../assets/svg/ads/ads-3/SoundWaveRight.vue'
    import Ads03 from './svg/Ads03.vue'

    export default{
        name: "AdMd",
        components:{BackgroundCircle, BackgroundCurve, BackgroundCurve2, AdsLine, Line2, SoundWaveRight1, Ads03},
        methods:{
            navigateTo(category) {
                this.$store.commit("products/SET_CATEGORY", category);
                this.$router.replace({ path: "/shop" }).catch(() => { });
            },
        },
        computed:{
            getDimensions(){
                return window.matchMedia('(min-width: 768px)').matches
            }
        }
    }
</script>

<style scoped>
.advertisements-3{
        background-color: #f9db5e;
        border-radius: 20px;
        height: 320px;
        overflow: hidden;
        position: relative;
    }
    .advertisements-3 h1{
        color: #d1ae18;
        font-family:'Myriad Pro Bold' !important;
        font-size: 98px;
        font-weight: 600;
    }
    .advertisements-3 > div > #img-advertisements-3-img-1{
        position: absolute;
        width: 140px;
        top: 8px;
        z-index: 2;
        transition: all 0.3s ease-in-out;
        left: 6%;
    }
    .advertisements-3 > div > #img-advertisements-3-img-2{
        width: 118px;
        position: absolute;
        left: 18%;
        bottom: -4px;
        z-index: 2;
        transition: all 0.3s ease-in-out;
    }
    .advertisements-3 h4{
        font-family:'Myriad Pro Light' !important;
        font-size: 25px;
    }
    .advertisements-3 > #background-circle{
        width: 570px;
        position: absolute;
        top: -126%;
        left: -8%;
        transition: all 0.3s ease-in-out;
    }
    .advertisements-3 > #background-curve{
        position: absolute;
        right: -40%;
        width: 654.904px;
        top: -24%;
    }
    .advertisements-3 > #background-curve-2{
        position: absolute;
        right: -36%;
        width: 654.904px;
        bottom: -62%;
    }
    .advertisements-3 > #sound-wave-right-1, .advertisements-3 > #sound-wave-right-2{
        position: absolute;
        width: 50px;
        transition: all 0.3s ease-in-out;
    }
    .advertisements-3:hover > #sound-wave-right-1, .advertisements-3:hover > #sound-wave-right-2{
        
        transform: translateX(30px);
    } 
    .advertisements-3 > #sound-wave-right-1{
        left: 20%;
        top: 22%;
    }
    .advertisements-3 > #sound-wave-right-2{
        left: 30%;
        bottom: 33%;
    }
    .advertisements-3 > #ads-line{
        position: absolute;
        left: -5%;
        bottom: -10%;
        width: 300px;
        z-index: 1;
    }
    .advertisements-3 > #ads-line-2{
        position: absolute;
        width: 700px;
        right: -5%;
        top: -17%;
    }
    .btn-advertisements-3{
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
        z-index: 4;
        background-color: #fff;
        border: none;
        border-radius: 50px;
        font-size: 22px;
        font-weight: 700;
        color: #e2ba14;
        height: 37px;
        width: 160px;
        bottom: 3%;
        opacity: 0;
        position: absolute;
    }
    .advertisements-3 .content{
        transition: all 0.3s ease-in-out;
    }
    .advertisements-3:hover .content{
        transform: translateY(-20px);
    }
    .advertisements-3:hover .content .btn-advertisements-3{
        transform: translateY(-30px);
        opacity: 1;
    }
    .advertisements-3:hover #background-circle{
        transform: translateY(20px);
    }
    .advertisements-3:hover > div > #img-advertisements-3-img-2, .advertisements-3:hover > div > #img-advertisements-3-img-1{
        transform: translateX(20px);
    }
    @media (max-width: 476px) {
        .advertisements-3{
            height: auto;
        }
        .advertisements-3 h1{
            font-size: 60px;
        }
        .btn-advertisements-3{
            position: relative;
            opacity: 1;
            bottom: 0;
            font-size: 20px;
            height: 45px;
            max-width: 150px;
        }
    }
    .btn-advertisements-3:hover{
        background-color: transparent;
        color: #fff;
        border: 1px solid #fff;
    }
</style>
