<template>
    <icon-base :fill="color" width="100%" viewBox="0 0 416.7 172.9">
        <g id="Group_7491_3_" transform="translate(85.996 42)">
            <g>
                <defs>
                    <path id="Rectangle_1715_3_" d="M-76-41.9h396.6c5.5,0,10,4.5,10,10v152.8c0,5.5-4.5,10-10,10H-76c-5.5,0-10-4.5-10-10V-31.9     C-86-37.4-81.5-41.9-76-41.9z"/>
                </defs>
                <use xlink:href="#Rectangle_1715_3_" style="overflow:visible;fill:#D8DCE6;"/>
                <clipPath id="Rectangle_1715_1_">
                    <use xlink:href="#Rectangle_1715_3_" style="overflow:visible;"/>
                </clipPath>
                <ellipse id="Ellipse_460_3_" class="st24" cx="274.6" cy="-178.8" rx="202.9" ry="202.9"/>
                <ellipse id="Ellipse_462_3_" class="st24" cx="342.2" cy="99.7" rx="62.2" ry="62.2"/>
                <ellipse id="Ellipse_463_3_" class="st24" cx="197.8" cy="112.8" rx="65.3" ry="65.3"/>
                <ellipse id="Ellipse_464_3_" class="st24" cx="-98.2" cy="46.6" rx="88.6" ry="88.6"/>
            </g>
            <g>
                <defs>
                    <path id="SVGID_13_" d="M-76-41.9h396.6c5.5,0,10,4.5,10,10v152.8c0,5.5-4.5,10-10,10H-76c-5.5,0-10-4.5-10-10V-31.9     C-86-37.4-81.5-41.9-76-41.9z"/>
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_13_" style="overflow:visible;"/>
                </clipPath>
            </g>
        </g>
        <g id="Group_7518_3_" transform="translate(52.355 112.767)">
            <g id="Group_7517_3_">
                <g id="Group_7516_3_">
                    <text transform="matrix(1 0 0 1 -26.1025 -33.6738)" class="st27 st28 st29">Full Freedom.</text>
                </g>
            </g>
        </g>
        <g id="Group_7521_3_" transform="translate(52.355 67)">
            <g id="Group_7520_3_">
                <g id="Group_7519_3_">
                    <text transform="matrix(1 0 0 1 -26.1025 -23.8243)" class="st27 st30 st31">Listen to your music with</text>
                </g>
            </g>
        </g>
        <g id="Mask_Group_99_3_" transform="matrix(0.998, -0.07, 0.07, 0.998, 666.993, 20.115)">
            <image width="333" height="281"  href="../../../assets/images/advertisements/ads-4/img-2.webp" transform="matrix(0.271 1.900950e-02 -1.900950e-02 0.271 -345.0398 -26.8428)">
            </image>
        </g>
        <g id="Mask_Group_100_3_" transform="translate(521.646 119.39) rotate(-9)">
            <image width="299" height="270" href="../../../assets/images/advertisements/ads-4/img-1.webp" transform="matrix(0.2697 4.271583e-02 -4.271583e-02 0.2697 -259.9019 -96.5661)">
            </image>
        </g>
        <g id="Component_310_2_3_" transform="translate(52.355 200.975)">
            <g id="Group_7563_3_">
                <g id="Group_7524_3_" class="st32">
                    <g id="Group_7523_3_">
                        <g id="Group_7522_3_">
                            <text transform="matrix(1 0 0 1 -26.103 -74.333)" class="st33 st34 st35">More </text>
                            <text transform="matrix(1 0 0 1 13.7588 -74.333)" class="st33 st28 st35">Music. </text>
                            <text transform="matrix(1 0 0 1 64.5342 -74.333)" class="st33 st34 st35">Less </text>
                        </g>
                    </g>
                </g>
            </g>
        </g>
        <text transform="matrix(1 0 0 1 149.6431 126.642)" class="st33 st28 st35">Noise.</text>
        <g>
            <path id="_3_5_" class="st36" d="M200.4,118l3.8,3.8l-3.8,3.8"/>
            <path id="_2_4_" class="st36" d="M206.1,118l3.8,3.8l-3.8,3.8"/>
            <path id="_1_1_" class="st36" d="M211.8,118l3.8,3.8l-3.8,3.8"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'Ads04',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: ''
    },
    
}
}
</script>

<style scoped>
    .st0{opacity:0.2;clip-path:url(#XMLID_3_);fill:#D88D48;enable-background:new    ;}
    .st1{opacity:0.5;clip-path:url(#XMLID_3_);fill:#D88D48;enable-background:new    ;}
    .st2{opacity:0.5;}
    .st3{opacity:0.2;}
    .st4{opacity:0.15;}
    .st5{opacity:0.1;}
    .st6{opacity:0.9;fill:#FFFFFF;enable-background:new    ;}
    .st7{fill:#E0EBC1;}
    .st8{clip-path:url(#SVGID_8_);}
    .st9{fill:#F4D65B;}
    .st10{fill:#BED37D;}
    .st11{fill:none;stroke:#212E3D;stroke-miterlimit:10;}
    .st12{fill:none;stroke:#212E3D;stroke-linecap:round;stroke-miterlimit:10;}
    .st13{opacity:0.54;}
    .st14{fill:none;stroke:#B5C66D;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
    .st15{fill:#FCE172;}
    .st16{opacity:0.6;clip-path:url(#SVGID_12_);fill:#F7D752;enable-background:new    ;}
    .st17{opacity:0.2;clip-path:url(#SVGID_12_);fill:#E0BF34;enable-background:new    ;}
    .st18{clip-path:url(#SVGID_12_);}
    .st19{opacity:0.6;fill:none;stroke:#EACA42;stroke-width:2.0002;stroke-miterlimit:10.0012;enable-background:new    ;}
    .st20{fill:none;}
    .st21{opacity:0.6;fill:none;stroke:#EACA42;stroke-width:2;stroke-miterlimit:10;enable-background:new    ;}
    .st22{opacity:0.6;}
    .st23{fill:#C1A11E;}
    .st24{opacity:0.18;clip-path:url(#Rectangle_1715_1_);fill:#A7B1C6;enable-background:new    ;}
    .st25{clip-path:url(#SVGID_14_);}
    .st26{opacity:0.18;fill:#C1A73D;enable-background:new    ;}
    .st27{fill:#8F9BB5;}
    .st28{font-family:'Myriad Pro Bold';}
    .st29{font-size:31.6099px;}
    .st30{font-family:'Myriad Pro Light';}
    .st31{font-size:24.9552px;}
    .st32{opacity:0.8;}
    .st33{fill:#212E3D;}
    .st34{font-family:'Myriad Pro Regular';}
    .st35{font-size:16.6861px;}
    .st36{fill:none;stroke:#45505E;stroke-linecap:round;stroke-linejoin:round;}
</style>