<template>
    <icon-base :width="size+'px'" :fill="color" viewBox="0 0 21.014 22.971">
        <g id="Group_7536" data-name="Group 7536" opacity="0.6">
            <path id="Path_3096" data-name="Path 3096" d="M782.111,3093.45a.968.968,0,0,1,.543-.619,12.934,12.934,0,0,0,5.981-19.594.97.97,0,1,1,1.635-1.045,16.528,16.528,0,0,1,2.5,6.485,14.024,14.024,0,0,1-.457,6.393,14.649,14.649,0,0,1-3.2,5.581,16.4,16.4,0,0,1-5.7,3.983.969.969,0,0,1-1.31-1.185Zm0,0" transform="translate(-771.934 -3071.745)" fill="#c1a11e"/>
            <path id="Path_3097" data-name="Path 3097" d="M751.192,3094.884a.968.968,0,0,1,.543-.619,8.2,8.2,0,0,0,3.8-12.46.97.97,0,0,1,1.635-1.045,10.987,10.987,0,0,1,1.691,4.415,10,10,0,0,1-.3,4.389,9.845,9.845,0,0,1-2.2,3.81,11.738,11.738,0,0,1-3.868,2.719.969.969,0,0,1-1.31-1.185Zm-2.887-4.477a.97.97,0,0,1,.544-.619,3.862,3.862,0,0,0,1.8-5.882.97.97,0,0,1,1.635-1.045,6.552,6.552,0,0,1,.96,2.5,5.618,5.618,0,0,1-1.428,4.678,6.562,6.562,0,0,1-2.19,1.534.968.968,0,0,1-1.309-1.185Zm-4.187-5.279" transform="translate(-744.118 -3078.025)" fill="#c1a11e"/>
        </g>
    </icon-base>
</template>

<script>
export default {
    name: 'SoundWaveRight',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>
