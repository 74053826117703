export default {
    SET_HISTORY(state, data){
      state.seachHistory = data;
      localStorage.setItem('searchHistory', JSON.stringify(data));
    },
    SET_OLD_QUANTITY(state, quantity){
      state.oldQuantity = quantity;
    },
    SET_QUICK_VIEW_STATE(state, payload){
      state.quickView = payload
    },
    SET_CATEGORY(state, data){
      state.filters.q = ''; 
      state.filters.category = data.value;
    },
    SET_CHECKOFFERS(state, data){
      state.filters.only_offers = data
    },
    RESET_FILTERS(state){
      const stateBranch = JSON.parse(localStorage.getItem(`branchSelected`))
      state.filters = {
        q: '',
        page: 1,
        brands_selected: [],
        models_selected: [],
        prices: [],
        limit: 24,
        order_by: 'popularity',
        category: 0,
        place: stateBranch?.id || 1,
        rated: null,
        only_offers: 0,
        only_with_existence: null,
        optional_category_filter: 1
      }
    },
    SET_VALUES_FILTERS(state, data){
      state.valueFilters = data
    },
    SET_Q_FILTER(state, data) {
      state.filters.q = data
    },
    SET_PLACE_FILTER(state, data) {
      state.filters.place = data
    },
    SET_SINGLE_VIEW_PRODUCT(state, data){
      state.singleProductsView = data
    }
};
