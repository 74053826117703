

export default {
    SET_PRODUCTS(state, products) {
        state.products = products || []
    },
    SET_PRODUCTS_WITH_QUANTITIES(state, products) {
        state.productsWithQuantities = products || []
    },
    REMOVE_PRODUCT(state, codarticulo) {
        state.products = state.products.filter((product) => product.cod_articulo !== codarticulo)
    },
    CLEAR_CART(state) {
        state.products = [];
        state.guestAsk = 0;
        state.cart = {total_amount: 0}
    },
    ADD_PRODUCT(state, {product, quantity}) {
        product.quantity = quantity;
        state.products.push(product)
    },
    UPDATE_QUANTITY(state, {codarticulo, quantity, totalAmount}) {
        let index = state.products.findIndex((product) => product.cod_articulo === codarticulo)
        state.products[index].quantity = quantity;
        state.products[index].total_amount = totalAmount;
    },
    ADD_NOTE_TO_PRODUCT(state, {codarticulo, note}){
        let index = state.products.findIndex((product) => product.cod_articulo === codarticulo)
        state.products[index].note = note;
    },
    SET_CART(state, cart){
        state.cart = cart
    },
    SET_LOADING_CART(state, payload){
        state.loadingCart = payload
    },
    SET_QUOTE_CREATED(state, payload) {
        state.quoteCreated = payload
    }, 
    UPDATE_QUOTE_CREATED_SHOW(state, show) {
        state.quoteCreated.show = show
    }
};
