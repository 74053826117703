import { mapGetters } from "vuex";


export const productMixin = {
    data(){
        return {
            wasIncrement: false,
            showNote: false,
            note:  "",
            quantity: 0,
            interval: null,
            loadingNote: false,
        }
    },
    computed: {
        ...mapGetters({
            employee: "auth/employee",
            isMultiplePlace: "page/isMultiplePlace",
            isLogged: "auth/isLogged",
            userData: "auth/userData",
            branchSelected: "branches/branchSelected",
            isPageDisable: "common/isPageDisable",
            lists:"wishlist/lists",
            getProducts: "cart/getProducts",
            productsWithQuantities: "cart/productsWithQuantities",
            employeeActiveCode: 'auth/employeeActiveCode',
            selectedQuotation: "account/selectedQuotation",
            changedQuotes: "account/changedQuotes",
            editingList: "wishlist/editingList",
            valueFilters: "products/valueFilters",
            mobile: "common/mobile"
        }),
    },
    methods: {
        getImages(images){
            if (images) {
                if (images.length > 0) {
                    return images[0].picture_url || require('../../assets/images/no-available-icon.svg');
                }
            }
            return require('../../assets/images/no-available-icon.svg');
        },
        async updateQuantity(quantity, product, quick) {// Limpiar el timeout
            let validation = true;

            // validates if there is a customer selected when the logged user is an employee
            if(this.employee?.entity?.activated_employee && !this.employeeActiveCode){
                validation = false;
            }

            if (validation) {
                clearTimeout(this.interval);
                if(this.isPageDisable){
                    this.$store.commit("common/SET_DISABLE_MODAL", true)
                    return
                }
                let findOldQuantity = this.productsWithQuantities.find(item => item.code == product.codarticulo)?.quantity || 0
                const quantityState = {
                    title: this.quantity > quantity ? 'product.product_quantity_decreased' : 'product.product_added',
                    decrement: this.quantity > quantity
                } //setting the title first   
                this.quantity = quantity; //then updating the local quantity.
                if(parseInt(product.existence) == 0 ){
                    this.$emit("deleteProduct", product)
                    return
                }

                if(parseFloat(quantity) >= parseInt(product.existence)) {
                    this.quantity = parseInt(product.existence)
                }
                // Nuevo estructura para soportar el product service.
                let obj = {
                    product: product,
                    quantity: quantity,
                    total_amount: 0,
                    cod_articulo: product.codarticulo,
                }
                // <---------------------> finish
                
                // <---------------------> actualiza el carrito localmente.
                if (this.getProducts.findIndex((productIndex) => productIndex.product.codarticulo == product.codarticulo) == -1) {
                    if(this.selectedQuotation){
                        this.$store.commit('account/ADD_PRODUCT_TO_QUOTATION', { id: this.selectedQuotation, product: product, quantity: quantity})
                    }
                    this.$store.commit("cart/ADD_PRODUCT", { product: obj, codarticulo: product.codarticulo, quantity })
                } else{
                    if(this.selectedQuotation){
                        this.$store.commit('account/UPDATE_QUANTITY_PRODUCT_QUOTATION', {
                            id: this.selectedQuotation, 
                            product: product.codarticulo, 
                            quantity: quantity
                        });
                    }
                    this.$store.commit("cart/UPDATE_QUANTITY", { codarticulo: product.codarticulo, quantity })
                }
                // <---------------------> finish

                // <–--------------------> delay para que solo se haga un request, y luego compara con el backend.
                this.loadingGroupAction = true;
                this.$store.commit("cart/SET_LOADING_CART", true)
                this.interval = setTimeout(async () => {
                    try {
                        this.loading = true;
                        await this.$store.dispatch("cart/updateQuantity", { product: obj, quantity: this.quantity });
                        let data = {
                            type: 'addProduct',
                            title: quantityState.title,
                            show: true,
                            quantity: this.quantity,
                            img: this.getImages(product.imageGallery),
                            product: product,
                            quick: quick,
                            decrement: quantityState.decrement,
                            oldQuantity: findOldQuantity
                        }
                        this.$store.commit("common/SET_NOTIFY", data);
                    } catch (error) {
                        console.log(error);
                        let notify = { text: 'common.notification.unexpected_error', type: 'danger', show: true}
                        this.$store.commit("common/SET_SHORT_NOTIFY", notify)
                    } finally{
                        // console.log(JSON.stringify(this.getProducts.length));
                        if(this.mobile)
                        window.AddToCartEvent.postMessage(JSON.stringify(this.getProducts.length));

                        this.loading = false;
                        this.loadingGroupAction = false;
                        if(!this.userData?.entity.activated_customer){
                            this.$store.commit("cart/SET_LOADING_CART", false)
                        }
                    }
                }, this.groupAction ? 0 : 2000);
                // <---------------------> finish
            } else {
                let notify = { text: `${this.$t("common.notification.not_customer_selected")} :(`, type: 'warning', show: true };
                this.$store.commit("common/SET_SHORT_NOTIFY", notify);
                return;
            }
        },
        async increment(product, quick){
            if(this.isPageDisable) {
                this.$store.commit("common/SET_DISABLE_MODAL", true);
                return;
            }
            if(!product.price) {
                let notify = { text: `El producto no tiene precio configurado  :(`, type: 'danger', show: true }
                this.$store.commit("common/SET_SHORT_NOTIFY", notify)
                return;
            }
            let quantity = this.getQuantity(product) + 1;
            if (quantity > parseInt(product.existence)) {
                quantity = parseInt(product.existence);
            }
            if (product.existence <= 0 || product.existence == this.getQuantity(product) || product.existence == quantity - 1 || !product.existence) {
                let notify = {
                    contentHTML: `
                        <span class="size-14">
                            ${this.$t("cart.this_product_has_no_quantity")} <b>${this.$options.filters.textFormat(this.branchSelected.description)}</b> :(
                        </span>
                    `,
                    type: 'warning', show: true
                }
                this.$store.commit("common/SET_SHORT_NOTIFY", notify)
                return;
            }
            await this.updateQuantity(quantity, product, quick);
            return true
        },
        async decrement(product, quick){
            if(this.isPageDisable){
                this.$store.commit("common/SET_DISABLE_MODAL", true)
                return
            }
            let verify = this.getQuantity(product) 
            if(verify === 1){
                this.$emit("deleteProduct", product)
            }else{
                let quantity = verify - 1;
                this.updateQuantity(quantity, product, quick);
            }
        },
        removeProduct(){
            this.$store.dispatch("cart/remove_all", this.product.codarticulo);
            if(this.mobile);
            window.AddToCartEvent.postMessage(JSON.stringify(this.getProducts.length));
        },
        // Cambiando el computed de price por un método
        getQuantity(data){
            let quantity = 0;
            if (this.getProducts.length) {
                const product = this.getProducts.find(product => product.product.codarticulo == data.codarticulo);
                if (product){
                    quantity = product.quantity
                }
            }
            return quantity;
        },
        addToWishList(product) {
            if(this.isPageDisable || this.employeeActiveCode){
                this.$store.commit("common/SET_DISABLE_MODAL", true);
            }else if(!this.isLogged){
                this.$store.commit("auth/OPEN_MODAL", true);
            }  else{
                this.$store.commit("wishlist/SELECT_PRODUCT", product);
            }
        },
        checkInFavorites(product) {
            for(let i = 0; i < this.lists.length; i++){
                const list = this.lists[i];
                for (let i = 0; i < list.products.length; i++) {
                    const element = list.products[i];
                    if (element.code === product.codarticulo) {
                        return true
                    }
                    continue;
                }
            }
            return false
        },
        async saveNote(new_note){
            let quantity = this.getQuantity(this.product);
            const action = new_note ? 'added':'updated';
            try {
                this.loadingNote = true;
                await this.$store.dispatch('cart/saveNoteByProduct', { product: this.product, note: this.note, quantity });
                this.addNote = false;
                let notify = { title: this.$t('product.note_'+action+'_title'), text: this.$t('product.note_'+action+'_text'), type: 'success', show: true };
                this.$store.commit("common/SET_SHORT_NOTIFY", notify);
            } catch (error) {
                let notify = {
                    text: 'common.notification.unexpected_error',
                    type: 'danger',
                    show: true,
                }
                this.$store.commit("common/SET_SHORT_NOTIFY", notify)
            } finally{
                this.loadingNote = false;
            }
        },
        getPriceFromCart(data){            
            if (this.getProducts.length) {
                const product = this.getProducts.find(product => product.cod_articulo == data.codarticulo);

                // <-------------------- VALIDACION DE OFERTA ---------------------->
                const applyForOffer = data?.special_price > 0 && (this.getQuantity(data) >= data.quantity_required_special_price);
                if(applyForOffer) return product?.product.special_price
                else return product?.product.price ?? data.price;
            }
            return data.price
        },
        isProductChanged(code, quotation){
            let find = this.changedQuotes.find(item => item.quotation == quotation)
            if(find){
                let product = find.products.find(item => item.codarticulo == code)
                return product
            }
        },
        getSpecial(product){
            if(product.special_price && (this.getQuantity(product) >= product.quantity_required_special_price)){
                return product.special_price
            } else {
                return 0
            }
        },
        getWishListName(wishList_id) {
            if (wishList_id) return this.lists[this.lists.findIndex(({_id}) => _id === wishList_id)].name
        },
    },
}
