<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 23.948 16.83">
        <g id="Group_602" data-name="Group 602" transform="translate(-1687.553 -3581.437)">
            <path id="Union_8" data-name="Union 8" d="M295.465,258.773q-3.885-.007-7.771,0h-2.156q-4.964,0-9.929,0a1.441,1.441,0,0,1-1.436-.875.341.341,0,0,1,.158-.513c2.85-1.794,5.693-3.6,8.544-5.387a.532.532,0,0,1,.461.014c.563.341,1.11.708,1.652,1.08a.866.866,0,0,0,1.078.007c.544-.373,1.105-.718,1.645-1.094a.4.4,0,0,1,.516,0q4.242,2.7,8.5,5.374a.359.359,0,0,1,.153.551,1.437,1.437,0,0,1-1.409.843Zm-21.412-12.888c2.58,1.7,5.13,3.378,7.731,5.092l-7.731,4.887Zm15.227,5.092L297,245.889v9.969Zm-4.026.572q-5.45-3.6-10.913-7.186a.389.389,0,0,1-.147-.595,1.455,1.455,0,0,1,1.426-.824q4.965.008,9.931,0,4.942,0,9.886,0a1.485,1.485,0,0,1,1.391.737c.179.3.17.5-.175.729-2.822,1.837-5.63,3.7-8.441,5.549-.8.528-1.6,1.049-2.4,1.588a.431.431,0,0,1-.561,0Z" transform="translate(1414 3338.994)" stroke="rgba(0,0,0,0)" stroke-width="1"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'iconEmail',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>
</style>
