
export default () => ({
    addresses: [],
    addressSelected: JSON.parse(localStorage.getItem('addressSelected')) || null,
    orderStatus: [],
    cards: [],
    addressAvailableList: JSON.parse(localStorage.getItem('addressAvailableList')) || [],
    invoice: JSON.parse(localStorage.getItem('invoice')) || null,
    quotes: {
        data: []
    },
    changedQuotes: JSON.parse(localStorage.getItem('changedQuotes')) || [],
    selectedQuotation: localStorage.getItem('selectedQuotation') || null,
    focusQuotation: false,
    carrierSelected: []
});