<template>
    <div :class="{'animation-close': animationClose}" class="notify notify-animation-desk"  @mouseover="pauseNotify" @mouseleave="counter = 0">
        <div class="d-flex">
            <div class="container-img-product position-relative pe-2">
                <wrapper-image v-if="notify.type === 'buyAgain' || (inWishList && notify.product.length)" :images="notify.product" :wrapperHeight="90" :wrapperWidth="90" :imgHeight="43" /> 
                <image-product v-else :source="notify.img" :styleObject="{objectFit: 'contain'}" class="me-3 img-product-notify" :key="imageProduct"/>
            </div>
            <div class="notify-desc d-flex flex-column align-items-start text-limited" :style="{maxWidth: inWishList ? '150px':'168px'}">
                <h5 class="title-16 mb-0 text-limited w-100" :class="{'weight-300': inWishList}">
                    {{$t(notify.title)}}
                </h5>
                <p v-if="inWishList" class="d-flex color-dark-main-navy weight-600 w-100 m-0 mt-1" :class="{'color-number': manyLists}" @click="closeWishListModal" 
                    data-toggle="tooltip" data-placement="top" :title="wishlistNames">
                    <span class="d-block cursor-pointer text-limited" :class="manyLists ? 'many-lists':'single-list'">
                        {{notify.wishList}}
                    </span>
                    <span v-if="manyLists" class="d-block color-blue-600 size-14 ms-1 cursor-pointer">
                        (+{{notify.many.length - 1}})
                    </span> 
                </p>
                <p v-else class="subtitle-14 weight-300 text-limited w-100 m-0 mt-1 color-dark-gray-300">
                    {{notify.product.description | textFormat}}
                </p>
                <h6 v-if="!inWishList" class="subtitle-14 text-limited color-dark-gray-200 weight-600" style="max-width: 168px;">
                    {{ notify.type == 'buyAgain' ? $t("account.all_products_have_been_added_to_your_cart") : `${$t("common.quantity")} ${parseFloat(notify.quantity)}`}}
                </h6>
                <main-button id="undo" title="account.undo" @click="undo" class="mt-1" :class="{'mt-auto': notify.type === 'buyAgain' || inWishList}" :disabled="disabled"/>
            </div>
            <icon-check-circle v-if="notify.quantity == 1 || inWishList" class="mb-auto ms-auto" :size="28" color="#68B84E" />
            <h4 v-else class="notify-svg subtitle-14 align-items-center mb-0">×{{notify.quantity}}</h4>
        </div>       
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { productMixin } from '../../../modules/products/helpers/mixin/product-mixin'
import MainButton from "../buttons/MainButton.vue"
import WrapperImage from '../images/WrapperImage.vue'
import ImageProduct from '../../../modules/products/components/Image.vue'
import IconCheckCircle from '../../../modules/account/assets/svg/iconCheckCircle.vue'

export default {
    name: "MainNotify",
    components: { MainButton, ImageProduct, WrapperImage, IconCheckCircle},
    computed: {
        ...mapGetters({
            notify: "common/notify",
            lastCreated: "wishlist/lastCreated",
            lists: "wishlist/lists"
        }),
        manyLists(){ return this.notify.many.length > 1},
        inWishList(){ return this.notify.type === 'wish-list' },
        wishlistNames(){
            let names = '';
            if (this.notify.many){
                this.notify.many.map((list, index) => { //parsing al list names into the same string adding a comma until the last one.
                    const list_name = this.getWishListName(list)
                    index === (this.notify.many.length - 1) ? names += list_name : names += list_name+', ';
                });
            }
            return names;
        }
    },
    data() {
        return {
            counter: 6,
            interval: 0,
            animationClose: false,
            imageProduct: 0,
            disabled: false
        }
    },
    mixins:[productMixin],
    methods: {
        pauseNotify(){
            clearInterval(this.interval);
        },
        async undo(){
            this.disabled = true;
            if (this.inWishList) {
                if (this.notify.action == "wishlist-created") { //if we just created a wishlist
                    await this.$store.dispatch('wishlist/destroyList', this.lastCreated._id);
                }else if(this.notify.action == "wishlist-modified") { //if we modify it an existing one
                    this.lastCreated.map(async ({list_id, product_code, action, name}) => { //this was the store value that we created while toggling the products existence between lists on AddToWishList.vue
                        if(action === 'to-add'){ //these were the products that we deleted.
                            await this.$store.dispatch('wishlist/addProductToList', {list_id, product_code, product_note: '', name});
                        }else if(action === 'to-remove') {//these were the products that we added.
                            await this.$store.dispatch('wishlist/removeProductFromList', {list_id, product_code, product_note: '', name}) //in this action we will also store the list we had to delete entiretely
                        }
                    });
                    await this.$store.dispatch("wishlist/restoreDeletedLists"); //(hint) these were added when we deleted the last product from a list, therfore deleting the entire list.
                    this.$store.commit("wishlist/RESET_DELETED_LIST"); 
                    this.$store.commit("wishlist/RESET_CREATED_LIST"); // cleaning the state.
                }else {
                    return;
                }
                this.$emit("undo"); //for addToWishList inside CreateWishlist to fill the selectedLists again
            }else {
                this.disabled = true;
                let params = {
                    product: {
                        product: this.notify,
                        quantity: this.notify.oldQuantity,
                        total_amount: 0,
                        cod_articulo: this.notify.product.codarticulo
                    }
                };
                await this.$store.dispatch("cart/updateQuantity", params);
                let data = {
                    type: 'addProduct', 
                    title: 'common.', 
                    show: true,
                    quantity: this.notify.oldQuantity,
                    img: this.getImages(this.notify.product.imageGallery),
                    product: this.notify.product
                }
                this.$store.commit("common/SET_NOTIFY", data);
            }
        },
        closeWishListModal(){
            this.$router.push(this.notify.route)
            this.$store.commit("wishlist/SET_STATE_MODAL", false);
        }
    },
    watch: {
        counter(val) {
            if (val == 0) {
                this.$store.commit("common/RESET_NOTIFY");
                clearInterval(this.interval);
            }
            if(val == 1) {
                this.animationClose = true;
                this.$emit("removeCartTriangle");
            }
        },
        'notify.quantity'() {
            clearInterval(this.interval);
            this.counter = 6;
            this.interval = setInterval(() => this.counter--, 1000);
        },
        'notify.product.codarticulo'() {
            this.imageProduct += 1;
            clearInterval(this.interval);
            this.counter = 6;
            this.interval = setInterval(() => this.counter--, 1000);
        }
    },    
    created(){
        clearInterval(this.interval);
        this.interval = setInterval(() => this.counter--, 1000);
    },
    destroyed(){
        clearInterval(this.interval);
        this.counter = 0;
        this.$store.commit("wishlist/RESET_DELETED_LIST"); // in case the undo button never gets used we straight up delete them
        this.$store.commit("common/RESET_NOTIFY");
    },
}
</script>

<style scoped>
    .notify {
        position: absolute !important;

        top: 56px;
        left: auto;
        right:0;

        z-index: 11;

        background: white;
        width: 328px;

        box-shadow: 0px 0px 6px 1px #909da41c;
        padding: 15px;

        border-radius: 0px 0px 6px 6px;
        
        transition: var(--transition-1);
    }
    .notify-animation-desk {
        top: 52px;
        animation: notify 0.4s;
    }
    .animation-close {
        animation: close-notify 1s;
    }
    @keyframes notify {
        from {
            opacity: 0;
            transform: scale(0.7) translateX(50px);
        }
        to {
            opacity: 1;
            transform: scale(1) translateX(0px);
        }
    }
    @keyframes close-notify {
        0% {
            opacity: 1;
            transform: scale(1) translateX(0px);
        }
        25% {
            opacity: 1;
            transform: scale(1) translateX(0px);
        }
        50% {
            opacity: 1;
            transform: scale(1) translateX(0px);
        }        
        75% {
            opacity: 1;
            transform: scale(1) translateX(0px);
        }
        100% {
            opacity: 0;
            transform: scale(0.7) translateX(50px);
        }
    }
    .notify-svg {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-left: auto;
        transform: translateY(.5px);

        width: 28px;
        height: 28px;

        border-radius: 50%;
        background: #68B84E;

        fill: #fff;
        color: #fff;

        padding: 7px;
    }
    .img-product-notify {
        border: 1px solid var(--light-gray-100);
        border-radius: 7px;
        overflow: hidden;
    }
    #undo {
        line-height: 30px;
    }
    .container-img-product {
        width: 100px;
        border-radius: 7px;
        overflow: hidden;
        transition: var(--transition-1);
    }
    .image__wrapper::v-deep > img {
        width: 100px;
        height: 100px;
    }
    .many-lists {
        max-width: 110px;
    }
    .single-list {
        width: 120px;
        color: var(--blue-600);
        transition: var(--transition-1);
    }
    .single-list:hover {
        color: var(--main-red);
        text-decoration: underline;
    }
    p.color-number:hover span:last-child {
        color: var(--main-red) !important;
        text-decoration: underline;
    }
</style>
