<template>
    <section class="home-section">
        <div class="card-grid-container" v-if="viewPort('sm')">
            <div class="card-container mt-lg-0 cursor-pointer" v-for="({color, name, category}) in cards" :key="name" @click="to(category)">
                <div class="card-content position-relative overflow-hidden" :style="{backgroundColor: color}">
                    <h4 class="size-34 weight-600 color-white text-center">{{$t(`home.${name}`)}}</h4>
                    <div class="bg-circle position-absolute" :class="`bg-circle-${name}`" />
                    <div v-if="name === 'cables'" class="bg-circle bg-circle-cables-2 position-absolute" />
                    <img alt="Categorias" loading="lazy" :src="require(`./${name}.webp`)" :class="name" class="position-relative" style="z-index: 1;"/>
                </div>
            </div>
        </div>
        <div v-else>
            <div v-for="{name, top} in cards" :key="name">
                <component :is="name" :style="{marginTop: top}"/>
            </div>
        </div>
    </section>
</template>

<script>
import Cables from './svg/Cables.vue';
import Cellphones from './svg/Cellphones.vue';
import Headphones from './svg/Headphones.vue';
import Speakers from './svg/Speakers.vue';
export default {
    components: { Headphones, Cellphones, Cables, Speakers },
    name: 'MainCategoriesCard',
    data() {
        return {
            cards: [
                {
                    name: 'cellphones',
                    color: '#FF9A9A',
                    category: 1
                },
                {
                    name:  'headphones',
                    color: '#FFC881',
                    category: 16,
                    top: '6px'                    
                },
                {
                    name: 'cables',
                    color: '#8299F0',
                    category: 9,
                    top: '14px'
                },
                {
                    name: 'speakers',
                    color: '#CCE1A3',
                    category: 5,
                    top: '-13px'
                },
            ]
        }
    },
    methods: {
        to(category){
            this.$store.commit("products/set_category", {value: category});
            this.$router.replace({ path: "/shop" }).catch(() => { });
        }
    }
}
</script>
<style scoped>
@font-face {
    font-family: 'myriad pro regular';
    font-style: normal;
    font-weight: normal;
    src: local('Myriad Pro Regular'), url('../../../../common/assets/fonts/myriad-pro/MYRIADPRO-REGULAR.woff') format('woff');
}
.card-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, 262px);
    justify-content: center;
    grid-auto-rows: 420px;
    gap: 48px;
}
.card-content {
    height: 420px;
    border-radius: 20px;
}
.card-content h4 {
    font-family: 'Myriad Pro Regular' !important;
    padding-top: 60px;
    transition: var(--transition-6);
}
.card-content:hover h4 {
    transform: scale(1.14) translateY(-10px);
    opacity: 0.7;
}
.bg-circle, .bg-circle-cables-2 {
    border-radius: 312rem;
    background-color: #FFFFFF40;
    transition: var(--transition-6);
}
.card-content img {
    transition: var(--transition-6);
}
/* cellphones hover */
.bg-circle-cellphones {
    width: 280px;
    height: 280px;
    top: 220px;
    right: 50%;
    transform: translateX(50%);
}
.card-content:hover .bg-circle-cellphones {
    transform: translateX(50%) scale(1.33);
}
img.cellphones {
    width: 170px;
    left: 50%;
    transform: translate(-50%, 15%);
}
.card-content:hover img.cellphones {
    transform: translate(-50%, 0%) scale(0.8);
}
/* headphone hover */
.bg-circle-headphones {
    width: 390px;
    height: 390px;
    right: 50%;
    transform: translate(50%, -150px);
}
.card-content:hover .bg-circle-headphones {
    transform: translate(50%, -50px) scale(0.75);
}
img.headphones {
    width: 230px;
    left: 50%;
    transform: translate(-50%, 15%);
}
.card-content:hover img.headphones {
    transform: translate(-50%, 10%) scale(1.1);
}
/* cable hover */
.bg-circle-cables, .bg-circle-cables-2 {
    width: 380px;
    height: 380px;
    top: 90px;
    right: 50%;
}
.bg-circle-cables {
    transform: translateX(-10%);
}
.bg-circle-cables-2 {
    transform: translateX(110%);
}
.card-content:hover .bg-circle-cables {
    transform: translateX(10%);
}
.card-content:hover .bg-circle-cables-2 {
    transform: translateX(90%);
}
img.cables {
    width: 180px;
    left: 50%;
    transform: translate(-50%, 2%);
}
.card-content:hover img.cables {
    transform: translate(-50%, -4%);
}
/* speakers hover */
.bg-circle-speakers {
    width: 250px;
    height: 250px;
    right: 50%;
    transform: translate(50%, 2%);
}
.card-content:hover .bg-circle-speakers {
    transform: translate(50%, 6%) scale(1.16);
}
img.speakers {
    width: 132.5px;
    left: 50%;
    transform: translate(-50%, 4%);
}
.card-content:hover img.speakers {
    transform: translate(-50%, -9%) scale(0.8);
}
@media (max-width: 576px) {
    .card-container {
        width: 268px !important;
    }
}
@media (min-width: 576px) {
    .card-grid-container {
        grid-template-columns: repeat(auto-fit, 238px);
        gap: 38px;
    }
}
@media (min-width: 768px) {
    .card-content:hover .bg-circle-speakers {
        transform: translate(50%, 8%) scale(1.16);
    }
    .card-content:hover img.speakers {
        transform: translate(-50%, -8%) scale(0.83);
    }
    .card-grid-container {
        grid-template-columns: repeat(auto-fit, 262px);
        gap: 48px;
    }
}
@media (min-width: 992px) {
    .card-grid-container {
        grid-template-columns: repeat(4, 22.9%);
        gap: 27px;
    }
}
@media (min-width: 1200px) {
    .card-grid-container {
        grid-template-columns: repeat(4, 20.217%);
        gap: 70px;
    }
}
@media (min-width: 1400px) {
    .card-grid-container {
        gap: 83px;
    }
}
</style> 