<template>
    <div class="modal-main-container" id="modal-main-container" @click="preventClick" :class="[{'closed-container': close}]">
        <div class="modal-main" :class="[classes, customClasses]">
            <div v-if="loading" class="modal-loading">
                <lottie :options="defaultOptions" :height="70" :width="70" />
            </div>
            <div class="d-flex align-items-center justify-content-end">
                <icon-close id="close" class="ms-auto close mb-2" @click.native="closeModal" />
            </div>
            <template v-if="!hideHeader">
                <h5 v-if="title" class="weight-500 mb-0 mb-md-2"> {{$t(title)}} </h5>
                <p v-if="description" class="size-15 weight-400 color-dark-gray-400 my-3" style="line-height: 1.2"> {{$t(description)}} </p>    
            </template>
            <div v-if="fixedHeader" class="modal-main-content">
                <slot />    
            </div>
            <slot v-else />        
        </div>
    </div>
</template>

<script>
import Lottie from "vue-lottie";
import animationData from '../../assets/animations/loading.json';
export default {
    name: 'MainModal',
    components: {
        IconClose: () => import("@/common/svg/iconClose"),
        Lottie
    },
    props: {
        title: { type: String },
        description: { type: String },
        fixedHeader: { type: Boolean },
        hideHeader: { type: Boolean },
        overflowVisible: {
            type: Boolean,
            default: false
        },
        backModal: { type: Boolean },
        loading: {
            type: Boolean
        },
        classes: {
            type: Array
        },
        preventClose: {
            type: Boolean,
            default: false
        },
        noRebound: {
            type: Boolean,
            default: false
        },
        overflowHidden: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            rebound: false,
            initialID: '',
            maskClickID: '',
            defaultOptions: { animationData: animationData, loop: true },
            close: false
        }
    },
    methods: {
        preventClick: function (event) {
            clearTimeout(this.maskClickID) //clearing the las timeout when invoked
            if(event.target.id === "modal-main-container" && !this.backModal) {
                if(!this.preventClose){
                    this.closeModal()
                    return
                }
                if(!this.noRebound) {
                    this.rebound = true
                }
                this.maskClickID = setTimeout(() => {
                    this.rebound = false
                }, 300);
            }
            if(event.target.id === "close"){
                // this.$emit('closeModal')
            }
        },
        closeModal(){
            this.close = true;
            setTimeout(() => {
                this.$emit('closeModal')       
            }, 300);
        },
        disableScrolling(){
            let body = document.body;
            var y = window.scrollY;
            body.onscroll = function(){  
                window.scrollTo({top: y, behavior: 'instant'});
            };
        },
        enableScrolling(){
            document.body.onscroll = null;
        }
    },
    computed: {
        customClasses(){
            return {
                'rebound-click': this.rebound,
                'closed': this.close,
                'overflow-visible': this.overflowVisible
            }
        }
    },
    destoyed(){ //clearing both timeouts
        clearTimeout( this.maskClickID)
        clearTimeout( this.initialID )
    },
    beforeDestroy(){
        this.enableScrolling()
        if(window.innerWidth <= 576 && this.overflowHidden) document.body.style.overflow = 'auto'
    },
    created(){
        this.disableScrolling();
        if(window.innerWidth <= 576 && this.overflowHidden) document.body.style.overflow = 'hidden'
    }
}
</script>

<style scoped>
.modal-main-container {
    background: #2f35444d !important;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 11;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
    backdrop-filter: blur(4px) !important;
    animation: open-modal-container 0.3s;
}
.modal-main {
    background: white;
    position: relative;
    min-width: 400px;
    border-radius: 20px;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    margin: auto;
    /* max-height: 90%; */
    padding: 25px;
    animation: open-modal 0.3s;
    max-width: 1000px;
    overflow-y: auto;
}
.closed {
    animation: close-modal 0.3s;
    user-select: none;
}
.closed-container {
    animation: close-modal-container 0.3s;
}
@keyframes close-modal {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(0.95);
    }
}
@keyframes open-modal {
    from {
        transform: scale(0.95);
    }
    to {
        transform: scale(1);
    }
}
@keyframes close-modal-container {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
@keyframes open-modal-container {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.rebound-click {
    animation: rebound-click 0.3s !important;
}
@keyframes rebound-click {
    0% {
        transform: scale(1);
    }
    40% {
        transform: scale(1.05);
    }
    80% {
        transform: scale(0.96);
    }
    100% {
        transform: scale(1);
    }
}
.icon-close {
    cursor: pointer;
    fill: var(--gray-1001);
    transition: var(--transition-1);
}
.icon-close:hover {
    fill: var(--main-red);
}
.modal-open {
    transition: all 0.25s ease;
    animation: rebound 0.4s;
}
@keyframes rebound {
    0% {
        transform: scale(0.8);
    }
    40% {
        transform: scale(1.08);
    }
    80% {
        transform: scale(0.98);
    }
    100% {
        transform: scale(1);
    }
}
@media (max-width: 576px) {
    .modal-main {
        min-width: 350px;
        margin: auto 10px;
        padding: 20px;
        max-height: 98%; 
    }
    /* .close-container { padding-top: 20px; } */
}
.modal-loading {
    position: absolute;
    width: 100%;
    left: 0;
    background: #fff 1a;
    height: 100%;
    z-index: 5;
    backdrop-filter: blur(2px) !important;
    border-radius: 20px;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-main-content {
    overflow-y: auto;
    max-height: 70vh; 
}
</style>
