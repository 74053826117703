<template>
    <icon-base width="100%" viewBox="0 0 364 135">
        <defs>
            <clipPath id="a">
                <rect class="a" width="364" height="135" rx="20" transform="translate(0 0.001)"/>
            </clipPath>
            <pattern id="cellphones" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 500 500">
                <image href="../cellphones.webp" height="500" width="500"/>
            </pattern>
        </defs>
        <g transform="translate(-13 -31)">
            <rect class="a" width="364" height="135" rx="20" transform="translate(13 31)"/>
            <text class="b" transform="translate(127 117)">
                <tspan x="-96.995" y="0">Celulares</tspan>
            </text>
            <g class="c" transform="translate(13 31)">
                <circle class="d" cx="170" cy="170" r="170" transform="translate(138 -102)"/>
                <rect class="e" width="189" height="189" rx="11" transform="translate(188 14.001)"/>
            </g>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'Cellphones',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
}
</script>
<style scoped>
    .a{fill:#ff9a9a;}
    .b,.d{fill:#fff;}
    .b{font-size:44px;font-family:'Myriad Pro Bold';font-weight:600;}
    .c{clip-path:url(#a);}
    .d{opacity:0.25;}
    .e{fill:url(#cellphones);}
</style>
