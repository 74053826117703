import { http } from "../../../common/services/web-backend"
// import { apis } from "../../apis"
// import {parseResponse} from "../../../common/helpers/responses/parser";

export const orderService = {
  getOrders,
  getOrdersIntranet,
  createOrder,
  deleteOrden,
  calculateDelivery
};
async function getOrders(filterDate, branch_code = null, order_status_id = null, option_name = null, global_id = null, id = null, order_document_id = null, pageNumber) {
  try {
    const orders = await http.get('orders', { params: { filterDate, branch_code, order_status_id, option_name, global_id, id, order_document_id, page: pageNumber || 1, limit: 10} })
    return orders.data.data;
  }catch (e) {
    console.log(e)
    throw e
  }
}

async function getOrdersIntranet(filterDate, branch_code = null, order_status_id = null, option_name = null, global_id = null, id = null, order_document_id = null, pageNumber) {
  try {
    const orders = await http.get('invoices', { params: { filterDate, branch_code, order_status_id, option_name, global_id, id, order_document_id, created_from: "ERP", page: pageNumber || 1, limit: 10} })
    return orders.data.data;
  }catch (e) {
    console.log(e)
    throw e
  }
}

async function deleteOrden(id){
  try {
    const response = await http.delete('orders' + '/' + id)
    return response
  } catch (error) {
    throw error.response
  }
}

async function createOrder(params) {
  try {
    Object.keys(params).forEach(key => {
      if (!params[key]) {
        delete params[key];
      }
    });
    Object.keys(params.payments).forEach(key => {
      if (!params.payments[key]) {
        delete params.payments[key];
      }
    });
    let formdata = new FormData();
    formdata.append('products', JSON.stringify(params.products));
    formdata.append('option_id', params.option == 1 ? 'local_delivery' : params.option == 2 ? 'nacional_delivery' : 'pick_up');
    formdata.append('codplace', params.branch);
    formdata.append('payments', JSON.stringify(params.payments) );


    if(params.only_confirm)
    formdata.append('only_confirm', params.only_confirm ?? 0);

    if(params.address)
    formdata.append('address_id', params.address);
    
    if(params.option == 3)
    formdata.append('partner_id', params.partner ?? 0 );

    if (params.customer_name)
    formdata.append('customer_name', params.customer_name);

    if(params.quotation)
    formdata.append('quote_id', params.quotation);

    if(params.shipments)
    formdata.append('shipments', JSON.stringify(params.shipments));
    
    formdata.append('ignore_diff_in_total_amount', params.ignoreDiff ?? 0);
    formdata.append('total_amount', params.amount);

    if (params.note)
      formdata.append('note', params.note);

    if(params.rnc)
    formdata.append('rnc', params.rnc['Cédula/RNC']);
    if(params.tax_amount)
    formdata.append('tax_amount', params.tax_amount)
    
    const order = await http.post('orders', formdata);
    return order.data;

  }catch (e) {
    throw e.response ?? e
  }
}
async function calculateDelivery(products, option_id, address_id, quote_id, shipments){
  const payload = {
    products,
    option_id,
    address_id,
    shipments
  }

  if (quote_id) {
    payload.quote_id = quote_id
  }

  try {
    const response = await http.post('orders/calculateDelivery', payload)
    return response
  } catch (error) {
    throw error.response
  }
}
