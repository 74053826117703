<template>
    <div class="d-flex flex-column justify-content-center align-items-center without-results orderAnimation">
        <div class="mb-3">
            <slot name="main-icon"/>
        </div>
            <h6 class="mt-0 mb-2 text-center weight-700" style="color:#212E3D">{{ $t(title) }}</h6>
            <p class="subtitle-14 text-center" :class="[span ? '' : ' color-dark-gray-200']" style="line-height: 19px;">
                {{$t(description)}} <span v-if="span" class="color-dark-gray-200">{{$t(span)}}</span> <a v-if="action" @click="$emit('action')">{{$t(action)}}</a>
            </p>
        <main-button v-if="!noButton" id="main-button-results" :title="titleButton" icon @click="$emit('click')">
            <slot name="button-icon"/>
        </main-button>
    </div>
</template>

<script>
import MainButton from '../buttons/MainButton.vue'
export default {
    name: "WithoutResults",
    props: {
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        titleButton: {
            type: String,
        },
        noButton: {
            type: Boolean,
            default: false
        },
        action: {
            type: String
        },
        span: {
            type: String
        }
    },
    components:{
        MainButton
    }
}
</script>

<style scoped>
.without-results > p{
    max-width: 300px;
}
</style>
