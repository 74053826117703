<template>
    <div class="container-ad-lg cursor-pointer" @mouseenter="overAds1 = true" @mouseleave="overAds1 = false" v-if="getDimensions">
        <img loading="lazy" src="../../assets/images/advertisements/ads-1/img-3.webp" alt="img-3" class="advertisements-1-img-3 d-none d-md-block" :class="{active : overAds1}">
        <div class="col-md-12 advertisements-1 position-relative"
        @click="navigateTo({value: verifyUSTECH() ? 8 : 5})">
            <circle-1 :size="600" id="circle-1" class="d-none d-md-block"/>
            <circle-2 :size="600" id="circle-2" class="d-none d-md-block"/>
            <curve-line id="curve-line"/>
            <div class="row">
                <div class="col-md-6 content d-flex d-md-block justify-content-center align-items-center flex-column">
                    <h1 class="mb-0">{{$t('home.ads.speakers')}}</h1>
                    <h3 class="mb-0 mt-4">{{$t('home.ads.wireless_speakers')}} <span class="d-inline-block d-md-none">VIDVIE</span> </h3>
                    <h3 class="d-none d-md-block mb-0">VIDVIE</h3>
                    <h6>{{$t('home.ads.take_the_music_with_you_wherever_you_go')}}!</h6>
                    <h4 class="mt-4 mb-0 d-none d-md-block">{{$t('home.ads.get_up_to_40_off_discount')}}!</h4>
                    <img loading="lazy" src="../../assets/images/advertisements/ads-1/img-1-mini.webp" alt="img-1" class="d-block d-md-none" width="auto" height="auto">
                    <button class="btn-advertisements-1 py-1 mt-2 mt-md-5">{{$t('home.ads.buy_now')}}</button>
                </div>
                <div class="col-md-6 position-relative images">
                    <img loading="lazy" src="../../assets/images/advertisements/ads-1/img-1.webp" alt="img-1" class="img-1">
                    <img loading="lazy" src="../../assets/images/advertisements/ads-1/img-2.webp" alt="img-2" class="img-2 d-none d-md-block">
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <ads-01/>
    </div>
</template>

<script>
    import Circle1 from '../../assets/svg/ads/Circle1.vue'
    import Circle2 from '../../assets/svg/ads/Circle2.vue'
    import CurveLine from '../../assets/svg/ads/CurveLine.vue'
    import Ads01 from './svg/Ads01.vue'
    export default {
        name: "AdLg",
        props:{
            ad:{
                type: Object
            }
        },
        data(){
            return{
                overAds1: false
            }
        },
        computed:{
            english() {
                return this.$i18n.locale == "en";
            },
            getDimensions(){
                return window.matchMedia('(min-width: 768px)').matches
            }
        },
        components: { Circle1, Circle2, CurveLine, Ads01},
        methods:{
            navigateTo(category) {
                this.$store.commit("products/SET_CATEGORY", category);
                this.$router.replace({ path: "/shop" }).catch(() => { });
            },
        }
    }
</script>

<style scoped>
    .advertisements-1{
        background-color: #f5c99d;
        border-radius: 20px;
        overflow: hidden;
        padding: 3rem 4rem;
    }
    .advertisements-1 #circle-1{
        top: -73%;
        left: -23%;
    }
    .advertisements-1 #circle-1, .advertisements-1 #circle-2, .advertisements-1 #curve-line{
        position: absolute;
        transition: all 0.3s ease-in-out;
        opacity: 0.7;

    }
    .advertisements-1 #circle-2{
        bottom: -76%;
        right: -19%;
    }
    .advertisements-1 #curve-line{
        left: 39%;
        top: -42%;
        width: 900px;
    }
    .advertisements-1 .images > img, .advertisements-1-img-3{
        /* width: 300px; */
        position: absolute;
        transition: all 0.3s ease-in-out;
    }
    .advertisements-1-img-3{
        width: 340px;
        z-index: 1;
        right: 12%;
        top: 11%;
    }
    .advertisements-1 .images > .img-3{
        right: 4%;
        top: -20%;
    }
    .advertisements-1 .content >h1 {
        font-size: 112px;
        color: #b47a4b;
        font-family:'Myriad Pro Bold' !important;
        font-weight: 600;
    }
    .advertisements-1 .content > h3 {
        font-size: 41px;
        font-family:'Myriad Pro Semibold' !important;

    }
    .advertisements-1 .content > h3:first-child{
        margin-top: 34px;
    }
    .advertisements-1 .content > h6 {
        font-size: 23px;
        font-family:'Myriad Pro Light' !important;
    }
    .advertisements-1 .content > h4 {
        font-size: 27px;
        color:#964f14;
        font-family:'Myriad Pro Bold' !important;
        font-weight: 600;
    }
    .btn-advertisements-1 {
        background-color: #fff;
        border: none;
        border-radius: 50px;
        font-size: 25px;
        font-weight: 700;
        color: #d88d48;
        width: 240px;
    }
    .container-ad-lg:hover #circle-1{
        transform: translate(10px, 10px);
    }
    .container-ad-lg:hover #circle-2{
        transform: translate(-10px, -10px);
    }
    .container-ad-lg:hover h4{
        transition: all 0.3s ease-in-out;
        color: #DB7A2A;
    }
    .img-1{
        width: 420px;
        top: 5%;
        left: -18%;
    }
    .img-2{
        width: 340px;
        top: 25%;
        left: 49%;
    }
    .container-ad-lg:hover .img-1{
        transform: translate(-30px, 30px);
    }
    .container-ad-lg:hover .img-2{
        transform: translate(30px, -30px);
    }
    .advertisements-1-img-3.active{
        transform: translate(-53px, -27px);
    }
    .btn-advertisements-1{
        border: 1px solid transparent;
    }
    .btn-advertisements-1:hover{
        background-color: transparent;
        color: #fff;
        border: 1px solid #fff;
    }
        /* Media Query */
    @media (max-width: 1400px) {
        .advertisements-1 .images > .img-2{
            right: -5%;
            top: 30%;
        }
    }
        @media (max-width: 476px) {
        .advertisements-1 .content >h1{
            font-size: 60px;
            text-align: center;
        }
        .advertisements-1 #circle-1{
            widows: 100px;
        }
        .advertisements-1 .content > h3{
            font-size: 30px;
            margin-top: 5px;
            text-align: center;
        }
        .advertisements-1 .content > h6{
            font-size: 16px;
            text-align: center;
        }
        .btn-advertisements-1{
            font-size: 15px;
            /* max-width: 130px; */
            height: 45px;
            z-index: 1;
        }
    }
</style>
