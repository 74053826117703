<template>
    <div class="quantity-input-container position-relative overflow-hidden" :class="[{'opacity-3 no-drop': noExistence }, {'pe-none': loading}]">
      <div class="w-100 h-100 position-absolute d-flex justify-content-center align-items-center" style="background-color:#ffffffb3" v-if="loading">
        <half-circle-spinner :animation-duration="1000" :size="20" color="var(--main-red)"/>
      </div>
      <div class="d-flex align-content-center align-items-center justify-content-between">
        <button @click="decrement(product, quick)" class="btn-quantity size-20 p-0 m-0" :disabled="noQuantity" :class="[{'btn-disabled': noQuantity}]">
          <icon-minus :class="[{'pointer-events-none': noQuantity }]"/> <!-- :class="{'pointer-events-none': noExistence}" -->
        </button>
        <input v-model.lazy="newQuantity" v-numeric-only type="number" min="0" :disabled="noExistence" class="quantity-input text-center h-100" @change="validate" 
        :class="[{'opacity-3': noExistence }, {'out-of-stock': outOfStock}]" @blur="validateBlur"/>
        <button class="btn-quantity size-20 p-0 m-0" @click="increment(product, quick)" :class="[{'btn-disabled': disabled }, {'no-drop': outOfStock }]" >
          <icon-plus /> <!-- :class="{'pointer-events-none': disabled || outOfStock}" -->
        </button>
      </div>
    </div>
</template>
<script>
import { productMixin } from "@/modules/products/helpers/mixin/product-mixin.js";
import IconPlus from "../../../common/svg/iconPlus.vue";
import IconMinus from '../assets/svg/iconMinus.vue';
import { mapGetters } from "vuex";
import { HalfCircleSpinner } from 'epic-spinners'
import { AlgoliaService } from "../services/algolia-service";
const service = new AlgoliaService()

export default {
  name: "ProductQuantityInput",
  components: {
    IconMinus,
    IconPlus,
    HalfCircleSpinner
  },
  props: {
    product: {
      type: Object
    },
    quick: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newQuantity: 0,
      oldQuantity: 0,
      loading: false,
      algoliaCartData: {
          product_code: '',
          queryID: ''
      }
    }
  },
  watch: {
    'loading'(){
      this.algoliaCartData.product_code = this.product.codarticulo
      this.algoliaCartData.queryID = this.valueFilters.queryID
      if(this.loading){
        if(this.isLogged && this.configurations?.general?.web_page?.activate_algolia_search){
          this.algoliaCart()
        }
      }
    },
    newQuantity(val, old) {
      this.oldQuantity = old
      // if(!Number.isInteger(val)){
      //   this.newQuantity = 0;
      //   this.quantity = 0;
      //   const notify = { text: 'Caracter no aceptado, digite un numero  :(', type: 'danger', show: true }
      //   this.$store.commit("common/SET_SHORT_NOTIFY", notify)
      // }
    },
    getQuantityLocal(val) {
      this.newQuantity = val;
    }
  },
  created(){
    this.newQuantity = this.getQuantityLocal;
  },
  mixins: [productMixin],
  directives: {
    numericOnly: { // Definición de directiva
      bind: (el) => {
        // el.addEventListener('keyup', (e) => {
        //   if (e.target.value === '') e.target.value = 0;
        // })
        el.addEventListener('keydown', (e) => {
          if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
              // Allow: Ctrl + A
              (e.keyCode === 65 && e.ctrlKey) ||
              // Allow: Ctrl + C
              (e.keyCode === 67 && e.ctrlKey) ||
              // Allow: Ctrl + X
              (e.keyCode === 88 && e.ctrlKey) ||
              // Allow: home, end, left, right
              (e.keyCode >= 35 && e.keyCode <= 39)) {
            // let it happen, don't do anything
            return;
          }
          // Ensure that it is a number and stop the keypress
          if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) e.preventDefault()
        })
      }
    }
  },
  computed: {
    ...mapGetters({
        isLogged: "auth/isLogged",
        configurations: "common/configurations"
    }),
    noExistence() {
      return this.product.existence == 0;
    },
    noQuantity() {
      return this.getQuantityLocal === 0;
    },
    disabled() {
      if(this.product.existence){
        return this.getQuantity(this.product) >= this.product.existence;
      }else {
        return true
      }
    },
    outOfStock(){
      return this.getQuantityLocal > this.product.existence;
    },
    getQuantityLocal(){
      let quantity = 0;
      if (this.getProducts.length) {
        const product = this.getProducts.find(product => product.product.codarticulo === this.product.codarticulo);
        if (product) quantity = product.quantity;
      }
      return quantity;
    },
  },
  methods:{
    async algoliaCart(){
        try {                
            const response = await service.algoliaCart(this.algoliaCartData)
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    },
    async validate(){
      if(!this.product.price){
        let notify = { text: 'El producto no tiene precio configurado  :(', type: 'danger', show: true }
        this.$store.commit("common/SET_SHORT_NOTIFY", notify)
        this.newQuantity = 0;
        return;
      }
      
      let quantity = parseInt(this.newQuantity);
      let existence = parseInt(this.product.existence);

      if (quantity == 0) {
        await this.$store.dispatch("cart/removeProduct", { cod_articulo: this.product.codarticulo, quantity: 0});
        this.$emit("deletedProduct");
      }

      if (quantity !== this.oldQuantity) {
        if(quantity > existence){
          let notify = {
            show: true,
            type: 'warning',
            contentHTML: 
            ` <p class="size-14 color-dark-gray-200 mb-0" style="line-height: 18px;">
                Lamentablemente no contamos con la cantida de 
                  <span class="color-main-red">${quantity}</span>, solo hay disponible 
                  <span class="color-main-navy">${existence}</span>.
              </p>
            `
          }
          this.$store.commit("common/SET_SHORT_NOTIFY", notify);
          quantity = existence;
          this.newQuantity = quantity;
        }
        if(quantity == '' || !quantity || quantity < 0) {
          quantity = 0;
          if (this.getQuantity(this.product) > 0) this.updateQuantity(quantity, this.product, this.quick);
        } else {
          this.updateQuantity(quantity, this.product, this.quick);
        }
      }
    },
    validateBlur(){
      if(this.newQuantity == '')
        this.newQuantity == 0
    }
  }
}
</script>

<style scoped>
input[type=number]::-webkit-inner-spin-button,-moz-inner-spin-button,
input[type=number]::-webkit-outer-spin-button, -moz-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance:textfield;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
}
input:disabled {
  background: transparent;
}
.no-drop {
  cursor: no-drop !important;
}
.quantity-input-container {
  display: inline-block;
  border: 1px solid var(--gray-1001);
  border-radius: 50px !important;
  font-size: 14px;
}
.quantity-input-container div {
  height: 32px;
}
.quantity-input {
  width: 40%;
  border-left: 1px solid var(--gray-1001) !important;
  border-right: 1px solid var(--gray-1001) !important;
  border: 0;
}
.quantity-input:focus {
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important; 
  outline: none;
}
.btn-disabled {
  cursor: no-drop !important;
  opacity: 0.3;
}
button.btn-quantity:hover svg {
  fill: var(--main-red) !important;
}
button.btn-disabled:hover svg {
  fill: var(--light-main-navy) !important;
}
.btn-quantity {
  background: transparent;
  border: none;
  width: 30%;
}
.btn-quantity svg {
  transition: var(--transition-1) !important;
}
.btn-quantity:hover svg {
  fill: var(--main-red);
}
.btn-disabled:hover svg {
  pointer-events: none;
}
input.out-of-stock {
  color: var(--dark-main-red) !important
}
.icon-minus {
  width: 10px;
  height: 10px;
}
.icon-plus {
  width: 12px;
  height: 12px;
}
@media (min-width: 768px) {
  .icon-minus {
    width: 8px;
    height: 8px;
    margin-top: -2px;
  }
  .icon-plus {
    width: 9px;
    height: 9px;
    margin-top: -3px;
  }
  .icon-minus, .icon-plus {
    fill: var(--light-main-navy) !important;
  }
}
@media (max-width: 767px) {
  .quantity-input-container div {
    height: 24px !important;
    width: 85px;
  }
  .quantity-input-container {
    border: none !important;
  }
  .btn-quantity {
    border-radius: 3.12rem !important;
    border: 1px solid var(--gray-1001);

    height: 24px;
    width: 24px;

    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  .quantity-input-container input {
    border: none !important;
  }
  .icon-minus, .icon-plus {
    fill: var(--gray-1001) !important;
  }
  .quantity-input-container button {
    transition: var(--transition-1);
  }
  .quantity-input-container button:hover {
    background-color: var(--main-red);
    border-color: var(--main-red);
  }
  .quantity-input-container button:hover svg {
    fill: white !important;
  }
  .quantity-input-container button.btn-disabled:hover {
    background-color: white;
    border-color: var(--gray-1001);
  }
  .quantity-input-container button.btn-disabled:hover svg{
    fill: var(--gray-1001) !important;
  }
}

</style>
