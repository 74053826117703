<template>
    <div v-if="show" class="alert bg-yellow-50 alertPadding col-sm-12" role="alert" :class="{'shaking': animation}">
        <div class="p-0 p-md-2 py-2 py-md-1 d-flex align-items-center" :class="{'justify-content-between' : showCounter || actionTitle}">
            <div class="d-inline-flex align-items-center">
                <icon-warning :size="25" class="me-3 flex-shrink-0"  :class="{'pulse-warning': vibration}"/>
                <div class="d-flex align-items-center">
                    <p v-if="!content" class="subtitle-14 color-warning-900 weight-400 mb-0" style="line-height: 18px;">
                        {{$t(title)}}.
                    </p>
                    <slot v-else/>
                </div>
            </div>
            <div class="ml-auto container-counter" v-if="showCounter">{{ counter }}</div>
            <a href="#" v-if="actionTitle" @click.prevent="$emit('handlerAction')" class="size-14">{{actionTitle}}</a>
        </div>
    </div>
</template>
<script>
import iconWarning from '../../svg/iconWarning.vue';
export default {
    components: { iconWarning },
    name: 'AlertWarning',
    props:{
        title: { type: String },
        show: { type: Boolean },
        size: { type: Number },
        content: { type: Boolean },
        actionTitle: { type: String },
        showCounter: {
            type: Boolean,
            default: true
        },
        shaking: {
            type: Boolean,
            default: false
        },
        vibration: {
            type: Boolean,
            default: false
        },
        animationReplay: {
            type: Boolean,
            default: false
        },
    },
    data(){
        return {
            interval: 0,
            counter: 10,
            animation: false,
        }
    },
    watch:{
        show(){
            this.animation = this.shaking
            setTimeout(() => {
                this.animation = false
            }, 1000);
            if(this.show && this.showCounter){
                window.scrollTo(0,0);
                this.interval = setInterval(() => this.counter--, 1000);
            }
            if(!this.show){
                this.counter = 10
                clearInterval(this.interval);
            }
        },
        counter(val) {
            if (val == 0) {
                this.$emit('showAlert', false)
                clearInterval(this.interval);
            }
        },
        animationReplay(){
            this.animation = true
            setTimeout(() => {
                this.animation = false
            }, 600);        }
    },
}
</script>
<style>
.st58 {
    fill: #ffd971;
}
.alertPadding {
    position: relative;
    padding: 0.3rem 1.5rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.alert-warning{
    animation: slide-down 0.5s;
}
.container-counter{
    border: 2px solid var(--warning-900);
    color: var(--warning-900);
    border-radius: 50%;
    padding: 5px;
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.shaking{
    animation: 0.2s horizontal-shaking;
    animation-iteration-count: 3;
}

@keyframes vibration {
    0% {
        transform: rotate(-5deg);
    }
    50% {
        transform: rotate(5deg);
    }
    100% {
        transform: rotate(-5deg);
    }
}

.pulse-warning {
    animation: vibration 0.2s ease-in-out infinite;
}

@keyframes horizontal-shaking {
    0% { transform: translateX(0) }
    25% { transform: translateX(5px) }
    50% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    100% { transform: translateX(0) }
}
</style>
