
import { http } from "../../../common/services/node-service";
import { parseResponse } from "../../../common/helpers/responses/parser";
// import axios from "axios"
export class ProductService{
    constructor(){
        this.http = http
    }
    async getDealsProduct(){
        try {
            const products = await http.get('/products/hots');
            const res = parseResponse(products.data);
            return (res.data);
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }
    async getProducts(params){
        try {
            // #####  #####
            const customParams = {...params};
            if(process.env.VUE_APP_VIDVIE == '2'){
                customParams.brands_selected = 'VIDVIE'
            } 
            // ##### ##### 

            const products = await http.get('/products', { params: customParams});
            const res = parseResponse(products.data);
            return (res.data)
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }
    async getProduct(id, codplace, code){
        try {
            const products = await http.get('/products/'+id, {
                params: {
                    codplace: codplace || 25,
                    customer_id: code === 1 ? null : code,
                }
            });
            const res = parseResponse(products.data);
            return (res.data)
        }catch (e) {
            throw e.response
        }
    }
    async getProductCodarticulo(id, codplace, code){
        try{
            const products = await http.get('/products/cod-articulo/'+id, {
                params: {
                    codplace: codplace || 25,
                    customer_id:code === 1 ? null : code,
                }
            });
            const res = parseResponse(products.data);
            return (res.data)
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }
    async getLastPriceSold(id){
        try {
            const get_last_price = await http.get(`products/${id}/last-prices-sold`);
            const res = parseResponse(get_last_price.data)
            return(res.data)
        } catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }
    async getOrderProduct(id, codplace){
        try{
            const products = await http.get('orders/products/'+id, {
                params: {
                    codplace: codplace || 25
                }
            });
            const res = parseResponse(products.data);
            return (res.data)
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }
    async getProductOffers(branch) {
        let brands = ''
        if(process.env.VUE_APP_VIDVIE == '2'){
            brands = 'VIDVIE'
        }
        const get_products = await http.get(`products/offers?place_id=${branch}&brands_selected=${brands}`);
        const res = get_products
        return res
    }
    async getTopProducts(){
        try {
            const topCategories = await http.get('/categories/top');
            const res = parseResponse(topCategories.data);
            console.log(res)
            return res.data;
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }
    async getTopCategories(){
        try {
            const topCategories = await http.get('/categories/top');
            const res = parseResponse(topCategories.data);
            return res.data;
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }
    async getCategories(){
        try{
            const categories = await http.get('/categories');
            const res = parseResponse(categories.data);
            return res.data
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }    
    async getReviewsPage(product_id, page, filter, keywords = ''){
        try {
            const res = await http.get(`/products/reviews/${product_id}?page=${page}&limit=5&order_by=${filter}&q=${keywords}`);
            return res.data;
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }   
    async getQuestionsPage(product_id, page, filter, keywords = ''){
        try {
            const res = await http.get(`/products/questions/${product_id}?page=${page}&limit=5&order_by=${filter}&q=${keywords}`);
            return res.data;
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }
    async createReview(code, form){
        let formdata = new FormData();
        formdata.append('title', form.title);
        formdata.append('comment', form.comment);
        formdata.append('rating', form.rating);
        formdata.append('cod_articulo', form.cod_articulo);
        
        form.new_images.map((img, index) => {
            formdata.append('images', form.new_images[index].file, form.new_images[index].name);
        })
        // we need to delete the ones that we dont need
        try {
            const res = await http.post('/products/reviews/'+code, formdata)
            return res
        } catch (error) {
            console.log(error);
        }
    }
    async editReview(code, form) {
        let formdata = new FormData();
        formdata.append('title', form.title);
        formdata.append('comment', form.comment);
        formdata.append('rating', form.rating);

        form.new_images.map((img, index) => {
            //Add the new ones as we have previously on createReview
            formdata.append('images', form.new_images[index].file, form.new_images[index].name);
        })
        //if we have to delete images
        form.images_to_delete.map((img) => {
            formdata.append('images_to_delete', img); //this will note the indexes
        })
        try {
            const res = await http.put('/products/reviews/'+code, formdata)
            return res;
        } catch (error) {
            console.log(error);
            throw error
        }
    }
    async deleteReview(reviewId){
        try {
            let res = await http.delete(`/products/reviews/${reviewId}`)
            return res
        } catch (error) {
            console.log(error);
        }
    }
    async voteReview(id, vote){
        try {
            const res = await http.post(`/products/reviews/${id}/votes`, vote)
            return res
        } catch (error) {
            console.log(error);
            throw error
        } 
    }
    async getReviewsSummary(id){
        try {
            const res = await http.get(`/products/reviews/${id}/summary`)
            return res;
        } catch (error) {
            console.log(error);
            throw error
        }
    }
    async reportReview(id, report){
        try {
            let formdata = new FormData();
            formdata.append('reason', report.reason);
            formdata.append('comment', report.comment);
            const res = await http.post(`/products/reviews/report/${id}`, formdata)
            return res;
        } catch (e) {
            console.log(e);
            throw e;
        } 
    }
    async createQuestion(code, form){
        try {
            const res = await http.post('/products/questions/'+code, form)
            return res
        } catch (error) {
            console.log(error);
            throw error
        }
    }
    async updateQuestion(code, question){
        try {
            const res = await http.put('/products/questions/'+code, {question})
            return res
        } catch (error) {
            console.log(error);
            throw error
        }
    }
    async deleteQuestion(code){
        try {
            const res = await http.delete('/products/questions/'+code)
            return res
        } catch (error) {
            console.log(error);
            throw error
        }
    }
    async reportQuestion(id, report){
        try {
            let formdata = new FormData();
            formdata.append('title', report.reason);
            formdata.append('comment', report.comment);
            const res = await http.post(`/products/questions/report/${id}`, formdata)
            
            return res
        } catch (error) {
            console.log(error);
            throw error
        } 
    }
    async createAnswer(code, answer){
        try {
            const res = await http.post('/products/questions/'+code+'/addAnswer', {answer: answer})
            return res
        } catch (error) {
            console.log(error);
            throw error
        }
    }
    async updateAnswer(code, answer){
        try {
            const res = await http.put('/products/questions/updateAnswer/'+code, {answer: answer})
            return res
        } catch (error) {
            console.log(error);
            throw error
        }
    }
    async deleteAnswer(code){
        try {
            const res = await http.delete('/products/questions/answer/'+code)
            return res
        } catch (error) {
            console.log(error);
            throw error
        }
    }
    async voteAnswer(id, vote){
        try {
            const res = await http.post(`/products/questions/answerVotes/${id}`, vote)
            return res
        } catch (error) {
        console.log(error);
        throw error
        } 
    }
}
