import { Auth } from 'aws-amplify';
import { AuthService } from "@/modules/auth/services/auth-service.js";
const authService = new AuthService();
import router from '@/router/'
import axios from 'axios';

export default {
    async login ({commit, dispatch}, {username, password, isVerify = false}) {
        try {

            Auth.configure({authenticationFlowType: "CUSTOM_AUTH"})
            const response = await Auth.signIn(username, password);

            // console.log({login:response});
            // console.log({username});
            // * VALIDA SI EL USUARIO ES EMPLEADO Y TIENE EL MFA ACTIVO
            if(!response?.challengeName === 'SOFTWARE_TOKEN_MFA' || !response?.challengeName === 'NEW_PASSWORD_REQUIRED' || !response.challengeParam?.challenge === 'EMAIL_CONFIRM_CHALLENGE' || response.preferredMFA === 'NOMFA'){
                if(!isVerify){
                    const mfaEmployee = await authService.getEmployeeMFA({username})
                    if(mfaEmployee.mfa_activated && !isVerify) return mfaEmployee
                }
            }

            // switch (response.challengeName) {
            //     case 'SOFTWARE_TOKEN_MFA':
            //         return response
            //     case 'NEW_PASSWORD_REQUIRED':
            //         return response
            //     case 'EMAIL_CONFIRM_CHALLENGE':
            //         return response
            // }
            if(response.challengeName === 'SOFTWARE_TOKEN_MFA' || response.challengeName === 'NEW_PASSWORD_REQUIRED' || response.challengeParam?.challenge === 'EMAIL_CONFIRM_CHALLENGE') {
                return response
            }else {
                const token = response.signInUserSession.idToken.jwtToken
                const refreshToken = response.signInUserSession.refreshToken.token
                commit("SET_TOKEN", token);
                commit("SET_REFRESH_TOKEN", refreshToken);

                commit("common/SET_STATE_LOADING", true, {root: true})
                const user = await authService.login();
                if(user.data.user.entity.is_employee){
                    commit("SET_EMPLOYEE", user.data.user);
                    commit("SET_EMPLOYEE_TOKEN", token);
                    commit("SET_EMPLOYEE_REFRESH_TOKEN", refreshToken);
                }
                commit("SET_USER", user.data.user);
                dispatch('cart/getMyCart', null, {root: true});
                dispatch('cart/syncCarts', null, {root: true});
                dispatch('account/getAddresses', null, {root: true})
                dispatch('wishlist/getLists', null, {root: true})
                return false;
            }
            
            /* eslint-disable-next-line no-useless-catch */
            }catch (e) {
                if(e.code == '565644887'){
                    commit('SET_TOKEN', null);
                    commit('SET_REFRESH_TOKEN', null);
                    localStorage.clear()
                }
                console.log(e)
                throw e
            }finally{
                commit("common/SET_STATE_LOADING", false, {root: true})
            }
    },
    async mfalogin ({commit, dispatch}, {response}) {
        try {
            if(response.challengeName === 'SOFTWARE_TOKEN_MFA') {
                const token = response.signInUserSession.idToken.jwtToken
                const refreshToken = response.signInUserSession.refreshToken.token
                commit("SET_TOKEN", token);
                commit("SET_REFRESH_TOKEN", refreshToken);

                commit("common/SET_STATE_LOADING", true, {root: true})
                const user = await authService.login();
                if(user.data.user.entity.is_employee){
                    commit("SET_EMPLOYEE", user.data.user);
                    commit("SET_EMPLOYEE_TOKEN", token);
                    commit("SET_EMPLOYEE_REFRESH_TOKEN", refreshToken);
                }
                commit("SET_USER", user.data.user);
                dispatch('cart/getMyCart', null, {root: true});
                dispatch('cart/syncCarts', null, {root: true});
                dispatch('account/getAddresses', null, {root: true})
                dispatch('wishlist/getLists', null, {root: true})
                return false;
            }
            /* eslint-disable-next-line no-useless-catch */
            }catch (e) {
                throw e
            }finally{
                commit("common/SET_STATE_LOADING", false, {root: true})
            }
    },
    async logout ({commit, dispatch}) {
        

        try{
            await Auth.signOut()

            commit('SET_TOKEN', null);
            commit('SET_REFRESH_TOKEN', null);
            
            commit('SET_USER', null);
            commit('SET_STATE_EMPLOYEE_ACTIVE_CODE', false);
            commit("SET_EMPLOYEE", null);
            commit('account/SET_ADDRESS_AVAILABLE_LIST', null, {root:true});
            commit('account/SET_ADDRESS', null, {root:true});
            commit('account/SET_QUOTES', {data: []}, {root: true});
            await dispatch('cart/clearCart', null, {root: true});

            router.push({ path: '/' }).catch(() => {});
            localStorage.clear()
        }catch (e) {
            console.log(e)
            throw e;
        }
    },
    async loginCustomer ({commit, state, dispatch}, customer) {
        try {
            let token = state.employeeToken;
            let refreshToken = state.employeeRefreshToken;
            const response = await axios.post(`${process.env.VUE_APP_MM_WEB_BACKEND_ENV}getAccessByCodeEntity`, {username: customer.customer}, {
                headers: { 'Authorization': `Bearer ${token}`, 'x-refresh-token': refreshToken  }
            });

            commit("SET_TOKEN", response.data.data.IdToken);
            commit("SET_REFRESH_TOKEN", response.data.data.RefreshToken);


            const user = await authService.login();
            commit('SET_STATE_EMPLOYEE_ACTIVE_CODE', true)
            commit("SET_USER", user.data.user);
            await dispatch("account/getAddresses", null, {root: true})

            if(customer.getCart)
            await dispatch('cart/getMyCart', null, {root: true});
        } catch (e) {
            throw e.response.data || e
        }
    },
    async logoutCustomer({getters, state, commit, dispatch}){
        try {
            commit("SET_TOKEN", state.employeeToken);
            commit("SET_REFRESH_TOKEN", state.employeeRefreshToken);
            commit('SET_STATE_EMPLOYEE_ACTIVE_CODE', false)
            commit("cart/CLEAR_CART", null, {root: true});
            await dispatch("account/getAddresses", null, {root: true})
            await dispatch('cart/getMyCart', null, {root: true});
            commit("SET_USER", getters.employee);
        } catch (error) {
            console.log(error)
            throw error
        }

    },
};
