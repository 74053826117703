<template>
<section  v-if="this.bannerMain.length > 0" class="row mx-0 banner-slide home-section" :class="{'principal-slider-animation': sliderLoaded}">
  <div class="col-12 px-0">
      <div class="banner-background">
        <swiper class="swiper main-swiper" :options="swiperOption">
          <swiper-slide :key="img._id" v-for="img in bannerMain" class="d-flex align-items-center">
              <img v-lazy="img.imageURL" :alt="img._id" v-show="sliderLoaded" @load="loaded" class="w-100 slide-img"/>
              <div class="h-100 w-100 align-items-center justify-content-center" :class="[sliderLoaded ? 'd-none' : 'd-flex']">
                <image-spinner/>
              </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev d-none d-md-block mr-5" slot="button-prev">
            <icon-arrow :size="40" style="margin-right: -12px; transform: rotate(180deg);" />
          </div>
          <div class="swiper-button-next d-none d-md-block" slot="button-next">
            <icon-arrow :size="40" style="margin-left: -12px"/>
          </div>
        </swiper>
    </div>
  </div>
</section>
<slider-interactive v-else @sliderLoaded="sliderLoaded = true" />
</template>

<script>
import { mapGetters } from "vuex";
import SliderInteractive from "./SliderInteractive.vue";
// import axios from "axios";
import ImageSpinner from '../../../products/components/ImageSpinner.vue';

export default {
  name: 'Slider',
  components: {
    IconArrow: () => import("@/common/svg/iconArrow.vue"),
    ImageSpinner,
    SliderInteractive
  },
  props: {
    productsSlider: {
      type: Array
    }
  },
  data () {
    return {
      product: {},
      vfImages: [],
      images: [
        {_id: 1, imageURL: require('./1/images/slider-banner-1.jpg')},
        {_id: 1, imageURL: require('./2/images/slider-banner-2.jpg')},
        {_id: 1, imageURL: require('./3/images/slider-banner-3.jpg')},
      ],
      swiperOption: {
        slidesPerView: 1,
        slidesPerColumn: 1,
        autoplay:{
          delay: 2500,
          stopOnLastSlide: true,
          pauseOnMouseEnter: true,
          disableOnInteraction: true,
        },
        loop: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: true
        }
      },
      sliderLoaded: false,
      // imgLoaded: false
    }
  },
  computed: {
    path () {
      return process.env.VUE_APP_MM_PRODUCT
    },
    mediaQuery () {
      return window.matchMedia('(max-width: 704px)').matches
    },
    ...mapGetters({
      loading: 'common/loading',
      bannerMain: 'home/bannerMain'
    })
  },
  methods: {
    isBanner (index) {
      if (index < 5) {
        return true
      }
      return false
    },
    loaded(){
      this.sliderLoaded = true
      this.$emit("sliderLoaded", this.sliderLoaded)
    }
  }
}
</script>

<style scoped>
.banner-slide {
  transform: translateY(30px);
}
.banner-background {
  height: 600px;
}
.swiper-button-prev {
  margin-left: 10px !important;
}
.swiper-button-prev, .swiper-button-next {
  width: 40px;
  z-index: 10;
}
.swiper-button-prev:before, .swiper-button-next:before {
  width: 80px;
}
.swiper-button-prev:after, .swiper-button-next:after {
  display: none;
  visibility: hidden;
}
.swiper-button-prev svg, .swiper-button-next svg {
  fill: var(--gray-100) !important;
  transition: var(--transition-1);
}
.swiper-button-prev svg:hover, .swiper-button-next svg:hover {
  fill: var(--main-red) !important;
}
.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: no-drop !important;
  pointer-events: all !important;
}
.swiper-button-prev.swiper-button-disabled:hover, .swiper-button-next.swiper-button-disabled:hover {
  color: var(--gray-50) !important;
}
.principal-slider-animation {
  animation: 800ms cubic-bezier(0.46, 0.89, 0.03, 0.97) slide-up;
  animation-delay: 200ms;
  animation-fill-mode: both;
}
.slide-img{
  height: 600px;
  object-fit: cover;
}
.main-swiper{
  border-radius: 30px; 
  height: 100%
}

@keyframes slide-up {
  from {
    transform: translateY(30px);
  }
  to {
    transform: translateY(0);
  }
}
  .swiper-container::v-deep > .swiper-pagination > .swiper-pagination-bullet{
    width: 17px;
    height: 17px;
  }
  .swiper-container::v-deep > .swiper-pagination > .swiper-pagination-bullet.swiper-pagination-bullet-active{
    background: #334056 !important;
  }
@media (max-width: 475px) {
  .banner-background {
    height: 250px;
  }
  .slide-img{
    height: 250px;
    object-fit: cover;
  }
  .main-swiper{
    border-radius: 10px; 
  }
  .swiper-container::v-deep > .swiper-pagination > .swiper-pagination-bullet{
    width: 13px;
    height: 13px;
  }
}
@media (max-width: 1200px) {
  .banner-background {
    height: auto;
  }
  .slide-img{
    height: auto;
    object-fit: contain;
  }
}
</style>
