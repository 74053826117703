<template>
    <icon-base :color="color" :size="size" viewBox="0 0 56 56" class="icon-twitter">
        <circle id="Ellipse_56" data-name="Ellipse 56" cx="28" cy="28" r="28" fill="#CDD0DC"/>
        <g id="Twitter_icon" data-name="Twitter icon" transform="translate(12 14.831)" :class="[hover ? 'hover' : '']">
            <path id="Twitter_icon-2" data-name="Twitter icon" d="M 4.0175781 4 L 13.091797 17.609375 L 4.3359375 28 L 6.9511719 28 L 14.246094 19.34375 L 20.017578 28 L 20.552734 28 L 28.015625 28 L 18.712891 14.042969 L 27.175781 4 L 24.560547 4 L 17.558594 12.310547 L 12.017578 4 L 4.0175781 4 z M 7.7558594 6 L 10.947266 6 L 24.279297 26 L 21.087891 26 L 7.7558594 6 z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
    name:'iconTwitter',
    components:{
        IconBase: () => import('@/common/components/iconBase.vue')
    },
    props:{
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        hover: {
            type: Boolean
        }
    }
}
</script>

<style scoped>
    .hover{
        transform: scale(0.8) translate(17px, 20px) !important;
    }
</style>

