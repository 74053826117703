import { http } from "../../../common/services/web-backend";
import { PartnersService } from "../services/partner-service";
const partnertService = new PartnersService

export default {
    async getDeposits({ commit }){
        try {
            const deposits = await http.get('deposits ')
            commit('SET_DEPOSITS', deposits.data?.data)
        } catch (error) {
            console.log(error)
        }
    },
    async getCreditsNotes({ commit }){
        try {
            const notes = await http.get('credits/note')
            commit('SET_CREDITS_NOTE', notes.data.data)

        } catch (error) {
            console.log(error);
        }
    },
    async getPartners({commit, rootGetters}){
      try {
        let user = rootGetters['auth/userData']
        const response = await partnertService.getPartners()
        commit('SET_PARTNERS', response)
        let findDefault = response.filter(partner => partner.default === true)
        commit("SET_PARTNER_SELECTED", {id: findDefault.length > 0 ? findDefault[0].id : 0, user})
        
      } catch (error) {
        console.log(error);
        throw error
      }
    },
    async updatePartner({commit, getters}, {id, form}){
      try {
        const response = await partnertService.updatePartner(id, form)
        let findSelect = getters.partners.findIndex((e) => e.id == response.id)
        getters.partners[findSelect] = response
        commit("SET_PARTNER_SELECTED", response)
      } catch (error) {
        console.log(error);
        throw error
      }
    },
    async defaultPartner({commit, dispatch}, {id, form}){
      try {
        const response = await partnertService.updatePartner(id, form)
        dispatch('getPartners')
        if(!form.default)
        commit("SET_PARTNER_SELECTED", response)
      } catch (error) {
        console.log(error);
        throw error
      }
    },
    async deletePartner({dispatch}, id){
      try {
        await partnertService.deletePartner(id)
        dispatch('getPartners')
      } catch (error) {
        console.log(error);
        throw error
      }
    },
    async getCurrencyActive({commit}){
      try {
        const {data} = await http.get("rates/3");
        commit("SET_CURRENCY", data.data)
      } catch (error) {
          console.log(error)
      }
    },
};
