<template>
<icon-base :width="size + 'px'" :fill="color" viewBox="0 0 621.736 621.736">
    <g id="Group_7580" data-name="Group 7580" transform="translate(-48 -2196.949)">
        <ellipse id="Ellipse_458" data-name="Ellipse 458" cx="310.868" cy="310.868" rx="310.868" ry="310.868" transform="translate(48 2196.949)" fill="#d88d48" opacity="0.2"/>
        <ellipse id="Ellipse_459" data-name="Ellipse 459" cx="218.908" cy="218.908" rx="218.908" ry="218.908" transform="translate(139.96 2288.908)" fill="#d88d48" opacity="0.2"/>
    </g>
</icon-base>
</template>

<script>
export default {
    name: 'IconCircle1',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>
