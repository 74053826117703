import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store/index'
import Home from "@/modules/home/view/Home.vue";
import Contact from "@/modules/home/view/Contact.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },  
  {
    path: "/contact/faq",
    name: "Contact",
    component: Contact,
    props: true
  },
  {
    // catch all 404 - define at the very end
    path: "*",
    component: () => import("@/common/components/404/404.vue")
  }
];
const router = new VueRouter({
  mode: "history",
  hash: false,
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.path == '/contact/faq' || from.path == to.path && to.query.page != '1') {
        return {};
      }else {
        return { x: 0, y: 0 };
      }
    }
  }
});
const validate = (employee) => {
  let isPageDisable = store.getters["common/isPageDisable"]
  // let employee = store.getters["auth/employee"]
  if(isPageDisable){
    store.commit("common/SET_DISABLE_MODAL", true)
  }else if(employee){
    store.commit("common/SET_DISABLE_MODAL", true)
  }else{
    store.commit("auth/OPEN_MODAL", true)
  }
}
router.beforeEach(async (to, from, next) => {
  let logged = store.getters["auth/isLogged"];
  let employee = store.getters["auth/employeeActiveCode"]
  let validateName = to.name == "Account" || to.name == "Orders" || to.name == "Addresses" || to.name == "WishList"
  || to.name == "Profile" || to.name == "Devices" || to.name == "Settings";
  let otherPath = to.fullPath == "/account/payments-methods" || to.fullPath == "/account/settings";

  if(validateName && (!logged)){
    let isPageDisable = store.getters["common/isPageDisable"]
    validate(employee);
    if(isPageDisable){
      next('/account/infoworks');
    }
  } else if(otherPath && employee){
    let isPageDisable = store.getters["common/isPageDisable"]
    validate(employee);
    if(isPageDisable){
      next('/account/infoworks');
    }
  }else next()
})
router.onError(error => {
  console.log(error);
  // No access to `to`, `from`, `next`
});

export default router;


