const Module = () => import("./Module.vue");
const Request = () => import("./view/Request.vue");


const moduleRoute = {
  path: "/auth",
  component: Module,
  children: [
    {
      name: "Request",
      path: "request",
      component: Request
    }
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
