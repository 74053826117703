<template>
    <main-modal :title="modalTitle" @closeModal="$emit('closeModalLogin')" :class="[modalClasses]" style="z-index: 13;" preventClose noRebound>
        <div v-if="this.openedLogin || this.showRegister" class="d-flex">
            <h5 @click.prevent="selectLoginTab" :class="{'un-focused': !openedLogin}" class="hover-weight weight-600 mb-3 cursor-pointer">{{$t('auth.sign_in')}}</h5>
            <span class="size-20 color-gray-100 mx-2" style="line-height: 1.2;">|</span>
            <h5 @click.prevent="showRegister = true" :class="[{'un-focused': !showRegister}]" class="hover-weight weight-600 mb-3 cursor-pointer">{{$t('auth.sign_up')}}</h5>
        </div>
        <div v-if="openedLogin" class="content-login">
            <h6 class="subtitle-16 p-0 py-2 mb-2">{{$t("auth.title_login")}}.</h6>
            <div v-if="!newPasswordRequired" class="return-customer">
                <div v-if="error !== null" class="alert alert-danger color-light-main-red d-flex align-items-center border-0 py-2" role="alert">
                    <div class="me-3">
                        <icon-warning color="var(--light-main-red)" :size="22" :red="true"/>
                    </div>
                    <span class="subtitle-14 weight-300 color-light-main-red">
                        {{ error }}
                    </span>
                </div>
                <alert-warning :show="false" :showCounter="false" :size="25" title="auth.you_must_be_logged_in_to_perform_this_action" style="padding: 0px 6px;" class="my-4" />
                <form id="formLogin" @submit.prevent="auth" autocomplete="off">
                    <main-input ref="email" id="email" :value="username.toLowerCase()" :title="$t('auth.email_username')" type="text" @focus="errorNotRegistered = null" 
                        @changeValue="(data) => {username = data}" placeholder="auth.enter_your_email"
                        :readonly="readonly" @activeInput="readonly = false" class="mb-3">
                        <icon-email :size="15" :color="'var(--dark-gray-200)'"/>
                        <span v-if="errorNotRegistered" class="not-registered d-flex align-items-center">
                            <icon-warning color="var(--main-red)" :size="14" class="me-2" :red="true"/>{{$t(errorNotRegistered)}}.
                        </span>
                    </main-input>
                    <main-input ref="password" id="password" :value="password" :title="$t('auth.password')" type="text" @changeValue="(data) => {password = data}"
                        placeholder="auth.enter_your_password" :fixedType="'password'" :readonly="readonly" @activeInput="readonly = false">
                        <icon-password :size="12" :color="'var(--dark-gray-200)'"/>
                    </main-input>
                    <div class="d-flex flex-column flex-sm-row justify-content-between mt-3 subtitle-15">
                        <main-checkbox id="remember-me" :classesDescription="['color-dark-gray-300']" class="" :checkValue="rememberMe" @checkboxChange="(data)=>{rememberMe = data;}">
                            <template v-slot:label>
                                <span class="ms-2">{{$t('auth.remember_me')}}</span>
                            </template>
                        </main-checkbox>
                        <a class="ms-auto" href="#" @click.prevent="showForgotPassword = true">{{ $t("auth.forgot_your_password") }} ?</a>
                    </div>
                    <div style="margin-top: 2rem" class="d-flex justify-content-center mb-3">
                        <main-button :title="loading ? $t('auth.loading')+'...' : $t('auth.sign_in')" id="sign-in"/>
                        <!-- <main-button class="ms-auto" type="button" title="show error" @click="test.value = !test.value" /> -->
                    </div>                  
                </form>                
                <div @click.prevent="showRegister = true" class="subtitle-15 d-flex justify-content-center">
                    <span class="me-2 cursor-pointer">{{ $t("auth.are_you_new") }}</span>
                    <a href="#">{{ $t("auth.sign_up") }}</a>
                </div>
            </div>
        </div>
        <!-- we need a go back button from confirming. -->
        <forgot-password v-if="showForgotPassword" :email="username" :step2="step2" @closeModal="$emit('closeModalLogin')" @goBack="showForgotPassword = false"/> 
        <register v-if="showRegister" @closeModal="$emit('closeModalLogin')" @validateEmail="validateEmail" @emailConfirm="emailConfirm" @confirmSignUp="confirmRegister" 
        @showLogin="selectLoginTab" />
        <new-password-required v-if="newPasswordRequired" :userAttributes="userAttributes" @closeModal="$emit('closeModalLogin')"/>
        <verify-intranet v-if="modalIntranetMFA" :userAttributes="userAttributes" :user="{username: username, password: password}" @closeModal="$emit('closeModalLogin')" @incorrectCode="incorrectCode"/>
        <verify-email v-if="verifyEmail" :userAttributes="userAttributes" :user="{username: username, password: password}" @closeModal="$emit('closeModalLogin')" @incorrectCode="incorrectCode"/>
        <VerifyMFA v-if="showGenerateTOTPModal" :userAttributes="userAttributes" :user="{username: username, password: password}" @closeModal="$emit('closeModalLogin')"/>
    </main-modal>
</template>
<script>
import ForgotPassword from './ForgotPassword.vue';
import VerifyMFA from './VerifyMFA.vue';
import Register from './Register.vue';
import NewPasswordRequired from './NewPasswordRequired.vue';
import VerifyEmail from './VerifyEmail.vue';
import VerifyIntranet from './VerifyIntranetMFA.vue';
import AlertWarning from '../../../common/components/alerts/AlertWarning.vue';
import IconPassword from '../assets/svg/iconPassword.vue';
import IconWarning from '../../../common/svg/iconWarning.vue';
import IconEmail from '../assets/svg/iconEmail.vue';
import { Auth } from 'aws-amplify';
import { mapGetters } from 'vuex';

export default {
    name: "Login",
    components: {
    MainModal: () => import("@/common/components/modals/MainModal"),
    MainInput: () => import("@/common/components/inputs/MainInput"),
    MainButton: () => import("@/common/components/buttons/MainButton"),
    MainCheckbox: () => import("@/common/components/checkbox/MainCheckbox"),
    ForgotPassword,
    Register,
    NewPasswordRequired,
    VerifyEmail,
    AlertWarning,
    IconPassword,
    IconWarning,
    IconEmail,
    VerifyMFA,
    VerifyIntranet
},
    computed: {
        ...mapGetters({ user: "auth/userData" }),
        modalTitle() {
            if (this.showForgotPassword) return 'auth.restore_password';
            if (this.verifyEmail) return 'auth.verify_email'
            if (this.modalIntranetMFA) return ''
            if (this.showGenerateTOTPModal) return ''
            return ''
        },
        openedLogin: {
            set(val){
                return val || !this.showForgotPassword && !this.showRegister && !this.newPasswordRequired && !this.verifyEmail && !this.confirmSignUp && !this.showGenerateTOTPModal && !this.modalIntranetMFA;
            },
            get(){
                return !this.showForgotPassword && !this.showRegister && !this.newPasswordRequired && !this.verifyEmail && !this.confirmSignUp && !this.showGenerateTOTPModal && !this.modalIntranetMFA;
            }
        },
        modalClasses(){
            return {
                'verify-mail': this.verifyEmail,
                'sign-up': this.showRegister,
                'sign-in': this.openedLogin,
                'forgot-password': this.showForgotPassword,
                'auth_totp': this.showGenerateTOTPModal,
                'error': this.openedLogin && this.error, //this.test.value
                '': this.modalIntranetMFA
            }
        }
    },
    data(){
        return {
            loading: false,
            show: true,
            username: "",
            password: "",
            showEye: false,
            newPasswordRequired: false,
            userAttributes: null,
            showForgotPassword: false,
            showRegister: false,
            verifyEmail: false,
            showGenerateTOTPModal: false,
            rememberMe: false,
            confirmSignUp: false,
            error: null,
            mfaEmploye: null,
            modalIntranetMFA: false,
            userEmployee: null,
            errorNotRegistered: null,
            step2: false,
            readonly: true,
            test: {
                value: false,
                label: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi laudantium nobis ab ad voluptatibus minus quod consequuntur earum culpa sunt sequi harum est dicta, iste pariatur. Aperiam magnam nobis assumenda!',
            }
        }
    },
    props: {
        asRegister: {  //to open the modal as a the register mode.
            type: Boolean,
        }
    },
    methods: {
        async auth(){
            try {
                if(!this.confirmSignUp) {
                    this.$refs.email.$v.$touch();
                    this.$refs.password.$v.$touch();
                    if (this.$refs.email.$v.$invalid || this.$refs.password.$v.$invalid) return
                }
                this.validate = !this.validate;
                this.loading = true;
                const username = this.username;
                const password = this.password;

                const response = await this.$store.dispatch('auth/login', {username, password});
                if(response?.mfa_activated){
                    return this.modalIntranetMFA = true
                }
                if (response.challengeName === 'SOFTWARE_TOKEN_MFA') {
                    this.userAttributes = response;
                    return this.showGenerateTOTPModal = true;
                }
                if(response.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    this.userAttributes = response;
                    this.newPasswordRequired = true;
                }else if(response.challengeParam?.challenge === 'EMAIL_CONFIRM_CHALLENGE') {
                    this.userAttributes = response;
                    this.verifyEmail = true;
                }else {
                    if(this.rememberMe){
                        Auth.rememberDevice()
                    }
                    this.$emit('closeModalLogin')
                    this.$store.commit("auth/OPEN_MODAL", false)
                }
                setTimeout(() => {
                    window.Freshbots.initiateWidget(
                        {
                            autoInitChat: false,
                            getClientParams: function () {
                                return { "sn::cstmr::id": `${this.user.entity?.code}`, "cstmr::eml": `${this.user.email}`, "cstmr::nm": `${this.user.entity?.first_name} ${this.user.entity?.last_name}` };
                            },
                        },
                    );
                }, 5000);
            }catch (e) {
                if(e.message === 'PreAuthentication failed with error El usuario no existe en nuestro sistema!.'){
                    this.errorNotRegistered = 'auth.the_email_you_entered_is_not_registered'
                }else{
                    if(e.message) this.error = e.message
                }
            }finally {
                this.loading = false
            }
        },
        emailConfirm(){
            this.newPasswordRequired = false
            this.verifyEmail = true
        },
        async confirmRegister(data){
            try {
                this.$store.commit("common/SET_STATE_LOADING", true)
                this.showRegister = false
                this.username = data.username
                this.password = data.password
                this.confirmSignUp = true
                await this.auth()
            } catch (error) {
                console.log(error);
            } finally{
                this.$store.commit("common/SET_STATE_LOADING", false)
            }
        },
        async incorrectCode(){
            this.confirmSignUp = true;
            await this.auth()
        },
        validateEmail(data) {
            this.step2 = true
            this.username = data
            this.showRegister = false
            this.showForgotPassword = true
        },
        selectLoginTab(){ //template methods
            this.openedLogin = true; 
            this.showRegister = false; 
        }
    },
    mounted() { this.showRegister = this.asRegister },
    destroyed() { this.showEye = false }
}
</script>
<style scoped>
.hover-weight {
    font-weight: 400;
}
.un-focused {
    font-weight: 400 !important;
    color: var(--gray-100) !important;
}
.un-focused.hover-weight:hover {
    text-shadow: 1px 0 0 var(--gray-100);
}
:focus {
    outline: none;
}
.input {
    width: 100%;
    border: 0;
    padding: 5px 5px;
    border-bottom: 1px solid #929fba;
}
.input ~ .border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
}
.input:focus ~ .border {
    width: 100%;
    transition: 0.5s;
}
.border {
    border: 1px solid var(--main-red) !important;
}
::placeholder {
    font-size: 1em !important;
    font-weight: 400;
}
.eye-container {
    position: absolute;
    right: 15px;
    top: 52%;
    fill: #A5B2B7;
}
.eye-container:hover svg {
    fill: #c40316 !important;
    cursor: pointer;
}
@keyframes open-forgot-password {
    from {
        transform: scale(0.8);
    }
    to {
        transform: scale(1);
    }
}
.modal-main-container.forgot-password::v-deep .modal-main {
    max-width: 440px;
    padding-bottom: 0;
}
.modal-main-container.sign-in::v-deep .modal-main {
    max-width: 410px;
    min-width: 410px;
    max-height: 455px;
    min-height: 455px;
}
.modal-main-container.verify_email::v-deep .modal-main {
    max-width: 540px !important;
}
.modal-main-container.sign-up::v-deep .modal-main {
    max-width: 590px;
    min-height: 540px;
    max-height: 540px;
}
@media (min-width: 768px) {
    .modal-main-container.sign-up::v-deep .modal-main {
        animation: open-sign-up 0.3s;
    }
}
@keyframes open-sign-up {
    from {
        width: 410px;
        height: 454px;
    }
    to {
        width: 590px;
        height: 540px;
    }
}
@keyframes opacity-0-to-1 {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.modal-main-container.sign-in::v-deep .modal-main {
    animation: open-login 0.3s;
}
@keyframes open-login {
    from {
        width: 590px;
        height: 500px;
    }
    to {
        width: 410px;
        height: 454px;
    }
}
.not-registered {
    position: absolute;
    top: 38px;
    font-size: 12px;
    left: 0;
    color: var(--main-red);
    background: #fff;
    box-shadow: 0px 5px 25px #212e3d1f;
    border-radius: 7px;
    padding: 10px;
    bottom: -45px;
    z-index: 2;
    animation: animation-invalid-input 0.3s;
}
.not-registered::after {
    content: '';
    border-bottom: 10px solid white !important;
    border-left: 10px solid #f7000000 !important;
    border-right: 10px solid #ff000000 !important;
    position: absolute;
    top: -10px;
    left: 10px;
}
@keyframes animation-invalid-input {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }to {
        opacity: 1;
        transform: translateY(0px);
    }
}
@media (max-width: 577px) {
    .register-form::v-deep .main-input {
        padding-inline: 0;
    }
    .modal-main-container.sign-in::v-deep .modal-main {
        max-width: 355px;
        min-width: 355px;

        max-height: none;
        min-height: none;
    }
    @keyframes open-login {
        from {
            width: 590px;
            height: 500px;
        }
        to {
            width: 410px;
            height: 495px;
        }
    }
    .modal-main-container.sign-in.error::v-deep .modal-main { /* if the sign-in login height is different, meaning there's an error we will deactivate all animations. */
        max-height: none;
        min-height: none;
        animation: none;
    }
}
.modal-main-container.sign-in.error::v-deep .modal-main {
    max-height: none;
    max-width: none;
    animation: none;
}
</style>