<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g id="Layer_10">
            <path class="st8" d="M244.3,187.6l-29.7-29.7c-5.8-5.8-15-6.9-21.9-2.4l-3.2,2.1v0l-18.7,12c-9.5,6.1-22,5-30.3-2.7l-29.8-27.5   l-27.5-29.8c-7.7-8.3-8.8-20.8-2.7-30.3L93,59.6l0,0l1.5-2.3c4.4-7,3.4-16.1-2.4-21.9L62.4,5.7c-6.3-6.4-16.4-7-23.5-1.4l0,0v0l0,0   L11.3,26.1C2.3,33.2-1.5,44.9,1.5,55.9c11.7,43.9,37.8,81.9,74.7,117.8c35.9,36.9,74,63,117.8,74.7c11,3,22.7-0.8,29.8-9.8   l21.7-27.5l0,0l0.1-0.2C251.3,204,250.7,193.9,244.3,187.6z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
    name: 'iconPhone',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>

<style scoped>

</style>
