<template>
    <icon-base width="100%" viewBox="0 0 364 135">
        <defs>
            <clipPath id="a">
                <rect class="a" width="364" height="135" rx="20" transform="translate(0 0)" />
            </clipPath>
            <pattern id="cables" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 1072 2973">
                <image loading="lazy" href="../cables.webp" height="2973" width="1072"/>
            </pattern>
        </defs>
        <g transform="translate(-13 -335)">
            <rect class="b" width="364" height="135" rx="20" transform="translate(13 335)" />
            <g class="c" transform="translate(13 335)">
                <circle class="d" cx="117.5" cy="117.5" r="117.5" transform="translate(274 -55)" />
                <circle class="d" cx="117.5" cy="117.5" r="117.5" transform="translate(-169 -55)" />
                <rect class="e" width="95" height="262" transform="translate(200 0)" />
            </g>
            <text class="f" transform="translate(157 416)">
            <tspan x="-70.119" y="0">Cables</tspan>
            </text>
        </g>
    </icon-base>
</template>

<script>
export default {
    name: 'Cables',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
}
</script>

<style scoped>
.a {
    fill: #ff9a9a;
}
.b {
    fill: #8299f0;
}
.c {
    clip-path: url(#a);
}
.d,
.f {
    fill: #fff;
}
.d {
    opacity: 0.27;
}
.e {
    fill: url(#cables);
}
.f {
    font-size: 44px;
    font-family:'Myriad Pro Bold';
    font-weight: 600;
}
</style>