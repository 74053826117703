<template>
    <div @mouseover="$emit('mouseover')" @mouseleave="$emit('mouseleave')" :class="['vue-star-rating', {'vue-star-rating-rtl': rtl}, {'vue-star-rating-inline': inline}]">
        <div @mouseleave="resetRating" class="vue-star-rating">
                <span v-for="n in maxRating" :key="n + 5" class="d-flex align-items-center" :class="[{'vue-star-rating-pointer': !readOnly }, 'vue-star-rating-star']" 
                :style="{'margin-right': margin + 'px', height: (starSize-1)+'px'}">
                    <star :fill="fillLevel[n-1]" :size="starSize" :points="starPoints" :star-id="n" :step="step" :active-color="activeColor" :inactive-color="inactiveColor" :border-color="borderColor" :border-width="borderWidth" :rounded-corners="roundedCorners" @star-selected="setRating($event, true)" @star-mouse-move="setRating" :rtl="rtl" :glow="glow" :glow-color="glowColor" :animate="true"></star>
                </span>
                <router-link tag="div" :to="{ name: 'product-detail', params: { id: product_id, scroll: 'reviews' } }">
                    <a v-if="showRating" :class="['vue-star-rating-rating-text', textClass]" style="transition: var(--transition-1) !important; text-transform: lowercase;"> ({{totalReviews}}) {{$t('product.reviews')}}</a>
                </router-link>
            <slot name="price"></slot>
            <span  v-if="!readOnly && currentRating > 0.1" class="ms-2 rating-text" :style="{'color': showDescription.color}" :key="Math.round(currentRating)">{{$t('product.score.'+showDescription.value)}}</span>
        </div>
    </div>
</template>
<script>
    export default {
        components: {
            Star: () => import('./Star'),
        },        
        data() {
            return {
                step: 0,
                fillLevel: [],
                currentRating: 0,
                selectedRating: 0,
                ratingSelected: false
            }
        },
        props: {
            totalReviews: {
                type: Number,
                default: 5
            },
            increment: {
                type: Number,
                default: 1
            },
            rating: {
                type: Number,
                default: 3.5
            },
            roundStartRating: {
                type: Boolean,
                default: true
            },
            activeColor: {
                type: String,
                default: '#FEC537'
            },
            inactiveColor: {
                type: String,
                default: '#c6d4db'
            },
            maxRating: {
                type: Number,
                default: 5
            },
            starPoints: {
                type: Array,
                default() {
                    return []
                }
            },
            starSize: {
                type: Number,
                default: 50
            },
            showRating: {
                type: Boolean,
                default: true
            },
            readOnly: {
                type: Boolean,
                default: false
            },
            textClass: {
                type: String,
                default: ''
            },
            inline: {
                type: Boolean,
                default: false
            },
            borderColor: {
                type: String,
                default: '#e88620'
            },
            borderWidth: {
                type: Number,
                default: 0
            },
            roundedCorners: {
                type: Boolean,
                default: false
            },
            padding: {
                type: Number,
                default: 0
            },
            rtl: {
                type: Boolean,
                default: false
            },
            fixedPoints: {
                type: Number,
                default: 0
            },
            glow: {
                type: Number,
                default: 0
            },
            glowColor: {
                type: String,
                default: ''
            },
            product_id: {
                type: Number,
                default: null
            }
        },
        methods: {
            setRating($event, persist) {
                if (!this.readOnly) {
                    const position = (this.rtl) ? (100 - $event.position) / 100 : $event.position / 100
                    this.currentRating = (($event.id + position) - 1).toFixed(2)
                    this.currentRating = (this.currentRating > this.maxRating) ? this.maxRating : this.currentRating
                    this.createStars()
                    if (persist) {
                        this.selectedRating = this.currentRating
                        this.$emit('rating-selected', this.selectedRating)
                        this.ratingSelected = true
                    } else {
                        this.$emit('current-rating', this.currentRating)
                    }
                }
            },
            resetRating() {
                if (!this.readOnly) {
                    this.currentRating = this.selectedRating
                    this.createStars(this.shouldRound)
                }
            },
            createStars(round = true) {
                if (round) {
                    this.round()
                }
                for (var i = 0; i < this.maxRating; i++) {
                    let level = 0
                    if (i < this.currentRating) {
                        level = (this.currentRating - i > 1) ? 100 : (this.currentRating - i) * 100
                    }
                    this.$set(this.fillLevel, i, Math.round(level))
                }
            },
            round() {
                var inv = 1.0 / this.increment
                this.currentRating = Math.min(this.maxRating, Math.ceil(this.currentRating * inv) / inv)
            }
        },
        computed: {
            formattedRating() {
                return (this.fixedPoints === null) ? this.currentRating : this.currentRating.toFixed(this.fixedPoints)
            },
            shouldRound() {
                return this.ratingSelected || this.roundStartRating
            },
            margin() {
                return this.padding + this.borderWidth
            },
            showDescription(){
                if (this.currentRating <= 1) {
                    return {value: 'bad', color: '#BC2C35'}
                }else if(this.currentRating <= 2){
                    return {value: 'regular', color: '#BC6B2C'}
                }else if(this.currentRating <= 3){
                    return {value: 'neutral', color: '#B5BF58'}
                }else if(this.currentRating <= 4){
                    return {value: 'good', color: '#A0BF58'}
                }
                return {value: 'excelent', color: '#72BF58'}
            }
        },
        watch: {
            rating(val) {
                this.currentRating = val
                this.selectedRating = val
                this.createStars(this.shouldRound)
            }
        },
        created() {
            this.step = this.increment * 100
            this.currentRating = this.rating
            this.selectedRating = this.currentRating
            this.createStars(this.roundStartRating)
        },
    }
</script>
<style scoped>
    .vue-star-rating-star {
        display: inline-block;
    }
    .vue-star-rating-pointer {
        cursor: pointer;
    }
    .vue-star-rating {
        display: flex;
        align-items: center;
    }
    .vue-star-rating-inline {
        display: inline-flex;
    }
    .vue-star-rating-rating-text {
        margin-left: 7px;
    }
    .vue-star-rating-rtl {
        direction: rtl;
    }
    .vue-star-rating-rtl .vue-star-rating-rating-text {
        margin-right: 10px;
        direction: rtl;
    }
    .rating-text {
        animation: show-text 0.5s;
    }
    @keyframes show-text {
        from{
            opacity: 0;
            transform: translateX(-10px);
        }
        to{
            opacity: 1;
            transform: translateX(0px);
        }
    }
</style>
