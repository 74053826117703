<template>
    <main-modal :title="'¿Te gutaria aprender algo nuevo?'">
        <p>Mejorar la experiencia de usuario es nuestra prioridad. Aqui un recuento rapido de todo lo que podras hacer.</p>
        <div class="row p-2">
            <div class="col-md-4">
                <card class="options-card">
                    <div class="d-flex align-items-center flex-column">
                        <img src="https://cdn.iconscout.com/icon/free/png-256/branch-locator-1795545-1522750.png" alt="" width="50">
                        <h6 class="mt-2 mb-0">Manejar sucursales</h6>
                        <p class="subtitle-13 text-center color-dark-gray-200">Te permite crear diferetes carrritos de compra.</p>
                    </div>
                </card>
            </div>
            <div class="col-md-4">
                <card class="options-card">
                    <div class="d-flex align-items-center flex-column">
                        <img src="https://cdn-icons-png.flaticon.com/512/776/776611.png" alt="" width="50">
                        <h6 class="mt-2 mb-0">Crear listas de deseo</h6>
                        <p class="subtitle-13 text-center color-dark-gray-200">La posibilidad de crear listas, guarda tus articulos favoritos.</p>
                    </div>
                </card>
            </div>
            <div class="col-md-4">
                <card class="options-card">
                    <div class="d-flex align-items-center flex-column">
                        <img src="https://qdeskmexico.com/wp-content/uploads/2019/10/perfil-laboral-y-descripci%C3%B3n-del-puesto-de-trabajo.png" alt="" width="50">
                        <h6 class="mt-2 mb-0">Actualiza tu perfil</h6>
                        <p class="subtitle-13 text-center color-dark-gray-200">Aprende a modificar tu informacion. ¿Nuevo Email?</p>
                    </div>
                </card>
            </div>
        </div>
    </main-modal>
</template>

<script>
import Card from '../cards/Card.vue'
import MainModal from '../modals/MainModal.vue'
export default {
    components: { MainModal, Card },
    name: "OptionsModal"
}
</script>
<style scoped>
.options-card{
    max-width: 250px;
}
.options-card:hover{
    border-color: var(--gray-100) !important;
    box-shadow: none;
}
</style>