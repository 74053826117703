<template>
<section class="home-section row mx-0">
  <div class="col-md-12 px-0">
    <div class="section-title-container d-flex">
      <h3 class="active-title px-0">{{ $t("home.recommended_categories") }}</h3>
    </div>
    <div class="px-0 d-flex categories-slider position-relative mx-auto">
      <div class="d-none d-md-flex swiper-button-prev-categories" slot="button-prev">
        <icon-arrow :size="40" style="transform: rotate(180deg) !important;"/>
      </div>
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide :key="name" v-for="{value, name, icon, viewBox} in categoriesENV" class="d-flex d-lg-block justify-content-center align-items-center align-items-md-start">
          <a @click.prevent="navigateTo({name, value})" class="d-flex justify-content-center category-container">
            <div class="d-flex justify-content-center align-content-center align-items-center">
              <div class="d-flex flex-column align-items-center text-center">
                <component :is="icon" :viewBox="viewBox" color="var(--main-navy)"/>
                <span>{{$t(('home.'+name))}}</span>
              </div>
            </div>  
          </a>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div class="d-none d-md-flex swiper-button-next-categories" slot="button-next">
        <icon-arrow :size="40"/>
      </div>
    </div>
  </div>
</section>

</template>

<script>
export default {
  name: "Categories",
  components: {
    IconArrow: () => import("@/common/svg/iconArrow.vue"),
    // shared icons
    IconHeadphone: () => import('@/common/svg/categories/iconHeadphone'),
    IconCable: () => import('@/common/svg/categories/iconCable'),
    IconCellphone: () => import('@/common/svg/categories/iconCellphone'),
    IconCharger: () => import('@/common/svg/categories/iconCharger'),
    IconSpeaker: () => import('@/common/svg/categories/iconSpeaker'),

    IconBattery: () => import('@/modules/home/assets/svg/Categories/iconBattery'),
    IconCamera: () => import('@/modules/home/assets/svg/Categories/iconCamera'),
    IconComputer: () => import('@/modules/home/assets/svg/Categories/iconComputer'),
    IconConfiguration: () => import('@/modules/home/assets/svg/Categories/iconConfiguration'),
    IconGlass: () => import('@/modules/home/assets/svg/Categories/iconGlass'),
    IconHandsFree: () => import('@/modules/home/assets/svg/Categories/iconHandsFree'),
    IconLightRing: () => import('@/modules/home/assets/svg/Categories/iconLightRing'),
    IconPhoneCase: () => import('@/modules/home/assets/svg/Categories/iconPhoneCase'),
    IconPowerBank: () => import('@/modules/home/assets/svg/Categories/iconPowerBank'),
    IconSIM: () => import('@/modules/home/assets/svg/Categories/iconSIM'),
    IconSecurityCamera: () => import('@/modules/home/assets/svg/Categories/iconSecurityCamera'),
    IconStorage: () => import('@/modules/home/assets/svg/Categories/iconStorage'),
    IconTablet: () => import('@/modules/home/assets/svg/Categories/iconTablet'),
    IconTools: () => import('@/modules/home/assets/svg/Categories/iconTools'),
    IconTouch: () => import('@/modules/home/assets/svg/Categories/iconTouch'),
  },
  data(){
    return {
      categories: [
        {
          icon: 'IconHeadphone',
          viewBox: "0 0 250 250",
          value: 16,
          name: 'headphones'
        },
        {
          icon: 'IconCharger',
          viewBox: "0 10 250 250",
          value: 11,
          name: 'chargers'
        },
        {
          icon: 'IconCable',
          viewBox: "0 0 250 250",
          value: 9,
          name: 'cables'
        },
        {
          icon: 'IconCellphone',
          viewBox: "0 0 250 250",
          value: 1,
          name: 'cellphones'
        },
        {
          icon: 'IconSpeaker',
          viewBox: "0 0 250 250",
          value: 5,
          name: 'speakers'
        },
        {
          icon: 'IconBattery',
          viewBox: "0 0 200 200",
          value: 3,
          name: 'batterys'
        },
        {
          icon: 'IconCharger',
          viewBox: "30 10 200 200",
          value: 11,
          name: 'charging_cube'
        },
        {
          icon: 'IconSecurityCamera',
          viewBox: "0 0 200 200",
          value: 36,
          name: 'security_cameras'
        },
        {
          icon: 'IconCamera',
          viewBox: "0 0 200 200",
          value: 36,
          name: 'cameras'
        },
        {
          icon: 'IconPhoneCase',
          viewBox: "0 0 200 200",
          value: 12,
          name: 'phone_cases'
        },
        {
          icon: 'IconTouch',
          viewBox: "0 0 200 200",
          value: 25,
          name: 'touch'
        },
        {
          icon: 'IconHandsFree',
          viewBox: "0 0 200 200",
          value: 16,
          name: 'handsfree'
        },
        {
          icon: 'IconComputer',
          viewBox: "0 0 200 200",
          value: 43,
          name: 'computers'
        },
        {
          icon: 'IconTools',
          viewBox: "0 0 200 200",
          value: 31,
          name: 'tools'
        },
        {
          icon: 'IconLightRing',
          viewBox: "0 0 200 200",
          value: 31,
          name: 'light_ring'
        },
        {
          icon: 'IconStorage',
          viewBox: "0 0 200 200",
          value: 43,
          name: 'storage'
        },
        {
          icon: 'IconPowerBank',
          viewBox: "0 0 200 200",
          value: 40,
          name: 'powerbank'
        },
        {
          icon: 'IconGlass',
          viewBox: "0 0 200 200",
          value: 34,
          name: 'glass'
        },
        {
          icon: 'IconConfiguration',
          viewBox: "0 0 200 200",
          value: 43,
          name: 'config'
        },
        {
          icon: 'IconTablet',
          viewBox: "0 0 200 200",
          value: 33,
          name: 'tablet'
        },
        {
          icon: 'IconSIM',
          viewBox: "0 0 200 200",
          value: 47,
          name: 'SIM'
        },
      ],
      categoriesUS: [
                {
                    icon: 'IconHeadphone',
                    viewBox: "0 0 250 250",
                    value: 4,
                    name: 'headphones'
                },
                {
                    icon: 'IconCharger',
                    viewBox: "0 10 250 250",
                    value: 3,
                    name: 'chargers'
                },
                {
                    icon: 'IconCable',
                    viewBox: "0 0 250 250",
                    value: 	2,
                    name: 'cables'
                },
                // {
                //     icon: 'IconCellphone',
                //     viewBox: "0 0 250 250",
                //     value: 1,
                //     name: 'cellphones'
                // },
                {
                    icon: 'IconSpeaker',
                    viewBox: "0 0 250 250",
                    value: 8,
                    name: 'speakers'
                },
                {
                    icon: 'IconBattery',
                    viewBox: "0 0 200 200",
                    value: 6,
                    name: 'batterys'
                },
                // {
                //     icon: 'IconSecurityCamera',
                //     viewBox: "0 0 200 200",
                //     value: 36,
                //     name: 'security_cameras'
                // },
                {
                    icon: 'IconPhoneCase',
                    viewBox: "0 0 200 200",
                    value: 17,
                    name: 'phone_cases'
                },
                {
                    icon: 'IconTouch',
                    viewBox: "0 0 200 200",
                    value: 21,
                    name: 'touch'
                },
                // {
                //     icon: 'IconTools',
                //     viewBox: "0 0 200 200",
                //     value: 31,
                //     name: 'tools'
                // },
                // {
                //     icon: 'IconStorage',
                //     viewBox: "0 0 200 200",
                //     value: 19,
                //     name: 'storage'
                // },
                {
                    icon: 'IconPowerBank',
                    viewBox: "0 0 200 200",
                    value: 5,
                    name: 'powerbank'
                },
                {
                    icon: 'IconGlass',
                    viewBox: "0 0 200 200",
                    value: 14,
                    name: 'glass'
                },
                // {
                //     icon: 'IconTablet',
                //     viewBox: "0 0 200 200",
                //     value: 33,
                //     name: 'tablet'
                // },
                // {
                //     icon: 'IconSIM',
                //     viewBox: "0 0 200 200",
                //     value: 27,
                //     name: 'SIM'
                // },
            ],
      swiperOption: {
        navigation: {
          nextEl: '.swiper-button-next-categories',
          prevEl: '.swiper-button-prev-categories',
        },
        pagination: {
            el: '.swiper-pagination',
            dynamicBullets: true,
            clickable: true
        },
        breakpoints: {
          // when window width is >= 320px
          0: {
            slidesPerView: 2,
            slidesPerColumn: 2,
            slidesPerGroup: 2,
            slidesPerColumnFill: 'row',
            spaceBetween: 0,
          },
          576: {
            slidesPerView: 3,
            slidesPerColumn: 3,
            slidesPerGroup: 3,
            slidesPerColumnFill: 'row',
            spaceBetween: 0,
          },            
          768: {
            slidesPerView: 3,
            spaceBetween: 0,
            slidesPerGroup: 1,
            slidesPerColumnFill: 'column',
            slidesPerColumn: 1,
          },
          // when window width is >= 992px
          992: {
            slidesPerView: 4,
            spaceBetween: 0,
            slidesPerGroup: 1,
            slidesPerColumnFill: 'column',
            slidesPerColumn: 1,
          },
          // when window width is >= 1200px
          1200: {
            slidesPerView: 5,
            spaceBetween: 0
          },
          1400: {
            slidesPerView: 5,
            spaceBetween: 0
          },
        },
      },
    }
  },
  computed: {
    branchSelected(){
      return this.$store.getters["branches/branchSelected"]
    },
    categoriesENV(){
        if(process.env.VUE_APP_VIDVIE == 1){
            return this.categoriesUS
        } else {
            return this.categories
        }
    },
  },
  methods: {
    navigateTo(category){
      this.$store.commit("products/SET_CATEGORY", category);
      this.$router.replace({path: '/shop'}).catch(() => {})
    },
  },
}
</script>

<style scoped>
.section-title-container {
  margin-bottom: 20px;
  justify-content: start;
}
.active-title {
  display: inline;
  font-size: calc(1.3rem + 0.6vw);
  max-width: 100%;
  font-weight: 700;
}
.active-title:after {
  content: '';
  display: block;
  position: relative;
  height: 4px;
  border-radius: 3.125rem;
  width: 100%;
  background-color: var(--main-red);
  top: 5px !important;
}
.categories-slider {
  height: 450px;
}
.swiper::v-deep .swiper-wrapper {
  height: 190px;
}
.swiper-pagination {
  bottom: 25px;
}
.category-container {
  border-radius: 15px;
  border: 2px solid var(--gray-50);
  height: 160px;
  width: 160px;
  transition: var(--transition-4);
}
.category-container svg {
  width: 80px;
  transition: var(--transition-4);
}
.category-container span {
  font-size: 13px;
  margin-top: 18px;
  transition: var(--transition-4);
}
.category-container:hover {
  cursor: pointer;
  color: var(--dark-main-navy);
  font-weight: 600;
  transform-origin: center;
  transition: var(--transition-4);
  transform: scale(1.03);
}
.category-container:hover svg {
  transform: scale(1.08);
  transition: var(--transition-4);
}
.category-container:hover span {
  transform: scale(1.08);
  transition: var(--transition-4);
}
.swiper-pagination {
  left: 50%;
}
.swiper-pagination::v-deep .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
}
.swiper-pagination::v-deep :is(.swiper-pagination-bullet-active-prev-prev, .swiper-pagination-bullet-active-next-next) {
  transform: scale(0.45);
}
.swiper-button-prev-categories {
  margin-left: -8px;
}
.swiper-button-next-categories {
  margin-right: -8px;
}
.swiper-button-prev-categories, .swiper-button-next-categories {
  position: static;
  width: 40px;
  z-index: 1;
}
.swiper-button-prev-categories:before, .swiper-button-next-categories:before {
  width: 80px;
  background-color: red;
}
.swiper-button-prev-categories:after, .swiper-button-next-categories:after {
  display: none;
  visibility: hidden;
}
.swiper-button-prev-categories svg, .swiper-button-next-categories svg {
  fill: var(--gray-100) !important;
  transition: var(--transition-1);
}
.swiper-button-prev-categories:hover svg, .swiper-button-next-categories:hover svg {
  fill: var(--main-red) !important;
}
.swiper-button-prev-categories.swiper-button-disabled, .swiper-button-next-categories.swiper-button-disabled {
  opacity: 0.35 !important;
  cursor: no-drop !important;
  pointer-events: all !important;
}
.swiper-button-prev-categories.swiper-button-disabled:hover svg, .swiper-button-next-categories.swiper-button-disabled:hover svg {
  fill: var(--gray-50) !important;
} 
@media (max-width: 578px) {
  .categories-section{
    margin-top: 30px;
  }
  .categories-slider{
    height: 410px;
  }
  .swiper-pagination {
    bottom: 0;
  }
}
@media (min-width: 578px) {
  .categories-slider {
    height: auto;
  }
  .swiper {
    height: 600px;
  }
  .swiper::v-deep .swiper-wrapper {
    height: 550px;
    align-content: space-evenly;
  }
  .swiper::v-deep .swiper-slide {
    height: auto;
    min-width: unset;
    min-height: unset;
  }
  .category-container {
    height: 150px;
    width: 150px;
    min-width: unset;
  }
  .category-container svg {
    width: 70px;
  }
}

@media (min-width: 768px) {
  .section-title-container {
    margin-bottom: 40px;
  }
  .categories-slider {
    height: 240px;
  }
  .swiper {
    height: 220px;
  }
  .swiper::v-deep .swiper-wrapper {
    height: 220px;
  }
  .swiper::v-deep .swiper-slide {
    padding-top: 5px;
  }
  .swiper-pagination {
    bottom: 15px;
  }
  .swiper-button-prev-categories, .swiper-button-next-categories {
    margin-top: 60px;
  }
}

@media (min-width: 992px) {
  .section-title-container {
    margin-bottom: 60px;
  }  
  .active-title {
    font-size: 27px;
  }
  .swiper {
    height: 305px;
  }
  .category-container {
    height: 185px;
    width: auto;
  }
  .swiper::v-deep .swiper-slide {
    padding: 3px 1em;
  }
  .swiper-pagination {
    bottom: 60px;
  }
  .swiper-button-prev-categories, .swiper-button-next-categories {
    margin-top: 75px;
  }
}

@media (min-width: 1200px) {
  .category-container svg {
    width: 80px;
  }
  .swiper-pagination {
    bottom: 65px;
  }
} 

@media (min-width: 1400px) {
  .swiper::v-deep .swiper-slide {
    padding: 3px 1.6em;
  }
}
</style>
