<template>
    <icon-base width="100%" :fill="color" viewBox="0 0 416.6 149.9">
        <g id="Capa_1">
            <g>
                <path id="Bg_yellow_3_" class="st0" d="M10,0h396.5c5.5,0,10,4.1,10,9.2v131.6c0,5.1-4.5,9.2-10,9.2H10c-5.5,0-10-4.1-10-9.2V9.2    C0,4.1,4.5,0,10,0z"/>
                <path class="st1" d="M77.8,143.7c-15.3-9.9-40.2-21.5-77.8-27.8v1.8c37.1,6.2,61.6,17.7,76.7,27.4c2.5,1.6,4.8,3.2,6.9,4.8h3.1    C84,147.9,81.1,145.8,77.8,143.7z"/>
                <path class="st2" d="M27.1,0h362.4c5.1,0,9.2,4.1,9.2,9.2v131.6c0,5.1-4.1,9.2-9.2,9.2H27.1c-5.1,0-9.2-4.1-9.2-9.2V9.2    C18,4.1,22.1,0,27.1,0z"/>
                <path class="st3" d="M407.4,0H295.1c-4.6,13.7-10,29-13.9,36.3c-7.7,14.5-51.3,61.2,11.4,67.8c55.4,5.8,100.2-26.9,123.9-49.2V9.2    C416.6,4.1,412.5,0,407.4,0z"/>
                <path class="st4" d="M416.6,10.5c-96,19.2-160.6,9.3-198.4-2.8c-7.6-2.4-14.3-5-20.4-7.7h-4.5c7,3.2,15.1,6.4,24.2,9.4    c25.3,8.1,54,12.8,85.2,13.7c4,0.1,8,0.2,12,0.2c31.6,0,65.8-3.6,101.8-10.8v-2H416.6z"/>
            </g>
            <path class="st5" d="M416.6,140.8v-5c-19.4,4.3-39.7,6.1-58-0.1c-51.8-17.5-112.5-41.3-100.1,6.3c0.7,2.7,1.5,5.4,2.4,7.9h145.7   C412.1,149.9,416.6,145.8,416.6,140.8z"/>
            <path class="st6" d="M0,9.2v12.2C23.7,44,55.9,58,91.3,58c45.5,0,85.6-23,109.4-58H10C4.5,0,0,4.1,0,9.2z"/>
            <g id="Component_301_1_3_" transform="translate(301.873 85.805)">
                <text transform="matrix(1 0 0 1 -151.596 -4.9277)" class="st7 st8 st9">Earphones</text>
            </g>
            <g>
                <text transform="matrix(1, 0, 0, 1, 281, 106)" class="st10 st11 st12">with your friends</text>
                <text transform="matrix(1 0 0 1 231.2829 106.592)" class="st10 st8 st12">MUSIC</text>
                <text transform="matrix(1, 0, 0, 1, 155, 106)" class="st10 st11 st12">Share your</text>
            </g>
        </g>
        <g id="Capa_2">
            <image href="../../../assets/images/advertisements/ads-3/img-4.webp" width="930" transform="matrix(0.1214, 0, 0, 0.1214, 22, 14)"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'Ads03',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: ''
    },
    
}
}
</script>

<style type="text/css" scoped>
    .st0{fill:#FBE072;}
    .st1{fill:#EACA42;}
    .st2{fill:none;}
    .st3{opacity:0.6;fill:#F7D752;enable-background:new    ;}
    .st4{opacity:0.6;fill:#E9C942;enable-background:new    ;}
    .st5{opacity:0.2;fill:#E0BF34;enable-background:new    ;}
    .st6{opacity:0.6;fill:#F6D652;enable-background:new    ;}
    .st7{fill:#D0AD19;}
    .st8{font-family:'Myriad Pro Bold';}
    .st9{font-size:54.5px;}
    .st10{fill:#212E3D;}
    .st11{font-family:'Myriad Pro Regular';}
    .st12{font-size:16px;}
    .st13{opacity:0.6;}
    .st14{fill:#C0A01E;}
</style>