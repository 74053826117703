<template>
    <div class="short-notify" id="short-notify" :style="{borderLeft: `13px solid ${getColor.border}`}" @mouseenter="pause" @mouseleave="continueAnimation">
        <div class="d-flex justify-content-end">
            <icon-close :size="14" :color="'var(--gray-1001)'" class="cursor-pointer hover-main-red" @click.native="counter = 0"/>
        </div>
        <div class=" d-flex align-items-start">
            <icon-check-circle class="me-2" :size="24" v-if="shortNotify.type == 'success'"/>
            <icon-warning-circle class="me-2" :color="'#FFD971'" v-else-if="shortNotify.type == 'warning'"/>
            <icon-close-circle class="me-2" :color="'#DB3535'" v-else-if="shortNotify.type == 'danger'"/>
            <div style="width: 88%">
                <h3 class="title-16 weight-700 mb-0">{{$t(`${shortNotify.title || getColor.title}`)}}</h3>
                <div v-if="shortNotify.contentHTML" v-html="shortNotify.contentHTML"></div>
                <p class="color-dark-gray-400 subtitle-14 m-0" v-else>{{$t(shortNotify.text)}}</p>
                <div class="container-progress mt-2">
                    <div class="progress-bar">
                        <span class="bar">
                            <span class="progress" id="progress" :style="{background: getColor.color}"></span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex"
import IconCheckCircle from '../../../modules/account/assets/svg/iconCheckCircle.vue';
import IconClose from "../../svg/iconClose.vue";
import IconWarningCircle from "../../svg/iconWarningCircle.vue";
import IconCloseCircle from "../../svg/iconCloseCircle.vue";

export default {
    name: "ShortNotify",
    props: {
        title: {
            type: String
        }, 
    },
    components: {
        IconClose,
        IconCheckCircle,
        IconWarningCircle,
        IconCloseCircle
    },
    data() {
        return {
            counter: 6,
            interval: 0,
            options: [
                {type: 'success', color: '#85B561', border: '#85b5617e', title: 'common.changes_saved_sucessfully'},
                {type: 'warning', color: '#FFD971', border: '#ffd97181', title: 'common.warning'},
                {type: 'danger', color: '#DB3535', border: '#db353584', title: 'common.something_went_wrong'},
                // {type: 'success', color: '#85B561', border: '#85b5617e'},
            ]
        }
    },
    computed:{
        ...mapGetters({
            shortNotify: "common/shortNotify",

        }),
        getColor(){
            let color = this.options.filter(e => e.type == this.shortNotify.type)
            return color[0]
        }
    },
    created(){
        this.interval = setInterval(() => this.counter--, 1000);
    },
    watch:{
        counter(val) {
            if (val === 0) {
                this.$store.commit("common/RESET_SHORT_NOTIFY")
                clearInterval(this.interval);
            }
        },
    },
    methods:{
        pause(){
            clearInterval(this.interval)
            let notify = document.getElementById('short-notify')
            let progress = document.getElementById('progress')

            notify.style.animationPlayState = 'paused'
            progress.style.animationPlayState = 'paused'
        },
        continueAnimation(){
            this.interval = setInterval(() => this.counter--, 1000);
            let notify = document.getElementById('short-notify')
            let progress = document.getElementById('progress')
            
            notify.style.animationPlayState = 'running'
            progress.style.animationPlayState = 'running'
        }
    },
    destroyed(){
        clearInterval(this.interval)
    }
}
</script>

<style scoped>
    .short-notify{
        position: fixed;
        background: #fff;
        bottom: 5%;
        right: 1%;
        border-radius: 10px;
        box-shadow: 0px 0px 6px 1px #909da41c;
        padding: 0.80rem;
        width: 345px;
        animation: up 0.8s, down 1s ease-in-out 5000ms, hidden 1s ease-in-out 5500ms;
        /* animation-iteration-count: infinite; */
        animation-fill-mode: forwards;
        z-index: 13;
    }
    @keyframes loader {
        0% {
            width: 0;
        }
        100% {
            width: 100%;
        }
    }
    .progress-bar {
        border-radius: 60px;
        overflow: hidden;
        width: 100%;
    }
    .progress-bar span {
        display: block;
    }
    .progress {
        -webkit-animation: loader 6s ease;
        animation: loader 6s ease;
        background: #db353584;
        color: #fff;
        padding: 5px;
        width: 0;
    }

    .progress-bar {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        background: var(--gray-50);
        height: 6px;

    }
    .container-progress{
        position: relative;
        height: 20px;
    }
    @keyframes up {
        from{
            transform: translateY(300px);
        }
        to{
            transform: translateY(0px);
        }
    }

    @keyframes down {
        from{
            transform: translateY(0px);
        }
        to{
            transform: translateY(300px);
        }
    }
    @keyframes hidden {
        100%{
            opacity: 0;
        }
    }
</style>
