const Module = () => import("./Module.vue");
const Home = () => import("./view/Home.vue");
const Policies = () => import("./view/Policies.vue");

const moduleRoute = {
  path: "/",
  name: "home",
  component: Module,
  redirect: { path: "welcome" },
  children: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "home/policies/:tab",
      component: Policies,
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
