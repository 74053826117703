
// var Big = require('big.js');
export default {
    orderConfirm:  (state)=>state.orderConfirm,
    confirmationTab: (state) => state.checkoutSteps.confirmationTab,
    addressTab: (state) => state.checkoutSteps.addressTab,
    paymentTab: (state) => state.checkoutSteps.paymentTab,
    checkoutSteps: (state) => state.checkoutSteps,
    partnerSelected: (state) => state.partnerSelected,
    creditNoteSelected: (state) => state.paymentsSelected.creditNoteSelected,
    cashSelected: state => state.paymentsSelected.cashSelected,
    cardSelected: state => state.paymentsSelected.cardSelected,
    stripeSelected: state => state.paymentsSelected.stripeSelected,
    paypalSelected: state => state.paymentsSelected.paypalSelected,
    depositSelected: state => state.paymentsSelected.depositSelected,
    depositExpressSelected: state => state.paymentsSelected.depositExpressSelected,
    creditSelected: state => state.paymentsSelected.creditSelected,
    paymentsSelected: state => state.paymentsSelected,
    currency: state => state.currency,
    elements: state => state.elements,

    // payments
    difference(state, getters){
        let difference = getters.orderConfirm.total_order
        for(let index in state.paymentsSelected){
            if (index === 'creditNoteSelected'){
                for (let credit in state.paymentsSelected[index]){
                    // difference =   difference.minus(state.paymentsSelected[index][credit].balanceSelected)
                    if(state.paymentsSelected[index][credit].balanceSelected > 0)
                    difference =   difference - parseFloat(state.paymentsSelected[index][credit].balanceSelected)
                }
            }
            if (index ===  'depositSelected'){
                for (let deposit in state.paymentsSelected[index]){
                    // difference =   difference.minus(parseFloat(state.paymentsSelected[index][deposit].balanceSelected))
                    if(state.paymentsSelected[index][deposit].balanceSelected > 0)
                    difference =   difference - parseFloat(state.paymentsSelected[index][deposit].balanceSelected)

                }
            }
            if (index === 'depositExpressSelected' && state.paymentsSelected[index].images.length >= 1){
                if (state.paymentsSelected[index].balance){
                    // difference =   difference.minus(state.paymentsSelected[index].balance)
                    difference =   (difference - state.paymentsSelected[index].balance)

                }
            }
            if (index === 'cashSelected'){
                if (state.paymentsSelected[index].balance){
                // difference =   difference.minus(state.paymentsSelected[index].balance)
                    difference =   (difference - state.paymentsSelected[index].balance)

                }
            }
            if (index === 'stripeSelected'){
                if (state.paymentsSelected[index].balance){
                // difference =   difference.minus(state.paymentsSelected[index].balance)
                    difference =   (difference - state.paymentsSelected[index].balance)

                }
            }
            if (index === 'creditSelected'){
                if (state.paymentsSelected[index].balance){
                // difference =   difference.minus(state.paymentsSelected[index].balance)
                    difference =   (difference - state.paymentsSelected[index].balance)
                }
            }
            if (index === 'cardSelected'){
                if (state.paymentsSelected[index].balance){
                    // difference =   difference.minus(state.paymentsSelected[index].balance)
                    difference =   (difference - state.paymentsSelected[index].balance)

                }
            }
            if (index === 'paypalSelected'){
                if (state.paymentsSelected[index] !== null && state.paymentsSelected[index].balance){
                    // difference =   difference.minus(state.paymentsSelected[index].balance)
                    difference =   (difference - state.paymentsSelected[index].balance)
                }
            }
            if (index === 'azulSelected'){
                if ( state.paymentsSelected[index] !== null){
                // difference =   difference.minus(state.paymentsSelected[index].balance)
                    difference =   (difference - state.paymentsSelected[index].balance)
                }
            }
        }
        if(parseFloat(difference) < 0){
            difference = 0
        } 
        return  parseFloat(difference).toFixed(2)
    },
    amountSelected(state){
        let amount = 0;
        for(let index in state.paymentsSelected){

        if(index === 'creditNoteSelected'){
            for (let credit in state.paymentsSelected[index]){
                if(state.paymentsSelected[index][credit].balanceSelected)
                amount =   amount + parseFloat(state.paymentsSelected[index][credit].balanceSelected)
            }
        }
        if (index ===  'depositSelected'){
            for (let deposit in state.paymentsSelected[index]){
                if(state.paymentsSelected[index][deposit].balanceSelected)
                amount =   amount + parseFloat(state.paymentsSelected[index][deposit].balanceSelected)
            }
        }
        if (index === 'depositExpressSelected'){
            if (state.paymentsSelected[index].balance && state.paymentsSelected[index].images.length >= 1){
                amount =   amount + parseFloat(state.paymentsSelected[index].balance)
            }
        }
        if (index === 'cashSelected'){
            if (state.paymentsSelected[index].balance){
            amount =   amount + parseFloat(state.paymentsSelected[index].balance)
            }
        }
        if (index === 'creditSelected'){
            if (state.paymentsSelected[index].balance){
            amount =   amount + parseFloat(state.paymentsSelected[index].balance)
            }
        }
        if (index === 'stripeSelected'){
            if (state.paymentsSelected[index].balance){
            amount =   amount + parseFloat(state.paymentsSelected[index].balance)
            }
        }
        if (index === 'cardSelected'){
            if (state.paymentsSelected[index].balance){
            amount =   amount + parseFloat(state.paymentsSelected[index].balance)
            }
        }
        if (index === 'paypalSelected'){
            if (state.paymentsSelected[index] !== null && state.paymentsSelected[index].balance){
            amount =   amount + parseFloat(state.paymentsSelected[index].balance)
            }
        }
        if (index === 'azulSelected'){
            if ( state.paymentsSelected[index] !== null){
            amount =   amount + parseFloat(state.paymentsSelected[index].balance)
            }
        }

        }
        return amount
    },
    rnc: (state) => state.rnc,
    singlePaymentActive: (state) => {
        if(state.paymentsSelected.cardSelected?.balance > 0  || state.paymentsSelected.creditSelected?.balance > 0 || state.paymentsSelected.paypalSelected?.balance > 0){
            return true
        }else{
            return false
        }
    },
    normalPayments: (state) => {
        if(state.paymentsSelected.cashSelected?.balance > 0  || state.paymentsSelected.depositExpressSelected?.balance > 0 || state.paymentsSelected.creditNoteSelected.length
            || state.paymentsSelected.depositSelected.length){
            return true
        }else{
            return false
        }
    },
    
    deposits: (state) => state.deposits,
    creditsNote: (state) =>  state.creditsNote,
    partners:(state) =>  state.partners,
    deliveryQuotationID:(state) =>  state.deliveryQuotationID,
    note: (state) => state.note,
    customer_name: (state) => state.customer_name,
}
