import { ListService } from "./../services/list-service";
const Service = new ListService();

export default {
    async getLists({ commit }){
        try {
            commit('SET_LIST_LOADER', true)
            const lists = await Service.getWishLists();
            commit('SET_LISTS', lists )
        }catch (e) {
            commit('SET_ERROR', true)
            console.log(e)
            throw e;
        } finally {
            commit('SET_LIST_LOADER', false)
        }
    },
    async storeWishList({ commit }, wishList) {
        try {
            const list = await Service.storeWishList(wishList);
            commit('CREATE_LIST', list);
            commit('SET_CREATED_LIST', list);
            return list;
        }catch (e) {
            console.log(e)
            throw e;
        }
    },
    async addProductToList({ commit }, wishList) {
        try {
            commit('SET_EDITING_LISTS', {value: true, id: wishList.list_id})
            const res = await Service.updateWishList(wishList); // if we don't add any products to the wishlist then we are updating its name.
            if (wishList.action == 'change-name') commit("common/SET_SHORT_NOTIFY", { show: true, text: 'wishlist.short_notification_title', type: 'success' }, { root: true });
            commit('UPDATE_LIST', { _id: wishList.list_id, products: res.products, name: wishList.name, note: wishList.note });
            commit('SET_EDITING_LISTS', { value: false, id: wishList.list_id })
        }catch (e) {
            commit("common/SET_SHORT_NOTIFY", { show: true, text: 'common.notification.unexpected_error', type: 'danger' },  { root: true });
            console.log(e);
            throw e;
        }
    },
    async destroyList({commit}, list_id) {
        try {
            await Service.removeWishList(list_id);
            commit('DESTROY_LIST', list_id);
        }catch (e) {
            console.log(e)
            throw e;
        }
    },
    async removeProductFromList({ state, commit, dispatch}, wishList){
        try {
            const index = state.lists.findIndex(element => element._id === wishList.list_id);
            const restOfProducts = state.lists[index].products.filter(element => element.code !== wishList.product_code);
            if (restOfProducts.length == 0) {
                commit('SET_DELETED_LIST', wishList); //we will store the list that we just had to delete with its product.
                await dispatch('destroyList', wishList.list_id);
            }else {
                await Service.removeProductFromList({list_id: wishList.list_id, product_code: wishList.product_code}); // we need the product ID not the code in order to remove the product
                commit('REMOVE_PRODUCT',{ index, product_code: wishList.product_code });
            }
        }catch (e) {
            console.log(e);
            throw e;
        }
    },
    async restoreDeletedLists({state, dispatch}) {
        if (!state.lastDeleted.length) { return; }
        state.lastDeleted.map(async ({name, product_code, product_note}) => {
            await dispatch('storeWishList', {name, product_code, product_note: product_note || '', note:''})
        });
    }
};
