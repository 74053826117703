<template>
    <icon-base :width="size + 'px'" :fill="color">
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50"/>
        </svg>
    </icon-base>
</template>

<script>
export default {
name: 'iconArrow',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: ''
    },
    
}
}
</script>

<style scoped>
	.st0{fill:#BC2C35;}
	.st1{fill:#FFFFFF;}
	.st2{clip-path:url(#SVGID_2_);}
	.st3{fill:#EA3838;}
	.st4{fill:#72BF58;}
</style>
