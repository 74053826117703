<template>
    <div class="mt-5 advertisements-2 position-relative d-flex flex-column justify-content-center align-items-center py-4 cursor-pointer"
        @click="$router.replace({path: '/products/9495'})" v-if="getDimensions">
        <h6 class="position-relative mb-0">{{$t('home.ads.feel_the')}}</h6>
        <div class="d-flex aling-items-center mb-4 container-title">
            <sound-wave-right :size="26" class="me-2"/>
            <h1 class="mb-0 mt-1">{{$t('home.ads.music')}}</h1>
            <sound-wave-left :size="26" class="ms-2"/>
        </div>
        <img loading="lazy" src="../../assets/images/advertisements/ads-2/img-1.webp" alt="img-advertisements-2">
        <h3 class="mb-0 mt-4">XPODS VIDVIE</h3>
        <router-link to="/products/9495" class="btn-advertisements-2 position-absolute" tag="button">Buy now</router-link>
        <curved-bottom id="curved-bottom"/>
        <curved-bottom :color="`#F4D65B`" id="curved-bottom-2"/>
        <background-text id="background-text"/>
    </div>
    <div style="border-radius: 20px;overflow: hidden;" class="mt-4" v-else>
        <ads-02/>
    </div>
</template>

<script>
    import CurvedBottom from '../../assets/svg/ads/ads-2/CurvedBottom.vue'
    import BackgroundText from '../../assets/svg/ads/ads-2/BackgroundText.vue'
    import SoundWaveLeft from '../../assets/svg/ads/ads-2/SoundWaveLeft.vue'
    import SoundWaveRight from '../../assets/svg/ads/ads-2/SoundWaveRight.vue'
    import Ads02 from './svg/Ads02.vue'
    export default{
        name: "AdSm",
        components:{CurvedBottom, BackgroundText, SoundWaveLeft, SoundWaveRight, Ads02,},
        computed:{
            getDimensions(){
                return window.matchMedia('(min-width: 768px)').matches
            
            }
        }
    }
</script>
<style scoped>
    .advertisements-2{
        background-color: #dfeac0;
        border-radius: 20px;
        height: 575px;
        overflow: hidden;
    }
    .advertisements-2 > h6{
        font-size: 26px;
        font-family:'Myriad Pro Light' !important;
        transition: all 0.3s ease-in-out;
    }
    .advertisements-2 > h6::after, .advertisements-2 > h6::before{
        content: '';
        width: 85px;
        height: 1px;
        background: #000;
        position: absolute;
        top: 50%;
        margin-left: 20px;
    }
    .advertisements-2 > h6::before{
        right: 99px;
    }
    .advertisements-2 > .container-title{
        transition: all 0.3s ease-in-out;
    }
    .advertisements-2 > div > h1{
        font-size: 89px;
        color: #475458;
        font-family:'Myriad Pro Bold' !important;
        max-height: 62px;
        line-height: 78px;
        font-weight: 600;
    }
    .advertisements-2 > img{
        width: 200px;
        z-index: 4;
        transition: all 0.3s ease-in-out;
        transform: translate(10px);
    }
    .advertisements-2 > h3{
        font-size: 50px;
        font-weight: 700;
        z-index: 4;
        color: #9FB55A;
        transition: all 0.3s ease-in-out;
    }
    .btn-advertisements-2:hover{
        background-color: transparent;
        color: #fff;
        border: 1px solid #fff;
    }
    .btn-advertisements-2{
        border: 1px solid transparent;
        transition: transform 0.3s ease-in-out;
        z-index: 4;
        background-color: #fff;
        border: none;
        border-radius: 50px;
        font-size: 22px;
        font-weight: 700;
        color: #BED37D;
        width: 170px;
        bottom: -8%;
        opacity: 0;
    }
    .advertisements-2 #curved-bottom, .advertisements-2 #curved-bottom-2{
        position: absolute;
        bottom: -13%;
        width: 642px;
        right: -24px;
        z-index: 3;
        transition: all 0.3s ease-in-out;
    }
    .advertisements-2 #curved-bottom-2{
        transition: all 0.3s ease-in-out;
        z-index: 2;
    }
    .advertisements-2 #background-text{
        position: absolute;
        top: -10px;
        z-index: 1;
        padding: 20px;
    }
    .advertisements-2:hover > #curved-bottom-2{
        transform: translateY(-36px);
    }
    .advertisements-2:hover > .btn-advertisements-2{
        transform: translateY(-110px);
        opacity: 1;
    }
    .advertisements-2:hover h6,
    .advertisements-2:hover > #curved-bottom{
        transform: translateY(-10px);
    }
    .advertisements-2:hover > img{
        transform: translateY(-10px) scale(0.95);
    }
    .advertisements-2:hover .container-title{
        transform: translateY(-10px) scale(0.95);
    }
    .advertisements-2:hover h3{
        transform: translateY(50px);
        opacity: 0;
    }
    @media (max-width: 476px) {
        .advertisements-2 > h3{
            font-size: 40px;
        }
        .btn-advertisements-2{
            font-size: 15px;
            max-width: 130px;
            height: 45px;
            z-index: 1;
        }
    }
</style>
