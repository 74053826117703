<template>
    <MainModal @closeModal="$emit('successVerify')">
        <div class="d-flex flex-column justify-content-center py-2">
            <template v-if="!changeNumber">
                <div v-if="error" class="alert alert-danger color-light-main-red d-flex align-items-center border-0 py-2" role="alert">
                    <icon-error color="var(--light-main-red)" class="me-2"/>
                    {{ this.error }}
                </div>
                <p v-else class="alert alert-warning color-light-yellow subtitle-15 d-flex align-items-center border-0 py-2" role="warning">
                    <iconWarning class="me-2" />
                    <b>{{ $t("auth.info_verify_number") }} : <span style="color: #5A8CDB !important;" class="subtitle-15">{{ phone != null ? phone.slice(8) : user.entity.phone.slice(8) }}</span></b>
                </p>
                <span style="position: relative; top: -12px; left: 6px;" class="main-link subtitle-13" @click="changePhoneNumber()">{{$t('auth.change_phone_number')}}</span>
            </template>
            <template v-else>
                <div v-if="error" class="alert alert-danger color-light-main-red d-flex align-items-center border-0 py-2" role="alert">
                    <icon-error color="var(--light-main-red)" class="me-2"/>
                    {{ this.error }}<span class="main-link subtitle-13" @click="changePhoneNumber()">{{$t('auth.cancel_changes')}}</span>
                </div>
                <p v-else class="alert alert-warning color-light-yellow subtitle-15 d-flex align-items-center border-0 py-2" role="warning">
                    <iconWarning class="me-2" />
                    <b>{{ $t("auth.valid_number") + ' ' }} <span class="main-link subtitle-13" @click="changePhoneNumber()">{{$t('auth.cancel_changes')}}</span></b>
                </p>
            </template>
            <form @submit.prevent="" autocomplete="off" style="margin-top: 10px;">
                <main-input v-if="!changeNumber" :title="$t('auth.info_send_code')" id="code" ref="code" class="mb-2" placeholder="auth.code_placeholder" @changeValue="(data)=>{code = data}" :mask="'###-###'">
                    <icon-code :size="15" color="var(--dark-gray-200)" />
                </main-input>
                <span  v-if="!changeNumber" style="position: relative; left: 8px; top: -6px;" class="main-link subtitle-13 mb-1" @click="sendCode()">{{ $t("auth.try_again_code") }}</span>
                <VuePhoneNumberInput v-if="changeNumber" :preferred-countries="['DO', 'US']" class="vue-phone-number-input mb-5" style='position: relative; top: 6px;' :valid-color="color" :translations="translations" :default-country-code="countryCode" :border-radius="7" :error="isValidPhone == false" ref="phone" id="phone" v-model="phone" @update="handlePhoneNumberInput" />
                <div class="d-flex justify-content-end">
                    <main-button :disabled="loading"  :title="$t('auth.send')" icon @click="changeNumber ? sendCode() : verifyCode()">
                        <half-circle-spinner v-if="loading" :animation-duration="1000" :size="20" color="#fff" class="me-1"/>
                    </main-button>
                </div>
            </form>
        </div>
    </MainModal>
</template>

<script>
import IconError from '../../../common/svg/iconError.vue'
import IconCode from '../assets/svg/iconCode.vue'
import MainButton from '../../../common/components/buttons/MainButton.vue'
import MainInput from '../../../common/components/inputs/MainInput.vue'
import iconWarning from '../../../common/svg/iconWarning.vue'
import MainModal from '../../../common/components/modals/MainModal.vue'
import { http } from '../../../common/services/web-backend'
import VuePhoneNumberInput from 'vue-phone-number-input';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { HalfCircleSpinner } from 'epic-spinners'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    components:{
        IconError,
        IconCode,
        MainButton,
        MainModal,
        MainInput,
        VuePhoneNumberInput,
        iconWarning,
        HalfCircleSpinner
        // INSERT COMPONENT
    },
    name: "VerifyNumber",
    data(){
        return {
            loading: false,
            phone: null,
            error: false,
            code: '',
            changeNumber: false,
            countryCode: this.verifyUSTECH() ? 'US' : 'DO',
            color: 'yellowgreen',
            translations:{
                countrySelectorLabel: this.$t('auth.country_code'),
                countrySelectorError: this.$t('auth.selected_country'),
                phoneNumberLabel: this.$t('auth.phone_number'),
                example: this.$t('auth.example')
            },
            isValidPhone: null,
        }
    },
    props:{
        user:{
            type: Object
        },
    },
    methods:{
        focusInVerify(inputCode){
            setTimeout(() => { 
                let input = document.getElementById(inputCode)
                input.focus()
            }, 10);
        },
        handlePhoneNumberInput(value){
            this.isValidPhone = value.isValid
            this.detectCountry(value.nationalNumber, value.countryCode)
        },
        detectCountry(phone, country) {
            this.color = 'yellowgreen'
            const phoneUtil = PhoneNumberUtil.getInstance();
            try {
                const number = phoneUtil.parse(phone, country);
                const code = phoneUtil.getRegionCodeForNumber(number);
                if(code != null){
                    this.countryCode = code
                } else {
                    this.color = 'orangered'
                    this.isValidPhone = false
                }
            } catch (error) {
                console.log('Error al determinar el país del número de teléfono:', error);
            }
        },
        async sendCode(){
            try {
                this.loading = true
                this.error = false
                const send = await http.post('users/send-phone-verification-code', this.phone != null ? {phone: this.phone} : '')
                console.log(send);
                this.changeNumber = false
            } catch (e) {
                this.loading = false
                this.error = e.response.data.message
            }
            this.loading = false
        },
        async verifyCode(){
            try {
                this.loading = true
                const code = this.code.replace(/\D/g, '');
                const send = await http.post('users/verify-phone-verification-code', {verification_code: code})
                let notify = {
                    text: send.data.data.status,
                    type: 'success',
                    show: true,
                }
                this.$store.commit("common/SET_SHORT_NOTIFY", notify);
                this.$emit('successVerify')
            } catch (e) {
                this.loading = false
                this.error = e.response.data.message
            }
            this.loading = false
        },
        changePhoneNumber(){
            this.error = false
            this.phone = null
            if(this.changeNumber){
                const input = 'code'
                this.focusInVerify(input)
                this.changeNumber = false
            } else {
                const input = 'phone'
                this.focusInVerify(input)
                this.changeNumber = true
            }
        }
    },
    created(){
        const input = 'phone'
        this.focusInVerify(input)
        this.sendCode()
    }
}
</script>

<style>
</style>