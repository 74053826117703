<template>
    <figure v-if="source" v-lazyload class="image-product image__wrapper">
        <image-spinner class="image__spinner" />
        <img loading="lazy" :style="styleObject" :data-url="source" class="image__item" alt=". . ." />
    </figure>
</template>


<script>
import LazyLoadDirective from "@/modules/products/helpers/directives/LazyLoadDirective.js";
export default {
    name: "ImageProduct",
    components: {
        ImageSpinner: () => import('./ImageSpinner')
    },
    data() {
        return {
            url : null
        }
    },
    props: {
        styleObject: {
            type: Object,
            default: null
        },
        isProductPage: {
            type: Boolean,
        },
        source: {
            type: String,
            required: true
        }
    },
    directives: {
        Lazyload: LazyLoadDirective
    },
}
</script>

<style scoped lang="scss">
.image {
    &__wrapper {
        margin: 0;
        font: 1em sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;

    &:hover {
        cursor: pointer;
    }

    &.loaded {
        .image {
            &__item {
            visibility: visible !important;
            opacity: 1 !important;
            object-fit: contain;
            width: 100%;
        }
            &__spinner {
            display: none;
            width: 100%;
            }

        }
    }
    }
    &__item {
        transition: all 0.3s ease-in-out !important;
        opacity: 0;
        visibility: hidden;
    }
}
.image__wrapper{
    position: relative;
    width: 100%;
}
</style>
