import { parseResponse } from "@/common/helpers/responses/parser.js";
import { ProductService } from "../../products/services/product-service";
const service = new ProductService();

export default {
    async getProductByURL({rootGetters}, {product_id}){
        let branchSelected = rootGetters['branches/branchSelected']
        let userData = rootGetters['auth/userData']
        let code = 1
        if (this.isLogged) {
            code = userData.entity.id
        }
        const branchCode = branchSelected.codplace || 25;
        try {
            const res = await service.getProduct(product_id, branchCode, code);
            return res.product;
        } catch (error) {
            console.log(error)
        }
    },
    async getCategories({state}){
        // const StatusBranch = rootState

        try {
            state.error = null;
            state.loading = true;
            const categories = await service.getCategories();
            return categories;
        }catch (e) {
            if (e.response){
                state.error = e.response.data.message;
                throw parseResponse(e.response.data);
            }else{
                state.error = e.message;
                throw e;
            }
        }finally {
            state.loading =  false
        }
    },
    setHistory({ commit, state }, new_history){
        try {
            if (typeof new_history === 'string') { //The search is not a suggestion
                let time = new Date().valueOf(); // Date.now().getYear(); goes boom...
                new_history = {_id: time, id: time.toString(), description: new_history, byUser: true}
            }
            if (new_history.description !== '') {
                let full_history = state.searchHistory;
                let count_history = full_history.length; 
                
                full_history.map((history, index) => { //this works assuming we are refering to a suggested option
                    if (history.description == new_history.description) { //if we have said item already in our history
                        full_history.splice(index, 1); //we will remove it from the list
                        return;
                    }
                });
                count_history == 0 ? full_history.push(new_history) : full_history.unshift(new_history); // (unshift doesn't work on an empty array) 
                if (count_history >= 10) full_history.pop(); //once we reach the 20 items in our history we will remove the last one.
                commit('SET_HISTORY', full_history);
            }
            
        } catch (e) {
            return e;
        }
    },
    // {commit, state}, , id
    async removeFromHistory({commit, state}, id){
        let history = state.searchHistory;
        history.map((h, index) => {
            if (h.id == id) {
                history.splice(index, 1);
            }
        });
        commit('SET_HISTORY', history);
    }
};
