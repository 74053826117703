<template>
    <div class="button-group">
        <div class="button-group-container d-flex justify-content-around mx-auto">
            <div class="btn-container add-to-cart" @click="increment(product)">
                <span data-toggle="tooltip" :data-tooltip="loadingGroupAction ? 'Cargando..' :'Comprar'">
                    <icon-cart :color="'#c6d4db'" :size="18"/>
                </span>
            </div>
            <div class="btn-container add-to-wishlist ms-2" @click="$emit('showQuickView')">
                <span data-toggle="tooltip" data-tooltip="Vista rápida">
                    <icon-eye :color="'#c6d4db'" :size="17"/>
                </span>
            </div>
            <div class="btn-container share ms-2" @click="$emit('showShare')">
                <span data-toggle="tooltip" data-tooltip="Compartir">
                    <icon-share :color="'#c6d4db'" :size="16"/>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { productMixin } from "@/modules/products/helpers/mixin/product-mixin.js";
import { mapGetters } from 'vuex'
// import ModalService from "@/components/modals/ModalService";
export default {
name: "ButtonGroupAction",
props: {
    product: {
        type: Object,
        required: true,
    },
    disabledIncrement: {
        type: Boolean,
        default: false,
    },
},
mixins: [productMixin],
    components: {
        IconCart: () => import("@/common/svg/iconCart"),
        IconEye: () => import("@/common/svg/iconEye"),
        IconShare: () => import("@/common/svg/iconShare"),
    },
    computed: {
    isThereOne(){
        return this.quantity === 1;
    },
    ...mapGetters({
        askForLocation: "places/askForLocation",
        isLogged: "auth/isLogged",
        branchSelected: "branches/branchSelected",
        carts: "cart/getProducts",
        isPageDisable: "common/isPageDisable"
    }),
},
data(){
    return{
        quantityNotify: 0,
        urlImage: null,
        loadingGroupAction: false,
        groupAction: true
    }
},
// mixins: [productMixin],
    methods: {
        
    },
};
</script>

<style scoped>
.add-to-cart, .add-to-wishlist, .share {
    position: relative;
    animation: btn-animation;
    animation-duration: 2s;
    animation-fill-mode: both;
}
.add-to-wishlist {
    animation-delay: 0.1s;
}
.share {
    animation-delay: 0.2s;
}
@keyframes btn-animation {
    0% {
        bottom: -20px;
        opacity: 0;
    }
    25% {
        bottom: 0px;
        opacity: 1;
    }
}

.button-group-container {
    max-width: 150px;
}

.btn-container {
    border: 1px solid #c6d4db;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-container:hover {
    background: var(--main-red);
    color: white !important;
    cursor: pointer;
    border: none !important;
}
.btn-container:hover svg {
    fill: #fff !important;


}

/*
    Tooltip Styles
*/

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
    position: relative;
    z-index: 2;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    pointer-events: none;
    transition: var(--transition-1);
}

/* Position tooltip above the element */
[data-tooltip]:before {
    position: absolute;
    bottom: 180%;
    left: 50%;
    /* margin-bottom: 11px; */
    margin-left: -40px;
    padding: 4.5px;
    width: 80px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #282a2be3;
    color: #fff;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 10px;
    line-height: 1.2;
    font-weight: 500;
    font-family: "Franklin Gothic Medium", sans-serif;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
    position: absolute;
    bottom: 180%;
    left: 50%;
    margin-left: -5px;
    width: 0;
    border-top: 5px solid #282a2be3;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: "";
    font-size: 0;
    line-height: 0;
    margin-bottom: -4.5px;
}

/* Show tooltip content on hover */
.btn-container:hover [data-tooltip]:before,
.btn-container:hover [data-tooltip]:after {
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    bottom: 35px !important;
}


</style>