import {camelCase, startCase} from 'lodash';

const cipher =salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
};

const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
};

export function create_UUID(){
    let dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c==='x' ? r :(r&0x3|0x8)).toString(16);
    });
}

Number.prototype.formatMoney = function(c, d, t){
    var n = this,
        // c = isNaN(c = Math.abs(c)) ? 2 : c,
        // d = d == undefined ? "." : d,
        // t = t == undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};
export function valida_cedula(ced) {
    let c = ced.replace(/-/g,'');
    let cedula = c.substr(0, c.length - 1);
    let verificador = parseInt( c.substr(c.length - 1, 1));
    let suma = 0;
    if(ced.length < 11) { return false; }
    for (let i=0; i < cedula.length; i++) {
        let mod = "";
        if((i % 2) === 0){mod = 1} else {mod = 2}
        let res = cedula.substr(i,1) * mod;
        if (res > 9) {
            res = res.toString();
            let uno = res.substr(0,1);
            let dos = res.substr(1,1);
            res = eval(uno) + eval(dos);
        }
        suma += eval(res);
    }
    let el_numero = (10 - (suma % 10)) % 10;
    return (el_numero === verificador && cedula.substr(0,3) !== "000");
}

export function mediaQuery(){
    return window.matchMedia('(max-width: 704px)').matches
}
//viewPorts depending on bootstrap viewports

//min
// export function smMedia(){
//     return window.matchMedia('(min-width: 576px)').matches
// }
// export function mdMedia(){
//     return window.matchMedia('(min-width: 768px)').matches
// }
// export function lgMedia(){
//     return window.matchMedia('(min-width: 992px)').matches
// }
// export function xlMedia(){
//     return window.matchMedia('(min-width: 1200px)').matches
// }
// export function xxlMedia(){
//     return window.matchMedia('(min-width: 1400px)').matches
// }

// When involving the loop into the timeout it doesn't return at all, maybe promisify it?
// export function viewPort() {
//     let viewPort_;
//     const viewPorts = { xxl: xxlMedia(), xl: xlMedia(), lg: lgMedia(), md: mdMedia(), sm: smMedia(), mobile: true };
//     const temp = Object.keys(viewPorts);
//     for (let index = 0; index < temp.length; index++) {
//         const viewport = viewPorts[temp[index]];
//         if (viewport) {
//             viewPort_ = temp[index];
//             break;
//         }
//     }
//     return viewPort_;
// }

//max
// export function smMediaMax(){
//     return window.matchMedia('(max-width: 575px)').matches
// }
// export function mdMediaMax(){
//     return window.matchMedia('(max-width: 767px)').matches
// }
// export function lgMediaMax(){
//     return window.matchMedia('(max-width: 991px)').matches
// }
// export function xlMediaMax(){
//     return window.matchMedia('(max-width: 1999px)').matches
// }
// export function xxlMediaMax(){
//     return window.matchMedia('(max-width: 1399px)').matches
// }

export function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1);
        if(c.indexOf(name) === 0)
            return c.substring(name.length,c.length);
    }
    return null;
}

Window.prototype.storeStringFormat = function(description){
    description = description.trim();
    description = description.replace(/Sucursal/gi, "");
    description = description.trim();
    return description.charAt(0).toUpperCase()+description.slice(1).toLowerCase()
}

Number.prototype.withCommas = function() {
    return this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

String.prototype.hexEncode = function(){
    var hex, i;
    var result = "";
    for (i=0; i<this.length; i++) {
        hex = this.charCodeAt(i).toString(16);
        result += ("000"+hex).slice(-4);
    }
    return result
}

export const myCipherSeller = cipher('seller');
export const myDecipherSeller = decipher('seller');
export const myCipherUser = cipher('user');
export const myDecipherUser = decipher('user');



//This has to go each time the form changes or the Inputs does it.
export function checkInputs(inputs){
    //create inputs array
    let errorFound;
    //check the first one to have an error
    for (let index = 0; index < inputs.length; index++) {
        const input = inputs[index];
        if (input.$v.$error) {
            errorFound = index;
            input.$v.$touch();
            input.showErrorMessage();
            break;
        }
    }
    // remove the rest that have an error
    for (let index = (errorFound + 1); index < inputs.length; index++) {
        const input = inputs[index]; //
        if (input.$v.$error) { //
            input.hideErrorMessage();
        }
    }
}

export function toPascalCase(str) {
    return startCase(camelCase(str)).replace(/ /g, '');
}