import ApiFailResponse from "./ApiFailResponse";
import ApiErrorResponse from "./ApiErrorResponse";
import ApiSuccessResponse from "./ApiSuccessResponse";

export const parseResponse = (response) => {
    if (response.status) {
        switch (response.status) {
            case 'success':
                return new ApiSuccessResponse(response.data, response.message);
            case 'fail':
                return new ApiFailResponse(response.code, response.message, response.data);
            case 'error':
                return new ApiErrorResponse(response.code, response.message, response.data)
        }
    }
    console.log("Unknown response type encountered")
}
