import { http } from "../../../common/services/web-backend";

export class PartnersService {

    async getPartners(){
      try {
        const response = await http.get('users/partners'); //returns active partners
        return response.data.data
      } catch (e) {
          console.log(e);
          throw e
      }
    }
    async updatePartner(id, form){
      try {
        const response = await http.put(`users/partners/${id}`, form);
        return response.data.data
      } catch (e) {
          console.log(e);
          throw e
      }
    }
    async deletePartner(id){
      try {
        await http.delete(`users/partners/${id}`);
      } catch (e) {
          console.log(e);
          throw e
      }
    }
}
