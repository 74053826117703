<template>
    <icon-base :color="color" :size="size" viewBox="0 0 56 56" class="icon-pinterest">
        <circle id="Ellipse_56" data-name="Ellipse 56" cx="28" cy="28" r="28" fill="#ffdfe0"/>
        <g id="Icon_awesome-pinterest" data-name="Icon awesome-pinterest" transform="translate(14.063 14.063)">
            <path id="Icon_awesome-pinterest-2" data-name="Icon awesome-pinterest" d="M27.875,14.5A13.939,13.939,0,0,1,9.812,27.814a15.667,15.667,0,0,0,1.731-3.653c.169-.652.865-3.316.865-3.316a3.733,3.733,0,0,0,3.192,1.6c4.2,0,7.233-3.867,7.233-8.672,0-4.6-3.76-8.048-8.593-8.048-6.013,0-9.211,4.035-9.211,8.436,0,2.046,1.09,4.592,2.827,5.4.264.124.4.067.466-.185.045-.191.281-1.141.388-1.579a.417.417,0,0,0-.1-.4,5.489,5.489,0,0,1-1.028-3.181,6.031,6.031,0,0,1,6.294-6.047A5.5,5.5,0,0,1,19.7,13.842c0,3.771-1.905,6.384-4.384,6.384a2.022,2.022,0,0,1-2.063-2.518c.393-1.658,1.152-3.445,1.152-4.642a1.75,1.75,0,0,0-1.765-1.961c-1.4,0-2.523,1.444-2.523,3.383a5.022,5.022,0,0,0,.416,2.068s-1.377,5.834-1.63,6.924a13.491,13.491,0,0,0-.051,4A13.939,13.939,0,1,1,27.875,14.5Z" transform="translate(0 -0.563)" fill="#ca2127"/>
        </g>
    </icon-base>
</template>

<script>
export default {
    name:'iconPinterest',
    components:{
        IconBase: () => import('@/common/components/iconBase.vue')
    },
    props:{
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>

<style scoped>
</style>

