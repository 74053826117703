export default {
    SET_ASK_FOR_LOCATION(state, boolean){
        state.askForLocation = boolean
    },
    SET_BRANCHES(state, branches){
        state.branches = Object.freeze(branches);

    },
    SET_BRANCH(state, branch){
        this.commit("products/SET_PLACE_FILTER", branch);
        state.branchSelected = state.branches.find(element => element.codplace === branch) || null;
        localStorage.setItem("branchSelected", JSON.stringify(state.branchSelected))
    },
    OPEN_SELECT_BRANCH_MODAL(state, boolean){
        state.selectBranchModal = boolean
    }
};
