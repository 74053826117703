

export default {
  SET_TOKEN (state, token) {
    state.auth_token = token;
    if (token && token != 'null') {
      localStorage.setItem('token', state.auth_token);      
    } else {
      localStorage.removeItem('token');
    }
  },
  SET_REFRESH_TOKEN (_, token) {
    localStorage.setItem('refreshToken', token);
  },
  SET_USER (state, user) {
    state.auth_user = user;
    localStorage.setItem('user', JSON.stringify(user));
  },
  SET_ENTITY(state, entity){
    state.auth_user.entity = entity;
  },
  SET_STATE_PREFERRED_MFA(state, boolean){
    state.getPreferred = boolean;
  },
  UPDATE_USER(state, data){
    state.auth_user = data;
    localStorage.setItem('user', JSON.stringify( state.auth_user));
  },
  OPEN_MODAL(state, boolean){
    state.stateModal = boolean
  },
  OPEN_MODAL_NUMBER(state, boolean){
    state.stateModalNumber = boolean
  },
  SET_EMPLOYEE(state, payload){
    state.employee = payload
    localStorage.setItem('employee', JSON.stringify(payload));
  },
  SET_EMPLOYEE_TOKEN(state, payload){
    state.employeeToken = payload
    localStorage.setItem('employeeToken', payload);
  },
  SET_EMPLOYEE_REFRESH_TOKEN(state, payload){
    state.employeeRefreshToken = payload
    localStorage.setItem('employeeRefreshToken', payload);
  },
  SET_STATE_EMPLOYEE_ACTIVE_CODE(state, payload){
    state.employeeActiveCode = payload
  }
};
