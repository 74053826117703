<template>
    <icon-base :width="size + 'px'" :fill="color">
        <switch>
            <g i:extraneous="self">
                <g>
                    <path class="st5" d="M210.5,187.3H101.7c-15.8,0-28.6-12.8-28.6-28.6V29.1c0-15.8,12.8-28.6,28.6-28.6h108.7     c15.8,0,28.6,12.8,28.6,28.6v129.5C239.1,174.4,226.3,187.3,210.5,187.3z"/>
                    <path class="st49" d="M210.5,187.3H101.7c-15.8,0-28.6-12.8-28.6-28.6V29.1c0-15.8,12.8-28.6,28.6-28.6h108.7     c15.8,0,28.6,12.8,28.6,28.6v129.5C239.1,174.4,226.3,187.3,210.5,187.3z"/>
                    <path class="st5" d="M101.7,202.3c-24.1,0-43.6-19.5-43.6-43.6V54.7c0-1.4-1.1-2.5-2.5-2.5H39.5c-15.8,0-28.6,12.8-28.6,28.6     v140.1c0,15.8,12.8,28.6,28.6,28.6h129.5c15.8,0,28.6-12.8,28.6-28.6v-16.1c0-1.4-1.1-2.5-2.5-2.5H101.7z"/>
                </g>
            </g>
        </switch>
    </icon-base>
</template>

<script>
export default {
name: 'iconCopy',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
    type: Number,
    default: 20
    },
    color: {
    type: String,
    default: ''
    },
    enableBackground: {
    type: Boolean
    }
}
}
</script>

<style scoped>
</style>
