<template>
    <ul class="nav nav-tabs justify-content-between">
        <li class="nav-item" v-for="option in options" :key="option.value">
            <a class="nav-link me-3" style="white-space: nowrap;" :title="$t(option.title)" @click.prevent="$emit('select', option)" :class="{ 'active-tab' : selectedOption.value === option.value}" href="#">
                {{ $t(option.title) }}
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    name: "MainNavTab",
    props: {
        options:{
            type: Array,
            required: true
        },
        selectedOption: {
            type: Object
        },
    },
}
</script>

<style scoped>
.nav {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}
::-webkit-scrollbar {
    height: 0;
}
a{
    color: var(--gray-1001) !important;
}
a:hover {
    color: var(--dark-main-navy) !important;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.nav-tabs .nav-link {
    border: none !important;
    font-weight: 400;
    color: var(--gray-1001);
    padding: 0 !important;
    margin-bottom: 0px;
}

.nav-tabs {
    border-bottom: none !important;
    border: none;
    color: #c6d4db;
}
.active-tab {
    color: var(--dark-main-navy) !important;
    transition: var(--transition-1);
    text-align: center;

    font-weight: 700 !important;

    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    transition-timing-function: ease-out;
}

li > a {
    position: relative;
    font-size: 1.1rem;
    transition: var(--transition-1);
    color: #c6d4db;

}
a::after {
    content: '';
    display: block;
    position: relative;

    height: 4px;
    width: 100%;

    border-radius: 5rem;
    background-color: var(--main-red);
    top: -1px;

    transform: scaleX(0);
    transition: all .25s ease-out;
}
a.active-tab:after {
    transform: scaleX(1);
}

a::before {
    display: block;
    content: attr(title);
    height: 0;

    font-weight: bold;
    overflow: hidden;
    visibility: hidden;
}
@media (max-width: 576px) {
    .nav > .nav-item >.nav-link {
        font-size: 20px;
    }
}
</style>