import { http } from "../services/node-service";


export default {
    async getConfigurations({ commit }){
        try {
            const data = await http.get('configurations');
            let config = data.data.data
            if(!config.general.web_page){
                config.general['web_page'] = {
                    active: false
                }
            }
            commit('SET_CONFIGURATIONS', config);
        } catch (error) {
            console.log(error);
            throw error
        }
    }
};