<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 250 250" xml:space="preserve" :width="size + 'px'"
    :fill="color">
        <g>
            <path class="st0" d="M245.944,118.156c-18.587-19.061-39.74-34.609-63.959-45.761c-26.742-12.315-54.558-17.119-83.628-10.288
                    c-37.588,8.832-68.162,29.616-94.884,56.696c-4.116,4.171-3.831,7.658,0.579,12.228c12.872,13.338,27.224,24.88,43.037,34.531
                    c23.98,14.635,49.513,24.507,78.243,25.455c10.035-1.583,20.283-2.348,30.066-4.896c35.921-9.355,65.417-29.554,91.129-55.737
                    C250.632,126.201,250.376,122.702,245.944,118.156z M124.914,175.505c-28.144-0.069-50.899-22.899-50.83-50.998
                    c0.069-28.142,22.901-50.898,50.999-50.83c28.144,0.069,50.899,22.9,50.83,50.998C175.843,152.817,153.011,175.574,124.914,175.505
                    z"/>
            <path class="st0" d="M134.349,118.907c-4.523-10.056-1.591-18.904,6.66-26.594c-14.577-7.354-31.404-4.329-42.382,7.236
                c-11.067,11.658-13.188,29.268-5.196,43.141c8.052,13.977,24.41,21.087,39.872,17.33c14.261-3.465,26.021-15.963,26.78-28.762
                C148.686,133.038,139.301,129.917,134.349,118.907z"/>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'iconEye',
    props:{
    size: {
        type:Number,
        default: 20,
        },
    color:{
        type: String,
        default: ''
    }
    }
}
</script>
<style scoped>
    .st3{fill:#212E3D;}
</style>