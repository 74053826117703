<template>
    <section style="margin-bottom: 18vh;" class="container">
        <!-- <search-question :visibility="onViewport" ref="search" id="search"/> -->
        <contact-options :visibility="onViewport" ref="contact" id="contact"/>
    </section>
</template>

<script>
import ContactOptions from './ContactOptions.vue'
// import SearchQuestion from './SearchQuestion.vue'
export default {
    name: 'QuestionContact',
    components: {
        // SearchQuestion, 
        ContactOptions, 
    },
    data(){
        return {
            onViewport: false
        }
    },
    props: {
        visibility: {
            type: Boolean,
        }
    },
    watch: {
        visibility(val){
            if (val) {
                this.onViewport = true;
            }
        }
    },
}
</script>

<style>

</style>