<template>
    <icon-base width="100%" :fill="color" viewBox="0 0 338.6 347.8">
        <g id="Component_303_15_3_" transform="translate(296 3666.82)">
            <path id="Path_3073_4_" class="st7" d="M24-3319h-301.3c-10.2,0.1-18.5-8.1-18.5-18.3v-311.3c0.1-10.2,8.4-18.4,18.5-18.3H24   c10.2,0,18.5,8.2,18.5,14.1v311.3C42.6-3327.2,34.3-3319,24-3319z"/>
            <g id="Group_7560_4_" transform="translate(14)" class="st5">
                <text transform="matrix(1 0 0 1 -299.0886 -3624.6785)" class="st8 st9 st10" style="opacity: 0.2;">XPODS VIDVIE</text>
                <text transform="matrix(1 0 0 1 -299.0886 -3578.3372)" class="st8 st9 st10" style="opacity: 0.3;">XPODS VIDVIE</text>
                <text transform="matrix(1 0 0 1 -299.0886 -3531.9958)" class="st8 st9 st10" style="opacity: 0.4;">XPODS VIDVIE</text>
                <text transform="matrix(1 0 0 1 -299.0886 -3485.6545)" class="st8 st9 st10" style="opacity: 0.5;">XPODS VIDVIE</text>
                <text transform="matrix(1 0 0 1 -299.0886 -3439.3137)" class="st8 st9 st10" style="opacity: 0.6;">XPODS VIDVIE</text>
                <text transform="matrix(1 0 0 1 -299.0886 -3392.9714)" class="st8 st9 st10" style="opacity: 0.7;">XPODS VIDVIE</text>
                <text transform="matrix(1 0 0 1 -299.0886 -3346.6311)" class="st8 st9 st10" style="opacity: 0.8;">XPODS VIDVIE</text>
            </g>
            <g>
                <defs>
                    <path id="SVGID_7_" d="M24-3319h-301.3c-10.2,0.1-18.5-8.1-18.5-18.3v-311.3c0.1-10.2,8.4-18.4,18.5-18.3H24     c10.2,0,18.5,8.2,18.5,14.1v311.3C42.6-3327.2,34.3-3319,24-3319z"/>
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_7_" style="overflow:visible;"/>
                </clipPath>
                <g id="Group_7488_4_" transform="translate(0 6.893)" style="clip-path:url(#SVGID_2_);">
                    <path id="Path_3074_4_" class="st12" d="M-427.1-3397.5c0,0,53.6-145.9,215.6-109.3c190.8,43.1,250.4-33.1,275-129.2v248.9     C63.5-3387.2-381.2-3351.6-427.1-3397.5z"/>
                    <path id="Path_3075_4_" class="st13" d="M-299.3-3343.8l-2.9-146c25.5-5.9,55.5-6.5,90.7,1.4c190.8,43,250.4-33.1,275-129.2     l1.8,294.1C65.3-3323.5-302.9-3278.8-299.3-3343.8z"/>
                </g>
            </g>
            <g id="Group_7531_4_" transform="translate(60 53.004)">
                <text transform="matrix(1 0 0 1 -222.5105 -3682.3987)" class="st14 st15 st16">Feel the</text>
                <g id="Group_7496_4_" transform="translate(31.322 18.072)">
                    <line id="Line_253_4_" class="st17" x1="-112.9" y1="-3706.5" x2="-171.4" y2="-3706.5"/>
                    <line id="Line_254_4_" class="st17" x1="-263.1" y1="-3706.5" x2="-322.5" y2="-3706.5"/>
                </g>
                <text transform="matrix(1 0 0 1 -294.2903 -3624.5129)" class="st14 st9 st18">MUSIC</text>
                <g id="Group_7499_4_" transform="translate(-10 47.422)">
                    <g id="Group_7498_4_" class="st19">
                        <g id="Group_7497_4_">
                            <path id="Path_3081_4_" class="st20" d="M-290.5-3701.9c-2,4.5-1.7,9.7,0.8,14"/>
                            <path id="Path_3082_4_" class="st20" d="M-295.6-3707.2c0,0-8.3,10.7,0.7,25.4"/>
                            <path id="Path_3083_4_" class="st20" d="M-300.4-3713.4c0,0-16.4,18.4,0.5,38.9"/>
                        </g>
                    </g>
                </g>
                <g id="Group_7502_4_" transform="translate(308.433 47.422)">
                    <g id="Group_7501_4_" class="st19">
                        <g id="Group_7500_4_">
                            <path id="Path_3084_4_" class="st20" d="M-381.7-3701.9c2,4.5,1.7,9.7-0.8,14"/>
                            <path id="Path_3085_4_" class="st20" d="M-376.6-3707.2c0,0,8.3,10.7-0.7,25.4"/>
                            <path id="Path_3086_4_" class="st20" d="M-371.8-3713.4c0,0,16.4,18.4-0.5,38.9"/>
                        </g>
                    </g>
                </g>
            </g>
            <g>
                <defs>
                    <rect id="SVGID_9_" x="-207.8" y="-3519" width="162.9" height="224.8"/>
                </defs>
                <clipPath id="SVGID_4_">
                    <use xlink:href="#SVGID_9_" style="overflow:visible;"/>
                </clipPath>
            </g>
            <text transform="matrix(1 0 0 1 -248.4177 -3340.6528)" class="st21 st22 st23">XPODS VIDVIE</text>
        </g>
        <g id="Mask_Group_101_4_" transform="translate(-885.911 -4514.238)">
            <image href="../../../assets/images/advertisements/ads-2/img-1.webp" width="405" transform="matrix(0.363, 0, 0, 0.363, 977.878, 4594.46)"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'Ads02',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: ''
    },
    
}
}
</script>

<style scoped>
	.st0{opacity:0.2;clip-path:url(#XMLID_3_);fill:#D88D48;enable-background:new    ;}
	.st1{opacity:0.5;clip-path:url(#XMLID_3_);fill:#D88D48;enable-background:new    ;}
	.st2{opacity:0.5;}
	.st3{opacity:0.2;}
	.st4{opacity:0.15;}
	.st5{opacity:0.1;}
	.st6{opacity:0.9;fill:#FFFFFF;enable-background:new    ;}
	.st7{fill:#E0EBC1;}
	.st8{fill:none;stroke:#212E3D;stroke-miterlimit:10;}
	.st9{font-family:'Myriad Pro Bold';}
	.st10{font-size:48.181px;}
	.st11{clip-path:url(#SVGID_8_);}
	.st12{fill:#F4D65B;}
	.st13{fill:#BED37D;}
	.st14{fill:#212E3D;}
	.st15{font-family:'Myriad Pro Light';}
	.st16{font-size:21.4138px;}
	.st17{fill:none;stroke:#212E3D;stroke-linecap:round;stroke-miterlimit:10;}
	.st18{font-size:75px;}
	.st19{opacity:0.54;}
	.st20{fill:none;stroke:#B5C66D;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
	.st21{fill:#9FB55A;}
	.st22{font-family:'Myriad Pro Bold';}
	.st23{font-size:38.2388px;}
	.st24{fill:#FCE172;}
	.st25{opacity:0.6;clip-path:url(#SVGID_12_);fill:#F7D752;enable-background:new    ;}
	.st26{opacity:0.2;clip-path:url(#SVGID_12_);fill:#E0BF34;enable-background:new    ;}
	.st27{clip-path:url(#SVGID_12_);}
	.st28{opacity:0.6;fill:none;stroke:#EACA42;stroke-width:2.0002;stroke-miterlimit:10.0012;enable-background:new    ;}
	.st29{fill:none;}
	.st30{opacity:0.6;fill:none;stroke:#EACA42;stroke-width:2;stroke-miterlimit:10;enable-background:new    ;}
	.st31{opacity:0.6;}
	.st32{fill:#C1A11E;}
	.st33{opacity:0.18;clip-path:url(#Rectangle_1715_1_);fill:#A7B1C6;enable-background:new    ;}
	.st34{clip-path:url(#SVGID_14_);}
	.st35{opacity:0.18;fill:#C1A73D;enable-background:new    ;}
	.st36{opacity:0.8;}
	.st37{fill:none;stroke:#45505E;stroke-linecap:round;stroke-linejoin:round;}
</style>