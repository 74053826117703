

export default {
  //Adresses
  async SET_ADDRESSES(state, addresses) {
    state.addresses = (addresses) || [];

    if(!state.addressSelected)
    state.addressSelected = state.addresses.find(address => address.default) || state.addresses[0] || null;
  },
  SET_ADDRESS(state, id) {
    const address = state.addresses.find(a => a.id == id);
    state.addressSelected = address;

    localStorage.setItem('addressSelected', JSON.stringify(address))
  },
  SET_ADDRESS_AVAILABLE_LIST(state, data){
    state.addressAvailableList = data;
    localStorage.setItem('addressAvailableList', JSON.stringify(data))
  },
  SET_ALL_ADDRESS_AVAILABLE_LIST(state, data){
    state.allAddressCheckList = data;
  },
  SET_CARRIER_SELECTED(state, data){
    state.carrierSelected = data;
  },
  RESET_CARRIER(state){
      state.carrierSelected = {}
  },
  SET_STATUS(state, status){
    state.orderStatus = status;
  },
  SET_CARDS(state, cards){
    state.cards = cards
  },
  SET_INVOICE(state, invoice){
    state.invoice = invoice
    localStorage.setItem("invoice", JSON.stringify(invoice))
  },
  SET_QUOTES(state, payload){
    state.quotes = payload
  },
  ADD_PRODUCT_TO_QUOTATION(state, payload){
    const find = state.quotes.data.find(quotation => quotation.order_token_id == payload.id)
    const {order_details} = find
    
    order_details.push({
      product: {
        ...payload.product
      },
      quantity: payload.quantity ?? '',
      price_sold: payload.product.price,
      amount_sub: 0,
      amount_tax: 0,
      // amount_sub: payload.product.price - (payload.product.price * 0.18),
      // amount_tax: payload.product.price * 0.18,
      amount: (payload.product.price * parseInt(payload.quantity))
    })
    this.commit("account/CHANGED_QUOTATION", {
      quotation: payload.id, 
      product:{
        codarticulo: payload.product.codarticulo, 
        quantity: payload.quantity ?? 0, 
        oldQuantity: 0,
        delete: false,
        product: payload.product
      }
    })

  },
  ADD_PRODUCTS_TO_QUOTATION(state, payload){
    const find = state.quotes.data.find(quotation => quotation.order_token_id == payload.id)
    const {order_details, order_token_id} = find

    payload.products.forEach(product => {
      const index = order_details.findIndex(el=> el.product.codarticulo == product.codarticulo)
      if(index != -1){
        this.commit('account/UPDATE_QUANTITY_PRODUCT_QUOTATION',{
          id: order_token_id, 
          product:  product.codarticulo, 
          quantity: product.quantity
        })
        return
      }
      order_details.push({
        product: {
          ...product
        },
        quantity: product.quantity,
        price_sold: product.price,
        amount_sub: 0,
        amount_tax: 0,
        amount: (product.price * product.quantity)
      })
      this.commit("account/CHANGED_QUOTATION", {
        quotation: payload.id, 
        product:{
          codarticulo: product.codarticulo, 
          quantity: product.quantity, 
          oldQuantity: 0,
          product: product,
          delete: false
        }
      })
    });

  },
  DELETE_PRODUCT_FROM_QUOTATION(state, payload){
    const find = state.quotes.data.find(quotation => quotation.order_token_id == payload.id)
    const {order_details} = find
    const index = order_details.findIndex(product=> product.product.codarticulo == payload.product)
    order_details[index]['deleted'] = true

    this.commit("account/CHANGED_QUOTATION", {
      quotation: payload.id, 
      product:{
        codarticulo: payload.product, 
        quantity: order_details[index].quantity, 
        oldQuantity: order_details[index].quantity,
        delete: true,
        product: order_details[index].product
      }
    })

  },
  UPDATE_QUANTITY_PRODUCT_QUOTATION(state, payload){
    const find = state.quotes.data.find(quotation => quotation.order_token_id == payload.id)
    const {order_details} = find

    const index = order_details.findIndex(product=> product.product.codarticulo == payload.product)
    if(index != -1){
      let oldQuantity = order_details[index].quantity;
      order_details[index].quantity = parseInt(payload.quantity)
      order_details[index].deleted = false
      // order_details[index].amount_sub = (order_details[index].amount_sub * parseInt(payload.quantity))
      // order_details[index].amount_tax = (order_details[index].amount_tax * parseInt(payload.quantity))
      order_details[index].amount = (order_details[index].price_sold * parseInt(payload.quantity))

      if(!payload.isChaged){
        this.commit("account/CHANGED_QUOTATION", {
            quotation: payload.id, 
            product:{
              codarticulo: payload.product, 
              quantity: payload.quantity, 
              oldQuantity: oldQuantity,
              delete: false,
              product: order_details[index].product
            }
          })
        }
    }else{
      this.commit('account/ADD_PRODUCT_TO_QUOTATION', {id: payload.id, product: payload.response, quantity: payload.quantity})
    }
  },
  SET_NOTE_PRODUCT_QUOTATION(state, payload){
    const find = state.quotes.data.find(quotation => quotation.order_token_id == payload.id)
    const {order_details} = find
    
    const index = order_details.findIndex(product=> product.product.codarticulo == payload.product)
    order_details[index].product.note = payload.value
    order_details[index].note = payload.value

    // this.commit("account/CHANGED_QUOTATION", {quotation: payload.id, codarticulo: payload.product})

  },
  CHANGED_QUOTATION(state, payload){
    const quotes = state.changedQuotes
    const data = { quotation: payload.quotation, products: [] }
    const quotation = quotes.find(quotation=> quotation.quotation == payload.quotation)

    if (state.changedQuotes.length >= 5 && !quotation) {
      this.dispatch('account/getQuotationByID', {id: data.quotation, refresh: true})
      const error = {
        show: true,
        title: `Limite de cotizaciones simúltaneas superado`,
        description: 'No puedes modificar más de 5 cotizaciones al mismo tiempo, guarda o cancela las cotizaciones modificadas.', 
      }
      this.commit('common/SHOW_MODAL_ERROR', error)
      return;
    }

    if(!quotation){
      data.products.push(payload.product)
      state.changedQuotes.push(data)
    }else{
      const index = quotation.products.findIndex(product => product.codarticulo == payload.product.codarticulo)
      if(index == -1){
        quotation.products.push(payload.product)
      }else{
        Object.assign(quotation.products[index], payload.product)
        // quotation.products[index] = payload.product
      }
    }
    localStorage.setItem('changedQuotes', JSON.stringify(state.changedQuotes))
  },
  REMOVE_FROM_CHANGED_QUOTATION(state, id){
    const index = state.changedQuotes.findIndex(quotation=> quotation.quotation == id)
    if(index != -1){
      state.changedQuotes.splice(index, 1)
    }
    localStorage.setItem('changedQuotes', JSON.stringify(state.changedQuotes))
  },
  RESET_CHANGED_QUOTATION(state){
    state.changedQuotes = []
    localStorage.removeItem("changedQuotes")
  },
  SET_QUOTATION_TO_QUOTES(state, payload){
    let index = state.quotes.data.findIndex(quotation=> quotation.order_token_id == payload.order_token_id)
    Object.assign(state.quotes.data[index], payload)
  },
  SELECT_QUOTATION(state, quotation){
    state.selectedQuotation = quotation
    if (quotation != null) {
      localStorage.setItem("selectedQuotation", quotation)
    } else {
      localStorage.removeItem("selectedQuotation")
    }
  },
  SET_STATE_FOCUS_QUOTATION(state, payload){
    state.focusQuotation = payload
  },
  SET_STATE_INVOICE_RETURN_MODAL(state, boolean){
    // console.log('hola')
    state.openInvoiceReturnModal = boolean
  },
  SET_STATE_SELECTED_QUOTATION_FOR_RETURN(state, value){
    // console.log('llego 3')
    state.selectedQuotationForReturn = value
  }
};
