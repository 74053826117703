<template>
    <div>
        <hr class="home-section"/>
        <section class="home-section">
            <div class="title-container d-flex align-content-center">
                <h1 class="color-main-navy weight-300 ps-1 ms-2 mb-0">Season <b class="weight-500">Products</b></h1>
            </div>
            <div class="season-cards-container row d-flex flex-lg-row justify-content-between mt-4 pt-sm-3">
                <div class="cursor-pointer col-lg-6 col-6 mb-md-0 pb-0" v-for="({value, name, format}, index) in cards" :key="name" @click="navigateTo({name, value})"
                :class="{'mb-4 mb-md-5': index < 2 && viewPort('sm')}">
                    <div class="season-card d-flex flex-column overflow-hidden">
                        <div class="image-section overflow-hidden">
                            <img alt="Seasson" loading="lazy" :src="require(`./${name}.${format}`)" class="position-relative" :class="`${name}-img`"/>
                        </div>
                        <div class="information-section">
                            <h4 class="title color-main-navy weight-600 mb-1">{{$t(`home.season.${name}_title`)}}</h4>
                            <p class="description weight-300 color-dark-gray-200 mb-2 pb-1 d-none d-md-block">{{$t(`home.season.${name}_desc`)}}</p>
                            <h4 class="pricing color-dark-main-navy mb-0 mb-md-2">{{$t(`home.season.${name}_pricing`)}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    name: 'SeasonCards',
    data(){
        return {
            cards: [
                { name: 'accesories', format: 'webp', value: this.verifyUSTECH() ? 22  :  2 }, 
                { name: 'chargers', format: 'webp', value: this.verifyUSTECH() ? 3 : 11 },
            ]
        }
    },
    methods: {
        navigateTo(category){
            this.$store.commit("products/SET_CATEGORY", category);
            this.$router.replace({path: '/shop'}).catch(() => {})
        },
    },
    created(){
        let cards = [
            { name: 'covers', format: 'webp', value: this.verifyUSTECH() ? 17 : 12 },
            { name: 'smart_watches', format: 'webp',  value: this.verifyUSTECH() ? 16 : 2 }
        ]
        if(this.viewPort('sm')) {
            cards.forEach(card => {
                this.cards.push(card)
            });
        }
    }
}
</script>

<style scoped>
.title-container::before {
    content: '';
    position: relative;
    background-color: var(--main-red);
    width: 7px;
    border-radius: 2.5px;
}
.title-container h1 {
    font-family: 'Myriad Pro Light' !important;
    font-size: 42px;
}
.title-container h1 b {
    font-family: 'Myriad Pro Bold' !important;
}
.season-card {
    box-shadow: 3px 6px 20px #7C879329; /* dark-gray-400*/
    border-radius: 17px;
}
.season-card img {
    width: 100%;
    transition: all 0.35s var(--alt-ease-in-out);
    z-index: -1;
}
.information-section {
    height: 38%;
    padding: 28px 21px;
}
.title {
    font-size: 24px;
}
.description {
    font-size: 16px;
}
.pricing {
    font-size: 21px;
}
.accesories-img {
    bottom: 27px;
}
.chargers-img {
    bottom: 40px;
}
.season-card:hover h4.title {
    color: var(--blue-700) !important;
}
.season-card:hover img {
    transform: scale(1.1);
}
@media (max-width: 768px) {
    .title-container h1 {
        font-size: 32px;
    }
    .title-container::before {
        width: 4px;
        top: -1px;
    }
    .title {
        font-size: 15px;
    }
    .pricing {
        font-size: 14px;
        color: var(--dark-gray-200) !important;
        font-weight: 400 !important;
    }
    .season-card {
        height: auto;
    }
    .image-section {
        height: 142px;
    }
    .information-section{
        padding: 10px;
    }
}
@media (max-width: 991px) {
    p.description {
        font-size: 15px;
    }
    /* .season-card {
        height: 450px !important;
    } */
}
@media (min-width: 576px) {
    .image-section img {
        width: inherit;
    }
    .image-section {
        width: inherit;
    }
    .title-container::before {
        top: -3px;
    }
    .image-section {
        width: 100%;
    }
    .information-section {
        padding: 25px 20px;
    }
}
@media (min-width: 768px) {
    .season-card {
        height: 450px;
    }
    .chargers-img {
        top: -10px;
    } 
    .accesories-img {
        top: -5px;
    }
    .pricing {
        font-weight: 500;
    }
}
@media (min-width: 992px) {
    .season-card {
        height: 500px;
    }
    .season-cards-container {
        --bs-gutter-x: 2.4rem;
    }
    .information-section {
        height: 30%;
        padding-block: 20px;
    }

    .chargers-img {
        top: -50px;
    } 
    .accesories-img {
        top: -40px;
    }
}
@media (min-width: 1200px) {
    .information-section {
        height: 38%;
        padding-block: 21px;
    }
    .title {
        font-size: 24px;
    }
    .description {
        font-size: 18px;
    }
    .pricing {
        font-size: 21px;
    }
    .chargers-img {
        top: -140px;
    } 
    .accesories-img {
        top: -35px;
    }
}
@media (min-width: 1400px) {
    .information-section {
        padding: 24px 30px;
    }
    .chargers-img {
        top: -232px;
    } 
    .accesories-img {
        top: -105px;
    }

}
</style>