<template>
<section style="top: 84px" :class="{'on-viewport': onViewport}" class="mt-0 position-relative">
    <div class="container branches-father">
        <div class="branches-description">
            <h4 class="size-30 color-dark-main-navy-800 weight-700">{{$t("home.contact.branches")}}</h4>
            <p class="col-12 col-md-10 weight-300 color-main-navy">{{$t("home.contact.desc_1")+( options.length - 1 )+$t("home.contact.cities")+$t("home.contact.desc_2") }}</p>
        </div>
        <div class="branches-map-container">
            <MglMap ref="map" class="map-container mt-2" :min-zoom="3" :zoom="defaultSize()" :center="defaultCountry()" :accessToken="accessToken" :dragRotate="false" :mapStyle="mapStyle" @load="onMapLoaded">
                <MglMarker v-show="branch.active" :coordinates="[branch.long, branch.lat]" color="#BC2C35" v-for="(branch, index) in branches" :key="index">
                    <div slot="marker">
                        <icon-shop :size="40"/>
                    </div>
                </MglMarker>
                <MglGeolocateControl trackUserLocation position="top-right" />
                <MglNavigationControl position="top-right" style="margin-top: 30px; margin-right: 30px"/>
            </MglMap>
        </div>
    </div>
    <div class="branches-father branches-list container row flex-column m-auto">
        <div class="col-md-12 branches-container position-relative">
            <div class="row">
                <div class="d-flex justify-content-between align-items-center flex-wrap col-md-12 mb-4 mb-sm-3 px-0">
                    <h5 class="size-26 color-dark-main-navy-800 weight-600 col-12 col-lg-6 mb-3 mb-sm-0 ">{{ $t("home.contact.All_branches") }}</h5>
                    <div class="d-flex flex-wrap justify-content-start justify-content-md-end align-items-center col-12 col-lg-6">
                        <h6 class="size-16 color-dark-main-navy-800 weight-400 mb-2 mb-sm-0 me-0 me-sm-2" style="width: 110px">{{ $t("home.contact.search") }}</h6>
                        <main-select style="min-width: 300px" :options="options" :selectedOption="selectedOption" @clickHandler="(data) => {selectedOption = data}" summaryRef="branch-filter" />
                    </div>
                </div>
            </div>
            <div class="row branches-content flex-wrap mx-xl-2">
                <a class="d-flex col-sm-6 col-lg-4 py-4 py-md-4 px-md-4 py-xxl-5 px-xxl-5" v-for="(branch, index) of filteredBranches" :key="index" target="_blank" :href="'https://www.google.com/maps/place/'+branch.lat+','+branch.long" >
                    <card class="w-100 branch-card-container" style="padding: 15px 28px; border-radius: 15px; height: 361px;">
                        <MglMap ref="card-map" :attributionControl="false" :minZoom="13" :center="[branch.long, branch.lat]" :accessToken="accessToken" :dragRotate="false" :mapStyle="mapStyle" @load="onCardMapLoaded">
                            <MglMarker :coordinates="[branch.long, branch.lat]" color="#c40316"></MglMarker>
                        </MglMap>
                        <div style="height: 100px">
                            <h2 class="size-18 color-dark-main-navy-800 weight-600 text-center mb-1">{{ branch.description | textFormat}}</h2>
                            <p class="overflow-hidden size-15 color-dark-gray-400 text-center mb-0" style="text-transform: capitalize; height: 100px;" data-toggle="tooltip" data-placement="top" :title="branch.address | textFormat" >
                                {{ branch.address.toLowerCase()}}
                            </p>
                        </div>
                        <a class="size-14 text-center blue-600" target="_blank">{{ $t("home.contact.google_maps") }}</a>
                    </card>
                </a>
            </div>
        </div>
    </div>
    <div class="bg-branches" :style="{height: backgroundHeight}"></div>
</section>
</template>

<script>
import { MglMap, MglMarker, MglNavigationControl, MglGeolocateControl } from 'v-mapbox'
import Mapbox from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'v-mapbox/dist/v-mapbox.css';

import { addressesMixin } from "@/modules/account/helpers/mixins/addresses-mixin.js"
import iconShop from "@/modules/home/assets/svg/iconShop.vue";
import { mapGetters } from "vuex"
import MainSelect from "@/common/components/selects/MainSelect.vue";
import Card from "@/common/components/cards/Card.vue";

export default {
    name: "Branches",
    props: {
        visibility: {
            type: Boolean,
        }
    },
    components:{ MglMap, MglMarker, MainSelect, MglNavigationControl, MglGeolocateControl, iconShop, Card},
    mixins: [addressesMixin],
    data() {
        return {
            accessToken: process.env.VUE_APP_MAPBOX_KEY, 
            mapStyles: {
                Mapa: "ckgwp0y0g06r219pb8oqihafv",
                Satellite: "ckgwp7pg4058g19qk1bv044er",
            },
            layers: [],
            selectedOption: { title: "home.contact.All_branches", value: "TODAS"},
            onViewport: false,
        }
    },
    methods: {
        async onMapLoaded(event) {
            this.mapbox = Mapbox;
            this.sources.forEach((source, id)=>{
                event.map.addSource('limit-areas-'+id, source);
                event.map.addLayer(
                    {
                        'id': 'limit-areas-fill-'+id,
                        'type': 'fill',
                        'source': 'limit-areas-'+id,
                        'layout': {},
                        'paint': {
                            'fill-color': '#5A8CDB',
                            'fill-opacity': 0.5
                            }
                });
            });
            event.map.on('style.load', async () => {
                this.sources.forEach((source, id) => {
                event.map.addSource('limit-areas-'+id, source);
                event.map.addLayer(
                    {
                        id: 'limit-areas-fill-'+id,
                        type: 'fill',
                        source: 'limit-areas-'+id,
                        layout: {},
                        paint: {
                            'fill-color': '#c40316',
                            'fill-opacity': 0.5
                        }
                    },
                );

                })
            });
            if(this.vidvie != 1){
                setTimeout(() => {
                    event.map.zoomTo(6.4, { animate: true, duration: 600, essential: true })
                }, 6000);
            }
        },
        async onCardMapLoaded(event) {
            this.sources.forEach((source, id) => {
                event.map.addSource('limit-areas-'+id, source);
                event.map.addLayer(
                    {
                        id: 'limit-areas-fill-'+id,
                        type: 'fill',
                        source: 'limit-areas-'+id,
                        layout: {},
                        paint: {
                            'fill-color': '#5A8CDB',
                            'fill-opacity': 0.5
                        }
                    },
                );

            })
            event.map.on('style.load', async () => {
                this.sources.forEach((source, id) => {
                event.map.addSource('limit-areas-'+id, source);
                event.map.addLayer(
                    {
                        id: 'limit-areas-fill-'+id,
                        type: 'fill',
                        source: 'limit-areas-'+id,
                        layout: {},
                        paint: {
                            'fill-color': '#c40316',
                            'fill-opacity': 0.5
                        }
                    },
                );

                })
            });
        },
    },
    computed: {
        ...mapGetters({
            branches: "branches/branches",
            vidvie:  "home/vidvie"
        }),
        mapStyle() {
            return `mapbox://styles/fbernabe/clasqq2sj002w14qp6xg4dkus`
        },
        sources() {
            let sources = [];
            for (const layer of this.layers){
                sources.push({
                    'type': 'geojson',
                    'data': layer
                });
            }
            return sources;
        },
        options() {
            let cities = [];
            this.branches.map( ({city_name}) => {
                if (cities.indexOf(city_name) === -1) 
                    cities.push(city_name);
            });
            cities = cities.map(city => { return {title: city, value: city}})
            cities.unshift({title: "home.contact.All_branches", value: "TODAS"})
            return cities;
        },
        filteredBranches() {
            if (this.selectedOption.value == "TODAS") {
                return this.branches;
            }else {
                return this.branches.filter(branch => branch.city_name == this.selectedOption.value)
            }
        },
        backgroundHeight() {
            if (this.filteredBranches.length == 3){
                return 'calc(55% - 6px)';
            } else if (this.filteredBranches.length > 6) {
                return 'calc(73% - 6px)';
            }else if (this.filteredBranches.length == 5) {
                return 'calc(66% - 2px)';
            }else{
                return 'calc(55% - 2px)'
            }
        }
    },    
    watch: {
        visibility(val) {
            if (val) {
                this.onViewport = true;
            }
        }
    },
}
</script>

<style scoped>
    .branches-description, .branches-container, .map-container {
        opacity: 0;
    }
    .branches-list {
        --bs-gutter-x: 1.05rem;
    }
    .on-viewport > div.branches-father > .branches-description {
        animation: slide-from-bottom 600ms both ease-in-out;
    }

    .on-viewport > div.branches-father > .branches-container {
        animation: slide-from-bottom 600ms both ease-in-out;
    }

    @keyframes slide-from-bottom {
        from {
            opacity: 0;
            transform: translateY(10px);
        } to {
            opacity: 1;
            transform: translateY(0px);
        }
    }
    
    .mgl-map-wrapper {
        overflow: hidden;
        height: 520px;
    }
    .mgl-map-wrapper >>> .mapboxgl-map > .mapboxgl-control-container > .mapboxgl-ctrl-bottom-left{
        margin-bottom: 10px;
        margin-left:10px;
    }
    .mgl-map-wrapper >>> .mapboxgl-map > .mapboxgl-control-container > .mapboxgl-ctrl-top-right{
        margin-top: 10px;
        margin-right: 10px;
    }

    .on-viewport > div.branches-father > .branches-map-container > .map-container {
        animation: map-animation 600ms both ease-in-out;
    }

    @keyframes map-animation {
        0%{
            opacity: 0;
            border-radius: 15px;
            transform: translateY(120px);
        }
        50% {
            border-radius: 30px;
        }
        100% {
            border-radius: 30px;
            opacity: 1;
            transform: translateY(0px);
        }
    }

    .branches-container {
        margin-top: 150px;
    }

    .select-container >>> ul > li, .select-container >>> div > span {
        text-transform: capitalize;
    }
    .select-container >>> div > span {
        padding: 5px 0;
    }
    .card-container > div > a {
        opacity: 0;
        transform: translateY(20px);
        transition: all .25s ease-in-out;
    }
    .card-container:hover > div > a {
        opacity: 1;
        transform: translateY(5px);
    }
    .card-container:hover > div > .mgl-map-wrapper >>> .mapboxgl-map {
        transform: scale(1.35);
    }
    .card-container > div > .mgl-map-wrapper >>> .mapboxgl-map {
        transition: all .25s ease-in-out;
    }
    .card-container > div > .mgl-map-wrapper {
        border-radius: 3px;
        margin-bottom: 15px;
    }
    .card-container > div > .mgl-map-wrapper >>> .mapboxgl-map > .mapboxgl-control-container{
        visibility: hidden !important;
    }

    @media (max-width: 768px) {     
        .mgl-map-wrapper >>> .mapboxgl-map > .mapboxgl-control-container > .mapboxgl-ctrl-bottom-right{
            margin-bottom: 10px;
            margin-right: 10px;
        }
    }
    @media (min-width : 768px) {     
        .mgl-map-wrapper >>> .mapboxgl-map > .mapboxgl-control-container > .mapboxgl-ctrl-bottom-right{
            display: none;
            margin-right: 20px;
        }
    }
    @media (min-width: 992px) {
        .full-background-image {
            background-color: #DFE6ED29;
        }
        @keyframes map-animation {
            0% {
                opacity: 0;
                border-radius: 15px;
                transform: translateY(120px) scale(1.4);
            }
            50% {
                border-radius: 20px;
            }
            100% {
                border-radius: 20px;
                opacity: 1;
                transform: translateY(0px) scale(1);
            }
        }
    }
    .bg-branches {
        position: absolute;
        width: 100%;
        background: #F9FBFC;
        top: 400px;
        z-index: -1;
    }
</style>