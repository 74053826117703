// import each language file

//common
import common_en from '@/common/lang/en'
import common_es from '@/common/lang/es'

//home
import home_en from '@/modules/home/lang/en'
import home_es from '@/modules/home/lang/es'

//account
import account_en from '@/modules/account/lang/en'
import account_es from '@/modules/account/lang/es'

//auth
import auth_en from '@/modules/auth/lang/en'
import auth_es from '@/modules/auth/lang/es'

//layout
import layout_en from '@/modules/layout/lang/en'
import layout_es from '@/modules/layout/lang/es'

//product
import product_en from '@/modules/products/lang/en'
import product_es from '@/modules/products/lang/es'

//branch
import branch_en from '@/modules/branches/lang/en'
import branch_es from '@/modules/branches/lang/es'

//cart
import cart_en from '@/modules/cart/lang/en'
import cart_es from '@/modules/cart/lang/es'

//checkout
import checkout_en from '@/modules/checkout/lang/en'
import checkout_es from '@/modules/checkout/lang/es'

//checkout
import wishlist_en from '@/modules/wishlist/lang/en'
import wishlist_es from '@/modules/wishlist/lang/es'

// add all imported language files
export default {
    en: {
        account: account_en,
        auth: auth_en,
        layout: layout_en,
        product: product_en,
        branch: branch_en,
        cart: cart_en,
        checkout: checkout_en,
        common: common_en,
        home: home_en,
        wishlist:wishlist_en
    } ,
    es:{
        account: account_es,
        auth: auth_es,
        layout: layout_es,
        product: product_es,
        branch: branch_es,
        cart: cart_es,
        checkout: checkout_es,
        common: common_es,
        home: home_es,
        wishlist: wishlist_es
        
    }
}