<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 1158 223">
        <text id="Serie_Premium" data-name="Serie Premium" transform="translate(2.5 175.5)" fill="none" stroke="#fff" stroke-width="2.5" font-size="178" font-family="Myriad Pro Bold" font-weight="600"><tspan x="0" y="0">{{text}}</tspan></text>
    </icon-base>
</template>

<script>
export default {
    name: 'BackgroundText',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        },
        enableBackground: {
            type: Boolean
        },
        text:{
            type: String
        }
    }
}
</script>

<style scoped>
@font-face {
    font-family: 'Myriad Pro Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Myriad Pro Bold'), url('../../../../../common/assets/fonts/myriad-pro/MYRIADPRO-BOLD.woff') format('woff');
}

/* #Group_7560{
        animation: move-letters 2s infinite ease-in-out;
        transition:all 0.5s ease-in-out;
    }
    @keyframes move-letters {
        from{
            transform: translateX(-300px);
        }
        to{
            transform: translateX(0px);
        }
    } */
</style>

