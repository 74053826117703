<template>
    <icon-base :color="color" :size="size">
        <g id="grid">
            <g>
                <path d="M209.69,33.48c2.61-2.47,4.83-4.83,7.3-6.88c4.73-3.92,10.77-2.14,12.04,3.86    c3.66,17.26,7.13,34.57,10.36,51.92c1.08,5.82-4.16,9.82-9.88,7.97c-14.79-4.76-29.58-9.55-44.34-14.4    c-7.65-2.52-8.95-8.07-3.26-13.93c2.23-2.3,4.75-4.31,7.43-6.72c-14.93-13.41-31.24-21.45-49.93-24.37    C78.39,21.37,24.01,73.01,30.27,134.46c0.77,7.57-2.95,11.11-10.31,9.72c-3.82-0.72-7.62-1.51-11.42-2.36    c-5.9-1.32-7.63-3.42-7.91-9.82c-1.26-28.28,5.84-54.26,22.02-77.54c42.77-61.5,127.86-72.16,184.39-23.2    C207.79,31.91,208.54,32.52,209.69,33.48z"/>
                <path d="M39.74,215.69c-2.27,2.52-4.07,4.84-6.2,6.82c-4.93,4.58-11.19,2.86-12.6-3.71    c-3.62-16.95-7.05-33.94-10.28-50.97c-1.12-5.9,3.96-10.07,9.7-8.23c15.11,4.84,30.21,9.74,45.26,14.77    c6.57,2.2,7.94,7.75,3.27,12.81c-2.39,2.59-5.04,4.93-7.85,7.65c11.24,10.42,23.67,17.45,37.66,21.56    c64.21,18.84,127.84-34.21,121.04-100.83c-0.8-7.83,2.95-11.25,10.72-9.73c3.66,0.71,7.3,1.48,10.94,2.3    c5.89,1.34,7.64,3.33,7.94,9.27c1.61,32.85-7.85,62.15-28.98,87.34c-45.13,53.8-123.73,59.94-176.91,14.07    C42.46,217.96,41.47,217.15,39.74,215.69z"/>
            </g>
        </g>
    </icon-base>
</template>

<script>
export default {
    name:'iconChange',
    components:{
        IconBase: () => import('@/common/components/iconBase.vue')
    },
    props:{
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>


