export default () => ({

    orderConfirm: JSON.parse(localStorage.getItem('orderConfirm')) || null,
    deliveryQuotationID: localStorage.getItem('deliveryQuotationID') || null,
    checkoutSteps:JSON.parse(localStorage.getItem('checkoutSteps')) || {
        addressOptionSelected: 0,
        addressTab: true,
        confirmationTab: false,
        paymentTab: false,
    },
    partnerSelected: null,
    paymentsSelected: JSON.parse(localStorage.getItem('paymentsSelected')) ||  {
        azulSelected: null,
        cashSelected: {
            balance: 0,
            select: false,
            fullPay: false
        },
        creditSelected: {
            balance: 0,
            select: false,
            fullPay: false
        },
        stripeSelected: {
            balance: 0,
            paymentIntentId: null,
            select: false,
            fullPay: false
        },
        depositExpressSelected: {
            balance: 0,
            images: []
        },
        depositSelected: [],
        creditNoteSelected: [],
        paypalSelected: {
            data: null,
            balance: 0
        },
        cardSelected: {
            card : null,
            balance: 0,
            detailts: null,
            select: false,
        }
    },
    elements: {},
    note: null,
    customer_name: null,
    rnc: null,
    deposits: [],
    creditsNote: [],
    partners: [],
    currency: {}
});

