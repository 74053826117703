import { mapGetters } from 'vuex'
import {http as nodeService} from "@/common/services/node-service.js";
import * as turf from "@turf/turf";


export const addressesMixin = {
    computed: {
        ...mapGetters({
            userData: "account/userData",
            isLogged: "account/isLogged",
            getAddresses: "account/getAddresses",
            branchSelected: "branches/branchSelected",
            getAddressAvailableList:"account/getAddressAvailableList",
            vidvie: "home/vidvie"
        })
    },
    methods: {
        editAddress(address) {
            this.addressEditData = address
            this.showAddressModal = true
            this.$emit("openModal")
            this.$emit("addressData", address)
        },
        async defaultAddress(id){
            try {
                this.changingDefault = true
                await this.$store.dispatch('account/defaultAddress', id)

            } catch (error) {
                console.log(error)
            }finally{
                this.changingDefault = false
            }
        },
        deleteAddress(address){
            if (this.addresses.length > 1 ){
                this.showConfirmDelete = true;
                this.addressDelete = address
            }else {
                this.oneAddress = true
            }
        },
        addressIsAvailable(address) {
            if(this.getAddressAvailableList !== 0){
                let isAvaliable = this.getAddressAvailableList.find((obj) => address.id == obj.id);
                return isAvaliable?.available;
            }
        },
        async deleteConfirmed(){
            try {
                await this.$store.dispatch('account/deleteAddress', this.addressDelete.id)
                
            } catch (error) {
                console.log(error)
            }finally{
                this.showConfirmDelete =  false;
            }
        },

        async addAddress(address){
            try {
                await this.$store.dispatch('account/addAddress', address);
                this.$emit('closeModal');
                let notify = {
                    text: 'account.address_created_successfully',
                    type: 'success',
                    show: true,
                }
                this.$store.commit("common/SET_SHORT_NOTIFY", notify)
                this.addressEditData = null;
            }catch (e) {
                if(e.response.data.code === 406){
                    this.showConfirmLocation = false
                    this.directionNotAllowed = true
                }
                throw e;
            }
        },
        async updateAddress(address){  
            try {
                await this.$store.dispatch('account/updateAddress', address)
                this.$emit('closeModal')
                let notify = {
                    text: 'account.address_updated_successfully',
                    type: 'success',
                    show: true,
                }
                this.$store.commit("common/SET_SHORT_NOTIFY", notify)
                this.loadingAddress = false
            } catch (error) {
                console.log(error);
            }   
        },
        async getLimits(branch){
            try{
                if (this.$refs.layers){
                    this.$refs.layers.forEach(e=>{
                        console.log(e)
                        e.remove()
                    })
                    this.layers =[]
                }
    
                let data = await nodeService.get("stores/"+branch.codplace+"/limits");
                data = data.data.data;
                await data.forEach( (limit) => {
                    let coordinates = [];
                    limit.limits.forEach((l, i) => {coordinates[i] = [l.longitude, l.latitude];});
                    let poly = turf.polygon([coordinates]);
                    this.layers.push(poly);
                });
                return data
            }catch (e) {
                console.log(e)
                throw e
            }
    
        },
    }
    

}
