<template>
    <router-link tag="div" to="/products/9634" class="w-100 h-100 container-slider cursor-pointer" :class="[{active : active == index}, {prev : active == index + 1 || active == index - 1}]">
        <h1 class="title">{{data.title_1}}</h1>
        <div class="swiper-slider-content">
            <h3 class="mb-0">{{data.title_2}}</h3>
            <h1 class="mb-1">{{data.title_3}}</h1>
            <div class="text-container rounded-1 bg-white position-relative px-1">
                <h4 class="mb-0">{{data.description_1}}</h4>
            </div>
        </div>
        <img src="./images/down.webp" alt="down" class="down">
        <img src="./images/up.webp" alt="up" class="up">
        <img src="./images/speaker.webp" alt="img" class="img">
        <img src="./images/spund-waves-left.webp" alt="sound-waves-left" width="55px" class="sound-waves-left" v-if="data.sound_waves">
        <img src="./images/sound-waves-right.webp" alt="sound-waves-right" width="55px" class="sound-waves-right" v-if="data.sound_waves">
        <img src="./images/background-light.webp" alt="" srcset="" class="background-light">
    </router-link>
</template>

<script>
export default {
    name: "SliderType3",
    props:{
        data:{
            type: Object
        },
        active:{
            type: Number
        },
        index:{
            type: Number
        }
    }
}
</script>

<style scoped>
    .container-slider{
        background-color: #fba3a9;
    }
    .title{
        font-size: 160px;
        font-weight: 700;
        color: #FFE6EA;
        position: absolute;
        top: 7%;
        left: 5.5%;
    }
    .down{
        position: absolute;
        bottom: calc(-22% + 4px);
        left: 11%;
        width: 1000px;
    }
    .up{
        position: absolute;
        left: 17%;
        bottom: calc(19% - -4px);
        z-index: 0;
        width: 840px;
    }
    .img{
        position: absolute;
        left: 11%;
        bottom: 26%;
        width: 886px;
    }
    .sound-waves-right{
        position: absolute;
        right: 28%;
        bottom: 40%;
        animation: tweeder 2.2s ease infinite alternate;
    }
    .sound-waves-left{
        position: absolute;
        left: calc(25% + 6px);
        bottom: 40%;
        animation: tweeder 2.2s ease infinite alternate;
    }
    .swiper-slider-content > h3{
        font-size: 20px;
        color: #fff;
    }
    .text-container{transform: translateX(-15px);}
    .text-container > h4{
        font-size: 24px;
        text-shadow: 0 0 2px white, 0 0 2px white, 0 0 1px white, 0 0 1px white;
        color: #fba3a9;
        padding: 0px 10px;
        font-weight: 700;
    }
    .swiper-slider-content{
        position: absolute;
        top: calc(42% + 2px);
        left: calc(7% - 6px);
        text-align: start;
    }
    .swiper-slider-content > h1{
        font-size: 22px;
        color: #fff;
        font-weight: 700;
    }
    .swiper-slider-content > h5{
        font-size: 36px;
        color: #fff;
        font-weight: 300;
        margin-top: 7rem;
    }
    .active > h1{
        animation: show-slider-3-h1 0.6s;
    }
    @keyframes show-slider-3-h1 {
        from{
            opacity: 0;
            transform: translateY(150px);
        }
        to{
            opacity: 1;
            transform: translateY(0px);
        }
    }
    .active > .swiper-slider-content{
        animation: show-slider-3-content 0.6s;
    }
    @keyframes show-slider-3-content {
        from{
            transform: translateX(150px);
        }
        to{
            transform: translateX(0px);
        }
    }
    .active > .up, .active > .down, .active > .img{
        animation: show-slider-3-up 0.6s;
    }
    @keyframes show-slider-3-up {
        from{
            transform: translateY(50px);
        }
        to{
            transform: translateY(0px);
        }
    }
    @keyframes tweeder {
        0% {
            transform: scale(1.1);
        }
        15% {
            transform: scale(1.15);
        }
        35% {
            transform: scale(1.1);
        }
        70% {
            transform: scale(1.15);
        }
        90% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1.1);
            }
    }
</style>