<template>
    <form @submit.prevent="createWishList">
        <main-input id="name" ref="name" placeholder="wishlist.list_name_placeholder" title="wishlist.list_name" :max="50"
        @changeValue="(data) => {name = data}" class="mb-4" disableIcon />
        <div class="d-flex justify-content-between">
            <span class="weight-500 size-16">{{$t('wishlist.add_description')}} <b class="weight-400 color-dark-gray-200">{{$t('wishlist.add_desc_optional')}}</b></span>
            <span class="color-dark-gray-200 size-15">{{charactersLeft}}/200</span>
        </div>
        <textarea v-model="note" class="input-description form-control mt-2 size-14" :placeholder="$t('wishlist.add_desc_placeholder')" maxlength="200" rows="5"></textarea>
        <div class="d-flex justify-content-end mt-3">
            <main-button title="wishlist.save" />
        </div>
    </form>
</template>

<script>
import MainButton from '../../../common/components/buttons/MainButton.vue'
import MainInput from '../../../common/components/inputs/MainInput.vue'
import { mapGetters } from "vuex"

export default {
    components: { MainInput, MainButton },
    name: "WishListForm",
    data() {
        return {
            name: '',
            note: '',
        }
    },
    computed: {
        ...mapGetters({
            selectedProduct: "wishlist/selectedProduct",
            lastCreated: 'wishlist/lastCreated'
        }),
        charactersLeft() {
            var char = this.note.length, limit = 200;
            return (limit - char);
        },
    },
    methods: {
        async createWishList() {
            try {
                this.$refs.name.$v.$touch();
                if (this.$refs.name.$v.$invalid) return;
                //if there are multiple products send the array, otherwise follow this structure.
                let data = this.selectedProduct.length ? {product: this.selectedProduct.map(({cod_articulo, note}) => {return {product_code: cod_articulo, product_note: note}})} 
                : {product_code: this.selectedProduct._id, product_note: ''};
                await this.$store.dispatch('wishlist/storeWishList', {...data, name: this.name, note: this.note});
                this.$emit("saved", {name: this.name, action: 'wishlist-created', route: `account/wishlist/${this.lastCreated._id}`});
                
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>
<style scoped>
    .input-description {
        resize: none;
    }
    .main-input::v-deep label {
        font-size: 16px;
    }
    .main-input::v-deep input.form-control::placeholder, textarea::placeholder {
        color: var(--dark-gray-200) !important;
    }
    textarea.form-control {
        border: 0 !important;
        height: 9.6rem;
        box-shadow: 1px 2px 6px #7c879333;
    }
    form.tab-showing textarea.form-control {
        height: 6.9rem;
    }
    textarea.form-control:focus, .main-input::v-deep .form-control:focus {
        box-shadow: 1px 2px 6px #9ca7b233 !important;
    }
    @media (max-width: 767px) {
        button {
            border-radius: 7px;
            padding-inline: 55px;
            margin-inline: auto !important;
        }
    }
</style>
