<template>
    <main-modal class="main-confirm" @closeModal="$emit('cancel')">
        <div class="d-flex justify-content-center align-items-center mb-1">
            <slot />
        </div>
        <h5 v-if="deletingAll.delete_all" class="text-center fw-bold title-18 mb-2">
        {{$t(deletingAll.html.first)}} <span class="color-main-red">{{$t(deletingAll.html.second)}}</span> {{$t(deletingAll.html.third)}}
        </h5>
        <h5 v-else class="text-center fw-bold title-18 mb-2">
            {{ $t(confirmTitle) }}
        </h5>
        <div v-if="contentHTML" v-html="contentHTML" />
        <template v-else>
            <div class="d-flex justify-content-center">
                <p class="text-center desc-14 weight-400 mb-0" :class="descriptionClasses" data-toggle="tooltip" data-placement="top" :title="deletingItems">
                    {{ $t(description) | textFormat }} 
                    <span class="desc-14" v-if="deletingItems_">{{ deletingItems.split(', ').length > 1 ? deletingItems_ : `"${deletingItems_}"`}}</span>
                    <span v-if="!oneOption && isQuestion" class="desc-13" :class="secondTitle ? 'color-dark-main-navy':'color-dark-gray-300'"> ?</span> 
                    <span v-if="secondTitle" class="d-block color-dark-gray-300">{{ $t(secondTitle) }}</span>
                    <slot v-if="customDescription" name="customDescription" />
                </p>
            </div>
        </template>
        <slot name="map"/>
        <div class="mt-4">
            <div class="d-flex py-0 justify-content-center">
                <second-button class="me-3" :title="$t(buttonCancelTitle)" @click="$emit('cancel')" v-if="!oneOption" style="width: 110px;" :disabled="loading" />
                <main-button :title="buttonConfirmTitle ? $t(buttonConfirmTitle) : $t('auth.accept')" @click="$emit('confirm')" style="width: 110px;" 
                class="position-relative overflow-hidden" icon :disabled="loading" id="confirm-modal-button">
                    <div v-if="loading" class="confirm-loading position-absolute w-100 h-100 d-flex justify-content-center align-items-center bg-main-red">
                        <half-circle-spinner :animation-duration="1000" :size="20" color="#fff"/>
                    </div>
                </main-button>
            </div>
        </div>
    </main-modal>
</template>

<script>
import { HalfCircleSpinner } from 'epic-spinners'
export default {
    name: 'MainConfirm',
    components: {
        SecondButton: () => import("@/common/components/buttons/SecondButton"),
        MainButton: () => import("@/common/components/buttons/MainButton"),
        MainModal: () => import("@/common/components/modals/MainModal"),
        HalfCircleSpinner
    },
    props: {
        description: { type: String },
        secondTitle: { type: String },
        confirmTitle: { type: String, },
        deletingItems: { type: String, default: '', },
        deletingAll: {
            type: Object,
            default: () => {
                return {
                    delete_all: false,
                    html: {},
                    single_quoted: false,
                }
            }
        },
        oneOption: {
            type: Boolean,
            default: false
        },
        buttonConfirmTitle: {
            type: String
        },
        buttonCancelTitle: {
            type: String,
            default: 'auth.cancel'
        },
        isQuestion: {
            type: Boolean,
            default: true
        },
        customDescription: {
            type: Boolean
        },
        loading: {
            type: Boolean,
            default: false
        },
        contentHTML: {
            type: String
        },
    },
    computed: {
        deletingItems_(){
            let width = 20;
            if (this.viewPort('md')) width = 30;
            if (this.deletingItems.length > width) {
                let cut = this.deletingItems.slice(0, width);
                if (cut.slice(-1) === ',') cut = cut.slice(0, -1);
                cut+= "...";
                return cut;
            }else {
                return this.deletingItems;
            }
        },
        descriptionClasses(){
            return {
                'color-dark-main-navy': this.secondTitle,
                'color-dark-gray-300': !this.secondTitle,
            }
        },
    }
}
</script>
<style scoped>
    .modal-main-container::v-deep > .modal-main {
        padding: 25px;
    }
    .confirm-loading {
        left: 0;
        top: 0;
        z-index: 1;
    }
    /* .many-lists {
        -webkit-line-clamp: 3;
    }
    .single-lists {
        -webkit-line-clamp: 2;
    } */
    p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden !important;
        text-overflow: ellipsis;

        width: 350px; 
        line-height: 19px;

        /* word-break: break-all; */
    }
</style>
