<template>
    <icon-base :width="size+'px'" :fill="color" viewBox="0 0 21.407 11.869">
        <g id="Group_7582" data-name="Group 7582" transform="translate(-855.692 -6333.26)">
            <path id="_3" data-name="3" d="M1144.047,1738.953l4.9,4.9-4.849,4.849" transform="translate(-287.295 4595.367)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path id="_2" data-name="2" d="M1151.986,1738.953l4.9,4.9-4.849,4.849" transform="translate(-287.886 4595.367)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path id="_1" data-name="1" d="M1159.925,1738.953l4.9,4.9-4.849,4.849" transform="translate(-288.476 4595.367)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        </g>
    </icon-base>
</template>

<script>
export default {
    name: 'Arrow3Rigth',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>
