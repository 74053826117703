<template>
<icon-base :width="size + 'px'" :fill="color" viewBox="0 0 809.178 809.178">
  <g id="Group_7581" data-name="Group 7581" transform="translate(-1295.482 -2904.307)">
      <ellipse id="Ellipse_456" data-name="Ellipse 456" cx="404.589" cy="404.589" rx="404.589" ry="404.589" transform="translate(1295.482 2904.307)" fill="#d88d48" opacity="0.5"/>
      <ellipse id="Ellipse_457" data-name="Ellipse 457" cx="269.726" cy="269.726" rx="269.726" ry="269.726" transform="translate(1430.345 3039.17)" fill="#d88d48" opacity="0.5"/>
    </g>
</icon-base>
</template>

<script>
export default {
    name: 'Circle2',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>


