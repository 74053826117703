export default {
  SET_STATE_LOADING(state, boolean){
    state.loading = boolean
  },
  SET_NOTIFY(state, data){
    state.notify = data
  },
  RESET_NOTIFY(state){
    state.notify = {
      img: '',
      title: '',
      action: '',
      show: false,
      type: null,
      product: null,
      decrement: false,
      oldQuantity: 0,
      many: null
    }
  },
  SET_SHORT_NOTIFY(state, data){
    state.shortNotify = data 
  },
  RESET_SHORT_NOTIFY(state){
    state.shortNotify = {
      title: '',
      type: '',
      show: false,
    }
  },
  async SET_CONFIGURATIONS(state, configurations){
    state.configurations = configurations;
  },
  SET_DISABLE_MODAL(state, boolean){
    state.disableModal = boolean;
  },
  SET_MOBILE_VALUE(state, payload){
    state.mobile = payload;
    localStorage.setItem('display', 'mb');
  },
  SHOW_MODAL_ERROR(state, payload){
    state.error = payload;
  }
};
