import { AddressService } from "../services/address-service";
const addressService = new AddressService();
import { http } from "../../../common/services/web-backend";
import { http as nodeService} from "../../../common/services/node-service";
import {orderService} from "../../../modules/account/services/order-service";
import router from '../../../router';
import store from "../../../store";


export default {
    //addresses
    async getAddresses({ commit, dispatch}) {
    try {
        const addresses = await addressService.getAddresses();
        await commit('SET_ADDRESSES', addresses)
        await dispatch('addressWithLimitAvaliable', {addresses : addresses, options : {calculate_prices: router.currentRoute.path == '/checkout' ? 1 : 0}})
        return addresses
    }catch (e) {
        console.log(e)
        throw e
    }
    },
    async addressWithLimitAvaliable({commit, rootGetters},{addresses, options}) {
        try {
            const quantity = rootGetters["cart/quantityProducts"]
            const branch = rootGetters['branches/branchSelected']
            const branches = rootGetters['branches/branches']
            let branchesId = []
            let address = [];
            let addressList = [];
            for (let i = 0; i < branches.length; i++) { 
                branchesId.push(branches[i].codplace)
            }
            for (let i = 0; i < addresses.length; i++) {
                const item = addresses[i];
                address.push({
                    id: item.id,
                    latitud: item.latitud,
                    longitud: item.longitud,
                    quantity: quantity
                })
                
            }
            if(address.length > 0) {
                let checkAll = await nodeService.post("stores/limit/verify", {
                    address, branch_codes: branchesId, calculate_prices: options.calculate_prices
                });
                for (let i = 0; i < checkAll.data.data.length; i++) {
                    const check = checkAll.data.data[i];
                    check.verify.forEach(({limits, branch_code}) => {
                        if(branch_code === branch.codplace)
                            addressList.push({
                                id: check.id,
                                available: limits.available,
                                price: limits.price,
                                delivery: limits
                            });
                    });
                }
                commit("SET_ALL_ADDRESS_AVAILABLE_LIST", checkAll.data.data);
                commit("SET_ADDRESS_AVAILABLE_LIST", addressList); //state.addressAvailableList
            }
        } catch (error) {
            console.log(error);
        }
    },
    async addAddress({ commit, dispatch }, address){
        try{
            const {data:{data}} = await http.post('addresses', address);
            await dispatch('getAddresses');
            commit("SET_ADDRESS", data.id)
        }catch (e) {
            console.log(e)
            throw e
        }
    },
    async updateAddress({ dispatch }, address){
        try {
            await http.put('addresses/'+address.id, address)
            dispatch('getAddresses');
        } catch (error) {
            console.log(error)
            throw error
        }
    },
    async defaultAddress({ dispatch }, id){
        try {
            await http.patch('addresses/default/'+id)
            dispatch('getAddresses');

        } catch (error) {
            console.log(error)
        }
    },
    async setAddress({ commit, rootGetters }, address) {
        const quotationDelivery = rootGetters['checkout/deliveryQuotationID'];
        if (quotationDelivery) {
            const notify = {
                text: 'No puedes cambiar la dirección a una cotización, comunicate con un administrador.',
                type: 'warning',
                show: true,
            }
            commit('common/SET_SHORT_NOTIFY', notify, { root: true });
            return
        }
        
        commit('SET_ADDRESS', address.id);
        const notify = {
            text: 'account.notification.address_changed_succesfully',
            type: 'success',
            show: true,
        }
        commit('common/SET_SHORT_NOTIFY', notify, {root: true});
        commit('checkout/RESET_PAYMENTS', null, {root: true})
    },
    async deleteAddress({ dispatch, commit, getters }, id){
        try {
            let addressSelected = getters['addressSelected']
            await http.delete('addresses/'+id)
            if(addressSelected.id == id){
                localStorage.removeItem("addressSelected");
                commit('SET_ADDRESS', null)
            }
            dispatch('getAddresses');

        } catch (error) {
            console.log(error)
        }
    },
    async getOrderStatus({ commit }){
        try {
            const status = await http.get('orders/status');
            commit('SET_STATUS', status.data.data)
            }catch (e) {
                console.log(e)
                throw e;
            }
    },

    async getCards({ commit }){
        if(store?.getters['common/configurations']?.payment_methods?.stripe_backend_implementation?.active){
            const response = await http.get('stripe/get-payment-methods')
            commit("SET_CARDS", response.data.data)
        } else {
            const response = await http.get('azul/process-data-vault-get')
            commit("SET_CARDS", response.data.data)
        }
    },

    // async getCardsStripe({ commit }){
    //     const response = await http.get('stripe/get-payment-methods')
    //     commit("SET_CARDS", response.data.data)
    // },

    async deleteOrder(_, order){
        try {
            const res = await orderService.deleteOrden(order)
            return res
        } catch (error) {
            console.log(error);
            throw error
        }
    },
    async update({ commit }, data){
        try {
        const res = await http.put('users', data)
        localStorage.setItem('user', JSON.stringify(res.data.data.user));
        commit('auth/SET_USER', res.data.data.user, {root: true});
        } catch (e) {
        throw e.response;
        }
    },
    async updateEmail(_, data){
        try {
            const res = await http.put('users', data)
            return res
        } catch (e) {
            throw e.response;
        }
    },
    async getQuotes({commit, getters}, params) {
        try {
            let changedQuotes = getters['changedQuotes']
            const response = await nodeService.get('quotation', {params:params})
            commit('SET_QUOTES', response.data.data)
            changedQuotes.forEach(quotation => {
                quotation.products.forEach(product => {
                    commit('UPDATE_QUANTITY_PRODUCT_QUOTATION', {
                        id: quotation.quotation, 
                        product: product.codarticulo, 
                        quantity: product.quantity,
                        isChaged: true,
                        response: product.product
                    })
                });
            });
            // commit('RESET_CHANGED_QUOTATION')
        }catch (e) {
            console.log(e)
            throw e
        }
    },
    async getQuotationByID({commit}, {id, refresh}) {
        try {
            const response = await nodeService.get(`quotation/${id}`)
            // console.log(response.data.data)
            if(refresh)
            commit('SET_QUOTATION_TO_QUOTES', response.data.data)

            return response.data.data
        }catch (e) {
            console.log(e)
            throw e
        }
    },
    async updateQuotation({commit, getters, dispatch}, id) {
        let quotes = getters['quotes']
        const quotation = quotes.data.find(quote => quote.order_token_id == id)
        let products = []
        quotation.order_details.forEach(({product, quantity, deleted}) => {
            if(!deleted){
                products.push({
                    cod_articulo: product.codarticulo,
                    quantity : quantity,
                    note : product.note
                })
            }
        });
        try {
            await nodeService.post(`quotation/${quotation.branch_code}/${id}`, {products: products})
            commit('REMOVE_FROM_CHANGED_QUOTATION', id)
            await dispatch('getQuotationByID', {id: id, refresh: true})
        }catch (e) {
            console.log(e)
            throw e
        }
    },
    async updateQuotationFromCart({commit, dispatch}, quotation) {
        let products = []
        quotation.products.forEach(({product, quantity}) => {
            products.push({
                cod_articulo: product.codarticulo,
                quantity : quantity,
                note : product.note
            })
        });
        try {
            await nodeService.post(`quotation/${quotation.branch}/${quotation.id}`, {products: products})
            commit('REMOVE_FROM_CHANGED_QUOTATION', quotation.id)
            await dispatch('getQuotationByID', {id: quotation.id, refresh: true})
        }catch (e) {
            console.log(e)
            throw e
        }
    },
};
