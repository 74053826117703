<template>
    <icon-base :color="color" :size="size" viewBox="0 0 93.486 101.669">
        <defs>
            <clipPath id="clip-path">
            <path id="SVGID" d="M1624.118,821.875h-37.957a2.543,2.543,0,0,1-2.543-2.543h0V768.56a2.543,2.543,0,0,1,2.542-2.544h30l10.5,10.109v43.206A2.544,2.544,0,0,1,1624.118,821.875Z" fill="none"/>
            </clipPath>
        </defs>
        <g id="No_product_icon" data-name="No product icon" transform="translate(-1595.618 -766.016)">
            <path id="Path_1919" data-name="Path 1919" d="M1612.258,808.027a21.371,21.371,0,0,1,14.4-20.168V776.125l-10.5-10.109h-30a2.544,2.544,0,0,0-2.542,2.544v50.771h0a2.544,2.544,0,0,0,2.543,2.543h31.207A21.283,21.283,0,0,1,1612.258,808.027Z" transform="translate(16)" fill="#f4f7f9"/>
            <g id="Group_3967" data-name="Group 3967" transform="translate(16)">
            <g id="Group_3966" data-name="Group 3966" clip-path="url(#clip-path)">
                <g id="Group_3961" data-name="Group 3961">
                <path id="Path_1918" data-name="Path 1918" d="M1616.2,760.242h14.713v15.8H1616.2Z" fill="#dfe6ed"/>
                </g>
            </g>
            </g>
            <g id="Group_3968" data-name="Group 3968" transform="translate(16)">
            <path id="Path_1920" data-name="Path 1920" d="M1591.247,779.043H1611.2a1.5,1.5,0,0,0,0-3h-19.957a1.5,1.5,0,0,0,0,3Z" fill="#dae5ea"/>
            <path id="Path_1921" data-name="Path 1921" d="M1591.247,784.782H1611.2a1.5,1.5,0,0,0,0-3h-19.957a1.5,1.5,0,0,0,0,3Z" fill="#dae5ea"/>
            </g>
            <g id="Group_3959" data-name="Group 3959">
            <g id="Group_3954" data-name="Group 3954" transform="translate(16)">
                <path id="Path_1907" data-name="Path 1907" d="M1662.384,832.25a.078.078,0,0,0-.04-.04l-13.54-13.54a18.571,18.571,0,1,0-4.56,4.55l13.55,13.55a3.221,3.221,0,0,0,4.59-4.52Zm-28.77-10.38a13.835,13.835,0,1,1,13.84-13.84A13.836,13.836,0,0,1,1633.614,821.87Z" fill="#4979b2"/>
                <path id="Rectangle_1121" data-name="Rectangle 1121" d="M1650.807,830.628l5.409-5.409a.461.461,0,0,1,.65,0l11.525,11.526a4.283,4.283,0,0,1,0,6.056h0a4.283,4.283,0,0,1-6.058,0h0l-11.524-11.523a.461.461,0,0,1,0-.65Z" fill="#091d2b"/>
            </g>
            <ellipse id="Ellipse_35" data-name="Ellipse 35" cx="46.743" cy="3.833" rx="46.743" ry="3.833" transform="translate(1595.618 860.019)" fill="#f0f4f9"/>
            <g id="Group_3958" data-name="Group 3958" transform="translate(16)">
                <g id="Group_3955" data-name="Group 3955">
                <path id="Path_1908" data-name="Path 1908" d="M1630.942,804.152a1.606,1.606,0,1,0-1.606,1.606h0A1.608,1.608,0,0,0,1630.942,804.152Z" fill="#091d2b"/>
                </g>
                <g id="Group_3956" data-name="Group 3956">
                <path id="Path_1909" data-name="Path 1909" d="M1637.9,802.546a1.606,1.606,0,1,0,1.6,1.606h0A1.607,1.607,0,0,0,1637.9,802.546Z" fill="#091d2b"/>
                </g>
                <g id="Group_3957" data-name="Group 3957">
                <path id="Path_1910" data-name="Path 1910" d="M1633.619,809.773a8.174,8.174,0,0,0-6.087,2.706.625.625,0,0,0,.931.833,6.948,6.948,0,0,1,9.813-.5q.261.237.5.5a.625.625,0,0,0,.93-.833A8.172,8.172,0,0,0,1633.619,809.773Z" fill="#091d2b"/>
                </g>
            </g>
            </g>
            <g id="Group_3960" data-name="Group 3960" transform="translate(16)">
            <line id="Line_136" data-name="Line 136" x2="0.709" y2="10.966" transform="translate(1643.992 775.446)" fill="none" stroke="#c6d4db" stroke-linecap="round" stroke-width="2"/>
            <line id="Line_137" data-name="Line 137" x1="7.252" y2="8.255" transform="translate(1649.78 780.044)" fill="none" stroke="#c6d4db" stroke-linecap="round" stroke-width="2"/>
            <line id="Line_138" data-name="Line 138" x1="10.966" y2="0.709" transform="translate(1652.037 792.516)" fill="none" stroke="#c6d4db" stroke-linecap="round" stroke-width="2"/>
            </g>
            <path id="Path_1922" data-name="Path 1922" d="M1612.3,808.848h-21.02a1.5,1.5,0,0,0,0,3h21.338A21.288,21.288,0,0,1,1612.3,808.848Z" transform="translate(16)" fill="#dae5ea"/>
            <path id="Path_1923" data-name="Path 1923" d="M1613.322,801.413H1591.28a1.5,1.5,0,0,0,0,3h21.311A21.18,21.18,0,0,1,1613.322,801.413Z" transform="translate(16)" fill="#dae5ea"/>
            <path id="Path_1924" data-name="Path 1924" d="M1617.569,793.978H1591.28a1.5,1.5,0,0,0,0,3h24.094A21.472,21.472,0,0,1,1617.569,793.978Z" transform="translate(16)" fill="#dae5ea"/>
        </g>
    </icon-base>
</template>

<script>
export default {
    name:'iconChange',
    components:{
        IconBase: () => import('@/common/components/iconBase.vue')
    },
    props:{
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>

<style scoped>
.st0{fill:#212E3D;}
</style>

