<template>
    <div class="position-relative container-advertisements-4 cursor-pointer" @click="$router.replace({path: '/products/9631'})" v-if="getDimensions">
        <img loading="lazy" src="../../assets/images/advertisements/ads-4/img-2.webp" alt="img-advertisements-4-img-2" id="img-advertisements-4-img-2">
        <img loading="lazy" src="../../assets/images/advertisements/ads-4/img-3.webp" alt="img-advertisements-4-img-3" id="img-advertisements-4-img-3">
        <div class="row m-0 mt-5 px-2 px-lg-4 px-xxl-5 py-3 py-md-4 ms-0 ms-md-5 advertisements-4 position-relative">
            <backgorund-circle id="backgorund-circle-1" class="d-none d-md-block"/>
            <backgorund-circle id="backgorund-circle-2" class="d-none d-md-block"/>
            <backgorund-circle id="backgorund-circle-3" class="d-none d-md-block"/>
            <backgorund-circle id="backgorund-circle-4" class="d-none d-md-block"/>
            <div class="col-md-8 d-flex flex-column justify-content-center align-items-start">
                <h4 class="mb-0">{{$t('home.ads.listen_to_your_music_with')}}</h4>
                <h1>{{$t('home.ads.full_freedom')}}.</h1>
                <h6 class="mt-2 mb-0 position-relative">{{$t('home.ads.more')}} <b>{{$t('home.ads.music') | textFormat}}.</b> {{$t('home.ads.less')}} <b>{{$t('home.ads.noise')}} </b>
                    <arrow3-rigth :size="25" class="position-absolute" id="arrow-3-right"/>
                </h6>
            </div>
            <div class="col-md-4 mt-4 position-relative images d-flex d-md-block justify-content-center align-items-center">
                <img loading="lazy" src="../../assets/images/advertisements/ads-4/img-1.webp" alt="img-advertisements-4-img-1" id="img-advertisements-4-img-1">
            </div>
        </div>
    </div>
    <div class="mt-4" v-else>
        <ads-04/>
    </div>
</template>

<script>
    import BackgorundCircle from '../../assets/svg/ads/ads-4/BackgorundCircle.vue'
    import Arrow3Rigth from '../../assets/svg/ads/ads-4/Arrow3Rigth.vue'
    import Ads04 from './svg/Ads04.vue'
    export default{
        name: "AdMdSec",
        components:{
            BackgorundCircle,
            Arrow3Rigth,
            Ads04,
        },
        computed:{
            getDimensions(){
                return window.matchMedia('(min-width: 768px)').matches
            }
        }
    }
</script>
<style scoped>
    .advertisements-4{
        background-color: #d8dce6;
        border-radius: 20px;
        overflow: hidden;
    }
    .advertisements-4 h4{
        font-size: 43px;
        color: #909cb6;
        font-family:'Myriad Pro Light' !important;
        transition: all 0.3s ease-in-out;
    }
    .advertisements-4 h1{
        font-size: 55px;
        font-family:'Myriad Pro Bold' !important;
        color: #8f9bb5;
        font-weight: 600;
    }
    .advertisements-4 h6{
        font-size: 23px;
        color:#212d3d;
    }
    .advertisements-4 > .images >#img-advertisements-4-img-1, #img-advertisements-4-img-2{
        position: absolute;
        width: 116px;
        transition: all 0.3s ease-in-out;
    }
    #img-advertisements-4-img-1{
        left: 1%;
    }
    #img-advertisements-4-img-2{
        right: 3%;
        top: -12%;
        z-index: 1;
    }
    #img-advertisements-4-img-3{
        position: absolute;
        width: 140px;
        z-index: 1;
        right: 10%;
        top: 95%;
        transition: transform 0.3s ease-in-out;
    }
    .container-advertisements-4:hover > #img-advertisements-4-img-3{
        top: 93%;
        transform: translateY(-267px);
    }
    .container-advertisements-4:hover > #img-advertisements-4-img-2{
        transform: translate(33px, 13px) scale(0.7);
    }
    .container-advertisements-4:hover > .advertisements-4 > .images > #img-advertisements-4-img-1{
        transform: translate(-65px, 7px) scale(0.75);
    }
    .container-advertisements-4:hover > .advertisements-4 > #backgorund-circle-1{
        transform: translateX(-40px);
    }
    .container-advertisements-4:hover > .advertisements-4 > #backgorund-circle-2{
        transform: translate(30px);
    }
    .container-advertisements-4:hover > .advertisements-4 > #backgorund-circle-3{
        transform: translateX(-30px);
    }
    .advertisements-4 > #backgorund-circle-1{
        position: absolute;
        top: -10%;
        left: -18%;
        width: 275px;
        transition: all 0.3s ease-in-out;
    }
    .advertisements-4 > #backgorund-circle-2{
        position: absolute;
        top: 37%;
        right: -17%;
        width: 275px;
        transition: all 0.3s ease-in-out;
    }
    .advertisements-4 > #backgorund-circle-3{
        position: absolute;
        top: 50%;
        right: 11%;
        width: 350px;
        transition: all 0.3s ease-in-out;
    }
    .advertisements-4 > #backgorund-circle-4{
        position: absolute;
        bottom: 41%;
        right: -23%;
        width: 654px;
    }
    .advertisements-4 > div > h6 > #arrow-3-right{
        top: 26%;
        right: 0%;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        stroke: var(--main-navy);
    }
    .container-advertisements-4:hover > .advertisements-4 > div > h6 > #arrow-3-right{
        transform: translateX(35px);
        opacity: 1;
    }
    .container-advertisements-4:hover > .advertisements-4 > div > h4{
        transform: scale(0.95) translateX(-10px);
    }
    .advertisements-4 > div > h6:hover > #arrow-3-right{
        stroke: var(--blue-600);
        /* transition: all .15s ease-in-out; */
    }
    .advertisements-4 > div > h6:hover{
        color: var(--blue-600);
    }
</style>
