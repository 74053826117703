export default () => ({
    banners: {
        main: [],
        secondary: []
    },
    products: {
        newProducts: [],
        topProducts: [],
        offersProducts: [],
    },
    vidvie: process.env.VUE_APP_VIDVIE == '1' ||  process.env.VUE_APP_VIDVIE == '2'
})
