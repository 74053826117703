<template>
<section>
    <footer class="d-flex align-items-center">
        <div class="footer-content container" style="display: grid;">
            <div class="socials">                        
                <router-link to="/" class="cursor-pointer" @click.native="scrollUp">
                    <img alt="Mundo Movil Footer" :src="vidvie ? require('../../../common/assets/images/logo-vidvie.png') : require('../../../common/svg/logo.svg')" class="ms-0 px-0" :class="{'w-100':vidvie}"/>
                </router-link>
                <h4 class="size-20 mb-0 mt-3 mt-lg-4 pt-0 text-center color-white">{{$t('layout.footer.follow_us')}}</h4>
                <div class="d-flex justify-content-between w-100 mt-3 pt-1">
                    <div v-for="{address, icon} in socialMedia" class="social-icon d-flex flex-shrink-0" :key="icon">
                        <a class="d-flex justify-content-center align-items-center" :href="address" target="_blank">
                            <component :is="icon" color="white" />
                        </a>
                    </div>
                </div>
            </div>
            <ul v-for="({ nav_title, styles, options}, index) in verifyUSTECH() ?  navigation.filter((data) => data.vidvie == vidvie) : navigation" :key="verifyUSTECH() ? index + 1 : index" :class="[`nav-${verifyUSTECH() ? index+2 : index+1}`, styles, verifyUSTECH() ? 'position_li_1' : 'text-center']" class="nav d-flex flex-column align-content-center ps-0" style="list-style: none;">
                <li class="nav-header color-white size-21 weight-600 mt-3 mb-2 pb-1">{{$t(`layout.footer.${nav_title}`)}}</li>
                <li v-for="({link, title, external}, index) in verifyUSTECH() ? options.filter((data) => data.vidvie == vidvie) : options" :key="index" data-toggle="tooltip" data-placement="top" :title="$t(`layout.footer.${title}`)" style="max-width: 100%;" class="d-block mb-1 cursor-pointer">
                    <a v-if="external" class="d-block color-white size-14 text-limited" :href="link">{{$t(`layout.footer.${title}`)}}</a>
                    <router-link v-else class="d-block color-white size-14 text-limited" :to="link">{{$t(`layout.footer.${title}`)}}</router-link>
                </li>
            </ul>
            <ul v-if="verifyUSTECH()" class="nav d-flex flex-column align-content-center ps-0 position_li_2" style="list-style: none;">
                <li class="nav-header color-white size-21 weight-600 mt-3 mb-2 pb-1">{{$t(`Contact`)}}</li>
                <li data-toggle="tooltip" data-placement="top" class="d-block mb-1">
                    <span class="d-block color-white size-14 text-limited" :class="'text-sm-start align-content-md-start align-content-lg-center'"><icon-location color="var(--main-red)" :size="12"/> 196 E 16th St, Paterson, NJ 07524, EE. UU.</span>
                </li>
                <li data-toggle="tooltip" data-placement="top" class="d-block mb-1">
                    <span class="d-block color-white size-14 text-limited"><icon-message color="var(--main-red)" :size="12"/>  Email: info@vidvie.com</span>
                </li>
                <li data-toggle="tooltip" data-placement="top" class="d-block mb-1">
                    <span class="d-block color-white size-14 text-limited"><icon-phone color="var(--main-red)" :size="12" />  Phone: +1 (201)515-7659</span>
                </li>
                <li data-toggle="tooltip" data-placement="top" class="d-block mb-1">
                    <span class="d-block color-white size-14 text-limited"><icon-calendar color="var(--main-red)" :size="12"/> Monday - Saturday: 09:00 - 19:00</span>
                </li>
            </ul>
            <div class="subscribe-mail d-flex flex-column align-items-center align-items-sm-end justify-content-center">
                <h4 class="color-white size-16 text-uppercase weight-700 text-center text-sm-right mb-0 mt-3 pt-lg-2 pt-xl-1">{{$t("layout.footer.mail-title")}}</h4>
                <p class="color-white size-16 text-center text-sm-right pt-1">{{$t("layout.footer.mail-description")}}</p>
                <form class="d-lg-flex justify-content-end mt-2 pb-lg-3" @submit.prevent="">
                    <div class="footer-input-group position-relative d-flex mb-lg-3 size-12">
                        <input @keyup.enter="subscribe()" v-model="subscribe_mail" ref="subscribe_mail" :placeholder="$t('layout.footer.mail-placeholder')" class="footer-input size-16" :class="[{'is-invalid-input': $v.subscribe_mail.$error}]"  autofill="off" autocomplete="off" @focus="invalid = true" @blur="invalid = false"/>
                            <main-button type="button" @click="subscribe" title="layout.footer.mail-btn" class="footer-btn d-flex align-content-center align-items-center size-14 justify-content-center" icon>
                                <icon-message color="white" :size="20"/>
                            </main-button>
                        <div v-if="$v.subscribe_mail.$error && invalid" class="invalid-input">
                            <icon-warning red :size="14" class="me-2" />
                            <span class="ms-1">{{$t('layout.footer.invalid_mail')}}</span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <MainSuccess  :title="title" :subtitle="subtitle" :animation="animation" v-if="showModal" @closeModal="showModal = false" />
    </footer>
    <div class="lower-footer" v-if="vidvie == 0">
        <div class="container">
            <div class="row flex-column flex-md-row align-items-center justify-content-lg-between mx-0">
                <h4 class="col-12 col-md-9 size-14 color-dark-main-navy text-center text-md-start weight-400 mb-0" style="padding: 20px 0px; height: max-content">
                    {{ this.configurations.general.web_page.copyright }} {{$t('layout.footer.rights_reserved') }}.
                </h4>
                <div class="col-md-3 size-14" style="display: flex; align-items: center; justify-content: center; gap: 20px;">
                    <img alt="Visa" loading="lazy" src="@/modules/layout/assets/svg/footer/visa.webp" style="max-width: 80px; height: auto;">
                    <img alt="MasterCard" loading="lazy" src="@/modules/layout/assets/svg/footer/mastercard.webp" style="max-width: 55px; height: auto;">
                    <img alt="Paypal" loading="lazy" src="@/modules/layout/assets/svg/footer/paypal.webp" style="max-width: 90px; height: auto;">
                    <img alt="Azul" loading="lazy" src="@/modules/layout/assets/svg/footer/azul.webp" style="max-width: 35px; height: auto;">
                </div>
            </div>
        </div>
    </div>
    <div></div>
</section>

</template> 

<script>

import { required, email } from 'vuelidate/lib/validators'
import { addressesMixin } from '@/modules/account/helpers/mixins/addresses-mixin.js'
import { mapGetters } from "vuex";
import MainButton from '../../../common/components/buttons/MainButton.vue';
import IconEmail from '../../products/assets/svg/iconEmail.vue';
import iconPhone from '../../../common/svg/iconPhone.vue';
import iconLocation from '../../../common/svg/iconLocation.vue';
import IconCalendar from '../../../common/svg/iconCalendar.vue';
import { RegisterService } from "../../auth/services/register-service";
import MainSuccess from '../../cart/components/modals/MainSuccess.vue';
const Service = new RegisterService();
export default {
    name: "Footer",
    components: {
    IconTwitter: () => import("@/modules/layout/assets/svg/footer/iconTwitter.vue"),
    IconInstagram: () => import("@/modules/layout/assets/svg/footer/iconInstagram.vue"),
    IconFacebook: () => import("@/modules/layout/assets/svg/footer/iconFacebook.vue"),
    IconYoutube: () => import("@/modules/layout/assets/svg/footer/iconYoutube.vue"),
    IconMessage: () => import("@/modules/layout/assets/svg/footer/iconMessage.vue"),
    IconWarning: () => import("@/common/svg/iconWarning.vue"),
    MainButton,
    iconPhone,
    IconEmail,
    iconLocation,
    IconCalendar,
    MainSuccess
},
    mixins: [addressesMixin],
    methods: {
        goTo(path){
            if (this.isPageDisable) return ;
            if (this.isLogged){
                this.$router.push({ path:  path}).catch(() =>{ });
                return
            }
            this.$login.open()
                .then(() => {
                    this.$router.push({ path: path }).catch(() =>{ });
                })
                .catch(()=>{})
        },
        async setSocialMedia(){
            if(this.configurations.general.web_page){
                const intercomLink = {title: 'contact_us', external: true, link: this.configurations.general.web_page.url_intercom}
                this.navigation[0].options[1] = intercomLink

                const instagram = { address: this.configurations.general.web_page.url_instagram, icon: 'icon-instagram' };
                const facebook = { address: this.configurations.general.web_page.url_facebook, icon: 'icon-facebook' };
                const twitter = { address: this.configurations.general.web_page.url_twitter, icon: 'icon-twitter' };
                const youtube = { address: this.configurations.general.web_page.url_youtube, icon: 'icon-youtube' };

                this.socialMedia[3] = youtube;
                this.socialMedia[0] = twitter;
                this.socialMedia[2] = facebook;
                this.socialMedia[1] = instagram;
            }
        },
        goToCard(){
            this.$router.push({ path: '/cart' }).catch(() => {});
        },
        scrollUp(){
            window.scroll(0, 0);
        },
        async subscribe(){
            const mail = { 
                email : this.subscribe_mail
            }
            if(this.subscribe_mail != ''){
                const res = await Service.subscribeEmail(mail);
                if(res?.data?.is_subscribed){
                    this.title = 'layout.title_subscribe',
                    this.subtitle = 'layout.subscribe'
                    this.animation = 'success'
                    this.showModal = true
                    this.subscribe_mail = ''
                }else if(res?.code == '84593486') {
                    this.title = 'layout.title_subscribed',
                    this.subtitle = 'layout.subscribed'
                    this.animation = 'bell'
                    this.showModal = true
                } else {
                    this.$v.subscribe_mail.$touch()// this.subscribe_mail = ''
                    const notify = {
                        text: 'layout.later',
                        type: 'danger',
                        show: true,
                    }
                    this.$store.commit("common/SET_SHORT_NOTIFY", notify);
                }
            }
        },
        checkRoute(){
            if (this.$route.path === this.currentRoute) {
                this.$router.push({ query: {...this.$route.query, t: Date.now()} }) //if we are already on the FAQ route, we make the route change so the Contact.vue component scrolls back to said section
            }
        }
    },
    computed: {
        ...mapGetters({
            isLogged: "account/isLogged",
            isPageDisable: "page/isPageDisable",
            vidvie: 'home/vidvie',
            configurations: "common/configurations",
            mobile: 'common/mobile',
        }),
    },
    watch: {
        subscribe_mail(){this.invalid = false},
        '$route.path': function(val) {
            this.pathCounter = setTimeout(()=>{
                this.currentRoute = val;
            }, 150)
        },
        'configurations'(){
            this.setSocialMedia()
        },
    },
    data(){
        return {
            title: '',
            subtitle: '',
            animation: '',
            showModal: false,
            subscribe_mail: '',
            invalid: false,
            socialMedia: [
                { address: '', icon: 'icon-twitter'}, 
                { address: '', icon: 'icon-instagram'}, 
                { address: '', icon: 'icon-facebook'}, 
                { address: '', icon: 'icon-youtube'}
            ],
            navigation: [
                {
                    nav_title:'help',
                    options: [
                        { title: 'policies', external: false, link:'/home/policies/view-privacy', vidvie: true},
                        { title: 'contact_us', external: true, link:'https://help.mundomovil.com.do/en/', vidvie: false},
                        { title:'faq', external: false, link:'/contact/faq', vidvie: true},
                    ],
                    styles: 'text-sm-start align-content-md-start align-content-lg-center',
                    vidvie: true
                },
                {
                    nav_title:'us',
                    options: [
                        { title:'about', external: true, link: 'https://www.mundomovil.com.do/inicio#about', vidvie: false},
                        { title:'services', external: true, link: 'https://www.mundomovil.com.do/index.php#services', vidvie: false},
                        { title:'jobs', external: true, link: 'https://empleo.mundomovil.com.do/', vidvie: false},
                    ],
                    styles: 'text-lg-start align-content-md-center',
                    vidvie: false
                },
                {
                    nav_title:'products',
                    options: [
                        { title:'my_cart', external: false, link: '/cart', vidvie: false },
                        { title:'wishlist', external: false, link: '/account/wishlist', vidvie: false },
                        { title:'promotions', external: false, link: '/shop?page=1&limit=25&order_by=popularity&place=1&only_offers=1', vidvie: false },
                    ],
                    styles: 'text-sm-end text-lg-start align-content-md-end align-content-lg-center',
                    vidvie: false
                }
            ],
            currentRoute: null,
            pathCounter: null,
        }
    },
    validations () {
        return { subscribe_mail: { required, email } }// Matches this.contact.email 
    }, 
    async created(){
        this.currentRoute = this.$route.path
    },
    beforeDestroy() {
        clearTimeout(this.pathCounter)
    },
};
</script>
<style scoped>
footer {
    background-color: #121c23;
    height: auto;
}
.socials { grid-area: socials; } .nav-1 { grid-area: nav-1; } .nav-2 { grid-area: nav-2; } .nav-3 {  grid-area: nav-3; } .subscribe-mail { grid-area: subscribe-mail; }

ul.nav li:hover > a {
    transform: translateX(10px) !important;
}
.social-icon {
    border-radius: 3.12rem;
}
.social-icon a {
    border-radius: 1.35rem;
    border: 1.5px solid white;

    width: 40px;
    height: 40px;
}
.social-icon svg {
    width: 60%;
    height: 60%;

    padding: 0;
    transition: all 0.15s ease-in-out;
}
.social-icon:hover a {
    transform: translateY(-5px);
    background-color: #fff !important;
}

a:hover svg, .main-link:hover svg {
    fill: #000000;
}
/* .social-icon:nth-child(0):hover svg::v-deep #fill {fill: black;} */
.social-icon:nth-child(2):hover svg::v-deep #fill {fill: #e4405f;}
.social-icon:nth-child(3):hover svg::v-deep #fill {fill: #0077b5;}
.social-icon:nth-child(4):hover svg::v-deep #fill {fill: #c41714;}

.form-control.is-invalid, .was-validated .form-control:invalid {
    box-shadow: none !important;
}
.form-control {
    padding: 0.1rem 3px;
}
.footer-input-group {
    border-radius: 7px;
}
.footer-btn::v-deep span {
    align-items: center;
}
.footer-input-group button.footer-btn {
    position: absolute !important;
    right: 8px;
    top: 3px;
    height: 32px !important;
    width: 132px !important;
    line-height: 19px;
    border-radius: 5px !important;
}
.footer-input-group button.footer-btn svg {
    margin-right: 8px;
}
.footer-input {
    position: relative;
    height: 38px !important;
    width: 100%;
    padding: 0 150px 0 18px;
    border-color: #cedde9 !important;
    border-radius: 5px !important;
    border: 1px solid;
    border-left: 0;
}
.footer-input:disabled {
    background: transparent;
}
.footer-input:focus, .footer-input:focus-visible {
    box-shadow: none !important;
    outline: none;
}
.footer-input::placeholder {
    font-size: 14px;
    font-weight: 300;
    opacity: 1 !important;
    color: var(--dark-gray-200);
}
.is-invalid-input {
    outline: 3px solid #BC2C35B3 !important;
}
.invalid-input {
    position: absolute !important;
    background: #fff;
    box-shadow: 0px 5px 25px #212e3d1f;
    border-radius: 7px;
    padding: 10px;
    bottom: -55px;
    left:0;
    z-index: 2;
    animation: animation-invalid-input 0.3s;
}
@keyframes animation-invalid-input {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }to {
        opacity: 1;
        transform: translateY(0px);
    }
}
.invalid-input::after, .invalid-input-password::after {
    content: '';
    border-bottom: 10px solid white !important;
    border-left: 10px solid #f7000000 !important;
    border-right: 10px solid #ff000000 !important;
    position: absolute;
    
    top: -7px;
    left: 10px;
}
.invalid-input > span {
    font-size: 14px;
    color: var(--main-red);
    transition: var(--transition-1);
}

.footer-content {
    grid-template-columns: 340px;
    grid-template-rows: 1fr 160px 138px 136px;
    grid-template-areas:    "socials"
                            "nav-1"
                            "nav-2"
                            "nav-3"
                            "subscribe-mail";
    justify-content: center;
    padding-block: 30px;
    row-gap: 20px;
}
.socials {
    margin-inline: 4rem;
}
.position_li_1 {
        position: relative;
        left: -34px;
    }
.position_li_2 {
    position: relative; 
    left: 40px;
}

@media (min-width: 576px) {
    .socials {
        width: auto !important;
        margin-inline: 0 !important;
    }
    .social-icon a {
        width: 30px;
        height: 30px;
    }
    .footer-content {
        grid-template-columns: 160px 180px 122px;
        grid-template-rows: repeat(2, 160px);
        grid-template-areas:"nav-1   nav-2 nav-3"
                            "socials subscribe-mail subscribe-mail";
        justify-content: space-between;
    }
}
@media (min-width: 576px) and (max-width: 991px) {
    .footer-input {
        margin-left: auto !important;
        width: 84% !important;
    }
    .position_li_1 {
        position: relative; 
        left: 80px;
    }
}
@media (min-width: 768px) {
    .social-icon a {
        width: 34px;
        height: 34px;
    }
    .footer-content {
        grid-template-columns: 180px 230px 215px;
        grid-template-rows: 160px;
        grid-template-areas:    "nav-1   nav-2  nav-3"
                                "socials subscribe-mail subscribe-mail";
    }
    .position_li_1 {
        position: relative; 
        left: 80px;
    }
}
@media (min-width: 992px) {
    footer {
        height: 300px;
    }
    .social-icon a {
        width: 35px;
        height: 35px;
    }
    .footer-content {
        grid-template-columns: 164px repeat(3, 100px) 310px;
        grid-auto-rows: 165px;
        grid-template-areas: "socials nav-1 nav-2 nav-3 subscribe-mail";
    }

    .position_li_1 {
        position: relative; 
        left: 147px;
    }
    .position_li_2 {
        position: relative; 
        left: 65px;
    }
}
@media (min-width: 1200px) {
    .footer-content {
        grid-template-columns: 175px 151px 100px 110px 351px;
    }
    .position_li_1 {
        position: relative; 
        left: 100px;
    }
}
@media (min-width: 1400px) {
    .footer-content {
        grid-template-columns: 164px 151px 134px 125px 353px;
    }

    .position_li_1 {
        position: relative; 
        left: 80px;
    }
}
.nav-header {
    font-size: 1.5rem; /* Tamaño de fuente grande */
    font-weight: bold; /* Peso de fuente en negrita */
    margin-top: 1rem; /* Espacio superior entre encabezados y elementos de lista */
    margin-bottom: 0.5rem; /* Espacio inferior entre encabezados y elementos de lista */
}
</style>