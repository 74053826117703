<template>
    <main-modal @closeModal="$emit('closeModal')" :title="''">
            <div class="text-center">
                <h2 class="title-22 weight-700 mb-4 mt-4">{{$t(`${title}`)}}</h2>
            </div>
            <div class="position-relative d-flex justify-content-center align-items-center" style="height:100px; top: -11px; right: 3px;">
                <lottie v-if="animation == 'success'" :options="defaultOptions" :height="200" :width="200" class="position-absolute" style="margin-top: -40px;margin-bottom: -40px;"/>
                <lottie v-else :options="bellOptions" :height="200" :width="175" class="position-absolute" style="margin-top: -40px; margin-bottom: -40px;"/>
            </div>
            <div class="text-center">
                <h4 class="subtitle-18 color-dark-gray-300 pb-1" style="max-width: 480px;margin: 0 auto;">
                    {{$t(`${subtitle}`)}}
                </h4>
                <div class="d-flex justify-content-center mb-3 pt-4">
                    <main-button class="main-btn positionButton" :title="'Cerrar'" :icon="true"  @click="$emit('closeModal')"/>
                </div>
            </div>
    </main-modal>
</template>

<script>
    import MainModal from '../../../../common/components/modals/MainModal.vue'
    import Lottie from "vue-lottie";
    import animationData from '../../../checkout/assets/animations/check-4';
    import animationBell from '../../../checkout/assets/animations/bell';
    import MainButton from '../../../../common/components/buttons/MainButton.vue';
    export default {
        components: { MainModal, Lottie, MainButton },
        name: 'MainSuccess',
        data(){
            return{
                defaultOptions: { animationData: animationData, loop: true },
                bellOptions: { animationData: animationBell, loop: true },
            }
        },
        props:{
            title: {
                type: String,
                required: true
            },
            subtitle: {
                type: String,
                required: true
            },
            animation: {
                type: String,
                required: true
            },
        },
        methods:{
        }
    }
</script>

<style scoped>
    button.main-btn:hover > span > div > svg{
        fill: white;
    }
    .modal-main-container::v-deep > .modal-main {
        min-width: 500px;
    }
    .positionButton{
        width: 119px; 
        font-size: 19px; 
        height: 35px; 
        position: relative;
        right: 4px;
    }   
</style>