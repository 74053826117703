<template>
    <icon-base :width="size+'px'" :fill="color" viewBox="0 0 26.864 55.158">
        <g id="Group_7499" data-name="Group 7499" transform="translate(1.548 2.118)">
            <g id="Group_7498" data-name="Group 7498" opacity="0.54">
            <g id="Group_7497" data-name="Group 7497">
                <path id="Path_3081" data-name="Path 3081" d="M381.232,1248.416a20.165,20.165,0,0,0,.993,18.284" transform="translate(-358.958 -1233.317)" fill="none" stroke="#b5c66d" stroke-linecap="round" stroke-width="3"/>
                <path id="Path_3082" data-name="Path 3082" d="M373.832,1240.933s-10.8,13.944.92,33.232" transform="translate(-358.198 -1232.76)" fill="none" stroke="#b5c66d" stroke-linecap="round" stroke-width="3"/>
                <path id="Path_3083" data-name="Path 3083" d="M366.648,1232.1s-21.48,24.095.7,50.928" transform="translate(-357.306 -1232.103)" fill="none" stroke="#b5c66d" stroke-linecap="round" stroke-width="3"/>
            </g>
            </g>
        </g>
    </icon-base>
</template>

<script>
export default {
    name: 'SoundWaveRight',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>
