<template>
    <section class="grid-container position-relative home-section">
        <div class="title-container position-absolute" style="z-index: 4;">
            <h5 class="color-main-navy weight-200">{{$t("home.be_part")}}</h5>
            <h4 class="color-main-navy weight-600">{{$t("home.our_community")}}</h4>
        </div>
        <a :href=" verifyUSTECH() ? 'https://www.instagram.com/vidvierd_/' : 'https://www.instagram.com/mundomovil/'" target="_blank" class="banner" :class="`banner-${card}`" v-for="card in [1,2,3,4,5]" :key="card">
            <img alt="collage" loading="lazy" :src="require(`../../assets/svg/community-collage/${card}.webp`)" class="position-relative cursor-pointer" />
        </a>
        <div class="follow-container position-absolute">
            <a class="d-block color-dark-gray-200 weight-300 text-end mb-0 pb-1" :href=" verifyUSTECH() ? 'https://www.instagram.com/vidvierd_/' : 'https://www.instagram.com/mundomovil/'" target="_blank" @mouseover="hover = true" @mouseleave="hover = false"> 
                <icon-instagram3 :hover="hover" class="pb-1 me-1" :size="27" />{{$t("home.follow")}} Instagram
            </a> 
            <h4 class="color-main-navy weight-500 text-end mb-0" style="margin-top: -10px;">{{ title }}</h4>
        </div>
    </section>
</template>
<script>
import IconInstagram3 from '../../assets/svg/icon-instagram_3.vue'
export default {
    components: { IconInstagram3 },
    name: 'CommunityCollage',
    data(){
        return {
            hover: false,
            title: process.env.VUE_APP_VIDVIE == 1  ||  process.env.VUE_APP_VIDVIE == 2 ? '@vidvierd_' : '@mundomovil'
        }
    }
}
</script>

<style scoped>
h4 {
    font-family: 'Myriad Pro Bold' !important;
}
h5, h6 {
    font-family: 'Myriad Pro Light' !important;
}
.title-container {
    top: -10px;
}
.title-container h4 {
    margin-top: -10px; 
}
.title-container :is(h4, h5){
    letter-spacing: 1px !important;
    font-size: clamp(30px, 2.3em, 48px);
}
/* CSS Grid */
.grid-container {
    display: grid;
    grid-template-columns: 45% 50.5%; /* 2*/
    grid-template-rows: 8% 18% 28% 12% 16.5% 3%; /*6*/ 
    grid-template-areas:".        .       " /*1 */
                        "banner-2 banner-2" /*2 */
                        "banner-1 banner-1" /*3 */
                        "banner-4 banner-5" /*4 */
                        "banner-4 banner-3" /*4 */
                        "banner-4 .       "; /*4 */
    gap: 15px;
    height: 960px;
}
.banner {
    border-radius: 10px;
    overflow: hidden;
}
.banner img {
    transition: var(--transition-6);
}
.banner-1 { grid-area: banner-1; } .banner-2 { grid-area: banner-2; } .banner-3 { grid-area: banner-3; } .banner-4 { grid-area: banner-4; } .banner-5 { grid-area: banner-5; }

.banner-1 img {
    left: 7%;
    transform: translate(-30%, -33%) scale(0.5);
}
.banner-1:hover img {
    transform: translate(-33%, -33%) scale(0.65);
}
.banner-2:hover img {
    transform: translate(-35px, 30px) scale(1.35);
}
.banner-3 img {
    width: 100%;
    height: 100%;
}
.banner-3:hover img {
    transform: scale(1.18);
}
.banner-4 img {
    width: 100%;
    transform: translate(-40px, 80px) scale(2.95);
}
.banner-4:hover img {
    transform: translate(-25px, 105px) scale(2.45);
}
.banner-5 img {
    width: 100%;
    transform: translate(10%, -20%) scale(2.75);
}
.banner-5:hover img {
    transform: translate(0%, -20%) scale(1.85);
}
.follow-container h4 {
    font-size: 36px;
}
.follow-container h6:hover  {
    color: #ED4B61 !important;
}
.instagram-icon::v-deep stop{
    transition: var(--transition-6);
}
@media (max-width: 575px) {
    .follow-container {
        bottom: -24px;
        right: 50%;
        transform: translateX(50%);
    }
    .follow-container :is(h4, a){
        text-align: center !important;
    }
}
@media (min-width: 576px) {
    .title-container {
        top: 0px;
    }
    .grid-container {
        grid-template-columns: 55% 41%; /* 94.27% */
        grid-template-rows: 9% 22% 21.4% 19% 11% 5% 0px; /*83.68% */
        grid-template-areas:".        .       " /*1 */
                            "banner-2 banner-2" /*2 */
                            "banner-1 banner-3" /*3 */
                            "banner-1 banner-4" /*4 */
                            "banner-5 banner-4" /*5 */
                            "banner-5 .       " /*6 */
                            ".        .       "; /*7 */
        gap: 22px;
    }
    .banner-1 img {
        transform: translate(-34%, -18%) scale(0.65);
    }
    .banner-1:hover img {
        transform: translate(-27%, -23%) scale(0.75);
    }
    .banner-2 img {        
        transform: translate(-35px, 15px) scale(1.20);
    }
    .banner-2:hover img {
        transform: translate(-65px, 30px) scale(1.45);
    }
    .banner-4 img {
        transform: translate(-65px, 80px) scale(2.3);
    }
    .banner-4:hover img {
        transform: translate(-50px, 80px) scale(2.05);
    }
    .banner-5 img{
        transform: translate(0%, -18.9%) scale(1.85);
    }
    .banner-5:hover img{
        transform: translate(0%, -21.9%) scale(1.25);
    }
    .follow-container {
        right: 0;
        bottom: 0;
    }
    .follow-container h4 {
        font-size: 32px;
    }
}
@media (min-width: 768px) {
    .grid-container {
        grid-template-columns: 55% 42%; /* 94.27% */
    }
    .title-container {
        top: -23px;
    }
    .title-container :is(h4, h5) {
        font-size: 48px;
    }
    .banner-4 img {
        transform: translate(-91px, -12px) scale(2.3);
    }
    .banner-4:hover img {
        transform: translate(-70px, 16px) scale(1.85);
    }
    .banner-5 img{
        transform: translate(0%, -28.4%) scale(1.5);
    }
    .banner-5:hover img{
        transform: translate(0%, -26.8%) scale(1.2);
    }
    .follow-container h4 { /* follow */
        font-size: 36px;
    }
}
@media (min-width: 992px) {
    .title-container h4 {
        margin-left: 26px;
    }
    section.grid-container {
        grid-template-columns: 30.25% 17.52% 22.45% 24.69%; /* 94.27% */
        grid-template-rows: 22.66% 12.50% 12.06% 15.28% 3.18% 8% 5%; /*83.68 */
        grid-template-areas:".        banner-2 banner-2 banner-2"
                            "banner-1 banner-2 banner-2 banner-2"
                            "banner-1 banner-3 banner-4 banner-5"
                            "banner-1 banner-3 banner-4 banner-5"
                            "banner-1 banner-3 banner-4 ."
                            "banner-1 .        banner-4 ."
                            ".        .        banner-4 .";
        gap: 16px;
        height: 470px;
    }
    .banner-1 img {
        transform: translate(-34%, -32%) scale(0.55);
    }
    .banner-1:hover img {
        transform: translate(-32%, -28%) scale(0.65);
    }
    .banner-4 img {
        width: 100%;
        transform: translate(-55px, -20px) scale(1.80);
    }
    .banner-4:hover img {
        transform: translate(-40px, 5px) scale(1.65);
    }
    .banner-5 img{
        transform: translate(0%, -18.9%) scale(1.85);
    }
    .banner-5:hover img{
        transform: translate(0%, -16.9%) scale(1.25);
    }
}
@media (min-width: 1200px) {
    .title-container {
        top: calc(20px + 2%);
    }
    section.grid-container {
        gap: 19px; 
        height: 600px;
    }
    .banner-1 img {
        transform: translate(-34%, -21%) scale(0.58);
    }
    .banner-1:hover img {
        transform: translate(-34%, -21%) scale(0.63);
    }
    .banner-2 img {
        left: 50%;
        top: 50%;
        transform: translate(-39%, -50%) scale(1.3);
    }
    .banner-2:hover img {
        transform: translate(-39%, -35%) scale(1.6);
    }
    .banner-4 img {
        transform: translate(-75px, 0px) scale(1.80);
    }
    .banner-4:hover img {
        transform: translate(-55px, 20px) scale(1.65);
    }
    .banner-5 img{
        transform: translate(0%, -16.9%) scale(1.95);
    }
    .banner-5:hover img{
        transform: translate(0%, -14.9%) scale(1.35);
    }
}
@media (min-width: 1400px) {
    section.grid-container {
        gap: 22px;
    }
    .banner-2 img {
        left: 50%;
        top: 50%;
        transform: translate(-47%, -50%) scale(1.3);
    }
    .banner-2:hover img {
        transform: translate(-47%, -42%) scale(1.45);
    }
    .banner-4 img {
        transform: translate(-75px, -40px) scale(1.75);
    }
    .banner-4:hover img {
        transform: translate(-40px, -10px) scale(1.5);
    } 
    .banner-5 img{
        transform: translate(0%, -22%) scale(1.79);
    }
    .banner-5:hover img{
        transform: translate(0%, -20.9%) scale(1.39);
    }
}
</style>