import {parseResponse} from "@/common/helpers/responses/parser.js";
import {http as nodeService} from "@/common/services/node-service.js";

export default {
    set_ask_for_location({commit}, boolean){
        commit("SET_ASK_FOR_LOCATION", boolean)
    },
    setPlace({ commit }, place){
        return new Promise((resolve)=>{
            commit('SET_PLACE', place)
            resolve()
        })
    },
    // async nearestPlace({commit}, {latitude, longitude}) {
    //     try {
    //         const place = await nodeService.get('/stores/nearest', {
    //         params:{
    //             "clientLat": parseFloat(latitude),
    //             "clientLng": parseFloat(longitude),
    //         }
    //     });
    //         commit('SET_PLACE', place.data.data.codplace)
    //         return;
    //     }catch (e) {
    //         throw e.response
    //     }
    // },
    async getBranches({ commit, state }){
        try {
            state.error = null;
            state.loading = true;
            const branches = await nodeService.get('stores');
            const res = parseResponse(branches.data);
            commit('SET_BRANCHES', res.data);
            // if(!state.branchSelected)
            // commit('SET_BRANCH', state.branches[0].codplace)
            return res.data
        }catch (e) {
            console.log(e)
            if (e.response){
                state.error = e.response.data.message;
                throw parseResponse(e.response.data);
            }else{
                state.error = e.message;
                throw e;
            }
        }finally {
            state.loading =  false
        }
    },
    async nearestBranch({commit, getters}, {latitude, longitude}) {
        try {
            const place = await nodeService.get('/stores/nearest', {
                params:{ clientLat: parseFloat(latitude), clientLng: parseFloat(longitude) }
            });
            let code = place.data.data.codplace;
            if(getters.branches.findIndex(item=> item.codplace == code) == -1){
                commit('SET_BRANCH', 1)
            }else{
                commit('SET_BRANCH', code)
            }
            return code;
        }catch (e) {
            throw e.response
        }
    },
};