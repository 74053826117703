import { render, staticRenderFns } from "./CreateWishList.vue?vue&type=template&id=6fc7b871&scoped=true"
import script from "./CreateWishList.vue?vue&type=script&lang=js"
export * from "./CreateWishList.vue?vue&type=script&lang=js"
import style0 from "./CreateWishList.vue?vue&type=style&index=0&id=6fc7b871&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fc7b871",
  null
  
)

export default component.exports