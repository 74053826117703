<template>
    <icon-base :width="100+'%'" :fill="color" viewBox="0 0 417 504.162">
        <g id="Group_7560" data-name="Group 7560" transform="translate(1 1)" opacity="0.15">
            <text id="XPODS_VIDVIE_" data-name="XPODS VIDVIE " transform="translate(0 62)" fill="rgba(0,0,0,0)" stroke="#212e3d" stroke-width="1" font-size="65" font-family="Myriad Pro Bold, Myriad Pro" font-weight="700" opacity="0.1"><tspan x="0" y="0">XPODS VIDVIE</tspan></text>
            <text id="XPODS_VIDVIE_2" data-name="XPODS VIDVIE " transform="translate(0 122.595)" fill="rgba(0,0,0,0)" stroke="#212e3d" stroke-width="1" font-size="65" font-family="Myriad Pro Bold, Myriad Pro" font-weight="700" opacity="0.2"><tspan x="0" y="0">XPODS VIDVIE</tspan></text>
            <text id="XPODS_VIDVIE_3" data-name="XPODS VIDVIE " transform="translate(0 183.189)" fill="rgba(0,0,0,0)" stroke="#212e3d" stroke-width="1" font-size="65" font-family="Myriad Pro Bold, Myriad Pro" font-weight="700" opacity="0.3"><tspan x="0" y="0">XPODS VIDVIE</tspan></text>
            <text id="XPODS_VIDVIE_4" data-name="XPODS VIDVIE " transform="translate(0 243.784)" fill="rgba(0,0,0,0)" stroke="#212e3d" stroke-width="1" font-size="65" font-family="Myriad Pro Bold, Myriad Pro" font-weight="700" opacity="0.4"><tspan x="0" y="0">XPODS VIDVIE</tspan></text>
            <text id="XPODS_VIDVIE_5" data-name="XPODS VIDVIE " transform="translate(0 304.378)" fill="rgba(0,0,0,0)" stroke="#212e3d" stroke-width="1" font-size="65" font-family="Myriad Pro Bold, Myriad Pro" font-weight="700" opacity="0.5"><tspan x="0" y="0">XPODS VIDVIE</tspan></text>
            <text id="XPODS_VIDVIE_6" data-name="XPODS VIDVIE " transform="translate(0 364.973)" fill="rgba(0,0,0,0)" stroke="#212e3d" stroke-width="1" font-size="65" font-family="Myriad Pro Bold, Myriad Pro" font-weight="700" opacity="0.6"><tspan x="0" y="0">XPODS VIDVIE</tspan></text>
            <text id="XPODS_VIDVIE_7" data-name="XPODS VIDVIE " transform="translate(0 425.567)" fill="rgba(0,0,0,0)" stroke="#212e3d" stroke-width="1" font-size="65" font-family="Myriad Pro Bold, Myriad Pro" font-weight="700" opacity="0.7"><tspan x="0" y="0">XPODS VIDVIE</tspan></text>
            <text id="XPODS_VIDVIE_8" data-name="XPODS VIDVIE " transform="translate(0 486.162)" fill="rgba(0,0,0,0)" stroke="#212e3d" stroke-width="1" font-size="65" font-family="Myriad Pro Bold, Myriad Pro" font-weight="700" opacity="0.7"><tspan x="0" y="0">XPODS VIDVIE</tspan></text>
        </g>
    </icon-base>
</template>

<script>
export default {
    name: 'BackgroundText',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>

<style scoped>
    @font-face {
        font-family: 'Myriad Pro Bold';
        font-style: normal;
        font-weight: normal;
        src: local('Myriad Pro Bold'), url('../../../../../../common/assets/fonts/myriad-pro/MYRIADPRO-BOLD.woff') format('woff');
    }
    /* #Group_7560{
        animation: move-letters 2s infinite ease-in-out;
        transition:all 0.5s ease-in-out;
    }
    @keyframes move-letters {
        from{
            transform: translateX(-300px);
        }
        to{
            transform: translateX(0px);
        }
    } */
</style>

