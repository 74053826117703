<template>
    <section class="home-section">
        <div class="row m-0 position-relative" style="overflow: hidden;">
            <div class="col-md-12 container-title text-center">
                <h5 class="mb-0">#{{$t('home.ads.fill_your_summer_of_music_with')}}</h5>
                <h1>VIDVIE</h1>
            </div>
            <div class="p-0" v-for="(ad, index) in ads" :key="index" :class="[ad.type == 'ad-lg' ? 'col-md-12' : ad.type == 'ad-sm' ? 'col-md-4' : 'col-md-8 d-flex flex-column justify-content-end']">
                <ad-lg :ad="ad" class="col-md-12" v-if="ad.type == 'ad-lg'"/>
                <ad-sm v-else-if="ad.type == 'ad-sm'"/>
                <div v-if="ad.type == 'ad-md'">
                    <ad-md class="mt-4 mt-md-0"/>
                    <ad-md-sec/>
                </div>
            </div>
        </div>
        <hr class="d-none d-md-block home-section">
    </section>
</template>

<script>
import http from 'axios'
import AdLg from './AdLg.vue'
import AdSm from './AdSm.vue';
import AdMd from './AdMd.vue';
import AdMdSec from './AdMdSec.vue';
    export default {
        name: "Advertisements",
    data() {
        return {
            overAds1: false,
            ads: [
                {type: "ad-lg"},
                {type: "ad-sm"},
                {type: "ad-md"},
                {type: "ad-md-sec"},
            ]
        };
    },
    components: { AdLg, AdSm, AdMd, AdMdSec },
    computed: {
        getPositionImg() {
            return ""; // let width = 
        },
        english() {
            return this.$i18n.locale == "en";
        }
    },
    methods: {
        async getAds() {
            try {
                const response = await http.get("https://ecommerce-products.dev.minicode.com.do/api/testConllection");
                this.ads = response.data.data.json.ads;
                console.log(this.$i18n.locale);
            }
            catch (error) {
                console.log(error);
            }
        }
    },
    async created() {
        // await this.getAds();
    },
}
</script>

<style scoped>
    @font-face {
        font-family: 'Myriad Pro Bold';
        font-style: normal;
        /* font-weight: normal;*/
        font-weight: bold;
        src: local('Myriad Pro Bold'), url('../../../../common/assets/fonts/myriad-pro/MYRIADPRO-BOLD.woff') format('woff');
    }
    @font-face {
        font-family: 'Myriad Pro Semibold';
        font-style: normal;
        font-weight: normal;
        src: local('Myriad Pro Semibold'), url('../../../../common/assets/fonts/myriad-pro/MYRIADPRO-SEMIBOLD.woff') format('woff');
    }
    @font-face {
        font-family: 'Myriad Pro Light';
        font-style: normal;
        font-weight: normal;
        src: local('Myriad Pro Light'), url('../../../../common/assets/fonts/myriad-pro/MyriadPro-Light.woff') format('woff');
    }
    .container-title h5{
        font-size: 30px;
        font-weight: 100;
    }
    .container-title h1{
        font-size: 130px;
        color: #EF943A;
        font-family:'Myriad Pro Bold' !important;
        font-weight: 600
    }
    @media (max-width: 476px) {
        .container-title h5{
            font-size: 20px;
        }
        .container-title h1{
            font-size: 100px;
        }
    }

</style>
