export default () => ({
    openModal: false,
    selectedProduct: {},
    lists: [],
    lastCreated: null,
    lastDeleted: [], //This is for the undo button
    // loaders
    editingList: { value: false, id: null },
    loadingLists: true,
    //errorHandler
    listError: false,
});