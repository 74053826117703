<template>
    <main-modal :title="'home.contact.modal_follow_us_title'" @closeModal="$emit('closeModal')" >
        <div class="share-container">
            <h5 class="title-16 mb-4 weight-500">{{$t("home.contact.modal_follow_us_desc")}}</h5>
            <div class="row justify-content-between align-items-center">
                <a target="_blank" href="https://twitter.com/" class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4">
                    <div class="d-flex flex-column align-items-center justify-content-center">
                        <icon-twitter :size="50" class="icon"/>
                        <span class="subtitle-14 mt-1">X</span>
                    </div>
                </a>
                <a  href="https://instagram.com/mundomovil?utm_medium=copy_link" class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4">
                    <div class="d-flex flex-column align-items-center justify-content-center">
                        <icon-instagram :size="50" class="icon"/>
                        <span class="subtitle-14 mt-1">Instagram</span>
                    </div>
                </a>
                <a target="_blank" href="https://www.facebook.com/MUNDOMOVILOFICIAL/" class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4">
                    <div class="d-flex flex-column align-items-center justify-content-center">
                        <icon-facebook :size="50" class="icon"/>
                        <span class="subtitle-14 mt-1">Facebook</span>
                    </div>
                </a>
                <a class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4 mt-3 mt-sm-0" @click.prevent="copyFunction('8095817666')">
                    <div class="d-flex flex-column align-items-center justify-content-center">
                        <icon-whatsapp :size="50" class="icon"/>
                        <span class="subtitle-14 mt-1">Whatsapp</span>
                    </div>
                </a>
                <a class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4 mt-3 mt-sm-0" @click.prevent="openEmail">
                    <div class="d-flex flex-column align-items-center justify-content-center">
                        <icon-email :size="50" class="icon"/>
                        <span class="subtitle-14 mt-1">Email</span>
                    </div>
                </a>
                <a target="_blank" href="https://www.pinterest.com" class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4 mt-3 mt-sm-0">
                    <div class="d-flex flex-column align-items-center justify-content-center">
                        <icon-pinterest :size="50" class="icon"/>
                        <span class="subtitle-14 mt-1">Pinterest</span>
                    </div>
                </a>
            </div>
            <div>
            </div>
        </div>
    </main-modal>
</template>

<script>
import MainModal from '@/common/components/modals/MainModal.vue'
import IconEmail from '@/modules/products/assets/svg/iconEmail.vue'
import IconFacebook from '@/modules/products/assets/svg/iconFacebook.vue'
import IconInstagram from '@/modules/products/assets/svg/iconInstagram.vue'
import IconPinterest from '@/modules/products/assets/svg/iconPinterest.vue'
import IconTwitter from '@/modules/products/assets/svg/iconTwitter.vue'
import IconWhatsapp from '@/modules/products/assets/svg/iconWhatsapp.vue'
export default {
    components: { MainModal, IconTwitter, IconFacebook, IconWhatsapp, IconEmail, IconPinterest, IconInstagram},
    name: "FollowUs", 
    data() {
        return {
            closeCopy: false,
            copy: false,
            hover: false
        }
    },
    computed:{
        getBaseUrl(){
            var baseUrl = window.location.origin +'/products/'
            return baseUrl
        }
    },
    methods:{
        copyFunction(url){
            this.$copyText(url)
            this.copy = true
        },
        share(url){
            this.$copyText(url)
            this.hover = true
            this.copy = true
            this.closeCopy = true
            setTimeout(() => {
                window.open('https://www.instagram.com/','_blank');
                this.hover = false
                this.copy = false
                this.closeCopy = false
            }, 1000);
        },
        openEmail(){
            window.open('mailto:info@mundomovil.com.do?&subject=Tu%20asunto.')
        }
    }
}
</script>

<style scoped>
    .icon-twitter::v-deep g, .icon-instagram::v-deep g, 
    .icon-twitter::v-deep circle, .icon-instagram::v-deep circle,
    .icon-facebook::v-deep circle, .icon-facebook::v-deep g,
    .icon-whatsapp::v-deep circle, .icon-whatsapp::v-deep g,
    .icon-email::v-deep circle, .icon-email::v-deep g,
    .icon-pinterest::v-deep circle, .icon-pinterest::v-deep g{
        transition: var(--transition-1)
    }
    .icon-twitter:hover::v-deep circle{
        fill: #e8f7ff7a;
    }
    .icon-twitter:hover::v-deep g{
        transform: scale(0.9) translate(16px, 19px) !important;
    }
    .icon-instagram:hover::v-deep circle{
        fill: #ffecf278;
    }
    .icon-instagram:hover::v-deep g{
        transform: scale(0.9) translate(17px, 17px) !important;
    }
    .icon-facebook:hover::v-deep circle{
        fill: #ddecff75;
    }
    .icon-facebook:hover::v-deep g{
        transform: scale(0.9) translate(24px, 18px) !important;
    }
    .icon-whatsapp:hover::v-deep circle{
        fill: #e9fbf085;
    }
    .icon-whatsapp:hover::v-deep g{
        transform: scale(0.9) translate(17px, 17px) !important;
    }
    .icon-email:hover::v-deep circle{
        fill: #fef7ea80;
    }
    .icon-email:hover::v-deep g{
        transform: scale(0.9) translate(18px, 23px) !important;
    }
    .icon-pinterest:hover::v-deep circle{
        fill: #ffdfe078;
    }
    .icon-pinterest:hover::v-deep g{
        transform: scale(0.9) translate(17px, 17px) !important;
    }
    .icon-copy{
        position: absolute;
        right: 4px;
        top: 7px;
        cursor: pointer;
        width: 70px;
        background: white;
    }
    .input-group{
        border-radius: 7px;
        border-color: var(--gray-1001);
        width: 99%;
    }
    .input-group input{
        border-radius: 7px;
        border-color: var(--gray-1001);
        color: var(--dark-gray-200);
        cursor: pointer;
        font-size: 14px;
        border-right: 0;
    }
    .input-group-text{
        border-radius: 7px;
        background: transparent;
        border-left: 0;
    }
    .input-group:hover input{
        color: var(--main-navy);
    }
    .input-group:hover svg{
        fill: var(--main-red);
    }
    .active-hover input, .active-hover svg{
        color: var(--main-navy);
        fill: var(--main-red);
    }
    @media (min-width: 720px) {
        .modal-main-container::v-deep .modal-main{
            min-width: 628px;
            padding: 35px 50px;
        }
    }
    .copy-tooltip{
        position: absolute;
        right: -4px;
        top: -20px;
        font-size: 11px;
        color: #fff;
        background: #414146eb;
        border-radius: 4px;
        padding: 1px 10px;
        animation: 0.3s show-tootlip;
    }
    @keyframes show-tootlip {
        from{
            opacity: 0;
            top: 5px;
        }
        to{
            opacity: 1;
            top: -20px;
        }
    }    
    .copied{
        right: 2px !important;
        padding: 1px 5px !important;
        right: -2px !important;
        
    }
    .copy-tooltip::after{
        content: '';
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #414146eb;
        position: absolute;
        left: 20px;
        bottom: -6px;
    }
</style>