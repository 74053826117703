<template>
    <button :id="id" @click="$emit('click')" :disabled="disabled" :class="[disabled ? 'button-disabled' : '', { 'pressed': pressed} ]" 
    @focus="pressed = true" @blur="pressed = false" @mouseout="pressed = false" class="main-button d-flex align-items-center justify-content-center">
        <!-- <span class="d-flex justify-content-center"> -->
            <!-- <div v-if="!iconRight" :class="{'d-flex': flex}"> -->
            <!-- </div> -->
            <slot v-if="icon && !iconRight"/>
            {{$t(title)}}
            <slot v-if="icon && iconRight"/>
            <!-- <div v-if="iconRight" :class="{'d-flex': flex}"> -->
            <!-- </div> -->
        <!-- </span> -->
    </button>
</template>

<script>
export default {
    name: 'MainButton',
    props: {
        title: {
            type: String,
            required: true
        },
        id: {
            type: String
        },
        icon: {
            type: Boolean
        },
        disabled:{
            type: Boolean
        },
        iconRight:{
            type: Boolean,
            default: false
        },
        flex:{
            type: Boolean
        }
    },
    data() {
        return {
            pressed:false
        }
    },
    methods: {
        click() {
            this.$emit('click')
        }
    }
}
</script>

<style scoped>
    button{
        color: #fff !important;
        border-radius: 5px;
        border: 0;
        font-weight: 400;
        padding: 0 15px;
        line-height: 32px;
        background-color: var(--main-red);
        transition: var(--transition-1);
        font-size: 14px;
        user-select: none;
    }
    .button > svg{
        fill: #fff !important;
    }
    button:hover{
        background-color: var(--light-main-red);
    }
    .button-disabled{
        background: var(--gray-1001) !important;
        cursor:no-drop;
    }
    span{
        color: #fff !important;
    }
    .pressed{
        transform: scale(0.97);
        background: var(--dark-main-red) !important;
    }
</style>