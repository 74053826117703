<template>
    <router-link tag="div" to="/products/10922" class="w-100 h-100 container-banner cursor-pointer" :class="[{active : active == index}, {prev : active == index + 1 || active == index - 1}]">
        <img src="./images/triangles.webp" alt="" width="902px" class="triangles">
        <div class="content">
            <h3 class="mb-0">{{data.title_1}}</h3>
            <h1 class="mb-0">{{data.title_2}}</h1>
            <background-text :size="504" style="margin-top: -12px" text="2021"/>
            <h5>{{data.description_1}} <b class="weight-700">HD</b></h5>
            <div class="container-text rounded-1 bg-white d-inline-block position-relative px-3">
                <h4 class="mb-0">{{data.description_2}}</h4>
            </div>
        </div>
        <!-- <h6>Mundo Movil</h6> -->
        <img :src="data.img_primary" alt="" class="img-1">
        <img :src="data.img_secondary" alt="" class="img-2">
        <img src="./images/background-light-2.webp" alt="" class="background-light-2">
    </router-link>
</template>

<script>
import BackgroundText from '../1/BackgroundText.vue'
export default {
    components: { BackgroundText },
    name: "SliderType2",
    props:{
        data:{
            type: Object
        },
        active:{
            type: Number
        },
        index:{
            type: Number
        }
    }
}
</script>
<style scoped>
.container-banner{
    background-color: #30becc;
}
.content{
    position: absolute;
    top: 14%;
    left: calc(7% - 6px);
    text-align: start;
}
.content > h3{
    font-size: 54px;
    color: #fff;
}
.container-text{transform: translateX(-15px);}
.content > .container-text > h4{
    font-size: 30px;
    font-weight: 700;
    text-shadow: 0 0 2px white, 0 0 2px white, 0 0 1px white, 0 0 1px white;
    color: #30becc;
    
}
.content > h1{
    font-size: 64px;
    color: #fff;
    font-weight: 700;
}
.content > h5{
    font-size: 36px;
    color: #fff;
    font-weight: 300;
    margin-top: 5rem;
}
.triangles{
    position: absolute;
    width: 700px;
    right: 0;
    top: 0;
}
.img-1{
    position: absolute;
    width: 235px;
    left: 59%;
    top: 7%;
}
.img-2{
    position: absolute;
    width: 235px;
    right: 13%;
    top: 12%;
}

.container-banner.active >.img-1{
    animation: show-container-banner-img-1 0.6s;
}
@keyframes show-container-banner-img-1 {
    from{
        transform: translateY(-300px);
    }
    to{
        transform: translateY(0px);
    }
}
.container-banner.prev >.img-1{
    animation: show-container-banner-prev-img-1 0.6s;
}
@keyframes show-container-banner-prev-img-1 {
    from{
        transform: translateY(0px);
    }
    to{
        transform: translateY(-300px);
    }
}
.container-banner.active > .img-2{
    animation: show-container-banner-img-2 0.6s;
}
@keyframes show-container-banner-img-2 {
    from{
        transform: translateY(300px);
    }
    to{
        transform: translateY(0px);
    }
}
.container-banner.prev > .img-2{
    animation: show-container-banner-prev-img-2 0.6s;
}
@keyframes show-container-banner-prev-img-2 {
    from{
        transform: translateY(0px);
    }
    to{
        transform: translateY(300px);
    }
}
.container-banner.active > .content{
    animation: show-slider-content 1s;
}
@keyframes show-slider-content {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
</style>