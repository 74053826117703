<template>
    <section class="home-section">
        <div class="d-flex align-items-center justify-content-between flex-wrap">
            <h1 class="active-title mb-1 me-3 flex-shrink-0">{{$t("home.flash_offers")}}</h1>
            <clock-flip class="mt-4 mt-sm-0 mx-auto mx-sm-0" deadline="2022-9-10 00:00:00" :labels="{days: 'DAYS', hours: 'HOURS', minutes: 'MINS', seconds: 'SECS'}"/>
        </div>
        <div class="position-relative">
            <div class="button-prev swiper-button-prev-offers-flash d-none d-md-block">
                <icon-arrow-circle class="fill-gray-1001" :size="40"/>
            </div>
            <swiper class="swiper" ref="offersFlash" :options="options">
                <swiper-slide v-for="product in products" :key="product.codarticulo">
                    <div class="card-father-container d-flex pt-4 pt-md-5">
                        <card-product class="slider-card-product" :product="product" :skeleton="loading" :offer="true" @showQuickView="showQuickView" @showShare="showShare"/>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination" />     
            </swiper>
            <div class="button-next swiper-button-next-offers-flash d-none d-md-block" slot="button-next">
                <icon-arrow-circle class="fill-gray-1001" :size="40" />
            </div>
        </div>
        <quick-view v-if="quickView.value" :product="quickView.product" @closeModal="quickView.value = false"/>
        <share v-if="share" @closeModal="share = false" :product="shareProduct"/>
    </section>
</template>

<script>
    import { ProductService } from '@/modules/products/services/product-service.js'
    import CardProduct from '../../../products/components/CardProduct.vue'
    const service = new ProductService()
    import ClockFlip from '../../../../common/components/clock/ClockFlip.vue'
    import IconArrowCircle from '../../../../common/svg/iconArrowCircle.vue'
    import QuickView from '../../../products/components/quick-view/QuickView.vue'
    import Share from '../../../products/components/modals/Share.vue'
    export default {
    components: { CardProduct, ClockFlip, IconArrowCircle, QuickView, Share },
        name: 'OffersFlash',
        data(){
            return{
                products: [],
                loading: true,
                options:{
                    noSwiping: true,
                    preloadImages: true,
                    lazy: true,     
                    pagination: {
                        el: '.swiper-pagination',
                        dynamicBullets: true,
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next-offers-flash',
                        prevEl: '.swiper-button-prev-offers-flash',
                    },
                    initialSlide: 0,
                    autoplay: {
                        delay: 2500,
                        stopOnLastSlide: true,
                        pauseOnMouseEnter: true,
                        disableOnInteraction: true,
                    },
                    grabCursor: true,
                    slidesPerView: 4,
                    slidesPerColumnFill: 'row',
                    slidesPerColumn: 1,
                    preventClicks: true,
                    breakpoints: {
                        0: {
                            slidesPerView: 1.3,
                            spaceBetween: 0,
                            slidesPerColumn: 1,
                            speed: 300
                        },
                        576: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                            slidesPerColumn: 1,
                            speed: 800,
                        },
                        // when window width is >= 768px
                        768: {
                            slidesPerView: 2,
                            slidesPerColumn: 1,
                            slidesPerGroup: 1,
                            spaceBetween: 20,
                            centeredSlides: false,
                        },
                        // when window width is >= 992px
                        992: {
                            slidesPerView: 3,
                            slidesPerGroup: 1,
                            spaceBetween: 40,
                        },
                        // when window width is >= 1200px
                        1200: {
                            slidesPerView: 4,
                            slidesPerGroup: 1,
                            spaceBetween: 10
                        },
                        1400: {
                            slidesPerView: 4,
                            slidesPerGroup: 1,
                            spaceBetween: 30
                        },
                    }
                },
                quickView: { product: null, value: false},
                shareProduct: null,
                share: false
            }
        },
        methods:{
            async getProducts(){
                let branch = this.branchSelected?.codplace || 1;
                let params = { category: 5, limit: 10, place: branch, page: 1};
                try {
                    const response = await service.getProducts({order_by: 'newest', ...params});
                    this.products = response.docs
                } catch (e) {
                    console.log(e);
                }finally{
                    this.loading = false
                }
            },
            showQuickView({product, value}){
                this.quickView.product = product;
                this.quickView.value = value;
            },
            showShare(product){
                this.share = true
                this.shareProduct = product
            },
        },
        async created(){
            await this.getProducts();
        }
    }
</script>

<style scoped>
.active-title {
    display: inline-block;
    font-size: calc(1.3rem + 0.6vw);
    max-width: 100%;
    font-weight: 700;
}
.active-title:after {
    content: '';
    position: relative;
    top: 5px !important;
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 3.125rem;
    background-color: var(--main-red);
}
.slider-card-product::v-deep.card-product {
    margin-top: 5px;
    box-shadow: 3px 6px 20px #7c879329;
    max-width: 275px;
}
.slider-card-product::v-deep.card-product:hover {
    transform: scale(1.05);
}
.card-father-container{
    padding-bottom: 6rem;
}
.swiper::v-deep .swiper-wrapper .card-father-container {
    padding-left: 30px;
    padding-right: 30px;
}
.swiper-pagination::v-deep .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
}
.swiper-pagination::v-deep :is(.swiper-pagination-bullet-active-prev-prev, .swiper-pagination-bullet-active-next-next){
    transform: scale(0.45);
}
.button-prev{
    position: absolute;
    top: 41%;
    z-index: 5;
    left: 7px;
}
.button-next{
    position: absolute;
    top: 41%;
    right: 8px;
    z-index: 1;
    transform: rotate(180deg)
}
.button-prev.swiper-button-disabled, .button-next.swiper-button-disabled {
    opacity: 0.35;
    cursor: no-drop !important;
    pointer-events: all !important;
}
.button-prev.swiper-button-disabled:hover svg, .button-next.swiper-button-disabled:hover svg {
    fill: var(--gray-100) !important;
}
.button-prev:hover svg, .button-next:hover svg {
    fill: var(--main-red) !important;
}
@media (max-width: 576px) {
    h1.active-title {
        /* width: 185px; */
    }
    .flip-clock {
        /* margin: 1.5rem auto 0 auto; */
    }
    .flip-clock__piece > div:first-of-type{

    }
    .card-father-container{
        padding-bottom: 4rem;
    }
}
/*
.flip-clock::v-deep .flip > .flip-card{
    font-size: 1.8rem;
}
.flip-clock::v-deep .flip > .flip-card > .flip-card__top, 
.flip-clock::v-deep .flip > .flip-card > .flip-card__bottom{
    background-color: var(--light-gray-100);
    color: var(--dark-gray-200);
}
.flip-clock::v-deep .flip > .flip-clock__slot{
    font-size: 13px;
    color: var(--gray-1001);
}*/
</style>