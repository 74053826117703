export default {
    SET_BANNERS(state, banners) {
        state.banners.main = banners.main
        state.banners.secondary = banners.secondary
    },
    SET_PRODUCTS(state, products) {
        state.products = products
        // state.products.topProducts = products.top
        // state.products.offersProducts = products.offers
    },
    CHANGE_TO_VIDVIE(state, boolean){
        state.vidvie = boolean
    }
}
