<template>
<icon-base :width="100+'%'" :fill="color" viewBox="0 0 532.259 243.925">
    <path id="Path_3080" data-name="Path 3080" d="M1353.925,1617.048s-75.919-41.43-103.083-145.227,105.4-51.822,218.4-13.7,262.947-63.743,295.543-31.283,20.645,217.9-134.733,226.771S1353.925,1617.048,1353.925,1617.048Z" transform="translate(-1247.258 -1410.829)" fill="#e0bf34" opacity="0.2"/>
</icon-base>
</template>

<script>
export default {
    name: 'BackgroundCurve2',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>
