<template>
<icon-base :width="100+'%'" :fill="color" viewBox="0 0 351.293 149.435">
    <path id="Path_3105" data-name="Path 3105" d="M1009.317,1055.506s92.7,98.762,346.21,42.408" transform="matrix(-0.966, -0.259, 0.259, -0.966, 1036.54, 1429.355)" fill="none" stroke="#eaca42" stroke-width="3" opacity="0.6"/>
</icon-base>
</template>

<script>
export default {
    name: 'Line',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>
