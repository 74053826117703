<template>
    <div class="main-input position-relative" :class="[{'invalid-input is-invalid': $v.modelValue.$error}, {'focused': focused}]">
        <div v-if="title" class="d-flex mb-2">
            <label :for="id" class="title-14 mb-0" :class="{'error-title': $v.modelValue.$error}"> {{$t(title)}} </label>
            <slot name="forgotPassword" />
            <slot name="reviewArea" />
        </div>
        <div class="input-group" @mousedown="activeInput = !activeInput">
            <span v-if="!textArea" class="input-group-text" :class="[{'d-none': disableIcon,'invalid-input-group-text': $v.modelValue.$error, 'is-invalid-input-new-password': customValidate === 'new-password' && modelValue}]" :id="`input-group-${id}`">
                <slot/>
            </span>
            <textarea v-if="textArea" v-model="modelValue" :class="[{'is-invalid-input': $v.modelValue.$error}]" autofill="off" autocomplete="off" 
            :maxlength="max" :placeholder="$t(placeholder)" @input="$emit('input', modelValue)" @focus="focus" @blur="blur" />
            <input v-else v-model="modelValue" v-mask="mask" :type="showEye ? 'password' : type" class="form-control" autofill="off" :id="id" autocomplete="off"
            :readonly="readonly" :maxlength="max" :placeholder="$t(placeholder)" :class="[{'disable-icon': disableIcon, 'is-invalid-input': $v.modelValue.$error, 'is-invalid-input-new-password': customValidate === 'new-password' && modelValue}]"
            @input="$emit('input', modelValue)" @change="$emit('change')" @focus="focus" @blur="blur" @keyup.enter="$emit('enter')" :disabled="disabled">
            <span v-if="fixedType ==='password'" class="input-group-text" :class="[{'invalid-input-group-text': $v.modelValue.$error}, {'is-invalid-input-new-password': customValidate === 'new-password' && modelValue}]">
                <div @click="showEye = !showEye" class="d-flex eye-container align-items-center">
                    <icon-closed-eye v-if="showEye" class="eye-closed" :size="20" color="var(--dark-gray-200)"/>
                    <icon-opened-eye v-else class="eye-closed" :size="20" color="var(--dark-gray-200)"/>
                </div>
            </span>
        </div>
        <template v-if="customValidate == 'new-password' && focused || customValidate == 'new-password' && $v.modelValue.$error">
            <div class="error-pop-password d-flex flex-column error-pop d-none">
                <span class="label-password d-flex" :class="{'label-password-valid': modelValue.length > min }"><icon-check-circle v-if="modelValue.length > min" :size="14" class="me-2"/> <icon-circle :size="14" class="me-2" :color="'var(--gray-100)'" v-else/> {{min}} {{ $t("common.min_field") }}.</span>
                <span class="label-password d-flex mt-1" :class="{'label-password-valid': containsSpecial }"><icon-check-circle v-if="containsSpecial" :size="14" class="me-2"/><icon-circle :size="14" class="me-2" :color="'var(--gray-100)'" v-else/>1 {{ $t("common.special_character") }}.</span>
                <span class="label-password d-flex mt-1" :class="{'label-password-valid': containsNumber }"><icon-check-circle v-if="containsNumber" :size="14" class="me-2"/><icon-circle :size="14" class="me-2" :color="'var(--gray-100)'" v-else/> {{ $t("common.must_include_numbers") }}.</span>
                <span class="label-password d-flex mt-1" :class="{'label-password-valid': containsUppercase && containsLowercase }"><icon-check-circle v-if="containsUppercase && containsLowercase" :size="14" class="me-2"/> <icon-circle :size="14" class="me-2" :color="'var(--gray-100)'" v-else/>{{ $t("common.uppercase_and_lowercase_letters") }}.</span>
            </div>
        </template>
        <!-- <template v-if="customValidate == 'confirm-password' && $v.modelValue.$error">
            <div class="d-flex error-pop align-items-center error-pop d-none">
                <icon-warning class="me-2" color="var(--main-red)" :size="14" red />
                <div>
                    <span>{{$t("common.passwords_do_not_match")}}</span>
                </div>
            </div>
        </template> -->
        <template v-else>
            <div class="error-pop d-none align-items-center m-0">
                <icon-warning class="me-2" color="var(--main-red)" :size="14" red />
                <div v-if="customValidate == 'expiration-date'">
                    <span v-if="modelValue == null || modelValue == ''">{{ $t("common.required_field") }}</span>
                    <span v-else-if="!validateMonth">{{ $t("common.month_must_be_less_than_12") }}</span>
                    <span v-else-if="!validateYear">{{ $t("common.invalid_year") }} </span>
                </div>
                <div v-else-if="customValidate == 'confirm-password'">
                    <span>{{$t("common.passwords_do_not_match")}}</span>
                </div>
                <div v-else-if="customValidate == 'card-number'">
                    <span v-if="modelValue == null || modelValue == ''">{{ $t("common.required_field") }}</span>
                    <span v-else-if="!$v.modelValue.minLength">{{ $t("common.invalid_number") }}</span>
                    <span v-else>{{$t('common.brand_not_found')}}</span>
                </div>
                <div v-else>
                    <span v-if="type === 'email' && !$v.modelValue.email">{{ $t("common.enter_a_valid_email") }}</span>
                    <span v-else-if="customValidate === 'identification' && !$v.modelValue.email">{{ $t("common.please_enter_a_valid_identification_card") }}</span>
                    <span v-else-if="type !== 'phone' && fixedType !== 'password' && !$v.modelValue.minLength && $v.modelValue.required">{{min}} {{ $t("common.min_field") }}</span>
                    <span v-else-if="type === 'phone' && !$v.modelValue.valid && $v.modelValue.required">{{ $t("common.invalid_phone") }}</span>
                    <span v-else>{{ $t("common.required_field") }}.</span>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import iconClosedEye from '../../../modules/auth/assets/svg/iconClosedEye.vue'
import IconOpenedEye from '../../../modules/auth/assets/svg/iconOpenedEye.vue'
import IconWarning from '../../svg/iconWarning.vue'
import IconCircle from '../../svg/iconCircle.vue'
import IconCheckCircle from '../../../modules/account/assets/svg/iconCheckCircle.vue'

export default {
    components: {
        iconClosedEye, 
        IconOpenedEye, 
        IconWarning, 
        IconCircle, 
        IconCheckCircle 
    },
    name: 'MainInput',
    props: {
        id: {
            type: String,
            required: true
        },
        title: {
            type: String
        },
        placeholder: {
            type: String
        },
        value: {
            type: [String, Number, null],
        },
        type: {
            type: String,
            default: 'text'
        },
        fixedType: {
            type: String
        },
        textArea: {
            type: Boolean,
            default: false
        },
        mask: {
            type: String, 
            default: ''
        },
        max: {
            type: Number,
        },
        maxCharacterLength: {
            type: Number,
            default: 99
        },
        disabled: {
            type: Boolean,
        },
        min: {
            type: Number,
            default: 0
        },
        customValidate: {
            type: String
        },
        readonly: {
            type: Boolean,
            default: false
        },
        valuePassword: {
            type: String,
            default: ''
        },
        cardBrand: {
            type: String,
            default: ''
        },
        disableIcon: {
            type: Boolean,
        }
    },
    data() {
        return {
            modelValue: this.value,
            showEye: false,
            invalid: false,
            containsUppercase: false,
            containsLowercase: false,
            containsNumber: false,
            containsSpecial: false,
            activeInput: false,
            validateMonth: false,
            validateYear: true,
            focused: false
        }
    },
    watch: {
        value(val){
            this.modelValue = val;
            this.$emit('changeValue', val);
        },
        modelValue(val){
            if (this.customValidate === 'new-password') {
                this.$v.modelValue.$touch();
            }
            this.$emit('changeValue', val);
        }, 
        activeInput(){
            this.$emit('activeInput')
        },
        '$v.$error'(val){
            if(!val){
                let inputs = document.querySelectorAll('.main-input.is-invalid')
                inputs.forEach(el => {
                    el.classList.add('invalid-input')
                }) 
            }else{
                if(this.oldValue == ''){
                    let inputs = document.querySelectorAll('.main-input.is-invalid')
                    inputs.forEach(el => {
                        el.classList.remove('invalid-input')
                        el.querySelector('.error-pop').classList.remove('d-block')
                    });
                }
            }
        }, 
    },
    async mounted(){
        if(this.value){
            this.modelValue = this.value;
        }
        if(this.fixedType === "password"){
            this.showEye = true;
        }
    },
    methods: {
        focus(){
            this.$emit('focus');
            this.focused = true;
            if(this.$v.$error){
                const inputs = document.querySelectorAll('.main-input.is-invalid')
                inputs.forEach(el => {
                    el.classList.remove('invalid-input')
                    el.querySelector('.error-pop').classList.remove('d-block')
                });
            }
        },
        blur(){
            this.$emit('blur'); 
            this.focused = false;
        }
    },
    validations() {
        switch(this.customValidate){
            case 'new-password':
            return {
                modelValue: {
                    required, 
                    valid: function(value) {
                        const containsUppercase = /[A-Z]/.test(value);
                        const containsLowercase = /[a-z]/.test(value);
                        const containsNumber = /[0-9]/.test(value);
                        const containsSpecial = /[#?!@$%^&*-]/.test(value);
                        this.containsSpecial = containsSpecial;
                        this.containsNumber = containsNumber;
                        this.containsUppercase = containsUppercase;
                        this.containsLowercase = containsLowercase;
                        return containsUppercase && containsLowercase && containsNumber && containsSpecial
                    },
                    minLength: minLength(this.min)
                }
            }
            case 'confirm-password':
            return {
                modelValue: {
                    required, 
                    valid: function(value) {
                        return value == this.valuePassword
                    },
                }
            }
            case 'expiration-date':
                return {
                    modelValue: {
                        required,
                        minLength: minLength(this.min),
                        validMonth: function(value) {
                            this.validateYear = true;
                            let month = parseInt(value.slice(0,2));
                            this.validateMonth = month <= 12;
                            const current_year = new Date().getFullYear();
                            const year = value.substring(value.indexOf('/') + 1);
                            
                            if (value.indexOf('/') + 1 !== 0 && year.length === 4)
                            this.validateYear = current_year <= year; // console.log(`current year: ${current_year} | year: ${year}`);
                            return this.validateMonth && this.validateYear;
                        },
                    }
            }
            case 'identification':
                return {
                    modelValue: {
                        required, 
                        valid: function(value) {
                            let c = value.replace(/-/g,'');
                            let cedula = c.substr(0, c.length - 1);
                            let verificador = parseInt(c.substr(c.length - 1, 1));
                            let suma = 0;
                            if(value.length < 11) { return false; }
                            for (let i=0; i < cedula.length; i++) {
                                let mod = "";
                                if((i % 2) === 0){mod = 1} else {mod = 2}
                                let res = cedula.substr(i,1) * mod;
                                if (res > 9) {
                                    res = res.toString();
                                    let uno = res.substr(0,1);
                                    let dos = res.substr(1,1);
                                    res = eval(uno) + eval(dos);
                                }
                                suma += eval(res);
                            }
                            let el_numero = (10 - (suma % 10)) % 10;
                            return (el_numero === verificador && cedula.substr(0,3) !== "000");
                        },
                    }
            }
            case 'card-number':
                return {
                    modelValue: {
                        required,
                        minLength: minLength(this.min),
                        valid: function() {
                            let validate = this.cardBrand != '' && this.cardBrand != null
                            return validate;
                        },
                    }
            }
        }
        switch (this.type) {
            case 'email':
                return {
                    modelValue: {
                        required,
                        email
                    }
                };
            case 'text':
                return {
                    modelValue: {
                        required,
                        minLength: minLength(this.min),
                        maxLength: maxLength(this.maxCharacterLength)
                    },
                };
            case 'phone':
                return {
                    modelValue: {
                        required,
                        valid: function(value) {
                            return value.replace(/[^0-9]+/g,'').length === 11;
                        }
                    },
                };
            case 'password':
                return {
                    modelValue: {
                        required,
                        minLength: minLength(this.min)
                    },
                };
            case 'number':
                return {
                    modelValue: {
                        required,
                    },
                };
        }
    },
}
</script>

<style scoped>
    .debug-span > span.error {
        color: var(--main-red) !important;
    }
    label {
        font-size: 15px;
        color: var(--main-navy); 
    }
    label.error-title:after {
        content: '*';
        margin-left: .3rem;
        color: var(--main-red);
    }
    input {
        height: 38px !important;
        border-color: var(--gray-100) !important;
        border-radius: 7px;
        border: 1px solid;
        width: 100%;
        font-size: 14px;
        border-left: 0;
        background-color: none  !important;
    }
    input:focus {
        box-shadow: none;
    }
    input::placeholder, textarea::placeholder {
        font-size: 14px;
        color: var(--dark-gray-200);
    }
    .form-control:disabled {
        background: transparent;
    }
    .form-control.is-invalid, .was-validated .form-control:invalid {
        box-shadow: none !important;
        border-color: var(--main-red);
    }
    .form-control {
        padding: 0.1rem 3px;
    }
    .password {
        background: transparent !important;
    }
    .input-group-text {
        background: transparent;
        border-color: var(--gray-100) !important;
        border-radius: 7px;
    }
    .eye-container:hover svg {
        fill: var(--main-red) !important;
        cursor: pointer;
    }
    .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
        border-right: 0;
    }

    input:-webkit-autofill { 
        -webkit-background-clip: text;
        background-clip: text;
    }
    .form-control:disabled, .form-control[readonly] {
        background-color: transparent;
    }
    .error-pop {
        position: absolute;

        background: #fff;
        box-shadow: 0px 5px 25px #212e3d1f;
        border-radius: 7px;
        
        padding: 10px;

        bottom: -45px;
        
        z-index: 2;
        animation: animation-invalid-input 0.3s;
    }
    .error-pop-password {
        position: absolute;
        background: #fff;
        box-shadow: 0px 5px 25px #212e3d1f;
        border-radius: 7px;
        padding: 10px;
        bottom: -118px;
        z-index: 8;
        animation: animation-invalid-input 0.3s;
    }
    @keyframes animation-invalid-input {
        from{
            opacity:0;
            transform: translateY(-10px);
        }to{
            opacity: 1;
            transform: translateY(0px);
        }
    }
    .error-pop::after, .error-pop-password::after {
        content: '';
        border-bottom: 10px solid white !important;
        border-left: 10px solid #f7000000 !important;
        border-right: 10px solid #ff000000 !important;
        position: absolute;
        top: -10px;
    }
    .error-pop-password::after {
        box-shadow: none;
    }
    .error-pop > div > span {
        transition: var(--transition-1);
        font-size: 14px;
        color: var(--main-red);
    }
    .is-invalid-input, .invalid-input-group-text {
        border-color: var(--main-red) !important;
    }
    .label-password {
        font-size: 14px;
    }
    .is-invalid-input-new-password {
        border-color: var(--gray-100) !important;
    }
    .error-pop-password > span > svg {
        transition: var(--transition-1);
    }
    .label-password-valid {
        transition: var(--transition-1);
        color: var(--dark-gray-200);
        font-weight: 300 !important;
    }
    .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        border-left: 0;
    }
    input.disable-icon.form-control {
        border: 1px solid var(--gray-100) !important;
        border-radius: 7px !important;
        padding-left: .75rem;
    }
    input.disable-icon.form-control.is-invalid-input {
        border: 1px solid !important;
        border-color: var(--main-red) !important;
        box-shadow: unset;
    }
    input:disabled {
        -webkit-text-fill-color: var(--dark-gray-200);
        opacity: 1; /* required on iOS */
    }
</style>
