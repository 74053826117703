<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 100 100.008">
        <path id="X_icon" data-name="X icon" d="M-503.4,250.591A50.007,50.007,0,0,0-553.4,300.6v.012A50,50,0,0,0-503.375,350.6,50,50,0,0,0-453.4,300.585,50.008,50.008,0,0,0-503.4,250.591Zm24.424,69.664a4.717,4.717,0,0,1-4.716,4.713,5.2,5.2,0,0,1-.774-.061,5.008,5.008,0,0,1-2.736-1.487l-16.2-16.2-16.2,16.2a5.047,5.047,0,0,1-2.728,1.487,4.764,4.764,0,0,1-1.548,0,4.719,4.719,0,0,1-3.87-5.417,5.025,5.025,0,0,1,1.49-2.709l16.2-16.195-16.191-16.191a5.057,5.057,0,0,1-1.494-2.709,4.674,4.674,0,0,1,1.295-4.126,4.818,4.818,0,0,1,6.667,0l16.367,16.367,16.367-16.367a4.818,4.818,0,0,1,6.666,0,4.7,4.7,0,0,1,1.295,4.126,5.08,5.08,0,0,1-1.494,2.709l-16.183,16.191,16.191,16.195.038-.011a5.075,5.075,0,0,1,1.49,2.709,4.8,4.8,0,0,1,.065.774Z" transform="translate(553.396 -250.591)"/>
    </icon-base>
</template>

<script>
export default {
name: 'iconCloseCircle',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
    type: Number,
    default: 20
    },
    color: {
        type: String,
        default: '#8cb24b'
    },
    enableBackground: {
    type: Boolean
    }
}
}
</script>
