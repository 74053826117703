<template>
    <icon-base :color="color" :size="size" viewBox="0 0 56 56" class="icon-email">
        <circle id="Ellipse_56" data-name="Ellipse 56" cx="28" cy="28" r="28" fill="#fef7ea"/>
        <g id="Message_icon" data-name="Message icon" transform="translate(15.5 19.377)">
            <path id="Union_8" data-name="Union 8" d="M24.259,17.935q-4.4-.008-8.8,0H13.011q-5.624,0-11.249,0a1.632,1.632,0,0,1-1.626-.991.387.387,0,0,1,.178-.581c3.229-2.032,6.45-4.077,9.68-6.1a.6.6,0,0,1,.522.015c.637.386,1.257.8,1.872,1.224a.981.981,0,0,0,1.222.008c.616-.422,1.252-.814,1.864-1.24a.447.447,0,0,1,.584,0q4.806,3.056,9.628,6.089a.407.407,0,0,1,.173.624,1.628,1.628,0,0,1-1.6.955ZM0,3.333C2.923,5.259,5.812,7.16,8.759,9.1L0,14.64ZM17.252,9.1,26,3.337v11.3Zm-4.561.649Q6.516,5.671.326,1.609A.441.441,0,0,1,.16.935,1.649,1.649,0,0,1,1.775,0Q7.4.01,13.027,0q5.6,0,11.2,0A1.682,1.682,0,0,1,25.8.835c.2.34.193.572-.2.826-3.2,2.081-6.378,4.189-9.564,6.287-.907.6-1.818,1.189-2.715,1.8a.488.488,0,0,1-.635,0Z" transform="translate(0 0)" fill="#f5b14d" stroke="rgba(0,0,0,0)" stroke-width="1"/>
        </g>
    </icon-base>
</template>

<script>
export default {
    name:'iconEmail',
    components:{
        IconBase: () => import('@/common/components/iconBase.vue')
    },
    props:{
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>

<style scoped>
</style>

