<template>
<card class="m-0 align-items-center position-relative p-3"> 
    <div class="d-flex align-items-center justify-content-end mb-2">
        <icon-favourite :size="25" color="var(--gray-100)" @click.native="addToWishList(product)" :class="{'on-wishlist': checkInFavorites(product)}"/>
    </div>
    <router-link :to="`/products/${product.codarticulo}`" tag="img" :src="getImages(product.imageGallery)" alt="product-img" height="110" style="object-fit: contain;" class="mb-2"/>
    <div class="d-flex flex-column w-100">
        <router-link :to="`/products/${product.codarticulo}`" class="color-dark-gray-300 size-15 text-limited" style="max-width: 100px;">{{product.description | textFormat}}</router-link>
        <h5 class="mb-0 size-16">{{product.price | moneyFormat}}</h5>
    </div>
    <div class="btn-add-to-cart d-flex justify-content-center align-items-center">
        <icon-add-to-cart v-if="!loading" @click.native="increment(product)" color="#fff" :size="30" />
        <half-circle-spinner v-else :animation-duration="1000" color="#fff" :size="20" />
    </div>  
</card>
</template>

<script>
import Card from '../../../common/components/cards/Card.vue'
import IconAddToCart from '../../../common/svg/iconAddToCart.vue'
import iconFavourite from '../../../common/svg/iconFavourite.vue'
import { productMixin } from '../helpers/mixin/product-mixin'
import { HalfCircleSpinner } from 'epic-spinners'
export default {
    components: { iconFavourite, Card, IconAddToCart, HalfCircleSpinner },
    name: "CardProductMobile",
    props:{
        product:{
            type: Object
        }
    },
    data(){
        return{
            loading: false
        }
    },
    mixins: [productMixin],
}
</script>
<style scoped>
.card-container {
    border: none;
    overflow: hidden;
    border-radius: 10px;
}
svg.icon-favourite:hover, .on-wishlist {
    fill: var(--main-red) !important; 
}
svg.icon-favourite {
    transition: var(--transition-1);
}
.btn-add-to-cart {
    position: absolute;
    right: 0;
    bottom: 0;
    background: var(--main-red);
    border-top-left-radius: 5px;
    height: 40px;
    width: 40px;
}
</style>