<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 100 100">
        <path d="M20,55 L40,75 L77,27" fill="none" :stroke="color" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" />
    </icon-base>
</template>

<script>
export default {
name: 'iconArrow',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
    type: Number,
    default: 20
    },
    color: {
    type: String,
    default: ''
    },
    enableBackground: {
    type: Boolean
    }
}
}
</script>

<style scoped>
	.st0{fill:#BC2C35;}
	.st1{fill:#FFFFFF;}
	.st2{clip-path:url(#SVGID_2_);}
	.st3{fill:#EA3838;}
	.st4{fill:#72BF58;}
</style>
