import Vue from "vue";
import Filters from "./filters"
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
// import {viewPort} from "../../common/helpers/global-methods"
Vue.use(VueAwesomeSwiper);

import moment from 'moment/min/moment-with-locales';
Vue.prototype.moment = moment;

//import input mask
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

// import validation
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

//get agent
import browserDetect from "vue-browser-detect-plugin";
Vue.use(browserDetect);
import VueUA from '@jarvisniu/vue-ua'
// Simple usage
Vue.use(VueUA)

//fast testing purposes
Vue.mixin({
    methods: {
        cl: function (a) {
            console.log(a);
            return " P A P O "
        },
        showValueMoney(){
            return process.env.VUE_APP_DEFAULT_CURRENCY
        },
        verifyUSTECH(){
            return process.env.VUE_APP_VIDVIE == 1
        },
        defaultCountry(){
            return process.env.VUE_APP_VIDVIE == 1 ? [-102.8720307149821, 42.31277964204047] : [-70.7260555, 19.0781721]
        },
        defaultSize(){
            return process.env.VUE_APP_VIDVIE == 1 ? 3 : 7;
        },
    },
})
Vue.mixin({
    filters: Filters
});

// filters
Vue.filter('phoneFormat', function (phone) {
    let cleaned = ('' + phone).replace(/\D/g, '');
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        let intlCode = (match[1] ? '+1 ' : '+1');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
});
Vue.filter('fullName', function ({first_name, last_name}) {
    if(first_name && last_name)
    return first_name.split(" ")[0]+" "+last_name.split(" ")[0];
});
Vue.filter('textFormat', function (text) {
    if(text)
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
});
Vue.filter('moneyFormat', function (value1, value2 = 0, currency = true) {
        return value1 ? (currency ? process.env.VUE_APP_DEFAULT_CURRENCY + '$ ' : '') + parseFloat( parseFloat(value1) + parseFloat(value2) ).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (currency ? process.env.VUE_APP_DEFAULT_CURRENCY : '' ) +' 0.00';
});
Vue.filter('moneyFormatMultiply', function (value1, value2) {
        return process.env.VUE_APP_DEFAULT_CURRENCY + '$ ' + parseFloat( parseFloat(value1) * parseFloat(value2) ).toFixed(2)
});

Vue.filter('monthFormat', function (month) {
    switch (month) {
        case "01":
            return 'Enero'
        case "02":
            return 'Febrero'
        case "03":
            return 'Marzo'
        case "04":
            return 'Abril'         
        case "05":
            return 'Mayo'
        case "06":
            return 'Junio'     
        case "07":
            return 'Julio'
        case "08":
            return 'Agosto'   
        case "09":
            return 'Septiembre'
        case "10":
            return 'Octubre'
        case "11":
            return 'Noviembre'
        case "12":
            return 'Diciembre'
    }
});
Vue.directive('longPress', {
    bind: function (el, binding, vNode) {
        // Make sure expression provided is a function
        const longPressStart = new CustomEvent('long-press-start')

        // Define variable
        let pressTimer = null;

        // Define funtion handlers
        let start = (e) => { // Create timeout ( run function after 1s )
            if (e.type === 'click' && e.button !== 0) return;
            if (pressTimer === null) {
                pressTimer = setTimeout(() => { // Run function
                    if (vNode.componentInstance) {
                        vNode.componentInstance.$emit('long-press-start')
                    } else {
                        el.dispatchEvent(longPressStart)
                    }
                }, binding.value);
            }
        }

        // Cancel Timeout
        let cancel = () => {
            // Check if timer has a value or not
            if (pressTimer !== null) {
                clearTimeout(pressTimer)
                pressTimer = null
            }
        }
        // Run Function
        // const handler = (e) => {
        //     binding.value(e)
        // }

        // Add Event listeners
        el.addEventListener("mousedown", start);
        el.addEventListener("touchstart", start);
        // Cancel timeouts if this events happen
        el.addEventListener("click", cancel);
        el.addEventListener("mouseout", cancel);
        el.addEventListener("touchend", cancel);
        el.addEventListener("touchcancel", cancel);
    }
})
Vue.filter('filterBranch', function (branches, codplace) {
    const branch =  branches.filter((branch) =>{
        return branch.codplace === codplace
    })
    return branch[0]
});
Vue.filter('formatHour', function (date) {
    let hours = date.getHours() % 12 || 12;
    let minutes = date.getMinutes();
    let ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    let formatted_date = hours+":"+minutes+" "+ampm;
    return formatted_date;
});
//directives
Vue.directive("click-outside", {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
});
// Vue.directive('resize', {
//     inserted: function(el, binding) {
//         const onResize = binding.value;
//         onResize(viewPort());
//         window.addEventListener('resize', () => {
//             onResize(viewPort());
//         });
//     }
// })

import VueHtmlToPaper from 'vue-html-to-paper';

const options = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    ],
    timeout: 100, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options);

import Print from 'vue-print-nb'
// Global instruction 
Vue.use(Print);

import VueScrollactive from 'vue-scrollactive';
Vue.use(VueScrollactive);


import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)


import ScrollView from 'vue-scrollview'
Vue.use(ScrollView)

import VueTour from 'vue-tour'
require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
Vue.use(VueQuillEditor, /* { default global options } */)

import viewPortPlugin from './plugins';
Vue.use(viewPortPlugin)


import VueIntercom from '@mathieustan/vue-intercom';
Vue.use(VueIntercom, { appId: process.env.VUE_APP_INTERCOM_APP_ID, role: 'dialog' });

