import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import VueMeta from 'vue-meta';
Vue.use (VueMeta)

import "./common/libs"
import "./common/helpers/global-methods"

import { registerModules } from "@/common/helpers/register-modules.js";
import homeModule from "@/modules/home";
import accountModule from "@/modules/account";
import authModule from "@/modules/auth";
import cartModule from "@/modules/cart";
import branchesModule from "@/modules/branches";
import checkoutModule from "@/modules/checkout";
import productsModule from "@/modules/products";
import wishlistModule from "@/modules/wishlist";
import commonsModule from "@/common";
import {loadGtm} from './gtm';

import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload)

if(process.env.NODE_ENV == 'production'){
  if(process.env.VUE_APP_VIDVIE == 0) {
    loadGtm('GTM-WL7HDSM') //MUNDO MOVIL
  }else if(process.env.VUE_APP_VIDVIE == 2){
    loadGtm('G-H6J7P1N48D') // VIDVIE_DO
  }
}

// Import the tooltip component before calling 'new Vue()'
import VueCustomTooltip from "@adamdehaven/vue-custom-tooltip";
// Install the plugin using ONE of the options below:
// --------------------------------------------------
// 1. Install with default options
Vue.use(VueCustomTooltip);


//this is import for auth -- cognito please move later
import {Amplify} from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

Vue.config.ignoredElements = [/amplify-\w*/];

//jquery & Bootstrap
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;


import "bootstrap";
import "popper.js";
import "bootstrap/dist/css/bootstrap.min.css";

//language module
import i18n from "./i18n";

Vue.config.productionTip = false;

registerModules({
  home: homeModule,
  account: accountModule,
  auth: authModule,
  cart: cartModule,
  branches: branchesModule,
  checkout: checkoutModule,
  common: commonsModule,
  products: productsModule,
  wishlist: wishlistModule 
});

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
