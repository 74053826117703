<template>
<section class="home-section">
    <div v-if="!loading" class="wide-container d-flex row flex-column flex-lg-row justify-content-between">
        <template v-if="products[0].activated">
            <div class="card-column col-lg-6">
                <wide-card :product="products[1]" @showQuickView="(data) => {quick = data}" @showShare="(data) => {share = data}" @deleteProduct="(data) => {deleteP = data}"/>
            </div>
            <div class="card-column col-lg-6 mt-5 mt-lg-0">
                <wide-card :product="products[0]" @showQuickView="(data) => {quick = data}" @showShare="(data) => {share = data}" @deleteProduct="(data) => {deleteP = data}"/>
            </div>
        </template>
    </div>
    <confirm-delete-product v-if="deleteP.value" @closeModal="deleteP.value = false" @confirmDelete="deleteConfirmed" :loading="loadingDelete"/>
    <quick-view v-if="quick.value" @closeModal="quick.value = false" :product="quick.product"/>
    <share v-if="share.value" @closeModal="share.value = false" :product="share.product"/>
</section>
</template>
<script>
import WideCard from './WideCard.vue'
import Share from '../../../products/components/modals/Share.vue'
import QuickView from '../../../products/components/quick-view/QuickView.vue'
import { mapGetters } from 'vuex';
import ConfirmDeleteProduct from '../../../products/components/modals/ConfirmDeleteProduct.vue';
import {ProductService} from "../../../products/services/product-service.js";
const service = new ProductService();

export default {
    components: { 
        WideCard, 
        Share, 
        QuickView, 
        ConfirmDeleteProduct 
    },
    data() {
        return {
            deleteP: {value: false, product: null},
            quick: {value: false, product: null},
            share: {value: false, product: null},
            loading: true,
            products: [11025, 9301],
            loadingDelete: false
        }
    },
    computed: {
        ...mapGetters({
            branchSelected: "branches/branchSelected"
        }),
    },
    methods: {
        async deleteConfirmed() {
            let product = {
                cod_articulo: this.deleteP.product.codarticulo,
                quantity: 0, 
            }
            try {
                this.loadingDelete = true
                await this.$store.dispatch("cart/removeProduct", product);
            } catch (error) {
                console.log(error)
            } finally {
                this.loadingDelete = true
            }
            this.deleteP.value = false;
        },
        async searchProducts(){
            Promise.all(this.products.map(async (id) => {
                return await service.getProduct(id);
            })).then((products) => {
                this.products = [];
                products.map((product) => {
                    this.products.push(product.product);
                });
            }).catch(function(error){
                throw error
            }).finally(() => {
                this.loading = false;
            });
        }
    },
    created(){
        this.searchProducts();
    }
}
</script>

<style scoped>
    .card-container::v-deep img.amazon-fire-preview {
        width: 257px;
    }
    .card-container::v-deep img.playster-preview {
        transform: translateY(10px);
        width: 292px;
    }
    .card-container::v-deep:hover img.playster-preview {
        transform: translateX(136px) rotate(16deg);
    }
    .card-container::v-deep:hover img.amazon-fire-preview {
        transform: translateX(90px) rotate(10deg);
    }
    @media (min-width: 576px) {
        .card-container::v-deep img.playster-preview {
            width: 322px;
            top: 11px;
        }
        .card-container::v-deep img.amazon-fire-preview {
            width: 301px;
            top: -20px;
        }
        .card-container::v-deep:hover img.playster-preview {
            transform: translate(90px, -8px) rotate(10deg);
        }
    }
    @media (min-width: 992px) {
        .card-container::v-deep:hover img.playster-preview {
            transform: translate(108px, -8px) rotate(10deg);
        }
    }
    @media (min-width: 1200px) {
        .wide-container {
            --bs-gutter-x: 4rem;
        }
        .card-container::v-deep:hover img.playster-preview {
            transform: translate(90px, -8px) rotate(10deg);
        }
    }
</style>