<template>
    <svg :width="size + 'px'" :height="size + 'px'" :fill="color" viewBox="0 0 83.98 67.637" >
        <g id="Group_3990" data-name="Group 3990">
            <path id="Path_1927" data-name="Path 1927" d="M-182.7-95.262v-32.777a12.456,12.456,0,0,1,1-2.177,10.971,10.971,0,0,1,9.355-4.925q25.369-.017,50.737-.016a9.063,9.063,0,0,1,5.1,1.359c2.61,1.7,4.272,3.93,4.258,7.224q-.066,15.053,0,30.108a6.114,6.114,0,0,1-3.092,5.41,10.98,10.98,0,0,1-6.191,1.616q-18.294-.006-36.589.015a3.184,3.184,0,0,0-1.513.405q-8.072,4.667-16.113,9.388c-.278.162-.562.315-.987.553l2.735-10.379C-177.8-90.2-181.246-91.28-182.7-95.262Zm17.6-22.837a4.678,4.678,0,0,0-4.565,4.708,4.7,4.7,0,0,0,4.883,4.557,4.7,4.7,0,0,0,4.485-4.713A4.687,4.687,0,0,0-165.094-118.1Zm35.123,9.271a4.712,4.712,0,0,0,4.671-4.617,4.7,4.7,0,0,0-4.7-4.661,4.7,4.7,0,0,0-4.672,4.612A4.715,4.715,0,0,0-129.971-108.828Zm-22.169-4.682a4.648,4.648,0,0,0,4.656,4.682,4.662,4.662,0,0,0,4.663-4.6,4.661,4.661,0,0,0-4.662-4.682A4.65,4.65,0,0,0-152.139-113.51Z" transform="translate(182.696 135.158)"/>
            <path id="Path_1928" data-name="Path 1928" d="M-151.917-73.863a1.227,1.227,0,0,1,1.155-1.308h0q21.679,0,43.358,0a6.377,6.377,0,0,0,5.257-2.28,8.624,8.624,0,0,0,1.847-4.486,19.4,19.4,0,0,0,.149-2.512q.015-13.453.006-26.906h0a1.152,1.152,0,0,1,1.172-1.23,24.449,24.449,0,0,1,2.967.384,5.1,5.1,0,0,1,3.712,3.982,13.441,13.441,0,0,1,.316,2.914q.034,15.556.016,31.111a11.563,11.563,0,0,1-.879,4.8,5.521,5.521,0,0,1-5.5,3.575l1.871,8.736c-.411-.253-.661-.4-.9-.558-3.834-2.573-7.661-5.162-11.511-7.706a2.723,2.723,0,0,0-1.5-.463c-10.99-.027-21.979-.014-32.968-.024a8.425,8.425,0,0,1-7.609-4.572A7.033,7.033,0,0,1-151.917-73.863Z" transform="translate(175.943 124.72)"/>
        </g>
    </svg>
</template>

<script>
export default {
name: 'iconChat',
components: {
},
props: {
    size: {
    type: Number,
        default: 20
    },
    color: {
    type: String,
        default: ''
    },
    
}
}
</script>

