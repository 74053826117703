import axios from 'axios'
import store from '../../store'
import router from '../../router'

const token = localStorage.getItem('token');
const refreshToken = localStorage.getItem('refreshToken');

const http = axios.create({
  baseURL: process.env.VUE_APP_MM_PRODUCT+"api/",
  timeout: 120000,
  headers: {
    'Authorization': `Bearer ${token}`,
    'x-refresh-token': `${refreshToken}`,
  }
});
  http.interceptors.request.use((config) => {
    const token = localStorage.getItem('token')
    const refreshToken = localStorage.getItem('refreshToken')

    if (token) {
      config.headers['Authorization'] = `Bearer ${ token }`
      config.headers['x-refresh-token'] = `${refreshToken}`
    }
    
    return config
  },

  (error) => {
    console.log(error);
    return Promise.reject(error)
  })
  http.interceptors.response.use((response) => {
    return new Promise( (resolve) => {
      if(response.headers['x-id-token']){
        store.commit('auth/SET_TOKEN', response.headers['x-id-token'])
      }
      resolve(response)
    })
  },
  (err)=>{
    return new Promise( (resolve, reject) => {
      if(err.response){
        if (err.response.status == 401) {
            store.dispatch('auth/logout')
            router.push('/')
        }
      }else{
        reject({response: {data: {message: 'Ocurrio un error en inesperado.' }}})
      }
      reject(err)
    })
  });
  export { http };
