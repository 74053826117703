<template>
    <main-modal title="wishlist.modal_edit" @closeModal="$emit('closeModal')">
        <form @submit.prevent="updateWishList" class="mt-3">
            <div class="d-flex">
                <span class="d-block size-15 weight-500"> {{$t('account.name')}}</span>
                <span class="size-14 ms-auto" :class="change.new.name.length === 50 || change.new.name.length === 0  ? 'color-main-red':'color-dark-gray-200'">
                    {{change.new.name.length}}/200
                </span>
            </div>
            <main-input id="name" disableIcon ref="name" placeholder="wishlist.list_name_placeholder" @changeValue="(data)=>{change.new.name = data}" :value="change.new.name" class="mb-4" />
            <div class="d-flex">
                <span class="weight-500 size-15">{{$t('wishlist.description')}} <b class="weight-400 color-dark-gray-200">{{$t('wishlist.add_desc_optional')}}</b></span>
                <span class="size-14 ms-auto" :class="change.new.note.length === 200 ? 'color-main-red':'color-dark-gray-200'">{{change.new.note.length}}/200</span>
                <span class="ms-2 size-14 weight-500 color-blue-600 hover-color-main-red" @click="change.new.note = ''">{{$t('cart.clear')}}</span>
            </div>
            <textarea v-model="change.new.note" class="input-description form-control mt-2 size-14" style="resize: none;" :placeholder="$t('cart.press_enter_to_save')" maxlength="200" rows="5"></textarea>
            <main-button title="wishlist.save" class="ms-auto mt-3" />
        </form>
    </main-modal>
</template>

<script>
import MainButton from '../../../../common/components/buttons/MainButton.vue';
import MainInput from '../../../../common/components/inputs/MainInput.vue';
import MainModal from '../../../../common/components/modals/MainModal.vue';

export default {
    name: 'EditWishlist',
    components: { MainButton, MainInput, MainModal },
    props: {
        listSelected: {
            type: Object
        }
    },
    data() {
        return {
            name: '',
            description: '',
            change: {
                old: {
                    name: '',
                    note: ''
                },
                new: {
                    name: '',
                    note: ''
                }
            },
        }
    },
    methods: {
        async updateWishList() {
            this.$refs.name.$v.$touch();
            if (this.$refs.name.$v.$invalid) return;
            const validate = (field) => {
                const value = field.replace(/ +(?= )/g,''); //removing multiple spaces
                return value && value !== ' ' && !value.match(/\s{2,}/g); //validating equality on the old object.
            }
            const valid_name = validate(this.change.new.name);
            const valid_note = validate(this.change.new.note);
            const is_different = JSON.stringify(this.change.new) !== JSON.stringify(this.change.old);
            //validate by name and note only if there was a note in first place, otherwise validate by name.
            if ((this.change.new.note !== '' ? valid_note && valid_name : valid_name) && is_different) {
                await this.$store.dispatch("wishlist/addProductToList", { list_id: this.listSelected._id, name: this.change.new.name, note: this.change.new.note, action: 'change-name' });
                this.$emit('closeModal');
            }else if (!is_different) {
                this.$emit('closeModal');
            }
        }
    },
    created() {
        this.change.new.name = this.listSelected.name; // name
        this.change.old.name = this.listSelected.name;
        this.change.new.note = this.listSelected.note || ''; // note
        this.change.old.note = this.listSelected.note || '';
        
    },
};
</script>

<style lang="scss" scoped>

</style>