<template>
    <div v-if="openModal || notify.show">
        <main-modal v-if="openModal" :hideHeader="lottie" fixedHeader title="wishlist.wish_list" description="wishlist.wish_list_modal_description" @closeModal="closeModal" :backModal="level === 1"
        :classes="[lottie ? 'modal-wishlist-saved' : 'modal-wishlist', lists.length > 2 && !selectedProduct.length ? addToList ? 'add-to-list': 'create-list' : '']">
            <div v-if="!lottie" class="row w-100 mx-0 justify-content-center">
                <div v-if="!selectedProduct.length" class="product-adding d-flex d-md-block col-12 col-md-5">
                    <div class="img-product">
                        <image-product class="d-flex justify-content-center" :source="getImages(selectedProduct.imageGallery)" />
                    </div>
                    <div class="info d-flex flex-column d-md-block">
                        <h6 class="size-18 weight-600 mb-0 mt-md-3">
                            ({{selectedProduct.codarticulo}}) {{selectedProduct.description | textFormat}}
                        </h6>
                        <star-rating class="d-inline-flex mt-md-1 mb-auto mb-md-0 me-3"  :starSize="15" :showRating="false" readOnly />
                        <span class="price color-main-navy size-15 weight-500 mb-0">{{selectedProduct.price | moneyFormat}}</span>
                    </div>
                </div>
                <div v-else class="col-12 col-md-5 px-0">
                    <wrapper-image :images="selectedProduct" class="mx-auto mx-lg-0" :wrapperHeight="280" :wrapperWidth="280" :imgHeight="136" :gap="8" />
                    <span class="d-block color-dark-gray-300 mt-2 ms-1 me-2 size-15">
                        {{selectedProduct.length}} {{$t("wishlist.will_add_wishlist")}}
                        <span class="color-black weight-400">Subtotal</span> <span class="color-main-red weight-600">{{cart.total_amount | moneyFormat}}</span>
                    </span>
                </div>
                <div class="info-panel col-12 col-md-7 mt-4 mt-md-2">
                    <div v-if="loadingLists" class="d-flex align-items-center justify-content-center" style="height: 270px;">
                        <half-circle-spinner :animation-duration="1000" :size="50" color="var(--main-red)" class="ms-2" />    
                    </div>
                    <template v-else>
                        <div v-if="(lists.length && !selectedProduct.length) || listError" class="tab-select d-flex position-relative" :class="addToList && lists.length ? 'mb-2':'mb-3'" :style="{'z-index': level}">
                            <span class="overflow-auto" :class="{'inactive': !addToList && !listError, 'error-loading': listError}">
                                <h6 class="p-0 m-0 weight-600 cursor-pointer" @click="addToList = true">
                                    {{$t("wishlist.add_to_list")}}
                                </h6>
                                <div v-if="listError" class="custom-tooltip">
                                    {{$t('wishlist.loading_wishlists_error')}}
                                </div>
                            </span>
                            <div class="divider" />
                            <h6 class="p-0 m-0 weight-600 cursor-pointer" :class="{'inactive': addToList}" @click="addToList = false">{{$t("wishlist.create_new_wish_list")}}</h6>
                        </div>
                        <add-to-wish-list v-if="addToList && !selectedProduct.length" ref="addToWishList" class="animation-slide-down" :product="selectedProduct" @addedToWishLists="addedToWishLists" @modalIndex="(index) => {level = index}" />
                        <wish-list-form v-else class="animation-slide-down" :class="{'tab-showing': lists.length && !selectedProduct.length }" @saved="createdWishList" />
                    </template>
                </div>
            </div>
            <div v-else>
                <div class="lottie-content d-flex flex-column justify-content-center align-items-center text-center">
                    <lottie class="lottie" :options="defaultOptions" :height="150" :width="280" />
                    <h6 class="title-18 mb-2">{{$t("wishlist.created_a_new_wish_list")}}</h6>
                    <p class="d-inline-block subtitle-14 color-dark-gray-200" style="max-width: 290px;">
                        {{$t('wishlist.you_saved_this_product_in_list')}} 
                        <span class="d-inline-block mt-auto weight-600 color-dark-gray-200 ms-1 text-limited" style="max-width: 100%; vertical-align: top;">"{{wishListName}}"</span> 
                    </p>
                    <div class="d-flex justify-content-between mt-2">
                        <second-button title="wishlist.see_my_lists" class="me-4 px-0" id="see" @click="gotToWishLists" />
                        <main-button title="wishlist.continue" id="continue" @click="closeModal" />
                    </div>
                </div>
            </div>
        </main-modal>
        <main-notify v-if="notify.show && notify.type == 'wish-list'" class="wish-list-notify" @undo="undoChanges" />
    </div>
</template>

<script>
import MainModal from '../../../../common/components/modals/MainModal.vue'
import ImageProduct from '../../../products/components/Image.vue'
import StarRating from '../../../products/components/rating/StarRating.vue'
import AddToWishList from '../AddToWishList.vue'
import WishListForm from '../WishListForm.vue'
import WrapperImage from '../../../../common/components/images/WrapperImage.vue'
import MainButton from '../../../../common/components/buttons/MainButton.vue'
import animationData from '../../assets/animations/save-wishlist1.json';
import Lottie from "vue-lottie";
import SecondButton from '../../../../common/components/buttons/SecondButton.vue'
import MainNotify from '../../../../common/components/notifications/MainNotify.vue'
import { HalfCircleSpinner } from 'epic-spinners'
import { productMixin } from "../../../products/helpers/mixin/product-mixin";
import { mapGetters } from "vuex"

export default {
    components: { MainModal, AddToWishList, HalfCircleSpinner, WishListForm, StarRating, ImageProduct, WrapperImage, MainButton, Lottie, SecondButton, MainNotify },
    name: "CreateWishList",
    mixins: [productMixin],
    data() {
        return {
            addToList: false,
            lottie: false,
            wishListName: null,
            manyLists: false,
            defaultOptions: { animationData: animationData, loop: true },
            level: 3,
        }
    },
    computed: {
        ...mapGetters({
            openModal: "wishlist/openModal",
            selectedProduct: "wishlist/selectedProduct",
            loadingLists: "wishlist/loadingLists",
            lists: "wishlist/lists",
            listError:"wishlist/listError",
            cart: "cart/cart",
            notify: "common/notify",
        }),
    },
    methods: {
        showNotification({ name, many, action, route }) {
            let notify = {
                show: true,
                quick: true,
                type: 'wish-list',
                title: 'wishlist.notify_title',
                img: this.getImages(this.selectedProduct.imageGallery),
                product: this.selectedProduct,
                wishList: name,
                route,
                many: many || false,
                action,
            };
            this.$store.commit("common/RESET_NOTIFY");
            this.$store.commit("common/SET_NOTIFY", notify);
        },
        createdWishList(wishLists) {
            this.lottie = true;
            this.wishListName = wishLists.name;
            this.showNotification(wishLists);
        },
        addedToWishLists(wishLists) {
            this.wishListName = wishLists.name;
            this.$store.commit("wishlist/SET_STATE_MODAL", false);
            if (wishLists.many.length > 0) this.showNotification(wishLists); // we will show the notificacion just in case we have actually added a product to any wishlist
        },
        closeModal(){
            this.$store.commit("wishlist/SET_STATE_MODAL", false);
            this.manyLists = false;
            this.addToList = false;
            if (this.lottie) this.lottie = false;
        },
        gotToWishLists(){
            this.$router.push({path:'/account/wishlist'});
            this.closeModal();
        },
        undoChanges(){
            this.lottie = false;
            if (this.$refs.addToWishList) { //reloading some keys in the component again.
                this.$refs.addToWishList.fillSelected();
            }
        }
    },
    created() { this.addToList = this.lists.length >= 1 },
}
</script>
<style scoped>
    .custom-tooltip {
        display: none;
        position: absolute;
        left: 0;
        top: -21px;
        font-size: 11px;
        color: #fff;
        background: #414146eb;
        border-radius: 4px;
        padding: 1px 10px;
        animation: custom-tooltip 0.3s;
    }
    @keyframes custom-tooltip {
        from {
            opacity: 0;
            transform: translateY(5px);
        }to {
            opacity: 1;
            transform: translateY(0);
        }
    }
    .custom-tooltip::after {
        content: '';
        position: absolute;
        left: 12px;
        bottom: -7px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #414146eb;
    }
    .error-loading {
        cursor: not-allowed !important;
    }
    .error-loading h6 {
        color: var(--gray-1001) !important;
        pointer-events: none;
        font-weight: 400 !important;
    }
    .inactive, .inactive h6 {
        color: var(--gray-1001) !important;
        font-weight: 400 !important;
    }
    .inactive:hover {
        text-shadow: 1px 0 0 var(--gray-1001);
    }
    .divider {
        border: 1px solid var(--gray-1001);
        opacity: 0.4;
        margin-inline: 10px;
    }
    .wish-list-notify {
        position: fixed !important; 
        right: 23px !important;
        top: 84vh !important;
        z-index: 13;

        border-radius: 10px;
        padding-inline: 24px;
        width: 328px;
    }
    .wish-list-notify::v-deep h5 {
        font-size: 14px !important;
    }
    .wish-list-notify::v-deep p {
        font-size: 14px !important;
        margin-top: 2px !important;
    }
    .wish-list-notify::v-deep > div {
        height: 90px;
    }
    .wish-list-notify::v-deep .notify-desc {
        max-width: 150px !important;
    }
    .wish-list-notify::v-deep img {
        height: 88px;
    }
    .wish-list-notify::v-deep .container-img-product {
        padding-right: 1rem !important;
    }
    .img-product {
        border: 1px solid var(--gray-100);
        border-radius: 8px;
        overflow: hidden;
    }
    .img-product .image__wrapper::v-deep img {
        width: 280px;
        height: 280px;
        object-fit: cover;
        border-radius: 10px;
    }
    .animation-slide-down {
        position: relative;
        z-index: 1;
    }
    .wrapper-img {
        column-gap: 4px;
    }
    .wrapper-img::v-deep .show-rest-img {
        font-size: 32px !important;
    }
    .image__wrapper::v-deep .image__item {
        object-fit: contain !important;
    }
    @keyframes add-to-list {
        from { height: 517px; }
        to { height: 610px; }
    }
    .modal-main-container::v-deep .modal-main.add-to-list {
        animation: add-to-list 0.3s;
    }
    @keyframes create-list {
        from { 
            height: 610px; 
        }
        to {
            height: 500px; 
        }
    }
    .modal-main-container::v-deep .modal-main.create-list {
        animation: create-list 0.3s;
    }
    @keyframes modal-wishlist-saved {
        from { transform: scale(1.4); }
        to { transform: scale(1); }
    }
    .modal-main-container::v-deep .modal-wishlist-saved {
        height: auto !important;
        animation: modal-wishlist-saved 1s !important;
    }
    .animation-slide-down {
        width: auto;
        height: auto;
    }
    .tab-select h6 {
        font-size: 16px !important;
    }
    .tab-select span:first-child:hover .custom-tooltip {
        display: flex;
    }
    .lottie-content button {
        width: 130px;
        width: 130px;
    }
    @media (min-width: 576px) {
        .modal-main-container::v-deep .modal-main.modal-wishlist {
            width: 500px;
        }
        .tab-select h6 {
            font-size: 17px !important;
        }
        .info h6 {
            overflow: hidden !important;
            white-space: nowrap !important;
            text-overflow: ellipsis !important;
            max-width: 281px;
        }
    }
    @media (min-width: 768px) {
        .modal-main-container::v-deep .modal-main.modal-wishlist { 
            min-width: 782px; 
            overflow-y: hidden;
        }
        .modal-main-container::v-deep .modal-main-content { overflow-y: visible !important; }
        .animation-slide-down { width: 400px; }
    }
    @media (max-width: 575px) {
        .modal-main-container::v-deep :is(.modal-main.add-to-list, .modal-main.create-list) { animation: none }
        .product-adding .info h6 {
            display: inline-block;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden !important;
            text-overflow: ellipsis;

            height: 40px;
            max-width: 195px;
        }
        .modal-main-container::v-deep .modal-wishlist-saved {
            width: 330px;
            min-width: 330px;
        }
        .lottie-content button {
            width: 110px;
            width: 110px;
        }
    }
    @media (max-width: 767px) {
        .divider { margin-inline: 15px; }
        .img-product {
            width: 100px;
        }
        .img-product .image__wrapper::v-deep img {
            width: 100px;
            min-width: 100px;
            height: 100px;
        }
        .info-panel { padding-inline: 2px !important; }
        .product-adding { padding-inline: 0; }
        .product-adding .info { margin-left: 20px; }
        .product-adding .info h6 {
            font-size: 16px !important;
            font-weight: 500 !important;
        }
    }
</style>