<template>
    <main-confirm @confirm="$emit('closeModal')" @cancel="$emit('closeModal')" :confirm-title="error.title"
    :description="error.description" oneOption :contentHTML="error.contentHTML">
        <icon-warning :size="60"/>
    </main-confirm>
</template>
<script>
import { mapGetters } from 'vuex';
import IconWarning from '../../svg/iconWarning.vue'
import MainConfirm from './MainConfirm.vue'
export default {
    components: { MainConfirm, IconWarning, },
    name: "ConnectionError",
    computed: {
        ...mapGetters({
            error: 'common/error'
        }),
        modal(){
            if (this.error.title && this.error.description){
                return { title: this.error.title, description: this.error.description };
            }else {
                return { title: 'common.connection_error', description:'common.notification.unexpected_error' }
            }
        }
    }
}
</script>