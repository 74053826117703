<template>
    <div class="wrapper-img d-flex flex-wrap align-content-start" :style="{height: wrapperHeight + 'px', width: wrapperWidth + 'px', gap: gap+'px', overflow: 'hidden'}">
        <div v-for="(image, index) in images.slice(0,4)" :key="image._id" :class="[images.length >= 3 ? 'img-4':'img-1']" :style="{height: images.length > 2 ? imgHeight + 'px' : wrapperHeight + 'px'}" class="position-relative img">
            <image-product :styleObject="{width: '99%', height: wrapperHeight + 'px', objectFit: 'cover', borderRadius: '10px'}" :source="getImages(image.product ? image.product.imageGallery : image.imageGallery)" class="img-product"/>
            <div v-if="index === 3 && images.length > 4" class="show-rest-img w-100 h-100 position-absolute d-flex align-content-center align-items-center justify-content-center color-white size-20">
                +{{images.length - 4}}
            </div>
        </div>
    </div>
</template>

<script>
import ImageProduct from '../../../modules/products/components/Image.vue'
import { productMixin } from '../../../modules/products/helpers/mixin/product-mixin'

export default {
    components: { ImageProduct },
    name: "WrapperImage",
    mixins: [productMixin],
    props: {
        maxWidth: {
            type: Number,
            default: 100
        },
        wrapperWidth: {
            type: Number,
            default: 170
        },
        wrapperHeight: {
            type: Number,
            default: 170
        },
        imgHeight: {
            type: Number,
            default: 82
        },
        gap: {
            type: Number,
            default: 4
        },
        images: {
            type: Array
        }
    },
    created(){
        this.getImages(this.images.slice(0,4)[0]?.product?.imageGallery);
    },
}
</script>

<style scoped>
    .show-rest-img {
        content: '';
        top: 0;
        z-index: 6; /* #405469A6 */
        background-color: #121C238C !important; /* dark-main-navy */
    }
    figure::v-deep > img {
        object-fit: scale-down !important;
    }
    .img {
        display: flex;
        flex: 1 0 34%; /* explanation below */
        border: 1px solid var(--gray-1001);
        overflow: hidden;
        border-radius: 7px;
    }
    .img-4 {
        height: 82px;
    }
    .img-1 {
        margin: 0px 1px;
    }
</style>
