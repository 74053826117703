<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 250 250.031">
        <path id="Subtraction_9" data-name="Subtraction 9" d="M-1882.479,18776.031a123.675,123.675,0,0,1-88.051-36.469,123.653,123.653,0,0,1-36.469-88.031,123.751,123.751,0,0,1,36.469-88.062,123.679,123.679,0,0,1,88.051-36.469,123.618,123.618,0,0,1,88.016,36.469,123.764,123.764,0,0,1,36.465,88.063,123.665,123.665,0,0,1-36.465,88.031A123.615,123.615,0,0,1-1882.479,18776.031Zm-1.082-149.219a11.676,11.676,0,0,0-11.637,11.344,111.336,111.336,0,0,1-.387,11.313c-.447,8.172-.932,16.375-1.4,24.3l-.006.109q-.34,5.789-.68,11.578c-.2,3.969-.473,8.078-.717,11.672l-.018.281c-.25,3.75-.508,7.625-.7,11.406a19.137,19.137,0,0,0,1.43,7.766,15.488,15.488,0,0,0,14.227,9.2,18.331,18.331,0,0,0,3.75-.422c7.3-2.078,12.021-8.75,12.021-16.984-.254-3.25-.479-6.578-.7-9.781l-.01-.156-.055-.8c-.094-1.375-.186-2.734-.281-4.094-1.146-19.828-2.184-38.078-3.17-55.781A11.427,11.427,0,0,0-1883.562,18626.813Zm0-41.7a15.546,15.546,0,0,0-11.152,4.906,13.914,13.914,0,0,0-4.041,10.281,15.1,15.1,0,0,0,4.17,11.2,15.316,15.316,0,0,0,11.023,4.688,15.742,15.742,0,0,0,15.539-15.531A15.745,15.745,0,0,0-1883.562,18585.109Z" transform="translate(-1757.499 18776.531) rotate(180)" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    </icon-base>
</template>

<script>
export default {
name: 'iconWarningCircle',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: '#8cb24b'
    },
    
}
}
</script>

<style scoped>
    .st5 {
        fill: #ffffff;
    }
</style>
