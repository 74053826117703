<template>
    <div class="loading d-flex">
        <div class="loading-wrapper">
            <lottie :options="defaultOptions" :height="70" :width="70"/>
        </div>
    </div>
</template>

<script>
// import { TrinityRingsSpinner } from 'epic-spinners'
import Lottie from "vue-lottie";
import animationData from '../../assets/animations/loading.json';
export default {
    name: "MainLoading",
    components:{
        // TrinityRingsSpinner
        Lottie
    },
    data() {
        return {
            defaultOptions: { animationData: animationData, loop: true },
        }
    },
}
</script>

<style>
    .loading{
        background-color: rgba(255, 255, 255);
        z-index: 100; /* max z-index*/
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        position: fixed;
        margin: 0;
        align-items: center;
        justify-content: center;
    }
    .loading-wrapper {
        height: 100vh;
        width: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
}
</style>