<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 100.023 71.409">
        <g id="Group_7941" data-name="Group 7941" transform="translate(-149.359 -182.016)">
            <g id="Group_7921" data-name="Group 7921" transform="translate(149.359 182.016)">
            <g id="Group_6902" data-name="Group 6902" transform="translate(29.511 58.294)">
                <g id="Group_6901" data-name="Group 6901">
                <path id="Path_2764" data-name="Path 2764" d="M76.693,150.418a6.558,6.558,0,1,1,6.558-6.558A6.564,6.564,0,0,1,76.693,150.418Z" transform="translate(-70.135 -137.302)"/>
                <path id="Path_2765" data-name="Path 2765" d="M120.212,150.418a6.558,6.558,0,1,1,6.558-6.558A6.564,6.564,0,0,1,120.212,150.418Z" transform="translate(-90.701 -137.302)"/>
                </g>
            </g>
            <path id="Path_2766" data-name="Path 2766" d="M76.271,50.946H33.915l-2.056-7.161a8.19,8.19,0,0,0-7.873-5.955h-8.1a1.681,1.681,0,0,0-1.67,1.314,1.642,1.642,0,0,0,1.608,1.965h8.165a4.908,4.908,0,0,1,4.722,3.567L39.132,81.06A8.189,8.189,0,0,0,47,87.015H77.837a8.185,8.185,0,0,0,7.775-5.64l3.657-12.5a.817.817,0,0,0-.5-.988A18.415,18.415,0,0,1,77.081,51.73.817.817,0,0,0,76.271,50.946Z" transform="translate(-14.182 -31.999)"/>
            <g id="Group_6903" data-name="Group 6903" transform="translate(62.457 0)">
                <path id="Path_2767" data-name="Path 2767" d="M13.286,0A13.277,13.277,0,1,0,26.572,13.269,13.278,13.278,0,0,0,13.286,0Zm5.246,19.741a.834.834,0,0,1-.2-.017,1.405,1.405,0,0,1-.733-.392L13.3,15.041l-4.31,4.292a1.3,1.3,0,0,1-.715.392.84.84,0,0,1-.409,0,1.252,1.252,0,0,1-1.038-1.431,1.344,1.344,0,0,1,.391-.733l4.31-4.292L7.239,8.96a1.32,1.32,0,0,1-.409-.716,1.257,1.257,0,0,1,.34-1.09,1.276,1.276,0,0,1,1.772,0L13.3,11.5l4.343-4.343a1.276,1.276,0,0,1,1.772,0,1.258,1.258,0,0,1,.341,1.09,1.3,1.3,0,0,1-.392.716l-4.31,4.309,4.31,4.292a1.494,1.494,0,0,1,.409.733c0,.068.017.137.017.2A1.254,1.254,0,0,1,18.532,19.741Z" transform="translate(0 18.789) rotate(-45)"/>
            </g>
            </g>
        </g>
    </icon-base>
</template>

<script>
export default {
    name: 'iconArrow',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        },
    }
}
</script>

