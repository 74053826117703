

export default {
    SET_ORDER_CONRFIRM(state, order){
        state.orderConfirm = order
        localStorage.setItem('orderConfirm', JSON.stringify(state.orderConfirm));
    },
    SET_ADDRESS_OPTION(state, id){
        state.checkoutSteps.addressOptionSelected = id;
        localStorage.setItem('checkoutSteps', JSON.stringify(state.checkoutSteps));
    },
    SET_ADDRESS_STEP(state, boolean){
        state.checkoutSteps.addressTab = boolean;
        localStorage.setItem('checkoutSteps', JSON.stringify(state.checkoutSteps));
    },
    SET_CONFIRMATION_STEP(state, boolean){
        state.checkoutSteps.confirmationTab = boolean;
        localStorage.setItem('checkoutSteps', JSON.stringify(state.checkoutSteps));
    },
    SET_PAYMENT_STEP(state, boolean){
        state.checkoutSteps.paymentTab = boolean;
        localStorage.setItem('checkoutSteps', JSON.stringify(state.checkoutSteps));
    },
    SET_CASH(state, balance){
        state.paymentsSelected.cashSelected.balance = balance
        localStorage.setItem('paymentsSelected', JSON.stringify(state.paymentsSelected));
    },
    SET_CREDIT(state, balance){
        state.paymentsSelected.creditSelected = {
            balance: balance,
            select: true,
            fullPay: true
        }
        localStorage.setItem('paymentsSelected', JSON.stringify(state.paymentsSelected));
    },
    SET_STRIPE_PAYMENT(state, balance){
        state.paymentsSelected.stripeSelected.balance = balance
        localStorage.setItem('paymentsSelected', JSON.stringify(state.paymentsSelected));
    },
    SET_NEW_DEPOSIT(state, balance){
        state.paymentsSelected.depositExpressSelected.balance = balance
        localStorage.setItem('paymentsSelected', JSON.stringify(state.paymentsSelected));
    },
    SET_IMAGES_TO_NEW_DEPOSIT(state, images){
        state.paymentsSelected.depositExpressSelected.images = images
        localStorage.setItem('paymentsSelected', JSON.stringify(state.paymentsSelected));
    },
    SET_CARD_SELECTED(state, data){
        state.paymentsSelected.cardSelected.card = data
        localStorage.setItem('paymentsSelected', JSON.stringify(state.paymentsSelected));
    },
    SET_UNIQUE_CARD(state, data){
        state.paymentsSelected.cardSelected.detailts = data
    },
    SET_CARD_BALANCE(state, balance){
        state.paymentsSelected.cardSelected.balance = balance
        localStorage.setItem('paymentsSelected', JSON.stringify(state.paymentsSelected));
    },
    SET_PAYPAL_SELECTED(state, data){
        state.paymentsSelected.paypalSelected = data
        localStorage.setItem('paymentsSelected', JSON.stringify(state.paymentsSelected));
    },
    SET_RNC(state, rnc){
        state.rnc = rnc
    },
    SET_DELIVERY_QUOTATION(state, data){
        state.deliveryQuotationID = data;
        if (data) {
            localStorage.setItem('deliveryQuotationID', data);
        } else {
            localStorage.removeItem('deliveryQuotationID');
        }
    },
    SET_PARTNER_SELECTED(state, {id, user}){
        const findPartner = state.partners.filter(partner => partner.id == id)
        const findDefault = state.partners.filter(partner => partner.default == true)
        if(findPartner.length > 0){
            state.partnerSelected = findPartner[0]
        }else{
            const entity = {
                full_name: `${user.entity.first_name}  ${user.entity.last_name}`,
                identification: user.entity.identification,
                whatsapp: user.entity.phone,
                id: 0,
                default : findDefault.length == 0,
            }
            state.partnerSelected = entity
        }
    },
    RESET_STATE(state){
        state.checkoutSteps = {
            addressOptionSelected: 0,
            addressTab: true,
            confirmationTab: false,
            paymentTab: false,
        }
        state.customer_name = null,
        state.rnc = null,
        state.note = null,
        state.shipments = null
        state.paymentsSelected = {
            cardSelected: {
                card : null,
                balance: 0,
                detailts: null,
            },
            paypalSelected: {
                data: null,
                balance: 0
            },
            depositSelected: [],
            creditNoteSelected: [],
                depositExpressSelected: {
                balance: 0,
                images: []
            },
            stripeSelected: {
                balance: 0,
                data: null,
                select: false
            },
            cashSelected: {
                balance: 0,
                select: false,
                fullPay: false
            },
            creditSelected: {
                balance: 0,
                select: false,
                fullPay: false
            },
        }
        localStorage.removeItem('paymentsSelected');
        localStorage.removeItem('checkoutSteps');
    },
    RESET_PAYMENTS(state){
        state.paymentsSelected = {
            cardSelected: {
                card : null,
                balance: 0,
                detailts: null
            },
            paypalSelected: {
                data: null,
                balance: 0
            },
            stripeSelected: {
                balance: 0,
                paymentIntentId: null,
                select: false
            },
            depositSelected: [],
            creditNoteSelected: [],
                depositExpressSelected: {
                balance: 0,
                images: []
            },
            cashSelected: {
                balance: 0,
                select: false,
                fullPay: false
            },
            creditSelected: {
                balance: 0,
                select: false,
                fullPay: false
            },
        }
        localStorage.removeItem('paymentsSelected');
    },
    SET_CUSTOMER_NAME(state, data) {
        state.customer_name = data;
    },
    RESET_CASH(state){
        state.paymentsSelected.cashSelected = {
            balance: 0,
            select: false,
            fullPay: false
        }
        localStorage.setItem('paymentsSelected', JSON.stringify(state.paymentsSelected));
    },
    SET_DEPOSITS(state, deposits){
        let newObject = {
            balanceSelected: ''
        }
        let newArray = []
        if(deposits){
            for (let index = 0; index < deposits.length; index++) {
                const element = deposits[index];
                newArray.push(Object.assign(element,newObject))
            }
            state.deposits =  newArray;
        }
    },
    SET_DEPOSIT(state, data){
        const index = state.paymentsSelected.depositSelected.findIndex(element => element?.document_id === data.document_id);
        if(index >= 0){
            state.paymentsSelected.depositSelected[index].balanceSelected = ''
            state.paymentsSelected.depositSelected.splice(index, 1);
            localStorage.setItem('paymentsSelected', JSON.stringify(state.paymentsSelected));
            return
        }
        if (state.diference < data.balance){
            data.balance = state.diference
        }
        state.paymentsSelected.depositSelected.push(data)
        localStorage.setItem('paymentsSelected', JSON.stringify(state.paymentsSelected));
    },

    SET_CREDITS_NOTE(state, creditsNote){
        let newObject = {
            balanceSelected: ''
        }
        let newArray = []
        if(creditsNote){
            for (let index = 0; index < creditsNote.length; index++) {
                const element = creditsNote[index];
                newArray.push(Object.assign(element,newObject))
            }
            state.creditsNote =  newArray;
        }
    },
    SET_CREDIT_NOTE(state, data){
        const index = state.paymentsSelected.creditNoteSelected.findIndex(element => element?.document_id === data.document_id);
        if(index >= 0){
            state.paymentsSelected.creditNoteSelected[index].balanceSelected = ''
            state.paymentsSelected.creditNoteSelected.splice(index, 1);
            localStorage.setItem('paymentsSelected', JSON.stringify(state.paymentsSelected));
            return
        }
        if (state.diference < data.balance){
            data.balance = state.diference
        }
        state.paymentsSelected.creditNoteSelected.push(data)
        localStorage.setItem('paymentsSelected', JSON.stringify(state.paymentsSelected));
    },
    SET_PARTNERS(state, partners){
        state.partners = partners
    },
    SET_CURRENCY(state, currency){
        state.currency = currency
    },
    SET_STRIPE_ELEMENTS(state, data){
        state.elements = data
    },
    RESET_STRIPE_ELEMENTS(state){
        state.elements = {}
    },
    SET_NOTE(state, data) {
        state.note = data;
    }
};
