<template>
    <icon-base width="100%" viewBox="0 0 366 146">
        <defs>
            <clipPath id="a">
                <rect class="a" width="364" height="136" rx="20" transform="translate(0 -1.001)"/>
            </clipPath>
            <clipPath id="b">
                <rect class="b" width="364" height="155" rx="20" transform="translate(13 159)"/>
            </clipPath>
            <pattern id="c" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 1098 1484">
                <image href="../headphones.webp" height="1484" width="1098"/>
            </pattern>
            <clipPath id="e">
                <rect width="366" height="146"/>
            </clipPath>
            </defs>
            <g id="d" class="c">
                <rect class="i" width="366" height="146"/>
                <g transform="translate(-12 -169)">
                    <rect class="b" width="364" height="135" rx="20" transform="translate(13 179)"/>
                    <g class="d" transform="translate(13 179)">
                        <ellipse class="e" cx="205" cy="77.5" rx="205" ry="77.5" transform="translate(-16 -37.001)"/>
                    </g>
                    <text class="f" transform="translate(252 259)">
                        <tspan x="-109.098" y="0">Audífonos</tspan>
                    </text>
                    <g class="g">
                        <rect class="h" width="141" height="191" transform="translate(13 159)"/>
                    </g>
                </g>
            </g>
    </icon-base>
</template>

<script>
export default {
    name: 'Headphones',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
}
</script>

<style scoped>
    .a{fill:#ff9a9a;}
    .b{fill:#ffc881;}
    .c{clip-path:url(#e);}
    .d{clip-path:url(#a);}
    .e,.f,.i{fill:#fff;}
    .e{opacity:0.25;}
    .f{
        font-size: 44px;
        font-family:'Myriad Pro Bold';
        font-weight:600;
    }
    .g{clip-path:url(#b);}.h{fill:url(#c);}

</style>