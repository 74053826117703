<template>
<section class="home-section row mx-0">
    <div class="product-slide-navigation mb-0 mb-md-4 d-flex col-12 px-0 justify-content-between align-items-center">
        <main-nav-tab :options="optionsTab" :selectedOption="selectedOption" @select="(data) => {changeOption(data)}" class="products-navigation mb-4 mb-sm-0 col-12 col-md-10" style="justify-content: start !important; max-width: 860px"/>
        <div class="product-slider-controllers d-none d-md-flex px-lg-0">        
            <div v-for="({ value, navigation }, index) of optionsTab" class="swiper-controls" :key="index" :class="{'d-none': value !== selectedOption.value}">
                <div :class="[navigation.prevEl]" class="swiper-button-prev-product-slider d-inline" style="margin-right: 15px;" slot="button-prev">
                    <icon-arrow-circle class="fill-gray-50" />
                </div>
                <div :class="[navigation.nextEl]" class="swiper-button-next-product-slider d-inline" slot="button-next">
                    <icon-arrow-circle class="fill-gray-50" style="transform: rotate(180deg)"/>
                </div>
            </div>
        </div>
    </div>
    <div class="px-0">
        <div class="product-swipers position-relative">
            <div :class="[selectedOption.value+'-translate']" >
                <div v-for="({name, products, options}) in swiperInstances" :key="name" class="swiper-translate-content">
                    <swiper class="swiper" ref="productSwiper" :id="name" :options="options" :pagination="{ clickable: true }">
                        <swiper-slide v-for="product in products" :key="product.codarticulo">
                            <div class="card-father-container d-flex" @mouseover="pauseSwiper(name)">
                                <card-product class="slider-card-product" :product="product" :skeleton="loading" @showQuickView="showQuickView" @showShare="showShare" :offer="product.special_price > 0"/>
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>     
                    </swiper>
                </div>
            </div>
        </div>
    </div>
    <quick-view  v-if="quickView.value" :product="quickView.product" @closeModal="quickView.value = false"/>
    <share v-if="share" @closeModal="share = false" :product="shareProduct"/>
</section>
</template>
<script>
import { ProductService } from '@/modules/products/services/product-service.js'
const service = new ProductService()
import MainNavTab from '../../../common/components/tabs/MainNavTab.vue'
import { mapGetters } from 'vuex'

import 'swiper/css/swiper.css'
import Share from '../../products/components/modals/Share.vue'
import QuickView from '../../products/components/quick-view/QuickView.vue'

export default {
    name: 'ProductSlider',
    components: {
        CardProduct: () => import('@/modules/products/components/CardProduct.vue'),
        IconArrowCircle: () => import('@/common/svg/iconArrowCircle.vue'),
        MainNavTab,
        Share,
        QuickView,
    },
    data() {
        let swiperOption = {
            noSwiping: true,
            preloadImages: true,
            lazy: true,     
            pagination: {
                el: '.swiper-pagination',
                dynamicBullets: true,
                clickable: true
            },
            initialSlide: 0,
            grabCursor: true,
            slidesPerView: 4,
            slidesPerColumnFill: 'row',
            slidesPerColumn: 1,
            preventClicks: true,
            breakpoints: {
                0: {
                    slidesPerView: 1.5,
                    spaceBetween: 0,
                    slidesPerColumn: 1,
                    speed: 300
                },
                576: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                    slidesPerColumn: 1,
                    speed: 800,
                },
                // when window width is >= 768px
                768: {
                    slidesPerView: 2,
                    slidesPerColumn: 1,
                    slidesPerGroup: 1,
                    spaceBetween: 20,
                    centeredSlides: false,
                },
                // when window width is >= 992px
                992: {
                    slidesPerView: 3,
                    slidesPerGroup: 1,
                    spaceBetween: 40,
                },
                // when window width is >= 1200px
                1200: {
                    slidesPerView: 4,
                    slidesPerGroup: 1,
                    spaceBetween: 10
                },
                1400: {
                    slidesPerView: 4,
                    slidesPerGroup: 1,
                    spaceBetween: 30
                },
            },
        };
        return {
            optionsTab: [
                { title: 'product.new_products', value: 'new-products-swiper', navigation: { nextEl: 'swiper-button-next-new-product-slider', prevEl: 'swiper-button-prev-new-product-slider'}}, //, navigation: { nextEl: 'swiper-button-next-new-product-slider', prevEl: 'swiper-button-prev-new-product-slider'}
                { title: 'product.top_sellers', value: 'top-products-swiper', navigation: { nextEl: 'swiper-button-next-top-product-slider', prevEl: 'swiper-button-prev-top-product-slider'}},// swiper-button-next-top-product-slider | swiper-button-prev-top-product-slider 
                // {title: 'product.day_offers', value: 'offers-products-swiper',  navigation: { nextEl: 'swiper-button-next-offers-product-slider', prevEl: 'swiper-button-prev-offers-product-slider'}}
            ],
            selectedOption: { title: 'product.new_products', value: 'new-products-swiper' },
            swiperInstances: [
                { 
                    name: 'new-products-swiper', 
                    products: this.sliderProducts, 
                    options: {
                        skeleton: true,
                        navigation: {
                            nextEl: '.swiper-button-next-new-product-slider', 
                            prevEl: '.swiper-button-prev-new-product-slider'
                        },
                        ...swiperOption
                    }
                },
                {
                    name: 'top-products-swiper', 
                    products: [],  
                    options: {
                        skeleton: true,
                        navigation: {
                            nextEl: '.swiper-button-next-top-product-slider', 
                            prevEl: '.swiper-button-prev-top-product-slider'
                        },
                        ...swiperOption
                    }
                },
                {   
                    name: 'offers-products-swiper', 
                    products: [],  
                    options: {
                        skeleton: true,
                        navigation: {
                            nextEl: '.swiper-button-next-offers-product-slider', 
                            prevEl: '.swiper-button-prev-offers-product-slider'
                        },
                        ...swiperOption
                    }
                },
            ],
            quickView: { product: null, value: false},
            sliderReady: false,
            share: false,
            shareProduct: null,
            loading: true
        }
    },
    computed: {
        ...mapGetters({
            isLogged: "auth/isLogged",
            notify: "common/notify",
            branchSelected: "branches/branchSelected",
            user: "auth/userData",
            //products for swiper
            sliderProducts: "home/products",
            // topProducts: "home/topProducts",
            // offersProducts: "home/offersProducts",
        }),
        swiper() {
            return this.$refs.productSwiper.$swiper
        },
    },
    watch: {
        user: {
            deep: true,
            async handler(){
                await this.getProducts()
            }
        },
    },
    methods: {
        showQuickView({product, value}){
            this.quickView.product = product;
            this.quickView.value = value;
        },
        showShare(product){
            this.share = true
            this.shareProduct = product
        },
        changeOption(data) {
            this.pauseSwiper(this.selectedOption.value)
            // this.resumeSwiper(data.value)
            this.selectedOption = data;
        },
        async getProducts(){
            const branch = this.branchSelected?.codplace || 1;
            const filterParams = { limit: 10, prices: [0, 100000], gbSelected: [], place: branch, page: 1};
            try {
                if(!this.sliderProducts.length) this.loading = true;

                const {docs} = await service.getProducts({order_by: 'newest', ...filterParams, category: 16});
                this.swiperInstances[0].products = docs;
                this.$store.commit("home/SET_PRODUCTS", docs)

                this.loading = false;

                const {docs: tops} = await service.getProducts({order_by: 'popularity', ...filterParams});
                this.swiperInstances[1].products = tops;

                const {data: {data}} = await service.getProductOffers(branch);
                this.swiperInstances[2].products = data.offers.docs.splice(1, 10);
                
                if(this.optionsTab.length == 3)
                this.optionsTab.splice(2, 1)

                if (data.offers.docs.length) { //only add the third option when offers are available.
                    this.optionsTab.push({title: 'product.day_offers', value: 'offers-products-swiper',  navigation: { nextEl: 'swiper-button-next-offers-product-slider', prevEl: 'swiper-button-prev-offers-product-slider'}})// swiper-button-next-offers-product-slider | swiper-button-prev-offers-product-slider)
                }

            } catch (e) {
                console.log(e);
            }
        },
        pauseSwiper(swiper) {
            let swiperGetter = document.getElementById(swiper)
            swiperGetter.swiper.autoplay.stop();
        },
        pauseAllSwipers(){
            this.$refs.productSwiper.map((swiper) => {
                swiper.$swiper.autoplay.stop();
            });
        },
        resumeSwiper(swiper){
            let swiperGetter = document.getElementById(swiper)
            swiperGetter.swiper.autoplay.start();
        },
    },
    async mounted() {
        await this.getProducts()
        // try {
        //     if(this.newProducts.length === 0) 
        //     this.swiperInstances[0].products = this.newProducts;
        //     this.swiperInstances[1].products = this.topProducts;
        //     this.swiperInstances[2].products = this.offersProducts;
        // } catch (error) {
        //     console.log(error);
        // }finally {
        //     this.loading = false
        // }

        // if (this.offersProducts.length !== 0) { //only add the third option when offers are available.
        //     this.optionsTab.push({title: 'product.day_offers', value: 'offers-products-swiper',  navigation: { nextEl: 'swiper-button-next-offers-product-slider', prevEl: 'swiper-button-prev-offers-product-slider'}})// swiper-button-next-offers-product-slider | swiper-button-prev-offers-product-slider)
        // }
        this.pauseAllSwipers()

    },
}
</script>

<style scoped>
.products-navigation::v-deep > .nav-item > .nav-link {
    margin-right: 2.5rem !important;
}
.products-navigation::v-deep .nav-link {
    font-size: 24px;
}
.swiper::v-deep .swiper-wrapper {
    padding-top: 8px;
}
.swiper-pagination::v-deep .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
}
.swiper-pagination::v-deep :is(.swiper-pagination-bullet-active-prev-prev, .swiper-pagination-bullet-active-next-next) {
    transform: scale(0.45);
}
.swiper::v-deep .swiper-wrapper .card-father-container {
    padding-left: 30px;
    padding-right: 30px;
}
.product-swipers {
    overflow: hidden;
    width: 100%;
}
.product-swipers > div {
    width: calc(104.5% * 3 + 20px);
}
.swiper-translate-content {
    width: 94vw;
    float: left;
}
.swiper::v-deep.swiper-container {
    padding-bottom: 73px;
}
.swiper::v-deep.swiper-container .swiper-pagination {
    bottom: 0;
}
.new-products-swiper-translate, .top-products-swiper-translate, .offers-products-swiper-translate {
    transition: transform 400ms ease-in-out;
}
.new-products-swiper-translate {
    transform: translateX(0%);
}
.top-products-swiper-translate {
    transform: translateX(-31.55%);
}
.offers-products-swiper-translate {
    transform: translateX(-63.05%);
}
.slider-card-product::v-deep.card-product {
    margin-top: 5px;
    box-shadow: 3px 6px 20px #7c879329;
    max-width: 275px;
}
.slider-card-product::v-deep.card-product:hover {
    transform: scale(1.05);
}
.swiper-button-prev-product-slider, .swiper-button-next-product-slider {
    position: relative !important;
    margin-top: 4px;
}
.swiper-button-prev-product-slider svg, .swiper-button-next-product-slider svg {
    width: 40px;
}
.swiper-button-prev-product-slider.swiper-button-disabled, .swiper-button-next-product-slider.swiper-button-disabled {
    opacity: 0.35;
    cursor: no-drop !important;
    pointer-events: all !important;
}
.swiper-button-prev-product-slider.swiper-button-disabled:hover svg, .swiper-button-next-product-slider.swiper-button-disabled:hover svg {
    fill: var(--gray-100) !important;
}
.swiper-button-prev-product-slider:hover svg, .swiper-button-next-product-slider:hover svg {
    fill: var(--main-red) !important;
}
.product-slide-navigation {
    margin-bottom: 15px;
}

li > a {
    color: #c6d4db;
    text-decoration: none;
}
/* sm viewport */
@media (min-width: 576px) {
    .products-navigation::v-deep .nav-link {
        font-size: 27px;
    }
    .swiper::v-deep .swiper-wrapper .card-father-container {
        padding-left: 15px;
        padding-right: 15px;
    }
    .product-slide-navigation {
        margin-bottom: 45px;
    }
    .swiper-translate-content {
        width: 554px;
    }
    .new-products-translate {
        transition: transform 800ms ease-in-out;
    }
    .top-products-translate {
        transition: transform 800ms ease-in-out;
    }
    .offers-products-translate {
        transition: transform 800ms ease-in-out;
    }
    .product-swipers > div {
        width: calc(104.5% * 3 + 40px);
    }
}
@media (max-width: 576px){
    .swiper::v-deep .swiper-wrapper .card-father-container {
        padding-left: 6px;
    }
    .swiper::v-deep.swiper-container{
        padding-bottom: 3.5rem;
    }
}
@media (min-width: 768px) {
    .swiper::v-deep .swiper-wrapper .card-father-container {
        padding-left: 40px;
        padding-right: 40px;
    }
    .product-swipers {
        width: 105%;
        left: -5px;
    }
    .swiper::v-deep.swiper-container {
        transition: all 800ms ease-in-out;
    }
    .swiper-translate-content {
        width: 730px;
    }
    .products-navigation::v-deep > .nav-item > .nav-link{
        margin-right: 3rem !important;
    }
}
@media (min-width: 992px) {
    .swiper::v-deep .swiper-wrapper .card-father-container {
        padding-left: 18px;
        padding-right: 30px;
    }
    .product-swipers {
        width: 104.5%;
        left: -18px;
    }
    .swiper-translate-content {
        width: 985px;
    }
}
@media (min-width: 1200px) {
    .swiper::v-deep .swiper-wrapper .card-father-container {
        padding-left: 25px;
    }
    .product-swipers {
        left: -23px;
    }
    .product-slide-navigation .product-slider-controllers {
        padding-right: 2px !important;
    }
    .swiper-translate-content {
        width: 1166px; 
    }
    .products-navigation::v-deep > .nav-item > .nav-link {
        margin-right: 4rem !important;
    }
}
@media (min-width: 1400px) {
    .swiper::v-deep .swiper-wrapper .card-father-container {
        padding-left: 26px;
        padding-right: 37px;
    }
    .product-swipers {
        left: -29px;
    }
    .swiper-translate-content {
        width: 1360px; 
    }
}
.notify::v-deep {
    top: -82px;
    right: -65px;
}
.quick-view::v-deep{
    border: 1px solid;
}
</style>
