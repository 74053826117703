<template>
  <svg class="instagram-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :width="size"
    viewBox="-8.093805 -13.489675 70.14631 80.93805">
    <defs>
      <linearGradient id="a" y2="1.7526" x2="1.7537" y1="-5.8041" x1="-5.8093">
        <stop offset="0%" :stop-color="hover ? '#FFD521': 'var(--dark-gray-200)'" />
        <stop offset="5%" :stop-color="hover ? '#FFD521': 'var(--dark-gray-200)'" />
        <stop offset="50.1119%" :stop-color="hover ? '#F50000': 'var(--dark-gray-200)'" />
        <stop offset="95%" :stop-color="hover ? '#B900B4': 'var(--dark-gray-200)'" />
        <stop offset="95.0079%" :stop-color="hover ? '#B900B4': 'var(--dark-gray-200)'" />
        <stop offset="100%" :stop-color="hover ? '#B900B4': 'var(--dark-gray-200)'" />
      </linearGradient>
      <linearGradient id="b" y2=".9175" x2=".9175" y1=".0091" x1=".009">
        <stop offset="0%" :stop-color="hover ? '#FFD521': 'var(--dark-gray-200)'" />
        <stop offset="5%" :stop-color="hover ? '#FFD521': 'var(--dark-gray-200)'" />
        <stop offset="50.1119%" :stop-color="hover ? '#F50000': 'var(--dark-gray-200)'" />
        <stop offset="95%" :stop-color="hover ? '#B900B4': 'var(--dark-gray-200)'" />
        <stop offset="95.0079%" :stop-color="hover ? '#B900B4': 'var(--dark-gray-200)'" />
        <stop offset="100%" :stop-color="hover ? '#B900B4': 'var(--dark-gray-200)'" />
      </linearGradient>
      <linearGradient id="c" y2="1.3128" x2="1.3126" y1="-.456" x1="-.4558">
        <stop offset="0%" :stop-color="hover ? '#FFD521': 'var(--dark-gray-200)'" />
        <stop offset="5%" :stop-color="hover ? '#FFD521': 'var(--dark-gray-200)'" />
        <stop offset="50.1119%" :stop-color="hover ? '#F50000': 'var(--dark-gray-200)'" />
        <stop offset="95%" :stop-color="hover ? '#B900B4': 'var(--dark-gray-200)'" />
        <stop offset="95.0079%" :stop-color="hover ? '#B900B4': 'var(--dark-gray-200)'" />
        <stop offset="100%" :stop-color="hover ? '#B900B4': 'var(--dark-gray-200)'" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" gradientTransform="scale(1.00041 .99959)" y2="11.412" x2="11.3667" y1="-37.5455" x1="-37.6312" id="d" xlink:href="#a" />
      <linearGradient gradientUnits="userSpaceOnUse" y2="49.554" x2="49.5047" y1=".536" x1=".4867" id="e" xlink:href="#b" />
      <linearGradient gradientUnits="userSpaceOnUse" gradientTransform="scale(.99988 1.00012)" y2="36.4816"
        x2="36.4315" y1="-12.5305" x1="-12.5688" id="f" xlink:href="#c" />
    </defs>
    <g fill="none">
      <path d="M6.4867 3.292c0 1.7933-1.4534 3.2413-3.24 3.2413C1.46 6.5333.0053 5.0853.0053 3.292.0053 1.5053 1.46.0573 3.2467.0573c1.7866 0 3.24 1.448 3.24 3.2347"
        fill="url(#d)" transform="matrix(1 0 0 -1 38.1333 15.8707)" />
      <path d="M48.9373 16.124c-.12-2.6307-.56-4.06-.9253-5.0093-.4907-1.2587-1.076-2.1587-2.0253-3.1027-.9387-.944-1.8387-1.528-3.0974-2.0133-.9493-.3707-2.384-.812-5.0146-.9374-2.844-.1253-3.6867-.152-10.8987-.152-7.2053 0-8.0547.0267-10.8987.152-2.6306.1254-4.0586.5667-5.008.9374C9.804 6.484 8.9107 7.068 7.9667 8.012c-.9507.944-1.536 1.844-2.02 3.1027-.3654.9493-.812 2.3786-.9254 5.0093-.1386 2.844-.164 3.7-.164 10.8973 0 7.212.0254 8.0614.164 10.9054.1134 2.6306.56 4.0586.9254 5.016.484 1.2573 1.0693 2.152 2.02 3.096.944.9426 1.8373 1.528 3.1026 2.0186.9494.372 2.3774.8067 5.008.932 2.844.1254 3.6934.1574 10.8987.1574 7.212 0 8.0547-.032 10.8987-.1574 2.6306-.1253 4.0653-.56 5.0146-.932 1.2587-.4906 2.1587-1.076 3.0974-2.0186.9493-.944 1.5346-1.8387 2.0253-3.096.3653-.9574.8053-2.3854.9253-5.016.132-2.844.164-3.6934.164-10.9054 0-7.1973-.032-8.0533-.164-10.8973zm4.8574 22.024c-.132 2.8747-.5854 4.8387-1.2587 6.5493-.6853 1.7747-1.604 3.2787-3.108 4.7827-1.4973 1.4973-3.0013 2.416-4.776 3.1093-1.7173.6667-3.6747 1.1254-6.5507 1.2507-2.876.1387-3.7946.164-11.1253.164-7.324 0-8.2493-.0253-11.1253-.164-2.8694-.1253-4.8254-.584-6.5507-1.2507-1.768-.6933-3.272-1.612-4.7693-3.1093-1.504-1.504-2.4227-3.008-3.1147-4.7827C.7493 42.9867.296 41.0227.1573 38.148.032 35.272 0 34.352 0 27.0213c0-7.324.032-8.2426.1573-11.1186.1387-2.8694.592-4.832 1.2587-6.5507.692-1.768 1.6107-3.2787 3.1147-4.776C6.028 3.0787 7.532 2.1533 9.3 1.4613c1.7253-.6666 3.6813-1.12 6.5507-1.252 2.876-.132 3.8013-.164 11.1253-.164 7.3307 0 8.2493.032 11.1253.164 2.876.132 4.8334.5854 6.5507 1.252 1.7747.692 3.2787 1.6174 4.776 3.1147 1.504 1.4973 2.4227 3.008 3.108 4.776.6733 1.7187 1.1267 3.6813 1.2587 6.5507.132 2.876.164 3.7946.164 11.1186 0 7.3307-.032 8.2507-.164 11.1267z"
        fill="url(#e)" transform="matrix(1 0 0 -1 0 54.004)" />
      <path d="M13.9093 4.9693c-4.964 0-8.992 4.0214-8.992 8.9854 0 4.972 4.028 8.9986 8.992 8.9986 4.9654 0 8.9987-4.0266 8.9987-8.9986 0-4.964-4.0333-8.9854-8.9987-8.9854zm0 22.848C6.2573 27.8173.06 21.6067.06 13.9547.06 6.3093 6.2573.1053 13.9093.1053c7.652 0 13.856 6.204 13.856 13.8494 0 7.652-6.204 13.8626-13.856 13.8626z"
        fill="url(#f)" transform="matrix(1 0 0 -1 13.0667 40.9373)" />
    </g>
  </svg>
</template>

<script>
export default {
  name:'iconInstagram_3',
  components: {  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: 'var(--main-navy)'
    },
    hover: {
      type: Boolean,
      default: false
    }
  }
}
</script>

