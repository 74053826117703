<template>
    <icon-base :fill="color" :size="size" viewBox="0 0 459.313 459.314" class="icon-minus">
        <g>
            <path d="M459.313,229.648c0,22.201-17.992,40.199-40.205,40.199H40.181c-11.094,0-21.14-4.498-28.416-11.774   C4.495,250.808,0,240.76,0,229.66c-0.006-22.204,17.992-40.199,40.202-40.193h378.936   C441.333,189.472,459.308,207.456,459.313,229.648z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
    name:'iconMinus',
    components:{
        IconBase: () => import('@/common/components/iconBase.vue')
    },
    props:{
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>

<style scoped>
    .st0{
        fill:#212E3D;
    }
    svg {
        transition: all .05s ease-in-out;
    }
</style>

