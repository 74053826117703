<template>
<icon-base :width="size + 'px'" :fill="color" viewBox="0 0 900.471 875.793">
    <g id="Group_7579" data-name="Group 7579" transform="translate(-848.216 -2377.918)">
        <path id="Path_3071" data-name="Path 3071" d="M1184.344,1072.044C1006.557,1032.471,879.422,890.933,989.086,737.5c130.172-182.126,456.921,41.961,617.438-114.305,330.707-321.948-384.792-419.488-384.792-419.488" transform="translate(53.818 2175.201)" fill="none" stroke="#d88d48" stroke-width="2"/>
        <path id="Path_3072" data-name="Path 3072" d="M1448.229,304.339c113.1,291.072-52.473,281.87-262.842,264.165C1045.76,556.753,986.648,603.438,932.64,640.9,677.3,818.007,801.543,991.754,919.4,1085.214" transform="translate(66.11 2167.714)" fill="none" stroke="#d88d48" stroke-width="2"/>
    </g>
</icon-base>
</template>

<script>
export default {
    name: 'CurveLine',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>
