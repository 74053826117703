<template>
<icon-base :width="100+'%'" :fill="color" viewBox="0 0 654.904 286.141">
    <path id="Path_3078" data-name="Path 3078" d="M1808.652,1079.565S1649.29,1100.25,1514.741,1239.3c-44.6,46.1-151.6,139.18-288.33,124.942s-41.656-116.2-24.868-147.872c17-32.073,48.658-136.8,48.658-136.8Z" transform="translate(-1153.747 -1079.565)" fill="#f7d752"/>
</icon-base>
</template>

<script>
export default {
    name: 'BackgroundCurve',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>
