<template>
    <main-confirm @confirm="$emit('closeModal')" @cancel="$emit('closeModal')" :oneOption="true" :confirm-title="this.configurations._id ? title : $t('common.connection_error')" :description="this.configurations._id ? description : $t('common.notification.unexpected_error')">
        <icon-warning :size="60"/>
    </main-confirm>
</template>
<script>
import { mapGetters } from 'vuex';
import IconWarning from '../../svg/iconWarning.vue'
import MainConfirm from './MainConfirm.vue'
export default {
    components: { MainConfirm, IconWarning, },
    name: "ConnectionError",
    data(){
        return{
            title: "common.we_are_in_maintenance",
            description: "common.description_page_disable"
        }
    },
    computed: {
        ...mapGetters({
            employee: "auth/employee",
            configurations: "common/configurations"
        })
    },
    created(){
        // if(this.employee.entity.activated_employee){
        //     this.title = 'Empleado sin acceso'
        //     this.description = "Los empleados no pueden manipular la informacion del usuario. Cualquier duda contactar a la administración."
        // }
    }
}
</script>