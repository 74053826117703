import { http } from "@/common/services/web-backend.js";
import { parseResponse } from "@/common/helpers/responses/parser.js";
// import {deviceDetect} from "mobile-device-detect";
export class AuthService {
    async login(){
        try {
            const login = await http.post('login', {
                // username: credentials.username,
                // password: credentials.password,
                // useragent: JSON.stringify(deviceDetect())
            });
            const res = parseResponse(login.data);
            return res;
        }catch (e) {
            console.log(e)
            if (e.response){
                throw parseResponse(e.response.data) || e.response.data
            }else{
                throw e
            }
        }

    }
    async logout(){
        try {
            const logout = await http.post('logout');
            const res = parseResponse(logout.data);
            return res.data;
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }

    async getEmployeeMFA(getMFA){
        try {
            const getEmployeeMFAInfo = await http.post(`/users/is-mfa-activated`, getMFA);
            const response = parseResponse(getEmployeeMFAInfo.data) 
            return response.data
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }

    async validateMFAIntranet(code){
        try {
            const postvalidateMFAIntranet = await http.post(`/users/validate-google-2fa`, code);
            const response = parseResponse(postvalidateMFAIntranet.data); 
            return response.data
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }

    // async forgotPassword(username) {
    //     // return new Promise((resolve, reject)=>{
    //     // const forgot = await http.post('forgotpassword', {
    //     //     username
    //     // });
    //         // .then(res => {
    //         //     resolve(res.data)
    //         // }).catch(err=>{
    //         //     reject(err.response.data.message)
    //         // })

    //     // })
    // }


    // async  request(data) {
    //     return new Promise((resolve, reject)=>{
    //         http.post(apis.routes.mm_web_backend.forgotPassword.request(data.id), {
    //             otp: data.otp
    //         }).then(res=>{
    //             resolve(res.data);
    //             ;
    //         }).catch(err=>{
    //             reject(err.response)

    //         })
    //     })
    // }
}