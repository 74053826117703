<template>
    <div>
        <div v-if="!next">
            <p class="subtitle-14 color-dark-gray-300 mb-0">
                {{$t("auth.please_enter_a_or_email_associated_with_your_mundo_movil_account")}}. 
            </p>
            <alert-warning style="padding: 2px 15px" :size="25" :show="error !== null" :title="error" :showCounter="false" />
            <form @submit.prevent="" autocomplete="off" class="px-3 mt-3">
                <span v-if="email && !change" class="subtitle-15">{{$t("auth.send_code_to_email")}} <span class="color-blue-600">{{email}}</span></span>
                <main-input :value="username" v-else :title="$t('auth.email')" id="username" ref="forgot_password_email" :type="'email'" @changeValue="(data)=>{username = data}" :placeholder="'Example@gmail.com'" :readonly="readonlyStep1" @activeInput="readonlyStep1 = false">
                    <icon-email :size="16" :color="'var(--dark-gray-200)'"/>
                </main-input>
                <div class="d-flex justify-content-center mt-3 pt-2 mb-4">
                    <second-button v-if="!change && username" @click="change = true" :title="'auth.change'" class="me-3" />
                    <main-button :title="loading ? $t('auth.loading')+'...' : $t('auth.send')" id="save" @click="forgotPassword" style="min-width: 94px;" />
                </div>
            </form>
        </div>
        <div v-else class="form-2 px-2" style="max-width: 500px !important;">
            <p class="subtitle-15 color-dark-gray-300" style="word-break: break-all;">
                {{$t("auth.enter_the_code_sent_to_the_mail")}} <b class="color-main-navy">{{ username }}</b> {{$t("auth.before_they_pass")}} <count-down/> min.
                <a href="" @click.prevent="next = false">{{$t("auth.change_email")}}.</a>
            </p>
            <div v-if="error1 !== null" class="alert alert-danger d-flex align-items-center border-0 py-2" role="alert">
                <div class="me-3">
                    <icon-warning red :color="'var(--light-main-red)'" :size="25" />
                </div>
                <span class="subtitle-14 weight-300 color-light-main-red">
                    {{ $t(error1) }}.
                </span>
            </div>
            <alert-warning v-if="error !== null && newPassword != confirmNewPassword" style="padding: 2px 15px" :size="25" :show="error !== null" :title="error" :showCounter="false" />
            <form @submit.prevent="" class="row m-0" autocomplete="off">
                <div class="col-md-12">
                    <main-input :value="code" :title="$t('auth.code')" :id="'code'" @changeValue="(data)=>{code = data}" ref="code" :placeholder="'auth.enter_the_code'" :readonly="readonly" @activeInput="readonly = false">
                        <icon-code :size="20" color="var(--dark-gray-200)"/>
                    </main-input>
                </div>
                <div class="col-md-12 my-3">
                    <main-input :value="newPassword" :title="$t('auth.create_your_password')" ref="password" id="password" customValidate="new-password" :min="6" :type="'text'" @changeValue="(data)=>{newPassword = data}"
                    :placeholder="'auth.create_your_password'" :fixedType="'password'" :readonly="readonly" @activeInput="readonly = false">
                        <icon-password :size="12" color="var(--dark-gray-200)"/>
                    </main-input>
                </div>
                <div class="col-md-12 mb-4">
                    <main-input :value="confirmNewPassword" :title="$t('auth.confirm_new_password')" id="confirm" ref="confirm_password" :min="6" :type="'text'" @changeValue="(data)=>{confirmNewPassword = data}"
                    :placeholder="'auth.enter_your_password'" fixedType="password" :readonly="readonly" @activeInput="readonly = false">
                        <icon-password :size="12" color="var(--dark-gray-200)"/>
                    </main-input>  
                </div>
                <div class="d-flex justify-content-center mb-4">
                    <main-button :title="loading ? $t('auth.loading')+'...' : $t('auth.save')" id="sign-in" @click="changePassword"/>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import MainButton from '../../../common/components/buttons/MainButton.vue'
import MainInput from '../../../common/components/inputs/MainInput.vue'
import AlertWarning from '../../../common/components/alerts/AlertWarning.vue';
import SecondButton from '../../../common/components/buttons/SecondButton.vue';
import CountDown from '../../../common/components/clock/CountDown.vue';
import IconPassword from '../assets/svg/iconPassword.vue';
import IconEmail from '../assets/svg/iconEmail.vue';
import IconWarning from '../../../common/svg/iconWarning.vue';
import IconCode from '../../auth/assets/svg/iconCode.vue';

import { Auth } from 'aws-amplify';
export default {
    components: { MainInput, MainButton, IconPassword, AlertWarning, SecondButton, CountDown, IconEmail, IconWarning, IconCode },
    name: "ForgotPassword",
    data(){
        return{
            username:'',
            loading: false,
            error: null,
            next: false,
            code: "",
            newPassword: '',
            confirmNewPassword: '',
            showEye: false,
            error1: null,
            change: false,
            emailMask: null,
            readonlyStep1: true,
            readonly: true
        }
    },
    props: {
        email: {
            type: String
        },
        step2: {
            type: Boolean
        },
        inProfile: {
            type: Boolean,
            default: false
        }
    },
    created(){
        if(this.email){
            this.username = this.email
        }else{
            this.change = true
        }
        if (!this.inProfile) {
            if(this.step2){
                this.forgotPassword()
            }
        }
    },
    methods: {
        async forgotPassword(){
                try{
                    if(this.change){
                        this.$refs.forgot_password_email.$v.$touch();
                        if (this.$refs.forgot_password_email.$v.$invalid){
                            return
                        }
                    }
                    this.loading = true;
                    this.error = null;
                    const response = await Auth.forgotPassword(this.username);
                    console.log(`Forget Password Response: \n`, response);
                    this.emailMask = response.CodeDeliveryDetails.Destination
                    this.next = true

                }catch (e) {
                    this.error = e.message;
                }finally {
                    this.loading = false;
                }
            },
            async changePassword(){
                try {
                    this.$refs.code.$v.$touch();
                    this.$refs.password.$v.$touch();
                    this.$refs.confirm_password.$v.$touch();
                    if (this.$refs.code.$v.$invalid || this.$refs.password.$v.$invalid || this.$refs.confirm_password.$v.$invalid){
                        return
                    }
                    this.loading = true;
                    if(this.newPassword === this.confirmNewPassword){
                        await Auth.forgotPasswordSubmit(this.username, this.code, this.confirmNewPassword);
                        await this.$store.dispatch('auth/login', {username: this.username, password: this.confirmNewPassword});
                        this.$emit("closeModal")
                    }else {
                        this.error = 'auth.password_do_not_match'
                    }
                } catch (error) {
                    if(error.code=="CodeMismatchException"){
                        this.error1 = 'auth.incorrect_code_please_try_again';
                    }else if(error.code=="LimitExceededException"){
                        this.error1 = "auth.limit_time"
                    }
                    
                }finally{
                    this.loading = false;
                }
            }
    }
}
</script>
<style scoped>
    .password{
        position: relative;
    }
    .eye-container{
        position: absolute;
        right: 15px;
        top: 52%;
        fill: #A5B2B7;
        user-select: none;
    }

    .eye-container:hover svg{
        fill: #c40316 !important;
        cursor: pointer;
    }
</style>