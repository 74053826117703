export default {
    bannerMain: (state) => state.banners.main || [],
    bannerSecondary: (state) => state.banners.secondary || [],
    //swiper products
    products: (state) => state.products || [],
    // topProducts: (state) => state.products.topProducts || [],
    // newProducts: (state) => state.products.newProducts || [],
    // offersProducts: (state) => state.products.offersProducts || [],
    vidvie: (state) => state.vidvie,
}
