const Module = () => import("./Module.vue");

const WishList = () => import("./view/WishList.vue");
const WishListDetail = () => import("./components/WishListDetails/WishListDetails.vue");

const moduleRoute = {
    path: "/account/wishlist",
    component: Module,
    name: "Wishlist",
    children: [
        {
            path: "/",
            name: "WishList",
            component: WishList
        },
        {
            name:'wishlist-detail', 
            path: "/account/wishlist/:wishlist_id", 
            component: WishListDetail 
        },
    ]
};
export default router => { router.addRoutes([moduleRoute]); };