<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g id="Layer_2_00000160171948436163608180000016175944089701360544_">
            <g>
                <path class="st0" d="M174.12,43.17h-26.55v-4.83c0-8-6.48-14.48-14.48-14.48h-19.31c-8,0-14.48,6.48-14.48,14.48v4.83H72.75
                    c-6.66,0-12.07,5.4-12.07,12.07v9.65c0,2.67,2.16,4.83,4.83,4.83h115.85c2.67,0,4.83-2.16,4.83-4.83v-9.65
                    C186.18,48.58,180.78,43.17,174.12,43.17z M108.95,38.35c0-2.66,2.17-4.83,4.83-4.83h19.31c2.66,0,4.83,2.17,4.83,4.83v4.83
                    h-28.96v-4.83H108.95z"/>
                <g>
                    <g>
                        <g>
                            <g>
                                <circle class="st1" cx="198.32" cy="171.06" r="34.1"/>
                            </g>
                        </g>
                        <path class="st2" d="M213.91,182.09l-11.05-11.04l11.04-11.04c0.56-0.56,0.91-1.2,1.02-1.86c0.17-1.05-0.16-2.07-0.9-2.81
                            c-1.21-1.22-3.33-1.22-4.55,0l-11.16,11.16l-11.16-11.16c-1.21-1.22-3.33-1.22-4.55,0c-0.74,0.74-1.07,1.77-0.9,2.81
                            c0.11,0.66,0.46,1.3,1.02,1.86l11.04,11.04l-11.05,11.04c-0.56,0.56-0.91,1.2-1.02,1.86c-0.17,1.04,0.16,2.06,0.9,2.8
                            c0.74,0.74,1.76,1.06,2.8,0.9c0.66-0.1,1.3-0.46,1.86-1.01l11.05-11.05l11.05,11.05c0.56,0.56,1.2,0.91,1.86,1.01
                            c0.18,0.03,0.35,0.04,0.53,0.04c0.85,0,1.66-0.33,2.27-0.94c0.74-0.74,1.07-1.76,0.9-2.8
                            C214.82,183.29,214.47,182.65,213.91,182.09z"/>
                    </g>
                </g>
                <g>
                    <path class="st0" d="M156.2,171.06c0-14.99,7.9-28.15,19.73-35.61l2.6-54.49c0.04-0.86-0.65-1.58-1.51-1.58H69.84
                        c-0.86,0-1.55,0.72-1.51,1.58l3.98,83.58c0.37,7.74,6.72,13.79,14.46,13.79h70.1C156.46,175.97,156.2,173.55,156.2,171.06z
                        M104.12,154.2c0,2.67-2.16,4.83-4.83,4.83s-4.83-2.16-4.83-4.83V91.45c0-2.67,2.16-4.83,4.83-4.83s4.83,2.16,4.83,4.83V154.2z
                        M128.26,154.2c0,2.67-2.16,4.83-4.83,4.83s-4.83-2.16-4.83-4.83V91.45c0-2.67,2.16-4.83,4.83-4.83s4.83,2.16,4.83,4.83V154.2z
                        M152.39,154.2c0,2.67-2.16,4.83-4.83,4.83s-4.83-2.16-4.83-4.83V91.45c0-2.67,2.16-4.83,4.83-4.83s4.83,2.16,4.83,4.83V154.2z"
                        />
                </g>
                <path class="st3" d="M216.48,209c-5.51,2.65-11.65,4.18-18.16,4.18c-8.27,0-15.98-2.43-22.49-6.57
                    c-15.52-0.57-32.71-0.89-50.83-0.89c-68.76,0-124.5,4.57-124.5,10.21s55.74,10.21,124.5,10.21s124.5-4.57,124.5-10.21
                    C249.5,213.25,236.97,210.82,216.48,209z"/>
            </g>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'iconDelete',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>
	.st0{fill:#212E3D;}
	.st1{fill:#BC2C35;}
	.st2{fill:#F8FAFB;}
	.st3{fill:#F0F4F9;}
</style>
