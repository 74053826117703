// import auth from '../auth/store/state'

const Module = () => import("./Module.vue");

const Account = () => import("@/modules/account/view/Account");
const Profile = () => import("@/modules/account/view/Profile");
const Addresses = () => import("@/modules/account/view/Addresses");
const Devices = () => import("@/modules/account/view/Devices");
const Orders = () => import("@/modules/account/view/Orders");
const OrderDetails = () => import("@/modules/account/components/orders/OrderDetails");
const PaymentsMethods = () => import("@/modules/account/view/PaymentsMethods");
const Invoice = () => import("@/modules/account/view/Invoice");
const InvoiceIntranet = () => import("@/modules/account/view/invoiceIntranet");
const Settings = () => import("@/modules/account/view/Settings");
const Tickets = () => import("@/modules/account/view/Tickets");
const CreateAddressPage = () => import("@/modules/account/view/CreateAddressPage");
const Quotes = () => import("@/modules/account/view/Quotes");
const balance = () => import("@/modules/account/view/Balance.vue");
const Infoworks = () => import("@/modules/account/view/Infoworks.vue");

const moduleRoute = {
    path: "/account",
    component: Module,
    name: "Account",
    children: [
        {
            path: "/",
            component: Account,
            name: "Account",
        },
        {
            name: "Profile",
            path: "profile",
            component: Profile
        },
        {
            path: "addresses",
            component: Addresses,
            name: "Addresses",
        },
        {
            name: 'Devices',
            path: "devices",
            component: Devices
        },
        {
            path: "orders",
            name: "Orders",
            component: Orders
        },
        {
            path: "orders/:id",
            name: "OrderDetails",
            component: OrderDetails
        },
        {
            path: "invoices/:id",
            name: "OrderDetails",
            component: OrderDetails
        },
        {
            path: "payments-methods",
            component: PaymentsMethods
        },
        {
            name: 'Settings',
            path: "settings",
            component: Settings
        },
        {
            path: "invoice",
            component: Invoice
        },
        {
            path: "invoice-intranet",
            component: InvoiceIntranet
        },
        {
            path: "tickets",
            component: Tickets
        },
        {
            path: "create-address",
            component: CreateAddressPage
        },
        {
            path: "quotes",
            component: Quotes,
            name: "Quotes"
        },
        {
            path: "balance",
            component: balance,
            name: "balance"
        },
        {
            path: "infoworks",
            component: Infoworks,
            name: "infoworks"
        }
        
    ]
};

export default router => {
    router.addRoutes([moduleRoute]);
};
