<template>
    <div class="modal-width">
        <alert-warning class="mt-2" style="padding: 2px 15px" :size="25" :show="isShowAlert" @showAlert="showAlert = false" :title="Alertmsg"/>
        <div class="row">
            <div class="col-md-6">
                <div class="password">
                    <main-input :value="form.password" :title="$t('account.new_password')" :id="'new-password'" :customValidate="'new-password'" :type="showEye ? 'text' : 'password'" ref="new-password" @changeValue="(data)=>{newPassword = data}"
                    :placeholder="'auth.enter_your_password'" :readonly="readonly" @activeInput="readonly = false">
                        <icon-password :size="12"/>
                    </main-input>
                    <div @click="showEye = !showEye" class="eye-container">
                        <icon-closed-eye v-if="showEye" class="eye-closed" :size="20" :color="'var(--dark-gray-200)'"/>
                        <icon-opened-eye v-else class="eye-closed" :size="20" :color="'var(--dark-gray-200)'"/>
                    </div>
                </div>  
            </div>
            <div class="col-md-6">
                <div class="password">
                    <main-input :value="form.password" :title="$t('account.confirm_new_password')" :id="'confirm-new-password'" :type="showEye ? 'text' : 'password'" ref="new-password" @changeValue="(data)=>{confirmNewPassword = data}"
                    :placeholder="'auth.enter_your_password'" :readonly="readonly" @activeInput="readonly = false">
                        <icon-password :size="12"/>
                    </main-input>
                    <div @click="showEye = !showEye" class="eye-container">
                        <icon-closed-eye v-if="showEye" class="eye-closed" :size="20" :color="'var(--dark-gray-200)'"/>
                        <icon-opened-eye v-else class="eye-closed" :size="20" :color="'var(--dark-gray-200)'"/>
                    </div>
                </div>  
            </div>
        </div>
        <div class="mt-3 d-flex justify-content-center">
            <main-button :title="'auth.save'" @click="NewPassword"/>
        </div>
    </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import MainInput from '../../../common/components/inputs/MainInput.vue';
import MainButton from '../../../common/components/buttons/MainButton.vue';
import AlertWarning from '../../../common/components/alerts/AlertWarning.vue';
import IconPassword from '../assets/svg/iconPassword.vue';
import IconClosedEye from '../assets/svg/iconClosedEye.vue';
import IconOpenedEye from '../assets/svg/iconOpenedEye.vue';

export default {
    name: "NewPasswordRequired",
    props:{
        userAttributes: {
            type: Object
        }
    },
    data(){
        return {
            newPassword: '',
            confirmNewPassword: '',
            isShowAlert: false,
            Alertmsg: 'Password no concieden',
            showEye: false,
            readonly: true,

        }
    },
    components:{
        MainInput,
        MainButton,
        AlertWarning,
        IconPassword,
        IconClosedEye,
        IconOpenedEye

    },
    methods:{
        async NewPassword(){
            if(this.newPassword === this.confirmNewPassword){
                await Auth.completeNewPassword(this.userAttributes, this.confirmNewPassword)
                const response = await this.$store.dispatch('auth/login', {username: this.userAttributes.username, password: this.confirmNewPassword});
                
                if(response.challengeParam?.challenge === 'EMAIL_CONFIRM_CHALLENGE')
                this.$emit("emailConfirm")
            }else{
                this.isShowAlert = true
            }
        }
    },
}
</script>