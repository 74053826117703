<template>
    <div class="popup" :class="arrowPosition">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: 'Popup',
        props: {
            arrowPosition: {
                type: String,
                default: 'center-down-arrow'
            }
        }
    }
</script>

<style scoped>
    .popup {
        position: absolute;
        top: -24%;
        left: -38%;
        z-index: 1;
        background: #fff;
        box-shadow: 3px 6px 20px #7c879329;
        border-radius: 17px;
        animation: popup .3s;
    }
    .center-down-arrow::before, .right-top-arrow::before {
        position: absolute;
        --shadowSize: 10px;
        --shadowColor: #e2e2ef;
        background-color: #fff;
        content: "";
        width: 14px;
        height: 14px;
        clip-path: polygon(
            calc(var(--shadowSize) * -1) calc(var(--shadowSize) * -1), 
            calc(100% + var(--shadowSize)) calc(var(--shadowSize) * -1), 
            calc(100% + var(--shadowSize)) calc(100% + var(--shadowSize))
        );
        box-shadow: 0 0 var(--shadowSize) var(--shadowColor);
    }
    .center-down-arrow::before {
        top: 29px;
        left: 95.8%;
        transform: translate(50%, -50%) rotate(315deg);
    }
    .right-top-arrow::before {
        top: 1px;
        right: 20px;
        transform: translate(50%, -50%) rotate(314deg);
    }
    @keyframes popup {
        from{
            transform: scale(0.2);
        }
        to{
            transform: scale(1);
        }
    }
</style>