import { mapGetters } from "vuex";
import {http} from "../../../../common/services/web-backend";
import { AuthService } from "@/modules/auth/services/auth-service.js";
const authService = new AuthService();

export const cartMixin = {
    data(){
        return{
            error:{
                show: false,
                description: 'Hubo un error procesando el carrito',
                contentHTML: null,
            },
            confirmData: {},
            validateNumber: false
        }
    },
    computed:{
        ...mapGetters({
            branchSelected: "branches/branchSelected",
            amount: "cart/amount",
            products: "cart/getProducts",
            isLogged: "auth/isLogged",
            userData: "auth/userData",
            isPageDisable: "common/isPageDisable",
            addresses:"account/getAddresses",
            selectedQuotation: "account/selectedQuotation",
            configurations: "common/configurations",
            employeeActiveCode: 'auth/employeeActiveCode',
            employee: 'auth/employee',
            cart: "cart/cart",
            mobile: "common/mobile",
        })
    },
    methods: {
        async buy(){
            try{    
                    this.loading = true;
                    await this.$store.dispatch("common/getConfigurations");

                    if(this.mobile && !this.isLogged){
                        window.NeedToLoginEvent.postMessage(JSON.stringify({isLogged: this.isLogged}));
                        return;
                    }

                    if (!this.isLogged) {
                        this.$store.commit("auth/OPEN_MODAL", true);
                        return;
                    }

                    if(this.userData.entity.created_from == "ECOMMERCE" && this.configurations.general.web_page.validate_phone_on_checkout){
                        const data = await http.post("users/is-phone-number-validated");
                        if(!data.data.data.is_phone_number_validated){
                            return this.$store.commit('auth/OPEN_MODAL_NUMBER', true)
                        }
                    }

                    const user = await authService.login();
                    this.$store.commit("auth/SET_USER", user.data.user);
                    
                    const validateCustomer = !this.userData?.entity?.activated_employee && this.configurations.general.web_page.active_for_customer && !this.employeeActiveCode
                    const validateEmployee = this.employee?.entity?.activated_employee && this.configurations.general.web_page.active_for_employee

                    if(this.isPageDisable || validateCustomer || validateEmployee){
                        this.$store.commit("common/SET_DISABLE_MODAL", true);
                        return;
                    }
                    
                    await this.$store.dispatch('account/addressWithLimitAvaliable', {addresses : this.addresses, options : {calculate_prices: 1}});
                    await this.goToBuy();

                }catch (e) {
                    throw e ?? 'Ha ocurrido un error inesperdao'
                }finally {
                    this.loading = false;
                }
            },
            async goToBuy(){
                try {
                    await this.verificationOrder()
                    this.$router.push({ path:  '/checkout'}).catch(() =>{ });
                } catch (e) {
                    this.error = { show: true, description: e.message }
                    if (e.code == 8400016) {
                        this.error.contentHTML = `
                        <div style="width: 400px;" class="text-center">
                        <p class="color-dark-gray-300 mb-0 size-14">
                            Al parecer los precios han cambiando, el monto anterior es de <b class="color-main-red">${this.$options.filters.moneyFormat(e.data.total_order_sent)}</b> y el 
                            monto actual es <b class="color-main-navy">${this.$options.filters.moneyFormat(e.data.total_order)}</b>.
                        </p>
                        <h6 class="mt-3 mb-0 weight-400 size-15">¿Estás seguro que deseas continuar?</h6>
                        </div>
                        `
                        this.confirmData = e.data;
                    }
                    throw e ?? 'Ha ocurrido un error inesperdao'
                }
            },
            async verificationOrder(){
                let productByPlaces = {};
                productByPlaces[this.branchSelected.codplace.toString()] = []
                for (let product of this.products){
                    productByPlaces[this.branchSelected.codplace.toString()].push({ 
                        codarticulo: product.cod_articulo ?? product.product_code, 
                        quantity: product.quantity, 
                        product_id: product._id,
                        note: product.note
                    })
                }
                try {
                    const confirmOrderPayload = {
                        products: productByPlaces,
                        total_amount: this.cart.total_amount,
                        quote_id: localStorage.getItem('selectedQuotation'),

                    }
                    // console.log(this.selectedQuotation);
                    // if (this.selectedQuotation) {
                    //     confirmOrderPayload.quote_id = this.selectedQuotation
                    // }

                    const { data: { data } } = await http.post('orders/confirmOrder', confirmOrderPayload)
                    
                    this.$store.dispatch('cart/setProducts', data.products[this.branchSelected.codplace])
                    this.$store.commit('checkout/SET_ORDER_CONRFIRM', data);
                    localStorage.setItem('orderDetail', JSON.stringify(data))
                    
                } catch (error) {
                    throw error ? error.response.data : error
                }
        },
        buyWithDiference() {
            this.$store.commit('checkout/SET_ORDER_CONRFIRM', this.confirmData);
            localStorage.setItem('orderDetail', JSON.stringify(this.confirmData))
            this.$router.push({ path: '/checkout' }).catch(() => { });
        }
    },
}
