<template>
<icon-base :width="size + 'px'" :fill="color" >
    <path
    d="M125,1.037C56.537,1.037,1.037,56.537,1.037,125c0,68.463,55.5,123.963,123.963,123.963
    S248.963,193.463,248.963,125C248.963,56.537,193.463,1.037,125,1.037z M148.013,186.88c3.196,3.244,3.009,8.615-0.561,11.61
    c-3.111,2.612-7.749,2.144-10.603-0.753l-68.56-69.582c-1.518-1.54-1.518-4.039,0-5.579l69.447-70.481
    c1.509-1.531,3.488-2.298,5.465-2.298c1.891,0,3.782,0.7,5.264,2.1c3.223,3.046,3.055,8.395-0.059,11.556l-61.006,61.913
    L148.013,186.88z"
    />
</icon-base>
</template>

<script>
export default {
name: 'iconArrowCircle',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
    type: Number,
    default: 20
    },
    color: {
    type: String,
    default: 'var(--main-navy)'
    },
    enableBackground: {
    type: Boolean
    }
}
}
</script>

<style scoped>

</style>
