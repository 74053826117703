const Module = () => import("./Module.vue");
const Cart = () => import("./view/Cart.vue");



const moduleRoute = {
    path: "/cart",
    component: Module,
    name: "Account",
    children: [
        {
            path: "/",
            component: Cart
        },
    ]
};

export default router => {
    router.addRoutes([moduleRoute]);
};