<template>
    <svg class="icon-share" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 250 250" xml:space="preserve" :width="size + 'px'"
    :fill="color">
        <g>
            <path class="st0" d="M87.691,157.91c-20.249,24.915-50.243,21.409-66.151,6.169c-17.247-16.523-18.178-43.674-1.942-61.519
                c14.986-16.47,44.195-21.164,65.256,0.808c23.96-14.415,47.997-28.875,72.332-43.516c-4.673-13.062-3.908-25.663,2.928-37.627
                c5.268-9.221,12.971-15.705,22.993-19.346c20.157-7.323,42.262,0.73,53.048,19.317c10.564,18.204,6.493,41.548-9.639,55.277
                c-18.006,15.324-38.925,13.734-62.079-4.87c-23.809,14.333-47.698,28.713-71.885,43.274c3.381,9.384,4.436,18.755,1.113,28.603
                c23.133,11.849,46.036,23.579,69.151,35.419c6.476-7.914,14.187-13.547,23.914-16.271c23.333-6.533,47.927,7.839,53.957,31.638
                c5.982,23.608-8.943,48.058-32.62,53.438c-24.134,5.484-48.593-10.06-52.739-34.075c-1.021-5.916,0.191-12.198,0.106-18.308
                c-0.021-1.538-0.647-3.9-1.733-4.466C131.838,180.453,109.864,169.259,87.691,157.91z"/>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'iconShare',
    props:{
    size: {
        type:Number,
        default: 20,
        },
    color:{
        type: String,
        default: ''
    }
    }
}
</script>
<style scoped>

</style>