<template>
    <card>
        <icon-favourite class="icon-favourite position-relative cursor-pointer ms-auto" @click.native="addToWishList(product)" :class="{'on-wishlist': checkInFavorites(product)}" color="var(--gray-50)" style="z-index: 3;"/>
        <template>
            <div v-if="product">
                <h2 class="bg-title position-absolute color-light-gray-501 weight-600 text-center w-100 mt-4" style="z-index: 1;">{{name | textFormat}}</h2>
                <div class="content d-flex justify-content-center position-relative" style="z-index: 3;">
                    <div class="product-information position-absolute" style="z-index: 4;">
                        <h4 class="size-18 weight-500 mt-2">{{name | textFormat}}</h4>
                        <star-rating :rating="3" readOnly :showRating="false" :starSize="18" class="mb-2" style="margin-top: -10px;" :padding="3" :borderWidth="0"/>
                        <h3 class="size-23 weight-600 mt-3 pt-1">{{product.price | moneyFormat}}</h3>
                        <button-group-action class="button-group mt-2" style="max-width: 150px;" :product="product" @showQuickView="$emit('showQuickView', {product, value: true})" 
                        @showShare="$emit('showShare', {product, value: true})" />
                        <product-quantity-input class="mt-4" style="max-width: 100px;" :productQuantity="getQuantity(product)" :product="product" @deleteProduct="$emit('deleteProduct', {product, value:true})"/>
                    </div>
                    <router-link :to="`/products/${product.codarticulo}`" tag="img" :src="require('./'+preview+'.png')" class="position-relative" :class="`${preview}-preview`" :alt="preview"/>
                </div>
            </div>
        </template>
    </card>
</template>

<script>
import Card from '../../../../common/components/cards/Card.vue'
import IconFavourite from '../../../../common/svg/iconFavourite.vue'
import StarRating from '../../../products/components/rating/StarRating.vue';
import ButtonGroupAction from '../../../products/components/buttons/ButtonGroupAction.vue';
import ProductQuantityInput from '../../../products/components/ProductQuantityInput.vue';
import { productMixin } from '../../../../modules/products/helpers/mixin/product-mixin';

export default {
    components: { IconFavourite, Card, StarRating, ProductQuantityInput, ButtonGroupAction },
    props: {
        product: {
            type: Object
        }
    },
    data() {
        return {
            preview: '',
            name: ''
        }
    },
    mixins: [productMixin],
    created(){
        switch (this.product._id) {
            case 11025: //amazon-fire
                this.name = "amazon fire 7";
                this.preview = "amazon-fire";
                break;
            case 9301: //
                this.name = "tablet playster";
                this.preview = "playster";
                break;
            default:
                break;
        }
    }
}
</script>

<style scoped>
@font-face {
    font-family: 'Myriad Pro Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Myriad Pro Bold'), url('../../../../common/assets/fonts/myriad-pro/MYRIADPRO-SEMIBOLD.woff') format('woff');
}
.card-container:hover .bg-title {
    opacity: 0.45;
    top: 34px;
}
.card-container:hover .product-information {
    opacity: 1;
    left: 22px;
}
svg.icon-favourite {
    transition: var(--transition-1);
    min-width: 32px;
    min-height: 32px;
    top: -6px;
    right: 10px;
}
svg.icon-favourite:hover, .on-wishlist {
    fill: var(--main-red); 
}
.card-container {
    height: 388px;
    overflow: hidden;
    box-shadow: 3px 6px 20px #7C879329; /* dark-gray-400 */
}
.content img {
    transition: var(--transition-6);
}
.bg-title {
    top: 50px;
    left: 0;
    font-family: 'Myriad Pro';
    font-size: 77px;
    text-transform: capitalize;
    transition: var(--transition-6);
}
.product-information {
    opacity: 0;
    height: 200px;
    width: 250px;
    top: 30px;
    left: 0px;
    transition: var(--transition-6);
}
.button-group {
    max-width: 152px;
}
.button-group::v-deep .button-group-container {
    margin-left: 0 !important;
    justify-content: start !important;
    max-width: 250px;
}
.button-group::v-deep .button-group-container .btn-container{
    margin-right: 0.95rem !important;
}
.bg-title {
    font-size: 40px;
}
@media (min-width: 576px) {
    .bg-title {
        top: 32px;
        font-size: 47px;
    }
    .card-container:hover .bg-title {
        top: 24px;
    }
}
@media (min-width: 768px) {
    .bg-title {
        font-size: 77px;
    }    
    .card-container:hover .bg-title {
        top: 15px;
    }
}

@media (min-width: 992px) {
    .bg-title {
        font-size: 45px !important;
    }
}
@media (min-width: 1200px) {
    .bg-title {
        font-size: 55px !important;
    }
}
@media (min-width: 1400px) {
    .bg-title {
        font-size: 77px !important;
    }
}
</style>