<template>
    <icon-base :fill="color" :size="size" viewBox="0 0 73.345 100">
        <path id="Path_2072" data-name="Path 2072" d="M329.511,371.058H328.3c-6.946,0-13.892-.02-20.833.012-1.594,0-2.888-.427-3.618-1.929-.735-1.521-.2-2.791.789-4.014q22.548-27.836,45.064-55.694a3.885,3.885,0,0,1,4.795-1.379,3.83,3.83,0,0,1,2.151,4.49c-1.541,8.6-3.125,17.192-4.693,25.786-.356,1.978-.718,3.955-1.1,6.055h1.174c6.983,0,13.963.015,20.94-.01a3.5,3.5,0,0,1,3.525,1.9c.728,1.47.259,2.722-.72,3.926q-22.544,27.832-45.069,55.689a4,4,0,0,1-3.857,1.772,3.708,3.708,0,0,1-3.176-4.37c1.135-6.455,2.327-12.9,3.494-19.348C327.946,379.681,328.717,375.42,329.511,371.058Z" transform="translate(-303.511 -307.705)"/>
    </icon-base>
</template>

<script>
export default {
    name:'iconElect',
    components:{
        IconBase: () => import('@/common/components/iconBase.vue')
    },
    props:{
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>

