export default {
  SELECT_PRODUCT(state, product){
    state.openModal = true;
    state.selectedProduct = product;
  },
  SET_STATE_MODAL(state, boolean){
    state.openModal = boolean;
  },
  SET_LISTS(state, lists){
    if (lists) state.lists = lists;
  },
  CREATE_LIST(state, list) {
    state.lists.push(list);
  },
  SET_DELETED_LIST(state, list) { //for the undo button
    state.lastDeleted.push(list);
  },
  RESET_DELETED_LIST(state) { //for the undo button
    state.lastDeleted = [];
  },
  SET_CREATED_LIST(state, list) { //for the undo button
    state.lastCreated = list;
  },
  RESET_CREATED_LIST(state) {
    state.lastCreated = null;
  },
  async UPDATE_LIST(state, data){
    const index = await state.lists.findIndex(element => element._id === data._id );
    state.lists[index].name = data.name;
    state.lists[index].note = data.note;
    state.lists[index].products = data.products;
  },
  DESTROY_LIST(state, list_id){
    state.lists = state.lists.filter(element => element._id !== list_id);
  },
  REMOVE_PRODUCT(state, data){
    state.lists[data.index].products = state.lists[data.index].products.filter(element => element.code !== data.product_code);
  },
  SET_LIST_LOADER(state, value){
    state.loadingLists = value;
  },
  SET_EDITING_LISTS(state, value){
    state.editingList = value;
  },
  SET_ERROR(state, value) {
    state.listError = value;
  }
};
