import { http } from "../../../common/services/node-service";
import { mapGetters } from "vuex"
import _  from "lodash"

export const searchMixin = {
    data(){
        return {
            open: false, // 
            current: 0, // 'current' index selected by mouse.
            suggestions : [], // 
            selection: '', //
            brands: process.env.VUE_APP_VIDVIE == 2 ? 'VIDVIE' : '',
            searchWithDebounce: _.debounce(async function () {
                await this.fetchSuggestion();
            }, 100),
        }
    },
    computed: {
        ...mapGetters({
            branchSelected: "branches/branchSelected",
            searchHistory: "products/searchHistory",
            filters: "products/filters",
            configurations: "common/configurations"
        }),
        openSuggestion() {
            return this.selection !== "" && this.suggestions.length > 0 && this.open;
        },
    },
    methods: {
        close(){
            this.open = false;
        },     
        async searchProduct(selected) {
            if (!this.branchSelected) {
                this.$store.commit('branches/OPEN_SELECT_BRANCH_MODAL', true);
                return;
            }
            this.filters.q = this.selection
            if(selected){
                if(!this.configurations.general.web_page.activate_algolia_search){
                    try {
                        this.$store.dispatch("products/setHistory", selected);
                    } catch (err) {
                        console.log(err);
                    }
                }
            }
            this.open = false;
            this.$emit('searchMask', false) // history a
            this.$router.replace({path: `/shop?q=${this.selection}`}).catch(() => {})
        },
        //When enter pressed on the input
        enter(){
            let selected = this.selection;
            this.suggestions.map((suggestion, index) => {
                if (this.selection === this.$options.filters.textFormat(suggestion.description)) selected = this.suggestions[index];
            });
            this.isNav ? this.searchProduct(selected) : this.$emit('setSearch', selected);
            this.open = false;
        },
        //When up pressed while suggestions are open
        up() {
            if (this.openSuggestion) { // if we have typed something on the search-bar
                if (!this.open || this.suggestions.length == 0)
                return;

                if(this.current > 0) this.current--;
                this.selection = this.$options.filters.textFormat(this.suggestions[this.current].description);
            } else{
                if (this.searchHistory.length == 0)
                return;
                if(this.current > 0)
                    this.current--;
                this.selection = this.$options.filters.textFormat(this.searchHistory[this.current].description);
            }
        },
        //When up pressed while suggestions are open
        down() {
            if (this.openSuggestion) {
                if (this.suggestions.length === 0) return;

                if(this.current < this.suggestions.length - 1) this.current++;
                this.selection = this.$options.filters.textFormat(this.suggestions[this.current].description);
            }else {
                if (this.searchHistory.length === 0) return;
                if(this.current < this.searchHistory.length - 1) this.current++;

                this.selection = this.$options.filters.textFormat(this.searchHistory[this.current].description)
            }
        },
        isActive(index) { //For highlighting element
            return index === this.current;
        },
        async fetchSuggestion() {
            if(this.selection){
                if (!this.open) {
                    this.open = true;
                }
                let category = null;
                const data =  await http.get("/products/suggestions", {
                    params: {
                        q: this.selection,
                        category,
                        place: this.branchSelected.codplace,
                        brands_selected: this.brands
                    }
                });
                let suggestions = data.data.data;
                let userSuggestions = [];
                
                this.searchHistory.map( history => {
                    const add = (arg) => this.searchHistory.length > 0 ? userSuggestions.unshift(arg): userSuggestions.push(arg);
                    if (this.selection === '') return; // if we leave the input blank
                    if (history.byUser && this.selection[0].toLowerCase() === history.description[0].toLowerCase()) { //catching the history searches that start with the same letter that we just typed.
                        add(history);
                    } // gets all of the user generated suggestions whose first character match with the input's text
                } );
                
                let result = [...userSuggestions, ...suggestions.sort((a, b) => a.description.localeCompare(b.description) && Number(this.isInHistory(b)) - Number(this.isInHistory(a)))]
                
                result.map((suggestion, index) => {     // mutating the suggestion for positioning the ones that are on the history on top
                    if (this.isInHistory(suggestion)) {   // if said suggestion is in the search
                        suggestions.splice(index, 1);     // remove it from the suggestion array
                        suggestions.unshift(suggestion);  // move it on the first position
                    }
                });
                this.current = 0;
                this.suggestions = result;
            }
        },
        // When the user changes input
        change(){
            this.searchWithDebounce();
            // this.fetchSuggestion();
        },
        //When one of the suggestion is clicked
        suggestionClick(index) {
            this.selection = this.$options.filters.textFormat(this.suggestions[index].description); //
            this.open = false;
            this.searchProduct(this.suggestions[index])
        },
        historyClick(index) {
            this.selection = this.searchHistory[index].description; // (this was) this.selection = this.$options.filters.textFormat(this.searchHistory[index].description);
            this.open = false;
            this.searchProduct(this.searchHistory[index])
        },
        isInHistory({id}){
            let inHistory = false;
            this.searchHistory.map((history) => {
                if (history.id == id) {
                    inHistory = true;
                    return;
                }
            });
            return inHistory;
        },
    },
}
