<template>
    <svg :width="size + 'px'" viewBox="0 0 859 859" fill="none">
        <circle cx="429.5" cy="429.5" r="411" fill="#BC2C35" stroke="white" stroke-width="37"/>
        <path d="M429 565.625H293V474.875H429V565.625ZM633 474.875V429.5L610.333 316.062H247.667L225 429.5V474.875H247.667V611H474.333V474.875H565V611H610.333V474.875H633ZM610.333 248H247.667V293.375H610.333V248V248Z" fill="white"/>
    </svg>
</template>

<script>
export default {
    name: 'iconShop',
    components: {},
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>

<style scoped>
</style>

