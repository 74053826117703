const Module = () => import("./Module.vue");
const Products = () => import("./view/Products.vue");
const Search = () => import("./view/Search.vue");


const moduleRoute = {
  path: "/products",
  component: Module,
  children: [
    { name:'product-detail', path: "/products/:id", component: Products },
    {
      name: "",
      path: "/shop",
      component: Search
    }
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
