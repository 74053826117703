export default () => ({
    auth_user: JSON.parse(localStorage.getItem('user')) || null,
    auth_token: localStorage.getItem('token') || null,
    employeeToken: localStorage.getItem('employeeToken') || null,
    employeeRefreshToken: localStorage.getItem('employeeRefreshToken') || null,
    auth_pending: false,
    stateModal: false,
    stateModalNumber: false,
    getPreferred: false,
    employee: JSON.parse(localStorage.getItem('employee')) || {
        entity:{
            activated_employee: false
        }
    },
    employeeActiveCode: false,
});