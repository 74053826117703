<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 100 100" class="icon-plus">
        <path id="Plus_icon" data-name="Plus icon" d="M-779.738,589.237h-33.333V555.9a8.333,8.333,0,0,0-8.333-8.334,8.334,8.334,0,0,0-8.334,8.334v33.333h-33.333a8.332,8.332,0,0,0-8.333,8.333,8.333,8.333,0,0,0,8.333,8.334h33.333v33.333a8.333,8.333,0,0,0,8.334,8.333,8.333,8.333,0,0,0,8.333-8.333V605.9h33.333a8.334,8.334,0,0,0,8.334-8.334A8.333,8.333,0,0,0-779.738,589.237Z" transform="translate(871.404 -547.57)"/>
    </icon-base>
</template>

<script>
export default {
    name: 'iconPlus',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
        },
    }
}
</script>

<style scoped>
    /* .st14 {
        fill: #8cb24b;
    } */
    .st5 {
        fill: #ffffff;
    }
</style>
