<template>
    <div class="d-flex flex-column justify-content-center py-2">
        <div v-if="error" class="alert alert-danger color-light-main-red d-flex align-items-center border-0 py-2" role="alert">
            <icon-error color="var(--light-main-red)" class="me-2"/>
            {{ this.error }}
        </div>
        <p v-else class="alert alert-warning color-light-yellow subtitle-15 d-flex align-items-center border-0 py-2" role="warning">
            <iconWarning class="me-2" />
            <b>{{ $t("auth.validate_totp") }}</b>
        </p>
        <form @submit.prevent="" autocomplete="off" style="margin-top: 10px;">
            <main-input title="auth.code_info" id="code" ref="code" class="mb-2" placeholder="auth.code_placeholder" @changeValue="(data)=>{code = data}" :mask="'###-###'">
                <icon-code :size="15" color="var(--dark-gray-200)" />
            </main-input>
            <div class="d-flex justify-content-end">
                <main-button :title="'auth.save'" @click="verify"/>
            </div>
        </form>
    </div>
</template>

<script>
import IconError from '../../../common/svg/iconError.vue'
import IconCode from '../assets/svg/iconCode.vue'
import MainButton from '../../../common/components/buttons/MainButton.vue'
import MainInput from '../../../common/components/inputs/MainInput.vue'
import iconWarning from '../../../common/svg/iconWarning.vue'
import { Auth } from 'aws-amplify'


export default {
    components:{
        IconError,
        IconCode,
        MainButton,
        // MainModal,
        MainInput,
        iconWarning
        // INSERT COMPONENT
    },
    name: "VerifyMFA",
    data(){
        return {
            code: '',
            error: false,
            mfaType: 'SOFTWARE_TOKEN_MFA'
        }
    },
    props:{
        userAttributes: {
            type: Object
        },
        user:{
            type: Object
        },
    },
    methods:{
        focusInVerify(){
            setTimeout(() => { 
                let input = document.getElementById("code")
                input.focus()
            }, 10);
        },
        async verify(){
            try {
                this.$store.commit("common/SET_STATE_LOADING", true)
                this.$refs.code.$v.$touch();
                if (this.$refs.code.$v.$invalid){
                    return
                }
                const code = this.code.replace(/\D/g, '');

                if(this.userAttributes?.challengeName !== 'SOFTWARE_TOKEN_MFA'){
                    await Auth.verifyTotpToken(this.userAttributes, code)
                } else {
                    const response = await Auth.confirmSignIn(this.userAttributes, code, 'SOFTWARE_TOKEN_MFA')
                    await this.$store.dispatch('auth/mfalogin', {username: this.user.username, password: this.user.password, response: response});
                }
                this.$emit("closeModal")
            } catch (error) {
                this.error = error
            }finally{
                this.$store.commit("common/SET_STATE_LOADING", false)
            }
        }
    },
    created(){
        this.focusInVerify()
    }
}
</script>

<style>
</style>