import {http} from "../../../common/services/node-service";
import {parseResponse} from "../../../common/helpers/responses/parser";
export class ListService {
    async getWishLists() {
        try {
            const lists = await http.get('wishList');
            const res = parseResponse(lists.data);
            return res.data;
        }catch (e) {
            if (e.response){ throw parseResponse(e.response.data); }
            else { throw e; }
        }
    }
    async storeWishList(wishlist) {
        try {
            const listDetails = await http.post('wishList', wishlist);
            const res = parseResponse(listDetails.data);
            return res.data;
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else {
                throw e
            }
        }
    }
    async removeProductFromList({list_id, product_code}) {
        try {
            const listHeader = await http.delete('wishList/'+list_id+'/product/'+product_code);
            const res = parseResponse(listHeader.data);
            return res.data;
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }
    async updateWishList(wishList) {
        try {
            const listHeader = await http.put('wishList/'+wishList.list_id, {
                ...wishList
            });
            const res = parseResponse(listHeader.data);
            return res.data;
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }
    async removeWishList(list_id) {
        try {
            const listHeader = await http.delete('wishList/'+list_id);
            const res = parseResponse(listHeader.data);
            return res.data;
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }
}