<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <g>
                <path :class="[red ? 'warning-red' : 'st58']" d="M112.8,22.4c5.4-9.4,19-9.4,24.4,0l55.2,95.6l55.2,95.6c5.4,9.4-1.4,21.1-12.2,21.1H125H14.6    C3.8,234.7-3,223,2.4,213.6L57.6,118L112.8,22.4z"/>
                <g>
                    <path :class="[red ? 'warning-red-white' : 'st3']" d="M122.2,92.4c-5.5,1.6-9,6.6-9,12.7c0.3,3.7,0.5,7.4,0.8,11.1c0.8,14,1.6,27.8,2.4,41.8     c0.3,4.8,4,8.2,8.7,8.2s8.5-3.7,8.7-8.5c0-2.9,0-5.5,0.3-8.5c0.5-9,1.1-18,1.6-27c0.3-5.8,0.8-11.6,1.1-17.5c0-2.1-0.3-4-1.1-5.8     C133.3,93.8,127.8,91.1,122.2,92.4z"/>
                    <path :class="[red ? 'warning-red-white' : 'st3']" d="M125.1,174.2c-6.3,0-11.6,5.3-11.6,11.6s5.3,11.6,11.6,11.6c6.1,0,11.6-5.3,11.4-11.4     C136.8,179.5,131.5,174.2,125.1,174.2z"/>
                </g>
            </g>
        </g>
    </icon-base>
</template>

<script>
export default {
    name: 'iconWarning',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        red:{
            type: Boolean,
            default: false
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>

<style scoped>
	.st58{fill:#FFD971;}
	.st3{fill:#212E3D;}

    .warning-red{fill: var(--main-red);}
	.warning-red-white{fill:#fff;}
</style>
