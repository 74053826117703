export default () => ({
    loading: false,
    disableModal: false,
    notify: {
        img: '',
        title: '',
        action: '',
        show: false,
        type: null,
        product: null,
        quick: null,
        decrement: false,
        oldQuantity: 0
    },
    shortNotify: {
        title: '',
        type: '',
        show: false,
    },
    configurations: {
        general: {
            web_page: {
                active: true,
                message: 'Nos encontramos mejorando tu experiencia<br>y esta opción no esta disponibles.<br>!Estaremos de vuelta pronto!'
            },
        },
        payment_methods:{
            
        }
    },
    mobile: localStorage.getItem('display') == 'mb' || false,
    error: {
        show: false
    }
});
