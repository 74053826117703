<template>
    <main-confirm @confirm="$emit('confirmDelete')" @cancel="() => {$emit('closeModal')}" confirm-title="product.delete_product"  
        :description="description" :loading="loading" :contentHTML="contentHTML">
        <icon-delete :size="100"/>
    </main-confirm>
</template>

<script>
import MainConfirm from '../../../../common/components/modals/MainConfirm.vue'
import IconDelete from '../../../cart/assets/svg/iconDelete.vue'
export default {
    components: { MainConfirm, IconDelete },
    name: "ConfirmDeleteProduct",
    props: {
        loading: {
            type: Boolean
        },
        description:{
            type: String,
            default: 'product.are_you_sure_you_want_to_remove_this_product_from'
        },
        contentHTML:{
            type: String
        }
    }
}
</script>