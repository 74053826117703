export default {
    inserted: el => {
        function loadImage() {
            const imageElement = Array.from(el.children).find(
                el => el.nodeName === "IMG"
            );

            if (imageElement) {
                imageElement.addEventListener("load", () => {
                    // console.log('listo')
                    // console.log(el.classList + ' here')
                    el.classList.add("loaded")
                    // setTimeout(() => el.classList.add("loaded"), 100);
                });
                imageElement.addEventListener("error", () =>{
                    // el.classList.add("loaded")

                    // console.log('error')
                    // imageElement.src = 'https://mundomovil.com.do/assets/img/no-disponible.png';
                    imageElement.src = require('../../assets/images/no-available-icon.svg');
                    imageElement.style.padding = "15px"
                });
                // setTimeout(()=>{
                    imageElement.src = imageElement.dataset.url;
                    // console.log(imageElement.src)
                // }, 100 )
                imageElement.addEventListener('change', () => {
                    console.log('change')
                });
                // imageElement.src = imageElement.dataset.url;
                // console.log(imageElement.src)
            }
        }
        function handleIntersect(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    loadImage();
                    observer.unobserve(el);
                }
            });
        }

        function createObserver() {
            const options = {
                root: null,
                threshold: "0"
            };
            const observer = new IntersectionObserver(handleIntersect, options);
            observer.observe(el);
        }
        if (window["IntersectionObserver"]) {
            createObserver();
        } else {
            loadImage();
        }
    }
};