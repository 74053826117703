<!-- CookieBanner.vue -->
<template>
    <div v-if="!accepted" class="cookie-banner">
        <p style="color:#fff">{{ $t(`auth.${message}`) }}
            <router-link :to="`/home/policies/view-privacy`">
                    <span class="change-color" >{{$t(`layout.footer.policies`)}}</span>
            </router-link>
        </p>
        <div style="display: flex; justify-content: center; align-items: center;"> 
            <main-button type="button" title="checkout.accept" @click="acceptCookies" class="align-items-center size-14" style="position: relative; top: -8px;" />
        </div>
    </div>
</template>

<script>
import MainButton from '../../../common/components/buttons/MainButton.vue';
export default {
    data() {
        return {
            accepted: false,
            message: "info_cookies",
        };
    },
    components: {
        MainButton
    },
    methods: {
        acceptCookies() {
            this.accepted = true;
            localStorage.setItem("cookiesAccepted", "true");
        },
    },
};
</script>

<style scoped>
.cookie-banner {
    z-index: 2002;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--dark-main-navy);
    color: #fff;
    padding: 10px;
    text-align: center;
}

.change-color { 
    color: var(--main-red)
}
</style>
