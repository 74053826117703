<template>
<icon-base :width="100+'%'" :fill="color" viewBox="0 0 643.95 505.586">
    <path id="Path_3075" data-name="Path 3075" d="M113.4,1611.421s70.029-190.78,281.966-142.973c249.547,56.29,327.473-43.228,359.6-168.937l2.388,497.654S113.4,1863.695,113.4,1611.421Z" transform="translate(-113.399 -1299.511)"/>
</icon-base>
</template>

<script>
export default {
    name: 'CurvedBottom',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: '#bed37d'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>
