
import { http } from '../../../common/services/web-backend'
import { http as backend } from '../../../common/services/node-service';
import { parseResponse } from '../../../common/helpers/responses/parser'
export class RegisterService {
    constructor(){

    }

    async personalRegister(newAccount){
        try{

            const personal = await http.post('register/account', {
                code: newAccount.code,
                first_name: newAccount.first_name,
                last_name: newAccount.last_name,
                email: newAccount.email,
                price_level_id: newAccount.price_level_id,
                phone: newAccount.phone.replace(/[/(||)| |+|-]/g, ""),
                username: newAccount.username,
                password: newAccount.password,
                address: newAccount.address,
                password_confirm: newAccount.password_confirm,
                isClient: newAccount.isClient,
                user: newAccount.user
            });
            return  parseResponse(personal.data);
        }catch (e) {
            throw e.response
        }


    }
    async subscribeEmail(email){
        try {
            const subscribe = await backend.post('/email-subscriptions/subscribe', email)
            return parseResponse(subscribe.data)
        } catch (e) {
            return parseResponse(e.response.data)
        }
    }
}