<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <path class="st0" d="M242,5.8c-3.6-3.6-9.4-3.6-12.9,0l-62,62c-4.6-1.6-9.2-3-13.9-4.1c-11.8-2.7-24-3.6-36.1-2.8
                c-7.3,0.5-14.5,1.7-21.6,3.5C78.3,69,62,75.9,46.9,85.2c-15.1,9.3-28.8,20.6-41.4,33c-6.7,6.6-6.6,9.6,0,16.2
                c16.3,16.2,34.4,29.9,54.7,40.3L3.7,231.2c-3.6,3.6-3.6,9.4,0,12.9c1.8,1.8,4.1,2.7,6.5,2.7c2.3,0,4.7-0.9,6.5-2.7L242,18.8
                C245.5,15.2,245.5,9.4,242,5.8z M140.9,94c-14.4-7.3-31.3-4.3-42.3,7.2c-10.6,11.1-13,27.7-6.3,41.3l-10.6,10.6
                c-4.8-7.7-7.6-16.9-7.7-26.7c-0.2-28.1,22.5-51,50.8-51.1c9.9-0.1,19.2,2.8,27,7.7L140.9,94z"/>
            <path class="st0" d="M245.2,118.9c-12.8-13-26.9-24.3-42.5-33.8c-1.6-1-3.2-1.9-4.8-2.9l-25.5,25.5c2.2,5.7,3.5,11.9,3.6,18.4
                c0.2,28.1-22.5,51-50.8,51.1c-6.6,0-13-1.2-18.8-3.5l-13.8,13.8c19.2,5.2,38.9,6,59.4,1.2c36.8-8.7,66.8-29,93.2-55.1
                C251,127.9,250.9,124.7,245.2,118.9z"/>
            <path class="st0" d="M118.1,162c4.8,1,9.8,1,14.7-0.1c14.4-3.2,26.5-15.9,27.4-29c-4.6,0.7-8.8,0.6-12.6-0.4L118.1,162z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'iconIconClosedEye',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>

</style>
