<template>
<section class="row mx-0 mt-5">
    <div class="col-12 px-0">
        <div class="banner-background">
            <swiper class="swiper swiper-interactive" :options="swiperOption" @transition-start="change" ref="swiper" v-show="!loading">
                    <swiper-slide class="position-relative" v-for="(item, index) in slider" :key="index" @mouseenter.native="stop" 
                    @mouseleave.native="continueSlider">
                        <slider-type-1 :data="item" :active="activeIndex" :index="index" v-if="item.type == 1" @onImgLoad="onImgLoad"/>
                        <slider-type-2 :data="item" :active="activeIndex" :index="index" v-if="item.type == 2"/>
                        <slider-type-3 :data="item" :active="activeIndex" :index="index" v-if="item.type == 3"/>
                    </swiper-slide>
                    <swiper-slide v-for="img in bannerMain" :key="img._id" class="d-flex align-items-center">
                        <img loading="lazy" :src="img.imageURL" :alt="img._id" v-show="!loading" class="w-100 slide-img"/>
                        <div class="h-100 w-100 align-items-center justify-content-center" :class="[!loading ? 'd-none' : 'd-flex']">
                        </div>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                    <div class="swiper-button-prev d-none d-md-block mr-5" slot="button-prev">
                        <icon-arrow :size="40" style="margin-right: -12px; transform: rotate(180deg);"  :color="'#fff'"/>
                    </div>
                    <div class="swiper-button-next d-none d-md-block" slot="button-next">
                        <icon-arrow :size="40" style="margin-left: -12px" :color="'#fff'"/>
                    </div>
            </swiper>
            <div v-show="loading" class="w-100 h-100 skeleton" style="border-radius: 30px;"></div>
        </div>
    </div>
</section>

</template>

<script>
import { mapGetters } from "vuex";
import IconArrow from '../../../../common/svg/iconArrow.vue'
import SliderType1 from './1/SliderType1.vue'
import SliderType2 from './2/SliderType2.vue'
import SliderType3 from './3/SliderType3.vue'
export default {
    name: 'SliderInteractive',
    components: {
        IconArrow,
        SliderType1,
        SliderType2,
        SliderType3
    },
    props: {
        productsSlider: {
            type: Array
        }
    },
    data () {
        return {
            images: [],
            swiperOption: {
                slidesPerView: 1,
                slidesPerColumn: 1,
                autoplay:{
                    delay: 4000,
                    stopOnLastSlide: true,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: true,
                },
                // loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true
                }
            },
            activeIndex: 0,
            slider:[
                {
                    type: 1,
                    title_1: "XL-SEE1301",
                    title_2: "Serie Premium",
                    title_3: "VIDVIE X",
                    description_1: "Reducción de",
                    description_2: "Ruido inalámbrica",
                    img_primary: require("./1/images/headphones.webp"),
                    background_color: "#559aff"
                },
                {
                    type: 2,
                    title_1: "Celular",
                    title_2: "Xiaomi Poco",
                    title_3: "Huawei",
                    description_1: "Una Súper Pantalla",
                    description_2: "Para Más Entretenimiento",
                    img_primary: require("./2/images/xiaomi.webp"),
                    img_secondary: require("./2/images/xiaomi-2.webp"),
                    background_color: "#13B8BC"
                },
                {
                    type: 3,
                    title_1: "FEEL THE BEAT",
                    title_2: "XL-SP903",
                    title_3: "Speaker Bluetooth",
                    description_1: "VIDVIE PREMIUM",
                    description_2: "Para Más Entretenimiento",
                    img_primary: "..",
                    background_color: "#fba3a9",
                    sound_waves: true
                },
            ],
            timemoutID: null,
            loading: true,
        }
    },
    computed: {
        ...mapGetters({
        bannerMain: 'home/bannerMain'
        })
    },
    methods:{
        change(){
            this.activeIndex = this.$refs.swiper.$swiper.realIndex
            clearTimeout(this.timemoutID)
            if(this.$refs.swiper.$swiper.isEnd){
                this.timemoutID = setTimeout(()=>{
                    // this.$refs.swiper.$swiper.slideTo(0, 0);
                    // this.$refs.swiper.$swiper.autoplay.start()
                }, 4000)
            }
            // if(this.$refs.swiper.$swiper.activeIndex > 3){
            //     this.swiperKey += 1
            //     this.activeIndex = 1
            // }
            // console.log(this.$refs.swiper.$swiper.isEnd);
            // console.log(this.$refs.swiper.$swiper);
        },
        onImgLoad(){
            setTimeout(() => {
                this.loading = false
            }, 200);
        },
        stop(){
            // this.$refs.swiper.$swiper.autoplay.stop()
        },
        continueSlider(){
            // this.$refs.swiper.$swiper.autoplay.start()
        },
    },
    mounted(){
        this.$emit("sliderLoaded", true)
    }
}
</script>

<style scoped>
.banner-background {
    height: 610px;
}
.swiper-button-prev {
    margin-left: 10px !important;
}
.swiper-button-prev, .swiper-button-next {
    width: 40px;
    z-index: 10;
}
.swiper-button-prev:before, .swiper-button-next:before {
    width: 80px;
}
.swiper-button-prev:after, .swiper-button-next:after {
    display: none;
    visibility: hidden;
}
.swiper-button-prev svg:hover, .swiper-button-next svg:hover {
    fill: #334056 !important;
}
.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
    cursor: no-drop !important;
    pointer-events: all !important;
}
.swiper-button-prev.swiper-button-disabled:hover, .swiper-button-next.swiper-button-disabled:hover {
    color: var(--gray-50) !important;
}
.swiper-interactive{
    border-radius: 30px; 
    height: 100%;
}
.swiper-container::v-deep > .swiper-pagination > .swiper-pagination-bullet{
    width: 17px;
    height: 17px;
    background: #fff;
    opacity: 1;
}
.swiper-container::v-deep > .swiper-wrapper{
    transition-duration: 0.6s !important;
}
.swiper-container::v-deep > .swiper-pagination > .swiper-pagination-bullet.swiper-pagination-bullet-active{
    background: #334056 !important;
}
.skeleton {
    height: 160px;
    width: 160px;
    border-radius: 5px;
    background: rgba(130, 130, 130, 0.2);
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
    background: linear-gradient(to right, rgb(198 212 219 / 20%) 8%, rgb(198 212 219 / 30%) 18%, rgb(198 212 219 / 20%) 33%);
    background-size: 800px 100px;
    animation: wave-squares 1.5s infinite ease-out;
}
@keyframes wave-squares {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}
</style>
