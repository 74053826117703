<template>
    <div class="mt-5">
        <scroll-view :offset="130">
            <template slot-scope="contact">
                <question-contact key="presentation" :visibility="(contact.presentation && allowAnimation) || contact.faq" /> 
                <!-- <frequent-questions key="faq" name="faq" ref="faq" id="faq" :visibility="contact.faq && allowAnimation" style="margin-bottom: 20vh;" class="container" /> @questionsOpened="scrollHandler" -->
                <branches key="branches" ref="branches" id="branches" :visibility="contact.branches && allowAnimation" />
                <!-- <subscribe key="subscribe" ref="subscribe" class="container" /> -->
            </template>
        </scroll-view>
    </div>
</template>

<script>
import { $scrollview } from 'vue-scrollview'
import { mapGetters } from "vuex"
import QuestionContact from "../components/contact/QuestionContact.vue"
// import FrequentQuestions from '../components/contact/FrequentQuestions.vue'
import Branches from "../components/contact/Branches.vue"
// import Subscribe from "../components/contact/Subscribe.vue"
export default {
    components: {
        QuestionContact,
        // FrequentQuestions, 
        Branches, 
        // Subscribe
    },
    name: "Contact",
    data(){
        return {
            scrolledTop: false,
            scrollEndCounter: null,
        }
    },
    methods: {
        checkScrollisTop() {
            if (document.documentElement.scrollTop < 1) {
                this.scrolledTop = true   
            }
        },
        scrollHasFinished() {
            clearTimeout(this.scrollEndCounter)
            this.scrollEndCounter = setTimeout(() => {
                this.scrolledTop = true   
            }, 50)
        }
    },
    computed: {
        ...mapGetters({ loading: "common/loading"}),
        allowAnimation(){
            return !this.loading && this.scrolledTop
        }
    },
    watch: {
        '$route': {
            deep: true,
            handler(val){
                if (val.path == '/contact/faq') {
                    $scrollview.scrollToComponent("presentation", 300);
                }
            }
        }
    },
    mounted() {
        if (this.$route.path == '/contact/faq') {
            $scrollview.scrollToComponent("presentation", 320);
            document.addEventListener('scroll', this.scrollHasFinished, { passive: true });
        }else {
            this.checkScrollisTop()
            document.addEventListener('scroll', this.checkScrollisTop, { passive: true });
        }
        if (window.history.length <= 2) {
            this.checkScrollisTop()
        }
    },    
    beforeDestroy() {
        clearTimeout(this.scrollEndCounter)
        document.removeEventListener('scroll', this.scrollHasFinished, { passive: true });
        document.removeEventListener('scroll', this.checkScrollisTop, { passive: true })
    },
}
</script>
<style scoped>
.visibility-indicator {
    display: flex;
    position: fixed;

    top: 0px;
    left: 10px;
}
</style>