<template>
  <svg version="1.1" id="Layer_3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" :viewBox="viewBox" :class="{ 'background-enabled': enableBackground }" :width="size + 'px'">
    <slot />
  </svg>
</template>

<script>
export default {
  name: 'iconBase',
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ''
    },
    enableBackground: {
      type: Boolean
    },
    viewBox: {
      type: String,
      default: '0 0 250 250'
    }
  }
}
</script>

<style scoped>
  .background-enabled {
    enable-background: new 0 0 250 250;
  }
</style>
