export default () => ({
    products: [],
    productsWithQuantities: [],
    cart: {
      total_amount: 0
    },
    saving: false,
    loadingCart: true,
    quoteCreated: {
      show: false,
      quotation: null,
  }
});

