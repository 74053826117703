export default {
    getProducts: (state) => state.products || [],
    productsWithQuantities: (state) => state.productsWithQuantities,
    cart: (state) => state.cart,
    amount(state, getters) {
            let amount = 0 
            let products = getters["getProducts"]
            if(products.length){
                for (let product of products) {
                    amount += product.price * product.quantity
                }
            }
            return amount
    },
    quantityProducts(state) {
        let quantity = 0;
        if(state.products){
            for (let product of state.products) {
                quantity += product.quantity
            }
        }
        return state.cart.total_items_products ? state.cart.total_items_products : quantity
    },
    loadingCart: (state) => state.loadingCart,
    quoteCreated: (state) => state.quoteCreated,
}
