<template>
    <section class="home-section">
        <div class="row mx-0">
            <div class="parallax-background col-12 position-relative d-flex justify-content-center align-items-center align-content-center">            
                <div class="swiper-button-prev-brands d-none d-md-block" slot="button-prev">
                    <icon-arrow :size="35" />
                </div>
                <swiper class="swiper" :options="swiperOption" ref="brandSwiper">
                    <swiper-slide :key="brandName" v-for="({img, brandName, style}) in brands" class="position-relative d-flex justify-content-center">
                        <div class="brand-container d-flex justify-content-xl-start" @click="select(brandName)" @mouseover="stop" @mouseleave="start">
                            <component :is="img" viewBox="0 0 250 100" :style="style" style="enable-background:new 0 0 250 250; fill: var(--dark-gray-200)" class="brand-img" :data-name="brandName"/>
                        </div>
                    </swiper-slide>
                </swiper>
                <div class="swiper-button-next-brands d-none d-md-block" slot="button-next">
                    <icon-arrow :size="35" />
                </div>     
            </div>
        </div>
    </section>
</template>

<script>
import { searchMixin } from "@/modules/layout/mixins/search-mixin.js";
export default {
    name: 'BrandsSlider',
    components: {
        IconArrow: () => import("@/common/svg/iconArrow.vue"),
        //brands
        AlcatelBrand: () => import('@/modules/home/components/sliders/brands/alcatel-brand.vue'),
        HtcBrand: () => import('@/modules/home/components/sliders/brands/htc-brand.vue'),
        HuaweiBrand: () => import('@/modules/home/components/sliders/brands/huawei-brand.vue'),
        LgBrand: () => import('@/modules/home/components/sliders/brands/lg-brand.vue'),
        MicrosoftBrand: () => import('@/modules/home/components/sliders/brands/microsoft-brand.vue'),
        NokiaBrand: () => import('@/modules/home/components/sliders/brands/nokia-brand.vue'),
        SamsungBrand: () => import('@/modules/home/components/sliders/brands/samsung-brand.vue'),
        SonyBrand: () => import('@/modules/home/components/sliders/brands/sony-brand.vue'),
        VidvieBrand: () => import('@/modules/home/components/sliders/brands/vidvie-brand.vue'),
    },
    mixins: [searchMixin],
    computed: {
        brandSwiper(){
            return this.$refs.brandSwiper;
        }
    },
    data(){
        return {
            swiperOption: {
                grabCursor: true,
                loop: true,
                autoplay: {
                    delay: 1000,
                    pauseOnMouseEnter: true,
                },
                spaceBetween: 0,
                navigation: {
                    nextEl: ".swiper-button-next-brands",
                    prevEl: ".swiper-button-prev-brands",
                },
                pagination: {
                    el: '.swiper-pagination',
                    dynamicBullets: true
                },
                breakpoints: {
                    0: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                    },
                    576: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 0,
                    },
                    992: {
                        spaceBetween: 0,
                        slidesPerView: 4,
                    },
                    1200: {
                        slidesPerView: 5,
                        spaceBetween: 0,
                    },
                },
                clickable: true,
            },
            brands: [
            {
                img: 'nokia-brand',
                brandName: 'NOKIA',
                style: "width: 140px;"
            },
            {
                img: 'samsung-brand',
                brandName: 'SAMSUNG',
                style: "width: 120px;"
            },
            {
                img: 'sony-brand',
                brandName: 'SONY',
                style: "width: 100px;"
            },
            {
                img: 'vidvie-brand',
                brandName: 'VIDVIE',
                style: "width: 120px;"
            },
            {
                img: 'htc-brand',
                brandName:'HTC',
                style: "width: 100px;"
            },
            {
                img: 'alcatel-brand',
                brandName: 'ALCATEL',
                style: "width: 130px;"
            },
            {
                img: 'huawei-brand',
                brandName:'HUAWEI',
                style: "width: 145px;"
            },
            {
                img: 'lg-brand',
                brandName:'LG',
                style: "width: 90px;"
            },
            {
                img: 'microsoft-brand',
                brandName: 'MICROSOFT',
                style: "width: 150px;"
            },
            ],
        }
    },
    methods: {
        select(brand) {
            this.filters.brands_selected = [brand]
            this.$store.dispatch('products/selectBrands')
            this.$router.replace({path: '/shop'}).catch(() => {})
        },
        stop() {
            this.$refs.brandSwiper.$swiper.autoplay.stop()
        },
        start() {
            setTimeout(() => {
                this.$refs.brandSwiper.$swiper.autoplay.start()
            }, 500);
        }
    },
}
</script>

<style scoped>

section {
    margin-top: 20px;
}
.parallax-background {
    height: 166px;
    border-radius: 8px;
    background-color: var(--light-gray-501);
}
.swiper >>> .swiper-wrapper .swiper-slide {
    opacity: 0.8;
    transition: var(--transition-1);
    height: 80px;
}
.swiper >>> .swiper-wrapper .swiper-slide:hover {
    opacity: 1;
}
.swiper >>> .swiper-wrapper .swiper-slide:hover .brand-container .brand-img {
    transform: scale(1.2);
    fill: var(--dark-gray-400) !important;
}
.brand-img {
    transition: var(--transition-1);
}
.swiper-button-prev-brands, .swiper-button-next-brands {
    z-index: 1;
}
.swiper-button-prev-brands {
    transform: rotate(180deg);
    margin-left: 1.5%;
}
.swiper-button-next-brands {
    margin-right: 1.5%;
}
.swiper-button-prev-brands:after, .swiper-button-next-brands:after {
    display: none;
    visibility: hidden;
}
.swiper-button-prev-brands svg, .swiper-button-next-brands svg {
    fill: var(--dark-gray-200);
    transition: var(--transition-1);
}
.swiper-button-prev-brands:hover, .swiper-button-next-brands:hover {
    color: var(--gray-50) !important; 
}
.swiper-button-prev-brands:hover svg, .swiper-button-next-brands:hover svg {
    fill: black;
}
@media (max-width: 576px) {
    .parallax-background{
        height: 100px;
    }
}
@media (min-width: 768px) {
    .swiper-button-prev-brands{
        margin-left: 45px;
    }
    .swiper-button-next-brands{
        margin-right: 45px;
    }    
}
@media (min-width: 992px) {
    .parallax-background {
        padding-top: 5px;
    }
    .swiper-button-prev-brands{
        margin-left: 30px;
    }
    .swiper-button-next-brands{
        margin-right: 30px;
    }
}
@media (min-width: 1200px) {
}
@media (min-width: 1400px) {
    .swiper-button-prev-brands{
        margin-left: 40px;
    }
    .swiper-button-next-brands{
        margin-right: 40px;
    }    
}
</style>