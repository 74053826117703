<template>
    <form class="register-form row mx-0 px-0" @submit.prevent="" autocomplete="off">
        <h6 class="subtitle-16 p-0 py-2 mb-0">{{$t("auth.please_complete_fields")}}</h6>
        <div class="col-md-12 px-0">
            <alert-warning :show="isAgreeActive && !isAgree" :showCounter="false" :size="30" title="auth.you_must_accept_the_policies_to_continue" class="mt-2 mb-2 p-0"/>
            <alert-warning :show="incorrectPassword" :showCounter="false" :size="30" title="common.passwords_do_not_match" class="mt-2 mb-2 p-0"/>
            <alert-warning :show="error != null" :showCounter="false" :size="30" :title="error" class="mt-2 mb-2 p-0"/>
            <div v-if="isAlready" class="bg-yellow-50 px-3 py-1 animation-slide-down" style="border-radius: 5px">
                <div class="d-flex">
                    <icon-warning :size="40" class="me-2"/>
                    <div class="d-flex align-items-center ml-2">
                        <p class="subtitle-14 mb-0 color-warning-900 weight-300">
                            {{$t("auth.this_email_already_exists")}} 
                            <span class="ms-1 me-1 size-14 weight-500">{{form.email}}.</span>
                            {{$t("auth.validate_it_or_enter_a_new_one")}}.
                            <a href="#" @click.prevent="$emit('validateEmail', form.email)" class="size-14 weight-500" style="border-right: 2px solid;padding-right: 10px;border-color: #a68b4b66;">{{$t("auth.validate_email")}}</a>
                            <a href="#" @click.prevent="$emit('validateEmail', form.email)" class="size-14 weight-500 ps-2">{{$t("auth.why_is_this_happening")}}?</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <main-input ref="name" id="name" class="col-md-6 mt-2 mb-3 pe-md-2 ps-md-0" :value="form.first_name" :title="$t('auth.name')" @changeValue="(data) => {form.first_name = data}" placeholder="auth.enter_your_name" :readonly="readonly" @activeInput="readonly = false">
            <icon-person :size="15" color="var(--dark-gray-200)" />
        </main-input>
        <main-input ref="lastName" id="lastName" class="col-md-6 mt-2 mb-3 pe-md-0 ps-md-2" :value="form.last_name" :title="$t('auth.last_name')" @changeValue="(data) => {form.last_name = data}" placeholder="auth.enter_your_last_name" :readonly="readonly" @activeInput="readonly = false">
            <icon-last-name :size="17" color="var(--dark-gray-200)" />
        </main-input>
        <main-input ref="email" id="email" class="col-md-6 mt-2 mb-3 pe-md-2 ps-md-0" :value="form.email" :title="$t('auth.email')" type="email" @changeValue="(data) => {form.email = data}" placeholder="auth.mail_placeholder" :readonly="readonly" @activeInput="readonly = false">
            <icon-email :size="17" color="var(--dark-gray-200)" />
        </main-input>
        <!-- <main-input ref="phone" id="phone" class="col-md-6 mt-2 mb-3 pe-md-0 ps-md-2" :value="form.phone" :title="$t('auth.phone_number')" type="phone" :mask="'+1 ###-###-####'" @changeValue="(data) => {form.phone = data}" placeholder="+1 809-000-0000" :readonly="readonly" @activeInput="readonly = false">
            <icon-phone :size="15" color="var(--dark-gray-200)" />
        </main-input> -->
        <div class="main-input position-relative col-md-6 mt-2 mb-3 pe-md-0 ps-md-2">
            <span class="title-14 mb-0" >{{ $t('auth.phone_number') | textFormat }}</span>
            <VuePhoneNumberInput class="vue-phone-number-input" style='position: relative; top: 6px;' :valid-color="color" :translations="translations" :default-country-code="countryCode" :border-radius="7" :error="isValidPhone == false" ref="phone" id="phone" v-model="form.phone" @update="handlePhoneNumberInput" />
        </div>
        <main-input ref="currentPassword" id="currentPassword" class="col-md-6 mt-2 mb-3 pe-md-2 ps-md-0" :value="form.password" :title="$t('auth.create_your_password')" 
        @changeValue="(data)=>{form.password = data}" placeholder="auth.enter_your_password" customValidate="new-password" :readonly="readonly" @activeInput="readonly = false" 
        fixedType="password" :min="6">
            <icon-password :size="12" color="var(--dark-gray-200)" />
        </main-input>
        <main-input ref="passwordConfirm" id="passwordConfirm" class="col-md-6 mt-2 mb-3 pe-md-0 ps-md-2" :value="form.password_confirm" :title="$t('auth.confirm_password')"
            @changeValue="(data) => {form.password_confirm = data}" :readonly="readonly" @activeInput="readonly = false"
            type="text" placeholder="auth.confirm_your_password" fixedType="password" customValidate="confirm-password" :valuePassword="form.password">
            <icon-password :size="12" color="var(--dark-gray-200)" />
        </main-input>
        <div class="d-flex col-md-12 align-items-center p-0">
            <main-checkbox class="mt-0 me-2" :class="{'red-outline': agreeInvalid}" id="policies" @checkboxChange="(data) => { isAgree = data }" />
            <span class="me-3 subtitle-14">{{$t("auth.i_agree_with_the")}} 
                <a class="hover-underline" href="/home/policies/view-privacy" target="_blank">{{$t("auth.terms&conditions")}}</a>
            </span>
        </div>
        <div class="col-md-12 d-flex flex-column justify-content-center align-items-center mt-4">
            <main-button :title="'auth.create_account'" @click="createAccount" class="mt-3 mt-sm-0"/>
            <div @click.prevent="$emit('showLogin')" class="subtitle-15 mt-2 d-flex justify-content-center">
                <span class="me-2 size-14 cursor-pointer">{{ $t("auth.do_you_already_have_an_account") }}?</span> <a class="hover-underline" href="#">{{ $t("auth.sign_in") }}</a>
            </div>
        </div>
    </form>
</template>

<script>
import AlertWarning from '../../../common/components/alerts/AlertWarning.vue';
import MainButton from '../../../common/components/buttons/MainButton.vue';
import MainCheckbox from '../../../common/components/checkbox/MainCheckbox.vue';
import MainInput from '../../../common/components/inputs/MainInput.vue';

import IconPerson from '../../../common/svg/iconPerson.vue';
// import IconPhone from '../../../common/svg/iconPhone.vue';
import IconWarning from '../../../common/svg/iconWarning.vue';
import IconEmail from '../assets/svg/iconEmail.vue';
import IconLastName from '../assets/svg/iconLastName.vue';
import { PhoneNumberUtil } from 'google-libphonenumber';
import IconPassword from '../assets/svg/iconPassword.vue';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import { RegisterService } from "../../../modules/auth/services/register-service";
const Service = new RegisterService();

export default {
    name: "Register",
    data(){
        return {
            form: {
                code: 1,
                first_name: "",
                last_name: "",
                email: "",
                user: "",
                phone: "",
                price_level_id: 1,
                password: "",
                password_confirm: "",
                isClient: false,
                isEmail: false
            },
            isValidPhone: null,
            errorIndex: 0,
            isAgree: false,
            isAgreeActive: false,
            agreeInvalid: false,
            showEye: false,
            incorrectPassword: false,
            passwordRequired: false,
            error: null,
            isAlready: false,
            readonly: true,
            countryCode: this.verifyUSTECH() ? 'US' : 'DO',
            color: 'yellowgreen',
            translations:{
                countrySelectorLabel: this.$t('auth.country_code'),
                countrySelectorError: this.$t('auth.selected_country'),
                phoneNumberLabel: this.$t('auth.phone_number'),
                example: this.$t('auth.example')
            },
        }
    },
    components: {
        MainInput,
        MainButton,
        MainCheckbox,
        // IconPhone,
        VuePhoneNumberInput,
        IconPassword,
        AlertWarning,
        IconLastName,
        IconEmail,
        IconPerson,
        IconWarning
    },
    watch: {
        'form.password'(){
            if(this.form.password === this.form.password_confirm)
            this.incorrectPassword = false;
        },
        'form.password_confirm'(){
            if(this.form.password === this.form.password_confirm)
            this.incorrectPassword = false;
        }
    },
    methods: {
        handlePhoneNumberInput(value){
            this.isValidPhone = value.isValid
            this.detectCountry(value.nationalNumber, value.countryCode)
        },
        detectCountry(phone, country) {
            this.color = 'yellowgreen'
            const phoneUtil = PhoneNumberUtil.getInstance();
            try {
                const number = phoneUtil.parse(phone, country);
                const code = phoneUtil.getRegionCodeForNumber(number);
                if(code != null){
                    this.countryCode = code
                } else {
                    this.color = 'orangered'
                    this.isValidPhone = false
                }
            } catch (error) {
                console.log('Error al determinar el país del número de teléfono:', error);
            }
        },
        async createAccount(){
            const inputs = document.querySelectorAll('.main-input.is-invalid')
            inputs.forEach(el => {
                el.classList.add('invalid-input')
            }) 
            try {
                this.$refs.name.$v.$touch();
                this.$refs.lastName.$v.$touch();
                this.$refs.email.$v.$touch();
                // this.$refs.phone.$v.$touch();
                this.$refs.currentPassword.$v.$touch();
                this.$refs.passwordConfirm.$v.$touch();
                
                if (!this.isAgree) {
                    this.agreeInvalid = true;
                }
                if (this.$refs.name.$v.$invalid || this.$refs.lastName.$v.$invalid || this.$refs.email.$v.$invalid || this.$refs.currentPassword.$v.$invalid || this.$refs.passwordConfirm.$v.$invalid){
                    return
                }
                if(this.form.password != this.form.password_confirm){
                    this.incorrectPassword = true
                    return
                }
                if(!this.isValidPhone){
                    console.log(this.isValidPhone);
                    this.isValidPhone = false
                    return
                }
                if (!this.isAgree){
                    this.isAgreeActive = true;
                    return
                }else{
                    this.$store.commit("common/SET_STATE_LOADING", true)
                    const res = await Service.personalRegister(this.form);
                    this.$emit("confirmSignUp", {username: res.data.user.username, password: this.form.password_confirm})
                }
            }catch (e) {
                this.$store.commit("common/SET_STATE_LOADING", false)
                if(e.data.code === 423){
                    this.isAlready = true
                    return
                }
                if(e.data){
                    this.error = e.data.message
                }
            }
        },
    },
    mounted(){
        document.querySelector('.vue-phone-number-input input').style.minHeight = '36px';
        document.querySelector('.vue-phone-number-input input').style.height = '38px';
        // document.querySelector('.vue-phone-number-input input').style.borderColor = '#dae5ea';

        document.querySelector('.vue-phone-number-input .input-tel__input').style.minHeight = '36px';
        document.querySelector('.vue-phone-number-input .input-tel__input').style.height = '38px';
        // document.querySelector('.vue-phone-number-input .input-tel__input').style.width = '143px';
        // document.querySelector('.vue-phone-number-input .input-tel__input').style.borderColor = '#dae5ea';

    }
}
</script>
<style scoped>

.red-outline::v-deep .cbx span:first-child{
    border: 1px solid var(--main-red) !important;
}
.cbx span:first-child {
    border: 1px solid var(--gray-1001);
}
.custom-class {
    height: 38px !important;
    min-height: 36px !important;
}

</style>
