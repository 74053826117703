<template>
    <div>
        <div class="add-to-wishlist">
            <div class="responsive-list" :class="{'many-lists': lists.length > 3}">
                <div v-for="(list, index) in lists" :key="index" @click="selectList($event, list._id)" class="select-list d-flex justify-content-start justify-content-md-between cursor-pointer hover-bg-light-gray-50 position-relative" :class="{'ps-0': !viewPort('md')}">
                    <wrapper-image :images="list.products" :wrapperHeight="height" :wrapperWidth="height" :imgHeight="43.5" class="flex-shrink-0" />
                    <div class="list-info d-flex ms-3 me-1 mx-md-0 flex-column justify-content-md-center">
                        <div>
                            <span class="d-flex mb-1">
                                <h5 class="title-16 mb-0 text-limited">{{list.name}}</h5>
                                <!-- <span id="edit-list" @click="editList($event, list)" class="d-flex ">
                                    <icon-edit :size="19" class="d-md-none flex-shrink-0 ms-2 ms-lg-3 cursor-pointer pointer-events-none" color="var(--gray-1001)"/>
                                </span> -->
                            </span>
                        </div>
                        <span class="subtitle-13 color-dark-gray-400 text-limited">{{list.products.length}} {{$t(`wishlist.article_${list.products.length > 1 ? 'plural':'singular'}`)}}</span>
                        <span class="subtitle-13 color-dark-gray-400 text-limited">{{$d(new Date(list.updatedAt), 'alt_short')}}</span>
                        <!-- <span v-if="days(list.updatedAt) > 0" class="subtitle-13 color-dark-gray-400 text-limited">{{$t("wishlist.updated_ago")}} {{days(list.updatedAt)}} {{$t("wishlist.days")}}</span> -->
                        <!-- <span v-else class="subtitle-13 color-dark-gray-400 text-limited">{{$t("wishlist.updated_less_than_a_day_ago")}}</span> -->
                    </div>
                    <main-checkbox class="d-md-flex ms-auto ms-md-0" isList disabled :id="`select-list-${list._id}`" :values="selectedLists" :value="list._id" />
                </div>
            </div>
            <main-button id="save" :title="`wishlist.${!loading ? 'save' : 'saving'}`" class="ms-md-auto mt-3" @click="save" :class="{'many-lists': lists.length > 3 }" />
        </div>
        <edit-wishlist v-if="edit.value" @closeModal="closeEditModal" :listSelected="listSelected" style="z-index: 12;" />
    </div>
</template>
<script>
import MainButton from '../../../common/components/buttons/MainButton.vue'
import MainCheckbox from '../../../common/components/checkbox/MainCheckbox.vue'
import WrapperImage from '../../../common/components/images/WrapperImage.vue'

import EditWishlist from './modals/EditWishlist.vue'
import { mapGetters } from "vuex"

export default {
    components: { WrapperImage,  EditWishlist, MainCheckbox, MainButton },
    name: "AddToWishlist",
    computed: {
        ...mapGetters({
            lists: "wishlist/lists"
        }),
        height(){
            return this.viewPort('md') ? 91 : 100;
        }
    },
    props: {
        product: {
            type: Object
        },
    },
    data() {
        return {
            edit: { value: false, id: '' },
            listSelected: {},
            selectedLists: [],
            removeList: [],
            loading: false
        }
    },
    methods: {
        closeEditModal() {
            this.$emit('modalIndex', 3)
            this.edit.value = false;
        },
        editList(evt, list) {
            this.$emit('modalIndex', 1)
            this.edit = { id: list._id, value: true }
            this.listSelected = list
        },
        getWishListName(wishList_id) {
            if (wishList_id) return this.lists[this.lists.findIndex(({_id}) => _id === wishList_id)].name
        },
        productInWishList(product, wishlist){
            const list = this.lists[this.lists.findIndex(({_id}) => _id === wishlist)];
            return list.products.findIndex(({code}) => code === product) !== -1;
        },
        days(data){
            var date1 = this.moment().format('MM-DD-YYYY');
            var date2 = this.moment(data.slice(0,10));
            let time = date2.diff(date1, 'days');
            return time;
        },
        selectList(evt, id) {
            if (evt.target.id === 'edit-list' ) return;
            const select_index = this.selectedLists.findIndex(_id => _id === id);
            const remove_index = this.removeList.findIndex(_id => _id === id);
            if (select_index >= 0) { //if we already selected that id
                this.removeList.push(id);
                this.selectedLists.splice(select_index, 1);
            }else if (remove_index >= 0) { //if we already removed that id
                this.selectedLists.push(id);
                this.removeList.splice(remove_index, 1);
            }else { // if nothing has been done, just add it
                this.selectedLists.push(id)
            }
        },
        async save(){
            try {
                this.loading = true;
                let selectedNow = [];
                let undo = [];
                for (const list_id of this.selectedLists) {
                    if (!this.productInWishList(this.product.codarticulo, list_id)) {
                        const name = this.getWishListName(list_id);
                        selectedNow.push(list_id);
                        undo.push({list_id, name, product_code: this.product.codarticulo, action: 'to-remove'});
                        await this.$store.dispatch('wishlist/addProductToList', {list_id, name, product_code: this.product.codarticulo, product_note: ''});
                    }
                }
                for (const list_id of this.removeList) {
                    const name = this.getWishListName(list_id);
                    if (this.productInWishList(this.product.codarticulo, list_id)) {
                        //In case we don't have any more products we cannot simply add them back so...
                        const moreProductsLeft = this.lists[this.lists.findIndex(({_id}) => _id === list_id)].products.filter(({code}) => code !== this.product.codarticulo).length;
                        //The name is only needed here because this request modifies the wishlist fully
                        if (moreProductsLeft) undo.push({list_id, name, product_code: this.product.codarticulo, action: 'to-add'}); //the wishlist will not be deleted, so we could add them back otherwise, we would need to create them again
                        await this.$store.dispatch('wishlist/removeProductFromList', {list_id, name, product_code: this.product.codarticulo});
                    }
                }
                this.selectedLists = [];
                this.removeList = [];
                this.$store.commit("wishlist/SET_CREATED_LIST", undo);
                const route = selectedNow.length > 1 ? '/account/wishlist':`/account/wishlist/${selectedNow[0]}`
                //Tiene que cerrar antes de darle a guardar porque se tiene que borrar el estado.
                if (selectedNow.length > 0 ) {
                    this.$emit("addedToWishLists", {
                            name: this.getWishListName(selectedNow[0]),
                            many: selectedNow,
                            action: "wishlist-modified",
                            route,
                        });
                }else {
                    this.$store.commit("wishlist/SET_STATE_MODAL", false);
                }
            } catch (error) {
                let notify = {
                    show: true,
                    type: 'danger',
                    text: 'common.notification.unexpected_error',
                }
                this.$store.commit("common/SET_SHORT_NOTIFY", notify);
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        InFavorites(product) {
            for(let list in this.lists){
                for (let product_ in list.products) {
                    if(product_.code === product.codarticulo) {
                        return true;
                    }
                }
            }
            return false;
        },
        fillSelected(){
            this.lists.map((list) => {
                list.products.map((product) => {
                    if (product.code === this.product.codarticulo){
                        this.selectedLists.push(list._id);
                    }
                });
            });
        }
    },
    created(){
        this.fillSelected();
    }
}
</script>

<style scoped>
    .select-list {
        border-bottom: 1px solid var(--gray-100);
        padding: 15px;
    }
    .list-info {
        width: 170px;
        max-width: none;
    }
    .responsive-list {
        max-height: 370px;
        overflow: auto;
    }
    .main-button {
        border-radius: 7px;
    }
    #edit-list:active svg {
        fill: var(--blue-600);
    }
    @media (min-width: 576px) {
        .responsive-list {
            padding-right: 30px;
            overflow: auto;
        }
        .responsive-list.many-lists { /* This needs to go right after the wishlist */
            padding-right: 15px;
        }
        .main-button {
            margin-right: 30px;
        }
        .main-button.many-lists {
            margin-right: 25px;
        }
    }
    @media (max-width: 767px) {
        button {
            padding-inline: 55px;
            margin-inline: auto !important;
        }
    }
    @media (max-width: 575px) {
        .list-info {  max-width: 138px; }
    }
</style>
