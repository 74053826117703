// handling general usage of javascript viewports.
export default  {
    // called by Vue.use(FirstPlugin)
    install(Vue) { // create a mixin
        Vue.mixin({
            data() {
                return {
                    view_port: 'mobile', //(debug) :key="view_port" on the father div is used for debugging purposes only and has to be removed after changes in any component are finished
                };
            },
            methods: {
                async addHandler(){
                    await this.$nextTick(() => {
                        window.addEventListener('resize', this.handleResize);
                    });
                },
                removeHandler(){
                    window.removeEventListener('resize', this.handleResize); 
                },
                handleResize(){
                    this.view_port = this.viewPort();
                },
                viewPort(port = 'default', media = { max: false }, debug_){
                    const view = {
                        xxl: '1400px',
                        xl: '1200px',
                        lg: '992px',
                        md: '768px',
                        sm: '576px',
                        mobile: '0px',
                    }
                    const view_keys = Object.keys(view);
                    const media_type = media.max ? 'max':'min';
                    let viewPort;
                    const debug = (condition) => {
                        if (!debug_) return;
                        console.log(condition)
                    }
                    if (port === 'default') { //default returns the viewport
                        debug('default-usage');
                        for (let i = 0; i < view_keys.length; i++) {
                            let display_size = view[view_keys[i]];
                            if (window.matchMedia(`(min-width: ${display_size})`).matches) {
                                viewPort = view_keys[i];
                                break;
                            }
                        }
                    }
                    if (typeof(port) === 'string' && port !== 'default') { // if we pass a string it will tell us if said viewport is valid
                        debug('string-usage');
                        let display_size = view[port];
                        if (media.max) display_size = display_size.slice(0, display_size.indexOf('p')) * 1 - 1 +'px' // if we area dealing with max-media we reduce 1 pixel
                        if (view_keys.indexOf(port) === -1) throw 'viewport not valid';
                        viewPort = window.matchMedia(`(${media_type}-width: ${display_size})`).matches;
                    }
                    if (typeof(port) === 'object') {
                        debug('object-usage');
                        if (port.from == 'undefined' || port.to == 'undefined') throw 'viewport options not valid';
                        viewPort = window.matchMedia(`(min-width: ${view[port.from]}) and (max-width: ${view[port.to]})`).matches;
                    }
                    if (viewPort == undefined || viewPort == null) throw 'viewport not defined'; 
                    debug(viewPort)
                    return viewPort;
                }
            },
            // mounted() {
            //     this.$nextTick(() => {
            //         window.addEventListener('resize', this.handleResize);
            //     })
            // },
            // beforeDestroy() {
            //     window.removeEventListener('resize', this.handleResize); 
            // },
        });
    },
};