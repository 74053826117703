const Module = () => import("./Module.vue");
const Checkout = () => import("./view/Checkout.vue");
const DeliveryDetails = () => import("./view/DeliveryDetails.vue")

const moduleRoute = {
  path: "/checkout",
  component: Module,
  children: [
    {
      path: "/",
      component: Checkout,
    },
    {
      path: "delivery-details",
      component: DeliveryDetails,
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
