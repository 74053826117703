<template>
  <div id="app">
    <header
      v-if="$route.path != '/checkout' && $route.path != '/checkout/delivery-details' && $route.path != '/auth/request' && !hideHeader">
      <template v-if="!removeNbar">
        <main-navbar />
        <secondary-navbar />
        <MainCategoryNavBar v-if="$route.path == '/' && !mobile && configurations.general.web_page.nav_category" class="d-none d-md-block"/>
      </template>
    </header>
    <main style="min-height: 41.5vh;">
      <!-- loading component for request -->
      <main-loading v-if="loading" />
      <router-view />
      <create-wish-list v-if="openModal || notify.show" />
      <login v-if="stateModal && quickView && !mobile" @closeModalLogin="$store.commit('auth/OPEN_MODAL', false)" />
      <short-notify v-if="shortNotify.show" />
      <connection-error v-if="error.show" @closeModal="$store.commit('common/SHOW_MODAL_ERROR', false)" />
      <!-- <error-404 v-if="$route.matched.length == []"/> -->
      <page-disable v-if="disableModal && !mobile" @closeModal="$store.commit('common/SET_DISABLE_MODAL', false)" />
      <!-- <tour/> -->
      <options-modal v-if="false" />
      <!-- <quotation-created :quotationID="quoteCreated.quotation" @closeModal="quoteCreated.show = false" v-if="quoteCreated.show"/> -->
      <cookies v-if="verifyUSTECH() && cookiesAccepted == null" />
      <VerifyNumber :user="user" v-if="stateModalNumber" @successVerify="$store.commit('auth/OPEN_MODAL_NUMBER', false)"/>
    </main>
    <the-footer
      v-if="$route.path != '/checkout' && $route.path != '/checkout/delivery-details' && $route.path != '/auth/request' && !mobile" />
  </div>
</template>
<script>
import MainLoading from './common/components/loading/MainLoading.vue'
import CreateWishList from './modules/wishlist/components/modals/CreateWishList.vue'
import ShortNotify from './common/components/notifications/ShortNotify.vue'
import ConnectionError from './common/components/modals/ConnectionError.vue'
import PageDisable from './common/components/modals/PageDisable.vue'
import OptionsModal from './common/components/tour/OptionsModal.vue'
import Cookies from './modules/auth/components/Cookies.vue'
// import error404 from './common/components/404/404.vue'
import Login from './modules/auth/components/Login.vue'

import TheFooter from './modules/layout/components/TheFooter.vue'

import { getData } from './common/helpers/get-data'
import { mapGetters } from "vuex"


// IMPORT STRIPE AND SET
import { StripePlugin } from '@vue-stripe/vue-stripe';

import Vue from "vue";
import VerifyNumber from './modules/auth/components/VerifyNumber.vue'

export default {
  name: 'App',
  metaInfo() {
    return {
      title: process.env.VUE_APP_VIDVIE == 2 ? 'VIDVIE' : this.configurations.general.web_page.title_head,
      link: [
        {
          rel: 'icon',
          href: process.env.VUE_APP_VIDVIE == 0 ? 'logo.ico' : 'vidvie.ico'
        }
      ],
    }
  },
  components: {
    MainNavbar: () => import("@/modules/layout/components/navbar/MainNavbar"),
    TheFooter,
    SecondaryNavbar: () => import("@/modules/layout/components/navbar/SecondaryNavbar.vue"),
    MainLoading,
    CreateWishList,
    ShortNotify,
    ConnectionError,
    PageDisable,
    OptionsModal,
    Cookies,
    Login,
    VerifyNumber,
    MainCategoryNavBar: () => import("@/modules/layout/components/navbar/MainCategoryNavBar"),
},
  data() {
    return {
      connectionError: false,
      theme: '',
      cookiesAccepted: false
    }
  },
  mixins: [getData],
  computed: {
    ...mapGetters({
      loading: "common/loading",
      user: "auth/userData",
      shortNotify: "common/shortNotify",
      isLogged: "auth/isLogged",
      branches: "branches/branches",
      branchSelected: "branches/branchSelected",
      openModal: "wishlist/openModal",
      notify: "common/notify",
      error: "common/error",
      disableModal: "common/disableModal",
      lists: "wishlist/lists",
      mobile: 'common/mobile',
      stateModalNumber: "auth/stateModalNumber",
      stateModal: "auth/stateModal",
      quickView: "products/quickView",
      employee: 'auth/employee',
      vidvie: 'home/vidvie',
      configurations: "common/configurations",
      bannerMain: 'home/bannerMain',
      getProducts: "cart/getProducts",
      singleProductsView: 'products/singleProductsView'

    }),
    hideHeader() {
      return this.mobile && (this.$route.path == '/account/create-address')
    },
    removeNbar() {
      return this.conditionRemove()
    },
    routerPath() {
      return this.router?.path
    }
  },
  methods: {
    reload() {
      this.connectionError = false
      this.getData()
    },
    bootIntercom() {
      this.$intercom.boot();
    },
    conditionRemove() {
      if (this.mobile) {
        return this.$route.path != '/' && this.$route.path != '/shop' && this.$route.path != '/products/' + this.singleProductsView
      }
    },
  },
  watch: {
    async routerPath() {
      if (this.$route.path == '/checkout/delivery-details') {
        await this.getData()
      }
    },
    '$route.query.display'(val) {
      if (val == 'mb') {
        this.$store.commit("common/SET_MOBILE_VALUE", true)
      }
    }
  },
  async created() {
    console.log("  __  __                 _         __  __            _ _ ");
    console.log(" |  \\/  |               | |       |  \\/  |          (_) |");
    console.log(" | \\  / |_   _ _ __   __| | ___   | \\  / | _____   ___| |");
    console.log(" | |\\/| | | | | '_ \\ / _` |/ _ \\  | |\\/| |/ _ \\ \\ / / | |");
    console.log(" | |  | | |_| | | | | (_| | (_) | | |  | | (_) \\ V /| | |");
    console.log(" |_|  |_|\\__,_|_| |_|\\__,_|\\___/  |_|  |_|\\___/ \\_/ |_|_|", '® MM Todos los derechos reservados');

    this.cookiesAccepted = localStorage.getItem("cookiesAccepted");

    if (this.$route.query.display == 'mb') {
      this.$store.commit("common/SET_MOBILE_VALUE", true)
    }
    await this.getData()

    // console.log(this.$route);
    if (this.$route.path != '/checkout') {
      this.$store.commit("checkout/RESET_STATE")
      this.$store.commit("checkout/RESET_STRIPE_ELEMENTS")
      this.$store.commit("checkout/SET_DELIVERY_QUOTATION", null)
    }
    if (this.mobile) {
      window.AddToCartEvent.postMessage(JSON.stringify(this.getProducts.length));
    }

    // IMPORT STRIPE AND SET

    if (this.configurations.payment_methods.stripe_backend_implementation.active) {
      Vue.use(StripePlugin, {
        pk: this.configurations.general.web_page.stripe_var,
        testMode: process.env.VUE_APP_STRIPE_MODE == '0', // Boolean. To override the insecure host warning
        // stripeAccount: process.env.STRIPE_ACCOUNT,
        // apiVersion: process.env.API_VERSION,
        // locale: process.env.LOCALE,
      });
    }
  },
  mounted() {
    const root = document.documentElement;
    if (this.vidvie) {
      root.style.setProperty('--main-red', '#e47219');
      root.style.setProperty('--light-main-red', '#EF883A');
    }
    if (process.env.VUE_APP_INTERCOM_APP_ID) {
      if (!this.mobile) {
        this.$intercom.shutdown();
        this.$intercom.once('ready', this.bootIntercom);
      }
    }

    // root.style.setProperty('--dark-main-red', appearanceSettings.dark_main_color);
    // root.style.setProperty('--light-main-red', appearanceSettings.light_main_color);
    // root.style.setProperty('--inactive-main-red', appearanceSettings.inactive_main_color);
    // root.style.setProperty('--dark-main-navy', appearanceSettings.dark_main_navy);
    // root.style.setProperty('--main-navy', appearanceSettings.main_navy_color);
    // root.style.setProperty('--blue-700', appearanceSettings.link_color);
  },
}


</script>
<style lang="scss">
// @import url("https://mundomovil-test.odoo.com/im_livechat/external_lib.css");
// @import url("./common/assets/styles/chat.css");
/* do not errase this comment for sass styles to get imported globally*/
</style>
