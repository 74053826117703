<template>
    <icon-base width="100%" :fill="color" viewBox="0 0 338.6 348.2">
        <g>
            <g>
                <defs>
                    <path id="XMLID_1_" d="M320.1,348.2H18.5C8.3,348.2,0,339.9,0,329.7V18.5C0,8.3,8.3,0,18.5,0h301.6c10.2,0,18.5,8.3,18.5,18.5     v311.2C338.6,339.9,330.3,348.2,320.1,348.2z"/>
                </defs>
                <use xlink:href="#XMLID_1_" style="overflow:visible;fill:#F5C99D;"/>
                <clipPath id="XMLID_3_">
                    <use xlink:href="#XMLID_1_" style="overflow:visible;"/>
                </clipPath>
                <ellipse id="Ellipse_500_1_" class="st0" cx="4.4" cy="-49.8" rx="137.4" ry="137.4"/>
                <ellipse id="Ellipse_501_1_" class="st0" cx="4.4" cy="-49.8" rx="96.8" ry="96.8"/>
                <ellipse id="Ellipse_498_1_" class="st1" cx="383.1" cy="371.1" rx="133.2" ry="133.2"/>
                <ellipse id="Ellipse_499_1_" class="st1" cx="383.4" cy="370.4" rx="88.8" ry="88.8"/>
            </g>
            <g>
                <g>
                    <defs>
                        <rect id="SVGID_1_" x="117.1" y="222.3" width="160" height="135.3"/>
                    </defs>
                    <clipPath id="SVGID_2_">
                        <use xlink:href="#SVGID_1_" style="overflow:visible;"/>
                    </clipPath>
                </g>
                <g>
                    <defs>
                        <rect id="SVGID_3_" x="4.4" y="285.1" width="180.8" height="168.4"/>
                    </defs>
                    <clipPath id="SVGID_4_">
                        <use xlink:href="#SVGID_3_" style="overflow:visible;"/>
                    </clipPath>
                </g>
                <g>
                    <defs>
                        <rect id="SVGID_5_" x="175.9" y="324.9" width="158.2" height="155.1"/>
                    </defs>
                    <clipPath id="SVGID_6_">
                        <use xlink:href="#SVGID_5_" style="overflow:visible;"/>
                    </clipPath>
                </g>
            </g>
            <g class="st2">
                <g class="st3">
                    <text transform="matrix(1 0 0 1 23.6411 144.1735)" class="st4 st5 st6">Speakers</text>
                </g>
                <g class="st7">
                    <text transform="matrix(1 0 0 1 23.6411 215.8559)" class="st4 st5 st6">Speakers</text>
                </g>
                <g class="st8">
                    <text transform="matrix(1 0 0 1 23.6406 286.5375)" class="st4 st5 st6">Speakers</text>
                </g>
            </g>
        </g>
        <g>
            <text transform="matrix(1 0 0 1 23.6411 73.4916)" class="st9 st5 st6">Speakers</text>
        </g>
        <text transform="matrix(1 0 0 1 89.1745 273.8485)" class="st10 st11 st12">Get up to 40% off discount!</text>
        <text transform="matrix(1 0 0 1 51.4831 252.9357)" class="st13 st5 st14">Wireless Speakers VIDVIE </text>
        <g id="l_1_" transform="translate(-246.751 -3014)">
            <image href="../../../assets/images/advertisements/ads-1/img-1.webp" height="411" width="411" transform="matrix(0.4883 0 0 0.4883 322.6109 3050.5239)"/>
        </g>
        <g id="Component_299_1_1_" transform="translate(69.249 533)">
            <path id="Rectangle_1714_1_" class="st15" d="M48.3-236.6h103.4c8.5,0,15.5,6.9,15.5,15.5l0,0c0,8.5-6.9,15.5-15.5,15.5H48.3   c-8.5,0-15.5-6.9-15.5-15.5l0,0C32.9-229.7,39.8-236.6,48.3-236.6z"/>
            <text transform="matrix(1 0 0 1 71.0855 -215.7987)" class="st16 st17 st18">Buy Now</text>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'Ads01',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: ''
    },
    
}
}
</script>

<style scoped>
	.st0{opacity:0.2;clip-path:url(#XMLID_3_);fill:#D88D48;enable-background:new    ;}
	.st1{opacity:0.5;clip-path:url(#XMLID_3_);fill:#D88D48;enable-background:new    ;}
	.st2{opacity:0.5;}
	.st3{opacity:0.2;}
	.st4{fill:none;stroke:#B47A4C;stroke-miterlimit:10;}
	.st5{font-family:'Myriad Pro Bold';}
	.st6{font-size:74px;font-family: 'Myriad Pro Bold';}
	.st7{opacity:0.15;}
	.st8{opacity:0.1;}
	.st9{fill:#B47A4C;}
	.st10{fill:#964F14;}
	.st11{font-family:'Myriad Pro Regular';}
	.st12{font-size:15.7531px;}
	.st13{fill:#212E3D;}
	.st14{font-size:22.103px;}
	.st15{opacity:0.9;fill:#FFFFFF;enable-background:new    ;}
	.st16{fill:#D88D48;}
	.st17{font-family:'Myriad Pro Bold';}
	.st18{font-size:15.18px;}
	.st19{fill:#E0EBC1;}
	.st20{clip-path:url(#SVGID_8_);}
	.st21{fill:#F4D65B;}
	.st22{fill:#BED37D;}
	.st23{fill:none;stroke:#212E3D;stroke-miterlimit:10;}
	.st24{fill:none;stroke:#212E3D;stroke-linecap:round;stroke-miterlimit:10;}
	.st25{opacity:0.54;}
	.st26{fill:none;stroke:#B5C66D;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
	.st27{fill:#FCE172;}
	.st28{opacity:0.6;clip-path:url(#SVGID_12_);fill:#F7D752;enable-background:new    ;}
	.st29{opacity:0.2;clip-path:url(#SVGID_12_);fill:#E0BF34;enable-background:new    ;}
	.st30{clip-path:url(#SVGID_12_);}
	.st31{opacity:0.6;fill:none;stroke:#EACA42;stroke-width:2.0002;stroke-miterlimit:10.0012;enable-background:new    ;}
	.st32{fill:none;}
	.st33{opacity:0.6;fill:none;stroke:#EACA42;stroke-width:2;stroke-miterlimit:10;enable-background:new    ;}
	.st34{opacity:0.6;}
	.st35{fill:#C1A11E;}
	.st36{opacity:0.18;clip-path:url(#Rectangle_1715_1_);fill:#A7B1C6;enable-background:new    ;}
	.st37{clip-path:url(#SVGID_14_);}
	.st38{opacity:0.18;fill:#C1A73D;enable-background:new    ;}
	.st39{opacity:0.8;}
	.st40{fill:none;stroke:#45505E;stroke-linecap:round;stroke-linejoin:round;}
</style>