<template>
    <icon-base width="100%" viewBox="0 0 364 162">
        <defs>
            <clipPath id="id-speaker-a">
            <rect class="a" width="364" height="162" rx="20" transform="translate(0 -27)" />
            </clipPath>
            <pattern id="speakers" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 1080 1080" >
                <image href="../speakers.webp" height="1080" width="1080"/>
            </pattern>
        </defs>
        <g transform="translate(-13 -451)">
            <rect class="b" width="364" height="135" rx="20" transform="translate(13 478)" />
            <g class="c" transform="translate(13 478)">
                <ellipse class="d" cx="205" cy="105" rx="205" ry="105" transform="translate(-17 24)" />
                <rect class="e" width="184" height="185" transform="translate(0 -16)" />
            </g>
            <text class="f" transform="translate(237 564)">
            <tspan x="-82.002" y="0">Bocinas</tspan>
            </text>
        </g>
    </icon-base>
</template>

<script>
export default {
    name: 'Speakers',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
}
</script>

<style scoped>
.a {fill: #ff9a9a;}
.b {fill: #cce1a3;}
.c {clip-path: url(#id-speaker-a);}
.d,.f {fill: #fff;}
.d {opacity: 0.27;}
.e {fill: url(#speakers);}
.f {
    font-size: 44px;
    font-family: 'Myriad Pro Bold';
    font-weight: 600;
}
</style>