import { http } from "@/common/services/node-service.js";
import { parseResponse } from "@/common/helpers/responses/parser.js";

import _  from "lodash"
export class CartService {
    async getAllMyCarts(){
        try {
            const cart = await http.get('carts');
            const res = parseResponse(cart.data);
            return res.data;
        }catch (e){
            console.log(e);
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }

    }
    async updateMyCart(products, codplace){
        try {
            const cart = await http.post('carts/'+codplace, products);
            const res = parseResponse(cart.data);
            return res.data;
        }catch (e) {
            throw e.response ?? e
        }
    }
    async updateMyCartLocal(products, codplace){
        try {
            const cart = await http.post('cartsOffline/'+codplace, products);
            const res = parseResponse(cart.data);
            return res.data;
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }
    async syncMyCartLocal(products, codplace){
        try {
            const cart = await http.post('cartsBatch/'+codplace, {cart:products});
            const res = parseResponse(cart.data);
            return res.data;
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }
    async deleteMyCart(codplace){
        try {
            const cart = await http.delete('carts/'+codplace);
            const res = parseResponse(cart.data);
            return res.data;
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }
    async deleteProduct(product, codplace){
        try {
            const cart = await http.post('carts/'+codplace, product);
            const res = parseResponse(cart.data);
            return res.data;
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }
    debounceAddProductsToMyCart =  _.debounce(async (products) =>{
        await  this.updateMyCart(products);
    }, 2000);

    async getMyCart(codplace){
        try {
            const cart = await http.get('carts/'+ codplace);
            const res = parseResponse(cart.data);
            return res.data;
        }catch (e) {
            console.log(e);
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }
    async createQuotation(codplace, token){
        try {
            const cart = await http.post('quotation/'+codplace, {token: token});
            return cart
        }catch (e) {
            console.log(e)
            throw e
        }
    }
}