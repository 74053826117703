<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 14 18.908">
        <path id="Path_439" data-name="Path 439" d="M66.514,179.615h-.761v-2.683a5.149,5.149,0,0,0-10.3,0v2.683h-.762a1.089,1.089,0,0,0-1.089,1.091v8.9a1.09,1.09,0,0,0,1.089,1.091H66.514A1.091,1.091,0,0,0,67.6,189.6v-8.9a1.09,1.09,0,0,0-1.09-1.091m-4.75,7.812H59.442l.416-1.98a1.395,1.395,0,1,1,1.49,0Zm2.151-7.812H57.292v-2.683a3.312,3.312,0,1,1,6.624,0Z" transform="translate(-53.604 -171.784)"/>
    </icon-base>
</template>

<script>
export default {
name: 'iconPassword',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>

</style>
