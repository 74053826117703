import { CartService } from "@/modules/cart/services/cart-service";
const service = new CartService();

export default {
    getMyCart({rootGetters, dispatch}){
        try {
            const branch = rootGetters['branches/branchSelected'];
            dispatch('getCartByBranch', {codplace:branch.codplace}); 
        } catch (error) {
            console.log(error)
        }
    },
    async setProducts({commit}, products) {
        commit("SET_PRODUCTS", products);
        let quantities = products.map(({...product}) => {return {code: product.cod_articulo, quantity: product.quantity}})
        commit("SET_PRODUCTS_WITH_QUANTITIES", quantities);
    },
    async syncCarts({commit, rootGetters, dispatch}){
        const branches =  rootGetters['branches/branches'];
            try {
                for (const branch of branches){
                    let products = JSON.parse(localStorage.getItem('shopping_'+ branch.codplace));
                    if(products){
                        const response = await service.syncMyCartLocal(products, rootGetters['branches/branchSelected'].codplace);
                        commit("SET_CART", response)
                        dispatch('setProducts', response.products)
                    }
                    localStorage.removeItem('shopping_' + branch.codplace)
                }
            } catch (error) {
                console.log(error)
            }
        },
    async getCartByBranch({commit, rootGetters, state, dispatch}, {codplace}) {
        try {
            commit("SET_LOADING_CART", true);
            let cart = []
            if(rootGetters["auth/isLogged"]){
                cart = await service.getMyCart(codplace);
                commit("SET_CART", cart)
                dispatch('setProducts', cart.products)
            }else{
                cart = localStorage.getItem("shopping_" + codplace)
                if(cart)
                dispatch('setProducts', JSON.parse(cart))
                
                let amount = 0
                for (let i = 0; i < state.products.length; i++) {
                    const element = state.products[i];
                    amount += element.total_amount                
                }
                let cart = {
                    total_amount: amount
                }
                commit("SET_CART", cart)
            }
        } catch (e) {
            console.log(e)
            throw e
        } finally {
            commit("SET_LOADING_CART", false);
        }
    },
    async clearCart({commit, rootGetters}) {
        commit("CLEAR_CART")
        if (rootGetters['auth/isLogged']) {
            await service.deleteMyCart(rootGetters['branches/branchSelected'].codplace);
        } else {
            localStorage.removeItem('shopping_' + rootGetters['branches/branchSelected']?.codplace)
        }

    },
    async removeProduct({commit, rootGetters, state}, product) {
        commit("REMOVE_PRODUCT", product.cod_articulo)
        const selectedQuotation = rootGetters['account/selectedQuotation']
        if(selectedQuotation){
            commit('account/DELETE_PRODUCT_FROM_QUOTATION', {
                id: selectedQuotation, 
                product: product.cod_articulo, 
            }, {root: true})
        }
        if (rootGetters['auth/isLogged']) {
            const response = await service.deleteProduct(product, rootGetters['branches/branchSelected'].codplace);
            commit("SET_CART", response)
        } else {
            let amount = 0
            for (let i = 0; i < state.products.length; i++) {
                const element = state.products[i];
                amount += element.total_amount                
            }
            let cart = {
                total_amount: amount
            }
            commit("SET_CART", cart)
            localStorage.setItem('shopping_' + rootGetters['branches/branchSelected'].codplace, JSON.stringify(state.products));
        }
    },
    async updateQuantity({commit, rootGetters, state, dispatch}, {quantity, product}) {
        if (rootGetters['auth/isLogged']) {
          // actualiza el carrito en product service y verifica todo.
            try {
                const response = await service.updateMyCart({cod_articulo: product.cod_articulo, quantity: product.quantity}, rootGetters['branches/branchSelected'].codplace);
                commit("SET_CART", response)
                dispatch('setProducts', response.products)
                commit("SET_LOADING_CART", false)
            } catch (error) {
                console.log(error);
            }
          // <---------------------> finish
        } else {
            commit("UPDATE_QUANTITY", {codarticulo: product.cod_articulo, quantity, totalAmount: product.product.price * quantity})
            await service.updateMyCartLocal(product, rootGetters['branches/branchSelected'].codplace);
            let amount = 0
            for (let i = 0; i < state.products.length; i++) {
                const element = state.products[i];
                amount += element.total_amount                
            }
            let cart = { total_amount: amount }
            commit("SET_CART", cart)
            dispatch('setProducts', state.products)
            localStorage.setItem('shopping_' + rootGetters['branches/branchSelected'].codplace, JSON.stringify(state.products));
        }
    },
    async saveNoteByProduct({commit, rootGetters, state}, {product, note, quantity}){
        commit("ADD_NOTE_TO_PRODUCT", {codarticulo:product.codarticulo, note})
        let shortProduct = {
            cod_articulo: product.codarticulo,
            note,
            quantity
        }
        if (rootGetters['auth/isLogged']) {
            await service.updateMyCart(shortProduct, rootGetters['branches/branchSelected'].codplace);
        } else {
            localStorage.setItem('shopping_' + rootGetters['branches/branchSelected'].codplace, JSON.stringify(state.products));
        }
    },
    async setCart({state}, {codplace}) {
        if (state.products)
        {
            await service.updateMyCart(state.products, codplace);
        }
    },
    async createQuotation({commit, rootGetters}, token){
        let codplace = rootGetters['branches/branchSelected'].codplace;
        try {
            const response = await service.createQuotation(codplace, token);
            commit('CLEAR_CART')
            return response.data.data
        } catch (error) {
            console.log(error);
            throw error
        }
    }
};
