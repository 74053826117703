<template>
<div>
    <hr class="home-section"/>
    <section class="mt-0 home-section">
        <div class="section-title-container d-flex justify-content-between ">
            <h3 class="active-title px-0">{{$t('home.our_recommendations')}}</h3>
            <div class="swiper-controls d-none d-sm-flex position-relative px-lg-0">       
                <div class="swiper-button-prev" slot="button-prev" style="margin-right: 15px;" >
                    <icon-arrow-circle :size="50" class="fill-gray-50" />
                </div>
                <div class="swiper-button-next" slot="button-next">
                    <icon-arrow-circle :size="50" class="fill-gray-50" style="transform: rotate(180deg);"/>
                </div>
            </div>
        </div>
        <div v-if="viewPort('sm')" class="swiper-products-container position-relative">
            <swiper class="swiper" ref="products" :id="options.name" :options="options" :pagination="{ clickable: true }">
                <swiper-slide v-for="product in products" :key="product.codarticulo">
                    <div class="card-father-container d-flex" @mouseover="pauseSwiper" @mouseleave="resumeSwiper">
                        <card-product class="slider-card-product" :product="product" :skeleton="loading" @showQuickView="showQuickView" @showShare="showShare"/>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination" />
            </swiper>
        </div>
        <div v-else class="row m-0">
            <div class="col-6 mb-4" v-for="product in products" :key="product.codarticulo">
                <card-product-mobile :product="product"/>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center">
                <a href="#" @click.prevent="getProducts" v-if="!loading">
                    {{$t("product.show_more")}} <icon-arrow :size="12" style="transform:rotate(90deg)" color="var(--blue-600)"/>
                </a>
                <half-circle-spinner :animation-duration="1000" :size="30" color="var(--main-red)" v-else/>
            </div>
        </div>
        <quick-view v-if="quickView.value" :product="quickView.product" @closeModal="quickView.value = false"/>
        <share v-if="share.value" @closeModal="share.value = false" :product="share.product"/>
    </section>
</div>
</template>
<script>
import { ProductService } from '@/modules/products/services/product-service.js';
const service = new ProductService();

import { mapGetters } from 'vuex';
import iconArrowCircle from '../../../common/svg/iconArrowCircle.vue';
import CardProduct from '../../products/components/CardProduct.vue';
import QuickView from '../../products/components/quick-view/QuickView.vue';
import Share from '../../products/components/modals/Share.vue';
import CardProductMobile from '../../products/components/CardProductMobile.vue';
import IconArrow from '../../../common/svg/iconArrow.vue';
import { HalfCircleSpinner } from 'epic-spinners'

export default {
    name: 'RecommendedProducts',
    components: { iconArrowCircle, CardProduct, QuickView, Share, CardProductMobile, IconArrow, HalfCircleSpinner },
    
    data() {
        return {
            products: [], 
            loading: false,  
            options: {
                name: 'recommended-products', 
                
                    skeleton: true,
                    navigation: {
                        nextEl: '.swiper-button-next', 
                        prevEl: '.swiper-button-prev'
                    },
                    noSwiping: true,
                    preloadImages: true,
                    lazy: true,     
                    pagination: {
                        el: '.swiper-pagination',
                        dynamicBullets: true,
                        clickable: true
                    },
                    initialSlide: 0,
                    grabCursor: true,
                    slidesPerView: 4,
                    slidesPerColumnFill: 'row',
                    slidesPerColumn: 1,
                    preventClicks: true,
                    breakpoints: {
                        0: {
                            slidesPerView: 1.3,
                            spaceBetween: 0,
                            slidesPerColumn: 1,
                            speed: 300
                        },
                        576: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                            slidesPerColumn: 1,
                            speed: 800,
                        },
                        // when window width is >= 768px
                        768: {
                            slidesPerView: 2,
                            slidesPerColumn: 1,
                            slidesPerGroup: 1,
                            spaceBetween: 20,
                            centeredSlides: false,
                        },
                        // when window width is >= 992px
                        992: {
                            slidesPerView: 3,
                            slidesPerGroup: 1,
                            spaceBetween: 40,
                        },
                        // when window width is >= 1200px
                        1200: {
                            slidesPerView: 4,
                            slidesPerGroup: 1,
                            spaceBetween: 10
                        },
                        1400: {
                            slidesPerView: 4,
                            slidesPerGroup: 1,
                            spaceBetween: 30
                        },
                },
            },
            quickView: { product: null, value: false},
            share: {product: null, value: false},
            shareProduct: null,
            page: 0
        }      
    },
    computed: {
        ...mapGetters({
            isLogged: "auth/isLogged",
            notify: "common/notify",
            branchSelected: "branches/branchSelected",
            user: "auth/userData",
        }),
        skeletonProducts(){
            let products = []
            for (let i = 0; i < 10; i++) {
                products.push({codarticulo: i})
            }
            return products;
        },
    },
    methods: {
        showQuickView({product, value}){
            this.quickView.product = product;
            this.quickView.value = value;
        },
        showShare(product){
            this.share.value = true
            this.share.product = product
        },
        pauseSwiper(){
            this.$refs.products.$swiper.autoplay.stop();
        },
        pauseAllSwipers(){
            this.$refs.products.$swiper.autoplay.stop();
        },
        resumeSwiper(){
            // this.$refs.products.$swiper.autoplay.start();
        },
        async getProducts(){
            let branch = this.branchSelected?.codplace || 1;
            this.page += 1
            try {
                this.loading = true;
                const newResponse = await service.getProducts({order_by: 'newest', category: 16, limit: 10, prices: [0, 100000], gbSelected: [], place: branch, page: this.page, brands_selected: ['VIDVIE']});
                for (let index = 0; index < newResponse.docs.length; index++) {
                    const product = newResponse.docs[index];
                    this.products.push(product);
                }
            } catch (e) {
                console.log(e);
            } finally{
                this.loading = false;
            }
        },
    },
    created(){
        this.getProducts()
    },
}
</script>

<style scoped>
.section-title-container {
    margin-bottom: 20px;
    justify-content: start;
}
.active-title {
    display: inline;
    font-size: calc(1.3rem + 0.6vw);
    max-width: 100%;
    font-weight: 700;
}
.active-title:after {
    content: '';
    position: relative;
    top: 5px !important;
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 3.125rem;
    background-color: var(--main-red);
}
.swiper-products-container {
    overflow: hidden;
    width: 100%;
}
.slider-card-product::v-deep.card-product {
    margin-top: 5px;
    box-shadow: 3px 6px 20px #7c879329;
    max-width: 275px;
}
.slider-card-product::v-deep.card-product:hover {
    transform: scale(1.05);
    box-shadow: none;
}
.swiper::v-deep .swiper-wrapper .card-father-container {
    padding-inline: 21px;
}
.swiper-button-prev, .swiper-button-next {
    position: relative;
    width: 40px;
    margin: 0;
    top: 0;
    left: 0;
    z-index: 7;
}
.swiper-button-prev {
    margin-right: 12px !important;
}
.swiper-button-next svg, .swiper-button-prev svg {
    transition: var(--transition-1);
}
.swiper-button-prev:hover svg, .swiper-button-next:hover svg {
    fill: var(--main-red) !important;
}
.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
    cursor: no-drop !important;
    pointer-events: all !important;
}
.swiper.swiper-container {
    padding-top: 24px;
    padding-bottom: 95px;
}
.swiper-pagination::v-deep .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
}
.swiper-pagination::v-deep :is(.swiper-pagination-bullet-active-prev-prev, .swiper-pagination-bullet-active-next-next){
    transform: scale(0.45);
}
@media (min-width: 576px) {
    .swiper::v-deep .swiper-wrapper .card-father-container {
        padding-inline: 15px;
    }
}
@media (min-width: 768px) {
    .swiper::v-deep .swiper-wrapper .card-father-container {
        padding-inline: 40px;
    }
    .products-navigation::v-deep > .nav-item > .nav-link {
        margin-right: 3rem !important;
    }
}
@media (min-width: 992px) {
    .active-title {
        font-size: 27px;
    }
    .swiper::v-deep .swiper-wrapper .card-father-container {
        padding-left: 18px;
        padding-right: 30px;
    }
    .swiper-products-container {
        width: 105%;
        left: -17px;
    }
}
@media (min-width: 1200px) {
    .swiper::v-deep .swiper-wrapper .card-father-container {
        padding-left: 25px;
    }
    .swiper-products-container {
        width: 105%;
        left: -25px;
    }
}
@media (min-width: 1400px) {
    .swiper::v-deep .swiper-wrapper .card-father-container {
        padding-left: 26px;
        padding-right: 40px;
    }
}
</style>