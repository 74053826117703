import { http } from "../../../common/services/web-backend";
import { parseResponse } from "../../../common/helpers/responses/parser";

export class AddressService {

    async getAddresses(){
        try {
            const addresses = await http.get('addresses');
            const res = parseResponse(addresses.data);
            return res.data
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }

    }

}
