<template>
    <div :key="verifyEmail">
        <div v-if="error" class="alert alert-danger color-light-main-red d-flex align-items-center border-0 py-2" role="alert">
            <icon-error color="var(--light-main-red)" class="me-2"/>
            {{ $t("auth.incorrect_code") }} 
            {{$t("auth.we_have_sent_a_new_confirmation_code")}}.
        </div>
        <alert-warning style="padding: 2px 15px" :size="20" :show="error !== null" :title="'auth.description_warning_verify_email'" :showCounter="false"/>
        <p class="subtitle-15 color-dark-gray-300"> 
            {{$t("auth.enter_the_code_sent_to_the_mail")}} <b class="color-main-navy">{{userAttributes.challengeParam.email}}</b> {{$t("auth.before_they_pass")}} <count-down/> min.
        </p>
        <form @submit.prevent="" autocomplete="off">
            <main-input title="auth.code" id="code" ref="code" class="mb-2" placeholder="auth.code_placeholder" @changeValue="(data)=>{code = data}" :readonly="readonly" @activeInput="readonly = false">
                <icon-code :size="15" color="var(--dark-gray-200)" />
            </main-input>
            <div class="d-flex justify-content-end">
                <main-button :title="'auth.save'" @click="verify"/>
            </div>
        </form>
        <verify-intranet v-if="modalIntranetMFA" :userAttributes="userAttributes" :user="{username: username, password: password}" @closeModal="$emit('closeModalLogin')" @incorrectCode="incorrectCode"/>
    </div>
</template>

<script>
import MainInput from '../../../common/components/inputs/MainInput.vue'
import { Auth } from 'aws-amplify';
import MainButton from '../../../common/components/buttons/MainButton.vue';
import CountDown from '../../../common/components/clock/CountDown.vue';
import AlertWarning from '../../../common/components/alerts/AlertWarning.vue';
import IconError from '../../../common/svg/iconError.vue';
import IconCode from '../../auth/assets/svg/iconCode.vue';
import VerifyIntranet from './VerifyIntranetMFA.vue';
import { AuthService } from "@/modules/auth/services/auth-service.js";
const authService = new AuthService();

export default {
    components: { 
        MainInput, 
        MainButton, 
        CountDown, 
        AlertWarning,
        IconError, 
        IconCode,
        VerifyIntranet,
    },
    name: "VerifyEmail",
    data() {
        return {
            code: '',
            error: false,
            verifyEmail: 0,
            readonly: true,
            modalIntranetMFA: false,
        }
    },
    props:{
        userAttributes:{
            type: Object
        },
        user:{
            type: Object
        }
    },
    methods:{
        async verify(){
            try {
                const mfaEmployee = await authService.getEmployeeMFA({username: this.user.username})
                if(mfaEmployee.mfa_activated){
                    await Auth.sendCustomChallengeAnswer(this.userAttributes, JSON.stringify({otp_email : this.code}));
                    this.modalIntranetMFA = true
                    return 
                }
                this.$store.commit("common/SET_STATE_LOADING", true)
                this.$refs.code.$v.$touch();
                if (this.$refs.code.$v.$invalid){
                    return
                }
                await Auth.sendCustomChallengeAnswer(this.userAttributes, JSON.stringify({otp_email : this.code}));
                await this.$store.dispatch('auth/login', {username: this.user.username, password: this.user.password});
                this.$emit("closeModal")
            } catch (error) {
                this.$emit("incorrectCode")
                this.error = error.message
            }finally{
                this.$store.commit("common/SET_STATE_LOADING", false)
            }
        }
    }
}
</script>

<style scoped>
</style>