<template>
<icon-base :width="100+'%'" :fill="color" viewBox="0 0 576.767 576.767">
    <ellipse id="Ellipse_465" data-name="Ellipse 465" cx="288.383" cy="288.383" rx="288.383" ry="288.383" fill="#f7d752"/>
</icon-base>
</template>

<script>
export default {
    name: 'BackgroundCircle',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>
