<template>
    <span class="countdown">
        {{ timeLeft }}
    </span>
</template>

<script>
export default {
    name:"CountDown",
    data() {
        return {
        selectedTime: 0,
        timeLeft: '00:00',
        endTime: '0',
        intervalTimer: null,
        times: [
            {
                sec: 3,
                display: '3s'
            },
            {
                sec: 600,
                display: ' 10m'
            },
            {
                sec: 1800,
                display: '30m'
            }
            ]
        }
    },
    methods: {
        setTime(seconds) {
            clearInterval(this.intervalTimer);
            this.timer(seconds);
        },
        timer(seconds) {
        const now = Date.now();
        const end = now + seconds * 1000;
        this.displayTimeLeft(seconds);

        this.selectedTime = seconds;
        // this.initialTime = seconds;
        this.displayEndTime(end);
        this.countdown(end);
        },
        countdown(end) {
        // this.initialTime = this.selectedTime;
        this.intervalTimer = setInterval(() => {
            const secondsLeft = Math.round((end - Date.now()) / 1000);

            if(secondsLeft === 0) {
            this.endTime = 0;
            }

            if(secondsLeft < 0) {
            clearInterval(this.intervalTimer);
            return;
            }
            this.displayTimeLeft(secondsLeft)
        }, 1000);
        },
        displayTimeLeft(secondsLeft) {
        const minutes = Math.floor((secondsLeft % 3600) / 60);
        const seconds = secondsLeft % 60;

        this.timeLeft = `${this.zeroPadded(minutes)}:${this.zeroPadded(seconds)}`;
        },
        displayEndTime(timestamp) {
        const end = new Date(timestamp);
        const hour = end.getHours();
        const minutes = end.getMinutes();

        this.endTime = `${this.hourConvert(hour)}:${this.zeroPadded(minutes)}`
        },
        zeroPadded(num) {
        // 4 --> 04
        return num < 10 ? `0${num}` : num;
        },
        hourConvert(hour) {
        // 15 --> 3
        return (hour % 12) || 12;
        }
    },
    created(){
        this.setTime(1800)
    }

}
</script>

<style scoped>
    .countdown{
        color: var(--blue-600);
        font-size: 14px;
    }
</style>