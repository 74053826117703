<template>
    <div>
        <main-modal fixedHeader @closeModal="$emit('closeModal')" class="quick-view" :class="{'employee-active': entityValidation}">
            <div class="row m-0">
                <div class="p-0 carousel position-relative" :class="[entityValidation ? 'col-md-6 carousel-for-employee' : 'col-md-5']">
                    <div class="d-flex justify-content-end mx-3 my-3 icon-favorite" @click="addToWishList(product)">
                        <icon-favourite :size="25" color="var(--gray-1001)" class="hover-main-red" :class="[checkInFavorites(product) ? 'fill-light-main-red' : '']"/>
                    </div>
                    <swiper :options="swiperOption">
                        <swiper-slide :key="index" v-for="(img, index) in product.imageGallery" class="d-flex align-items-center justify-content-center">
                            <div :class="[entityValidation ? 'container-img-with-existence' : 'container-img']">
                                <router-link :to="disabledClick">
                                    <image-product :source="img.picture_url" :styleObject="{'object-fit': entityValidation? 'contain' : 'cover', 
                                    height: entityValidation ?'60vh': ''}"/>
                                </router-link>
                            </div>
                        </swiper-slide>
                        <div class="swiper-button-prev mr-5" slot="button-prev">
                            <icon-arrow :size="30" style="margin-right: -12px; transform: rotate(180deg);" color="var(--gray-1001)"/>
                        </div>
                        <div class="swiper-button-next" slot="button-next">
                            <icon-arrow :size="30" style="margin-left: -12px" color="var(--gray-1001)"/>
                        </div>
                        <div class="swiper-pagination" slot="pagination" />
                    </swiper>
                </div>
                <div class="d-flex flex-column px-0 px-md-4 mt-4 mt-md-0 text-start" :class="[entityValidation ? 'col-md-6' : 'col-md-7']">
                    <div class="hover-product-title">
                        <router-link :to="disabledClick" class="subtitle-14 main-link d-none d-md-block">
                            {{$t("product.product_details")}}
                        </router-link>
                        <router-link :to="disabledClick" class="d-block title-18 mb-0 mb-md-1 container-description color-main-navy text-limited">
                            ({{ product.codarticulo }}) {{ product.description | textFormat }}
                        </router-link>
                    </div>
                    <star-rating v-if="!mobile && this.$route.path == '/cart'" :totalReviews="product.total_reviews" :starSize="19" :showRating="true" style="margin-top: -3px;" :readOnly="true" :rating="product.avgRating" :product_id="product.codarticulo"/>
                    <div class="mt-2 mt-sm-4">
                        <div class="" style="vertical-align: middle;">
                            <h6 class="d-flex align-sm-items-center subtitle-14 mb-0">
                                <span v-if="viewPort('sm')" class="me-1">
                                    {{$t("product.price")}}:
                                </span>
                                <div class="d-inline-flex flex-column flex-sm-row-reverse">
                                    <b v-if="product.special_price" class="color-main-red size-16">{{product.special_price | moneyFormat}}</b>
                                    <p class="me-2 mb-0 size-16 color-dark-main-navy weight-600" :class="{'special-price': product.special_price }"> 
                                        {{product.price | moneyFormat}}
                                    </p> 
                                </div>
                                <span v-if="product.special_price" class="color-dark-gray-300 weight-400 size-12 ms-2">
                                    {{ $t("product.valid_until.1") }}
                                    {{ product.quantity_required_special_price }}
                                    {{ $t("product.valid_until.2") }}
                                </span>
                            </h6>
                        </div>
                        <div class="d-flex mt-2 align-items-center">
                            <div class="subtitle-14 me-3 d-flex align-items-center">{{$t("product.color")}}: 
                                <color-picker class="ms-2"/>
                                <!-- <b class="ms-1 color-dark-gray-200 weight-500">{{product.color ? (product.color) : 'N/a' | textFormat}}</b> -->
                            </div>
                            <span class="subtitle-14">{{$t("product.brand")}}: <b class="ms-1 color-dark-gray-200 weight-500">{{product.brand ? (product.brand) : 'N/a' | textFormat}}</b></span>
                        </div>
                    </div>
                    <p v-if="!mobile && this.$route.path == '/cart'" class="size-14 color-dark-gray-200 weight-300 mt-3" style="line-height: 18px;">
                        <router-link :to="{ name: 'product-detail', params: { id: product._id }}" class="weight-500">{{$t('product.see_more')}}</router-link>
                    </p>
                    <div class="d-flex justify-content-between justify-content-md-start mt-2" v-if="!checkout">
                        <product-quantity-input :productQuantity="getQuantity(product)" :product="product" quick style="max-width: 100px" 
                        @deleteProduct="$emit('deleteConfirm', product)" class="mt-auto me-md-4" />
                        <main-button :id="'buy-now'" icon :title="$t('product.buy_now')" @click="buyNow(product)" :class="{'button-disabled' : product.existence == 0 || isLogged == false }"
                        v-if="!entityValidation">
                            <icon-elect :size="10" class="me-2" :color="'#fff'"/>
                        </main-button>
                    </div>
                    <div class="row mt-4" v-if="entityValidation">
                        <h6 class="mb-3 d-flex">
                            <icon-circle :size="7" class="me-2"/>
                            {{$t('product.existence_level_price')}}
                        </h6>
                        <div class="table-wrapper-scroll-y my-branch-scrollbar col-md-6 col-lg-7">
                            <table class="table text-center mb-0 size-14 rounded overflow-hidden">
                                <thead>
                                    <th></th>
                                    <th class="text-start">{{$t('product.Branch')}}</th>
                                    <th>{{$t('product.quantity')}}</th>
                                </thead>
                                <tbody>
                                    <tr v-for="branch in filterBranchesAvaible" :key="branch.pos_id">
                                        <td>
                                            <icon-check v-if="parseFloat(branch.existencia) > 0" color="var(--green-600)" :size="17" />
                                            <icon-close v-else color="var(--danger-700)" :size="16" />
                                        </td>
                                        <td class="text-start" :class="{'weight-600': branch.pos_id == branchSelected.codplace}">
                                            {{branch.description | textFormat}}
                                        </td>
                                        <td>{{99 > parseInt(branch.existencia) ? branch.existencia : '+99'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-md-6 col-lg-5">
                            <table class="table text-center mb-0 size-14 rounded overflow-hidden">
                                <thead>
                                    <th>{{$t('product.level')}}</th>
                                    <th>{{$t('product.price')}}</th>
                                    <th>{{$t('product.quantity')}}</th>
                                </thead>
                                <tbody>
                                    <tr v-for="level in orderPrices" :key="level.codnivelprecio">
                                        <td><b>{{level.codnivelprecio}}</b></td>
                                        <td>{{level.precio | moneyFormat}}</td>
                                        <td>{{level.cantidadprecio}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-wrapper-scroll-y my-custom-scrollbar col-md-6 col-lg-7">
                            <table class="table text-center mb-0 size-14 rounded overflow-hidden">
                                <thead>
                                    <th>{{$t('product.date')}}</th>
                                    <th>{{ $t('product.quantity') }}</th>
                                    <th>{{ $t('product.price') }}</th>
                                </thead>
                                <tbody>
                                    <tr v-for=" soldPrice in reqPrice " :key="soldPrice.id" >
                                        <td>{{soldPrice.creation}}</td>
                                        <td>{{soldPrice.quantity}}</td>
                                        <td>{{soldPrice.price | moneyFormat}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <icon-no-product class="iconStyle" v-if="reqPrice == false" :size="60"/>
                        </div>
                    </div>
                    <div class="social-networks d-flex align-items-center mt-3" :class="[entityValidation ? 'mt-md-auto' : 'mt-md-auto']">
                        <span>{{$t('product.share')}}</span>
                        <a @click.prevent="share(getBaseUrl + product._id)">
                            <div class="d-flex flex-column align-items-center justify-content-center">
                                <icon-instagram :size="36" class="icon"/>
                            </div>
                        </a>
                        <share-network
                        network="facebook"
                        :url="getBaseUrl + product._id"
                        :title="product.description + '.'"
                        description="">
                        <div class="d-flex flex-column align-items-center justify-content-center">
                            <icon-facebook :size="36" class="icon"/>
                        </div>
                    </share-network>
                    <share-network
                        network="WhatsApp"
                        :url="getBaseUrl + product._id"
                        :title="product.description + '.'"
                        description="">
                        <div class="d-flex flex-column align-items-center justify-content-center">
                            <icon-whatsapp :size="36" class="icon"/>
                        </div>
                    </share-network>
                    <share-network network="Email" :url="getBaseUrl + product._id" :title="product.description + '.'"
                    description="">
                        <div class="d-flex flex-column align-items-center justify-content-center">
                            <icon-email :size="36" class="icon"/>
                        </div>
                    </share-network>
                    <div class="d-flex flex-column align-items-center justify-content-center btn-action" :class="[hover ? 'active-hover' : '']" @mouseover="closeCopy = true" @mouseleave="closeCopy = false; copy = false" @click="copyFunction(getBaseUrl + product._id)">
                        <popup class="option-tooltip size-13 px-2 py-1" :style="{top: `-40px`, left: `-15px`, 'border-radius': `6px`, 'white-space': 'nowrap'}">
                            {{copy ? $t("product.copied") : $t("checkout.copy")}}
                        </popup>
                        <icon-copy :color="'var(--gray-1001)'" :size="20"/>
                    </div>
                    </div>
                </div>
            </div>
        </main-modal>
        <main-notify v-if="notify.show" class="quick-view-notify" />
    </div>
</template>

<script>
import MainModal from '../../../../common/components/modals/MainModal.vue'
import IconArrow from '../../../../common/svg/iconArrow.vue'
import IconFavourite from '../../../../common/svg/iconFavourite.vue'
import ImageProduct from '../Image.vue'
import ProductQuantityInput from '../ProductQuantityInput.vue'
import StarRating from '../rating/StarRating.vue'
import { ProductService } from '@/modules/products/services/product-service.js'
const service = new ProductService()

import {productMixin} from '../../helpers/mixin/product-mixin';
import MainButton from '../../../../common/components/buttons/MainButton.vue'
import IconElect from '../../assets/svg/iconElect.vue'
import IconInstagram from '../../assets/svg/iconInstagram.vue'
import IconFacebook from '../../assets/svg/iconFacebook.vue'
import IconWhatsapp from '../../assets/svg/iconWhatsapp.vue'
import IconEmail from '../../assets/svg/iconEmail.vue'
import { mapGetters } from 'vuex'
import MainNotify from '../../../../common/components/notifications/MainNotify.vue'
import ColorPicker from '../ColorPicker.vue'
import { cartMixin } from "../../../cart/helpers/mixins/cart-mixin";
import IconCheck from '../../../../common/svg/iconCheck.vue'
import IconClose from '../../../../common/svg/iconClose.vue'
import IconCircle from '../../../../common/svg/iconCircle.vue'
import IconNoProduct from './../../assets/svg/iconNoProduct.vue';
import { AlgoliaService } from '../../services/algolia-service'
// import IconOrders from '../../../account/assets/svg/iconOrders.vue'
import IconCopy from '../../../../common/svg/iconCopy.vue'
import Popup from '../../../../common/components/popup/Popup.vue'
import { http } from '../../../../common/services/web-backend'
const serv = new AlgoliaService();

export default {
    name: "QuickView",
    components:{
        MainModal,
        IconNoProduct,
        ImageProduct,
        IconFavourite,
        IconArrow,
        StarRating,
        ProductQuantityInput,
        MainButton,
        IconElect,
        IconInstagram,
        IconFacebook,
        IconWhatsapp,
        IconEmail,
        MainNotify,
        ColorPicker,
        IconClose,
        IconCheck,
        IconCircle,
        // IconOrders,
        IconCopy,
        Popup
    },
    props:{
        product:{
            type: Object
        },
        checkout:{
            type: Boolean,
            default: false
        },
    },
    mixins:[productMixin, cartMixin],
    data() {
        return {
        reqPrice: [],
        swiperOption: {
            slidesPerView: 1,
            slidesPerColumn: 1,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                dynamicBullets: true
            }
        },
        closeCopy: false,
        copy: false,
        hover: false
        }
    },
    computed:{
        // getSpecial(){
        //     if(!this.product.special_price){
        //         return 0
        //     }
        //     return this.product.special_price
        // },
        entityValidation(){
            return this.employee?.entity?.activated_employee
        },
        getBaseUrl(){
            var baseUrl = window.location.origin +'/products/'
            return baseUrl
        },
        ...mapGetters({
            notify: "common/notify",
            branches: "branches/branches",
            employee: 'auth/employee',
            employeeActiveCode: 'auth/employeeActiveCode',
            branchSelected: "branches/branchSelected",
            valueFilters: "products/valueFilters",
            isLogged: "auth/isLogged",
            configurations: "common/configurations",
            mobile: "common/mobile",
            userData: "auth/userData",
        }),
        disabledClick(){
            if(this.$route.path == '/cart' && this.mobile){
                console.log(this.$route.path == '/cart');
                return ''
            } else {
                return { name: 'product-detail', params: { id: this.product._id }}
            }
        },
        orderPrices(){
            const productPrices = this.product.prices;
            if(productPrices){
                let order = productPrices.sort((a, b)=> a.codnivelprecio - b.codnivelprecio)
                return order
            }else{
                return ''
            }
        },
        filterBranchesAvaible(){
            let branchesExistence = []
            this.branches.forEach(b=>{
            const validate = this.product.branchesAvailable.find(e=> e.pos_id == b.codplace);
                if(validate){
                    branchesExistence.push({city_name : b.city_name, description: b.description, 
                        existencia: validate.existencia, pos_id : validate.pos_id})
                }
            })
            return branchesExistence;
        }
    },
    methods:{
        async buyNow(product){
            if(this.userData.entity.created_from == "ECOMMERCE" && this.configurations.general.web_page.validate_phone_on_checkout){
                const data = await http.post("users/is-phone-number-validated");
                this.loading = false
                if(!data.data.data.is_phone_number_validated){
                    return this.$store.commit('auth/OPEN_MODAL_NUMBER', true)
                }
            }
            if(product.existence == 0) {
                let notify = {                  
                    contentHTML: `
                        <span class="size-14">
                            ${this.$t("cart.this_product_has_no_quantity")} <b>${this.$options.filters.textFormat(this.branchSelected.description)}</b> :(
                        </span>
                    `, type: 'warning', show: true 
                };
                this.$store.commit("common/SET_SHORT_NOTIFY", notify);
                return;
            }
            // Nuevo estructura para soportar el product service.
            let obj = {
                product: product,
                quantity: 1,
                total_amount: 0,
                cod_articulo: product.codarticulo,
                note: product.note
            }
            this.$store.commit('cart/CLEAR_CART')
            try {
                this.$store.commit("cart/ADD_PRODUCT", {product: obj, codarticulo: product.codarticulo, quantity: 1, note: product.note}); // await this.increment(product, true)
                this.$store.commit("cart/SET_CART", {total_amount: product.price, total_items_products: 1})

                await this.verificationOrder()
                this.$router.push({ path: '/checkout'}).catch(() =>{ });
                this.$emit('closeModal');
            } catch (error) {
                let errorData = {
                    show: true,
                    title: `Lo sentimos ha ocurrido un error.`,
                    description: error.response.data.message
                }
                this.$store.commit('common/SHOW_MODAL_ERROR', errorData)
            }
        },
        copyFunction(url){
            this.$copyText(url)
            this.copy = true
        },
        share(url){
            this.$copyText(url);
            setTimeout(() => {
                window.open('https://www.instagram.com/','_blank');
            }, 1000);
        },
        async algoliaClick(param){
            try {                
                const response = await serv.algoliaClick(param)
                console.log(response);
            } catch (error) {
                console.log(error);
            }
        },
    },
    destroyed(){
        // window.removeEventListener('resize', this.handleResize); 
        this.$store.commit('products/SET_QUICK_VIEW_STATE', false)
    },
    async created(){
        if(this.configurations?.general?.web_page?.activate_algolia_search){
            const searchObject =  this.product.codarticulo
            const position = this.valueFilters.docs.findIndex((item) => item.codarticulo === searchObject);
            const newPosition = position + 1 + this.valueFilters.page * this.valueFilters.hitsPerPage
            const params = {
                product_code: this.product.codarticulo,
                queryID: this.valueFilters.queryID,
                position: newPosition
            }
            if(this.isLogged){
                this.algoliaClick(params)
            }
        }
        const reqSold = await service.getLastPriceSold(this.product.codarticulo)
        this.reqPrice = reqSold.data
        await this.$nextTick(() => {
            // window.addEventListener('resize', this.handleResize);
            
        });
        this.$store.commit('products/SET_QUICK_VIEW_STATE', true)
    }
}
</script>

<style scoped>
    .quick-view-notify {
        position: fixed !important; 
        right: 23px !important;

        top: 84vh !important;
        height: fit-content;
        border-radius: 6px;
        z-index: 13;
    }
    .my-custom-scrollbar {
        position: relative;
        height: 100px;
        overflow: auto;
    }
    .my-branch-scrollbar{
        position: relative;
        height: 166px;
        overflow: auto;
    }
    .table-wrapper-scroll-y {
        display: block;
    }
    .employee-active::v-deep > .modal-main{
        /* max-width: 90%; */
        max-width: 1120px;
    }
    .container-img {
        width: 390px;
        height: 360px;
    }
    .container-img-with-existence {
        height: 60vh;
        width: 60vh;
    }
    .carousel-for-employee{
        height: 60vh;
    }
    .modal-main-container::v-deep > .main-modal{
        max-width: 800px;
    }
    .image__wrapper::v-deep > img{
        width: 390px;
    }
    .image__wrapper::v-deep{
        min-height: 360px;
    }
    .image__wrapper::v-deep:hover > img{
        transform: scale(1.1);
    }
    .carousel {
        overflow: hidden;
        min-height: 360px;
        border: 1px solid var(--gray-1001);
        border-radius: 10px;
    }
    .swiper-pagination::v-deep .swiper-pagination-bullet {
        width: 17px;
        height: 17px;
    }
    .main-button::v-deep{
        border-radius: 5rem;
    }
    .quantity-input-container::v-deep{
        border-radius: 5rem;
    }
    .social-networks{
        gap: 18px;
    }
    .vue-star-rating::v-deep > .vue-star-rating > .vue-star-rating-rating-text{
        color: var(--dark-gray-200);
    }
    .icon-instagram::v-deep g, 
    .icon-instagram::v-deep circle,
    .icon-facebook::v-deep circle, .icon-facebook::v-deep g,
    .icon-whatsapp::v-deep circle, .icon-whatsapp::v-deep g,
    .icon-email::v-deep circle, .icon-email::v-deep g {
        transition: var(--transition-1)
    }
    .icon-instagram:hover::v-deep circle {
        fill: #ffecf278;
    }
    .icon-instagram:hover::v-deep g {
        transform: scale(0.9) translate(17px, 17px) !important;
    }
    .icon-facebook:hover::v-deep circle{
        fill: #ddecff75;
    }
    .icon-facebook:hover::v-deep g{
        transform: scale(0.9) translate(24px, 18px) !important;
    }
    .icon-whatsapp:hover::v-deep circle {
        fill: #e9fbf085;
    }
    .icon-whatsapp:hover::v-deep g {
        transform: scale(0.9) translate(17px, 17px) !important;
    }
    .icon-email:hover::v-deep circle {
        fill: #fef7ea80;
    }
    .icon-email:hover::v-deep g {
        transform: scale(0.9) translate(18px, 23px) !important;
    }
    .icon-favorite {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
    }
    .swiper-container::v-deep .swiper-button-prev.swiper-button-disabled, .swiper-container::v-deep .swiper-button-next.swiper-button-disabled {
        pointer-events: visible;
    }
    .swiper-container::v-deep .swiper-button-prev:hover svg, .swiper-container::v-deep .swiper-button-next:hover svg {
        fill: var(--main-red);
    }
    .swiper-container::v-deep .swiper-pagination .swiper-pagination-bullet:hover {
        background-color: var(--gray-50);
        transform: scale(0.8);
    }
    .swiper-container::v-deep .swiper-wrapper .swiper-slide-active {
        z-index: 2;
    }
    .container-description {
        display: -webkit-box;
        text-overflow: ellipsis;
        word-break: break-all;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .notify::v-deep {
        top: 0;
        right: 0;
    }
    .hover-product-title:hover > a {
        color: var(--main-red) !important;
    }
    .hover-product-title > a {
        transition: var(--transition-1);
    }
    .color-picker::v-deep .color-outline{
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }
    .iconStyle{
        display: block;
        margin: 5px auto 0 auto;
    }
    .color-picker::v-deep .color-outline .color{
        width: 14px;
        height: 14px;
    }
    @media (max-width: 768px) {
        .quantity-input-container::v-deep div{
            height: 30px !important;
            width: 100px;
        }
    }
    @media (max-width: 574px) {
        .quick-view-notify {
            display: none;
        }
        .special-price {
            font-size: 13px !important;
        }
    }
    .button-disabled{
        background: var(--gray-1001) !important;
        cursor:no-drop;
    }
    .table::v-deep > tbody > tr > td {
        padding: 3px;
    }
    .table::v-deep > :not(:first-child) {
        border-top: 1px solid var(--gray-100);
    }
    .table > thead {
        background-color: var(--light-gray-100);
        color: var(--dark-gray-200);
    }
    .special-price {
        text-decoration: line-through;
        color: var(--dark-gray-200) !important;
        font-weight: 400 !important;
    }
    .btn-action:hover svg{
        fill: var(--main-red);
    }
    .active-hover .btn-action, .active-hover svg{
        color: var(--main-navy);
        fill: var(--main-red);
    }
    .icon-copy{
        position: absolute;
        right: 4px;
        top: 7px;
        cursor: pointer;
        width: 70px;
        background: white;
    }

    .option-tooltip {
        border-radius: 6px;
        display: none;
    }
    .popup::v-deep::before {
        top: 25px;
        left: 50%;
        transform: translate(-50%, -50%) rotate(134deg);
        width: 12px;
        height: 12px;
    }
    .btn-action{
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        position: relative;
    }
    .btn-action:hover > .option-tooltip, .order-token-id:hover > .option-tooltip{
        display: flex;
    }
</style>
