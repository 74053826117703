
<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <path class="st0" d="M245.2,117.9c-12.8-13-26.9-24.3-42.5-33.8c-15.3-9.3-32-17.3-49.6-21.3c-11.8-2.7-24-3.6-36.1-2.8
                c-7.3,0.5-14.5,1.7-21.6,3.5C78.3,67.9,62,74.9,46.9,84.2c-15.1,9.3-28.8,20.6-41.4,33c-6.7,6.6-6.6,9.6,0,16.2
                c17.8,17.7,37.8,32.5,60.6,43.3c27.4,13,55.9,18.2,85.9,11.1c36.8-8.7,66.8-29,93.2-55.1C251,126.9,250.9,123.7,245.2,117.9z
                M125.2,176.2c-28.1,0.2-51-22.6-51.1-50.8c-0.2-28.1,22.5-51,50.8-51.1c28.1-0.2,51,22.6,51.1,50.8
                C176.1,153.2,153.4,176,125.2,176.2z"/>
            <path class="st0" d="M134.4,119.6c-4.5-10-1.6-18.9,6.6-26.6c-14.4-7.4-31.4-4.4-42.4,7.2c-11.1,11.6-13.2,29.2-5.3,43.2
                c7.9,13.8,24.1,21,39.4,17.6c14.4-3.2,26.5-15.9,27.4-29C148.7,133.7,139.3,130.6,134.4,119.6z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'iconOpenedEye',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>

</style>