<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 15.426 15.426">
        <path id="Path_602" data-name="Path 602" d="M400.97,57.658a7.713,7.713,0,1,1-7.713-7.713,7.713,7.713,0,0,1,7.713,7.713" transform="translate(-385.544 -49.945)"/>
        <path id="Path_603" data-name="Path 603" d="M401.08,60.979l-4.316,4.329a.884.884,0,0,1-.6.247.855.855,0,0,1-.587-.247l-2.021-2.021a.842.842,0,0,1,0-1.187.827.827,0,0,1,1.174,0l1.434,1.434,3.729-3.729a.842.842,0,0,1,1.187,0,.826.826,0,0,1,0,1.173" transform="translate(-389.605 -54.845)" fill="#fff"/> 
    </icon-base>
</template>
<script>
import iconBase from '../../../../common/components/iconBase.vue'
export default {
    components: { iconBase },
    name: 'iconCheckCircle',
    props:{
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: '#85b561'
    },
    
    }
}
</script>