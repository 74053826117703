<template>
  <icon-base :width="size + 'px'" :fill="color">
    <path
      d="M125,0.194c-51.607,0-93.604,42.288-93.604,94.28c0,73.874,84.805,150.151,88.414,153.355
	c1.487,1.321,3.339,1.976,5.19,1.976s3.703-0.655,5.19-1.966c3.609-3.214,88.414-79.491,88.414-153.365
	C218.604,42.483,176.607,0.194,125,0.194L125,0.194z M125,145.801c-28.674,0-52.002-23.328-52.002-52.002S96.326,41.796,125,41.796
	s52.002,23.328,52.002,52.002S153.674,145.801,125,145.801z"
    />
  </icon-base>
</template>

<script>
export default {
  name: 'iconLocation',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: 'var(--main-navy)'
    },
    enableBackground: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.st0 {
  fill: #bc2c35;
}
.st1 {
  fill: #ffffff;
}
.st2 {
  clip-path: url(#SVGID_2_);
}
.st3 {
  fill: #ea3838;
}
.st4 {
  fill: #72bf58;
}
</style>
