<template>
    <router-link tag="div" to="/products/9708" :class="[{active : active == index}, {prev : active == index + 1 || active == index - 1}]" 
    class="cursor-pointer container-slider-1">
        <img src="./images/bg.webp" class="w-100" @load="onImgLoad">
        <img src="./images/stand.webp" class="w-100 stand">
        <img :src="data.img_primary" class="img-primary"/>
        <div class="shadow-img-primary"></div>
        <div class="content">
            <h3 class="mb-0">{{data.title_1}}</h3>
            <background-text :size="504" style="margin-top: -12px" text="Serie Premium"/>
            <h1>{{data.title_3}}</h1>
            <h5>{{data.description_1}}</h5>
            <div class="container-text rounded-1 bg-white d-inline-block position-relative px-3">
                <h4 class="mb-0">{{data.description_2}}</h4>
            </div>
        </div>
    </router-link>
</template>

<script>
    import BackgroundText from './BackgroundText.vue'
    export default {
    components: { BackgroundText },
        name: "SliderType1",
        props:{
            data:{
                type: Object
            },
            active:{
                type: Number
            },
            index:{
                type: Number
            }
        },
        methods:{
            onImgLoad(){
                this.$emit('onImgLoad');
            }
        }
    }
</script>
<style scoped>
.container-slider-1{
    background-color: #589bff;
}
.img-primary{
    position: absolute;
    top: 8%;
    right: 16%;
    width: 340px;
}
@keyframes img-primary {
    from{
        transform: translateY(10px);
    }
    to{
        transform: translateY(0px);
    }  
}
h6{
    position: absolute;
    right: 25px;
    top: 70%;
    transform: rotate(-90deg);
    color: #fff;
    font-weight: 100;
}
.active > .img-primary{
    animation: active-img 0.6s, img-primary 1.5s infinite 0.6s alternate-reverse;
    /*animation: img-primary 1.5s infinite alternate-reverse !important;*/
}
@keyframes active-img {
    from{
        transform: translateY(-300px);
    }
    to{
        transform: translateY(0px);
    }
}
.prev > .img-primary{
    animation: prev-img 0.6s;
}
@keyframes prev-img {
    from{
        transform: translateY(0px);
    }
    to{
        transform: translateY(-300px);
    }
}
.active > .shadow-img-primary {
    position: absolute;
    width: 23%;
    height: 3%;
    right: 17%;
    border-radius: 50%;
    z-index: 0;
    bottom: 28%;
    content: "";
    box-shadow: 0 50px 8px rgb(0 0 0 / 9%);
    animation: shadow-img-primary 1.5s infinite alternate-reverse;
    animation-delay: 0.6s;
}
@keyframes shadow-img-primary {
    from{
        width: 28%;
        transform: translateX(40px);
    }
    to{
        width: 23%;
        transform: translateX(0px);
    }
}
.content{
    position: absolute;
    top: 14%;
    left: calc(7% - 6px);
    text-align: start;
}
.content > h3{
    font-size: 54px;
    color: #fff;
}
.content > h2{
    font-size: 56px;
    text-shadow: 0 0 2px white, 0 0 2px white, 0 0 1px white, 0 0 1px white;
    color: #5097ff;
    font-size: 50px;
}
.content > h1{
    font-size: 64px;
    color: #fff;
    font-weight: 700;
}
.content > h5{
    font-size: 36px;
    color: #fff;
    font-weight: 300;
    margin-top: 5rem;
}
.container-text{transform: translateX(-15px);}
.container-text > h4{
    color: #5097ff;
    font-size: 30px;
    font-weight: 700;
}
.stand{
    margin-top: -12%;
    position: absolute;
    left: 10%;
    transform: scale(1.3);
}
</style>