<template>
  <icon-base :width="size + 'px'" :fill="color" class="icon-favourite">
    <g>
      <path d="M229.7,35.954c-12.884-13.974-30.562-21.67-49.783-21.67c-14.367,0-27.524,4.542-39.107,13.499
        c-5.845,4.521-11.141,10.053-15.81,16.509c-4.667-6.454-9.965-11.988-15.812-16.509C97.607,18.826,84.45,14.284,70.083,14.284
        c-19.22,0-36.901,7.696-49.785,21.67C7.568,49.765,0.556,68.632,0.556,89.083c0,21.049,7.844,40.317,24.685,60.639
        c15.066,18.178,36.718,36.631,61.793,57.999c8.562,7.297,18.267,15.569,28.344,24.38c2.662,2.332,6.078,3.615,9.622,3.615
        c3.541,0,6.959-1.284,9.618-3.612c10.077-8.813,19.788-17.088,28.354-24.389c25.071-21.364,46.724-39.816,61.789-57.995
        c16.841-20.32,24.683-39.588,24.683-60.639C249.444,68.632,242.432,49.765,229.7,35.954z"/>
    </g>
  </icon-base>
</template>

<script>
export default {
  name: 'iconFavourite',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ''
    },
    enableBackground: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
	.st0{fill:#BC2C35;}
	.st1{fill:#FFFFFF;}
	.st2{clip-path:url(#SVGID_2_);}
	.st3{fill:#EA3838;}
	.st4{fill:#72BF58;}
</style>
