<template>
    <icon-base :color="color" :size="size" viewBox="0 0 56 56" class="icon-instagram">
        <circle id="Ellipse_56" data-name="Ellipse 56" cx="28" cy="28" r="28" fill="#ffecf2"/>
        <g id="IG_icon" data-name="IG icon" transform="translate(14 14)">
            <path id="IG_icon-2" data-name="IG icon" d="M167.463,3627.528a1.66,1.66,0,1,0,1.659,1.66A1.635,1.635,0,0,0,167.463,3627.528Zm-7.31,2.084a6.92,6.92,0,1,0,6.921,6.921A6.932,6.932,0,0,0,160.153,3629.613Zm0,11.333a4.413,4.413,0,1,1,4.414-4.412A4.414,4.414,0,0,1,160.153,3640.945Zm13.947-10.168a8.439,8.439,0,0,0-8.439-8.439H154.539a8.439,8.439,0,0,0-8.439,8.439V3641.9a8.439,8.439,0,0,0,8.439,8.439h11.122a8.439,8.439,0,0,0,8.439-8.439Zm-2.647,11.122a5.772,5.772,0,0,1-5.792,5.792H154.539a5.771,5.771,0,0,1-5.79-5.792v-11.122a5.77,5.77,0,0,1,5.79-5.79h11.122a5.772,5.772,0,0,1,5.792,5.79Z" transform="translate(-146.1 -3622.337)" fill="#ed4b5f"/>
        </g>
    </icon-base>
</template>

<script>
export default {
    name:'iconInstagram',
    components:{
        IconBase: () => import('@/common/components/iconBase.vue')
    },
    props:{
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>

<style scoped>
</style>

