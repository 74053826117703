<template>
    <div class="select-container d-flex align-items-center" :class="[{'disabled-select' : disabled},   {'open': open && !search}, {'openSearch': open && search}, {'spanish-ac-size': ($i18n.locale == 'es' && title == 'product.all_categories')}, {'is-invalid': $v.modelValue.$error}]" @mouseleave="open = false">
        <div class="d-flex justify-content-between align-items-center w-100" @click="open = !open">
            <span class="select-placeholder subtitle-14 text-limited" :class="[selectedOption.value !== '' ? disabled ? 'disabled-select' : 'color-dark-main-navy' : 'opacity-3']">
                <slot />
                {{ $t(title) }}
            </span>
            <slot name="icon">
                <icon-arrow :size="10" class="opacity-1 ms-3" :class="{'rotate': open}"/>
            </slot>
        </div>
        <ul v-if="open" class="animation-slide-down select-options pt-0">
            <li :key="index" v-for="({title, value}, index) of options" @click="clickHandler(value, title)" :class="[value === selectedOption.value ? 'active' : '', index === 0 ? 'mt-0 pt-0' : '']" class="subtitle-14 text-limited">
                {{$t(title)}}
            </li>
        </ul>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
    name: "MainSelect",
    components: {
        IconArrow: () => import("@/common/svg/iconArrow"),
    },
    props: {
        loading: { //not tested yet
            type: Boolean,
            default: false
        },
        options: { //the object array containing the values and the labels that will be shown
            type: Array,
            required: true,
        },
        value: { //guess what, it also needs to be a constantly changing value not only the default one
            type: Object,
            default(){
                return {value: 0, title: 'account.select_an_option'}
            }
        },
        selectedOption: { //guess what, it also needs to be a constantly changing value not only the default one
            type: Object,
            default(){
                return {value: 0, title: 'account.select_an_option'}
            }
        },
        summaryRef: { //the name of said instance of the selects main instance
            type: String,
        },
        placeholder: {
            type: String,
            default: "..."
        },
        search: {
            type: Boolean
        },
        defaultInvalid: { //set the input as invalid if the default option is the one selected
            type: Boolean,
            default: false
        },
        disabled : {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            open: false
        }
    },
    methods: {
        async clickHandler(value, title){
            this.title = title;
            this.open = false;
            this.$emit('clickHandler', {value, title});
            this.$v.modelValue.$model = value;
            this.$v.modelValue.$touch();
        },
        closeOutside(event) {
            const targetId = this.summaryRef;
            const details = document.querySelector(`#details_` + this.summaryRef);
            
            if (event.target.id !== targetId) {
                details.removeAttribute("open");
            }
        },
        close(){
            const details = document.querySelector(`#details_` + this.summaryRef);
            details.removeAttribute("open");
        }
    },
    created(){
        this.$v.modelValue.$model = this.value; 
    },
    computed: {
        title: {
            get: function() {
                if (this.selectedOption.value === ''){
                    return this.placeholder;
                }
                for(const {title, value} of this.options){
                    if (this.selectedOption.value === value){
                        return title;
                    }
                }
                return ''
            },
            set: function (selected) {
                return selected;
            }
        }
    },
    validations() {
        return {
            modelValue: {
                required,
            }
        }
    }
}
</script>

<style scoped>
.select-container.spanish-ac-size {
    padding: 4px 10px;
    padding-left: 10px;
}
.select-container {
    padding: 4px 15px;
    padding-left: 15px;

    cursor: pointer;
    border-radius: 5px;
    border: 1px solid var(--gray-50);
    background-color: #fff;

    list-style: none;
    user-select: none;
    transition: var(--transition-1);

    position: relative;
}
.select-container:hover{
    box-shadow: 0px 0px 10px 0.5px #909da41c;
}
@keyframes SummaryAnimation {
    from {
        opacity: 0;
        transform: translateY(-10%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
summary::-webkit-details-marker {
	display: none;
}
.select-container.open{
    box-shadow: 0px 0px 10px 0.5px #909da413;
    border-color: #f3f7ff;
    border: 1px solid transparent;
    border-bottom: 1px solid #f3f7ff;
    border-radius: 10px 10px 0px 0px;
}

summary:focus {
	outline: none;
}

.rotate {
	transform: rotate(-90deg) translate(0%, 0%) !important;

}
.icon-arrow {
    transition-timing-function: ease-in-out;
    transition: var(--transition-1);

	transform: rotate(90deg) translate(0%, 0%);
}
ul {
    /* for firefox */
    scrollbar-color: var(--gray-100) #edf2f500;
    margin-top: 1px !important;
	width: 100%;
	background: #fff;
	position: absolute;
	top: calc(100%);
	left: 0;
    border:8px solid transparent;
	margin: 0;
	box-sizing: border-box;
	border-radius: 0px 0px 15px 15px;
	max-height: 200px;
	overflow-y: auto;
    list-style: none;
    animation: ulAnimation .3s;
    box-shadow: 0px 9px 9px 1px rgb(144 157 164 / 13%);
    padding: 1px;
    text-align: initial;
    z-index: 2;
    border-top: 10px solid transparent;
    border-right: 14px solid transparent;
}

@keyframes ulAnimation {
    from {
        opacity: 0;
        transform: translateY(-10%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

li {
    margin-bottom: 5px;
    padding: 1rem 0;
    cursor: pointer;
    padding: 5px;
    border-radius: 6px;
    margin-right: 10px;
    margin-left: 3px;
}
li:hover {
    color: var(--blue-600) !important;
}

.disabled-select {
    pointer-events: none;
    color: var(--gray-1001) !important;
}

.active {
    color: var(--blue-600);
    background: var(--light-gray-100);
}
li:last-child {
	border-bottom: none;
}
label {
	width: 100%;
	display: block;
	cursor: pointer;
}
.openSearch {
    border-bottom-left-radius: 0;
}
</style>
