<template>
    <card class="text-center card-product">
        <div class="position-relative">
            <ribbon v-if="offer"/>
        </div>
        <div v-if="!skeleton" class="d-flex flex-column product-slider-card-content align-items-center">
            <router-link :to="linkProduct" class="product-description">
                <swiper :class="{'container-img-product' : grid}" class="container-img-product" @click="showQuickView({product, value: true})" 
                :ref="`product-${product.codarticulo}`" @slideChange="onSlideChange(`product-${product.codarticulo}`)">
                    <swiper-slide v-for="(img, i) in product.imageGallery" :key="`product-${product.code}-${i}`">
                        <div v-if="shouldRenderItem(i)">
                            <router-link :to="`/products/${product.codarticulo}`" class="d-flex justify-content-center">
                                <image-product :styleObject="{ height: '150px','object-fit': 'contain'}" :source="img.picture_url" class="img-product-details"/>
                            </router-link>
                            <div :class="{'icon-eye d-none' : grid}" v-if="grid">
                                <icon-eye :class="'defaultColor'" :size="30"/>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </router-link>
            <div class="container-desc">
                <h6 class="product-desc d-inline-block size-14 weight-400 appear-from-bottom text-limited mx-auto" :class="[grid ? 'mt-2' : 'mt-3']" :title="product.description | textFormat">
                    <router-link :to="{ name: 'product-detail', params: { id: this.product._id }}" class="product-description">
                        {{ product.description | textFormat }}
                    </router-link>
                </h6>
            </div>
            <div v-if="product.special_price" class="d-flex flex-column flex-md-row mb-1 mb-md-2">
                <h4 class="special-price size-15 weight-700 mb-0 color-dark-gray-200 me-0 me-md-2">{{product.price | moneyFormat}}</h4>
                <h4 class="color-main-red size-15 weight-700 mb-0">{{product.special_price | moneyFormat}}</h4>
            </div>
            <h4 v-else class="product-price d-inline-block size-15 appear-from-bottom weight-700 mb-0">{{product.price | moneyFormat}}</h4>
            <product-quantity-input style="max-width: 130px;" class="mt-0" :product="product" @deleteProduct="showConfirmDeleteProduct = true"  v-if="getQuantity(product) > 0 || grid"/>
            <button-group-action :product="product" class="mt-2 " @showQuickView="$emit('showQuickView', {product, value: true})" @showShare="$emit('showShare', product)" v-else/>
            <quick-view v-if="quickView.value" :product="quickView.product" @closeModal="quickView.value = false" />
        </div>

        <!-- It may not be reused by a component that uses the card component in the component in which it is called. 28/03/2023-->
        <div class="d-flex flex-column product-slider-card-content align-items-center" style="opacity: 0.4;" v-else>
            <div class="skeleton-img bg-gray-100" style="width: 140px; height: 160px; border-radius: 5px;" />
            <div class="bg-gray-100 mt-auto" style="width: 180px; height: 20px; "/>
            <div class="bg-gray-100 mb-2" style="width: 80px; height: 15px; "/>
        </div>
        <confirm-delete-product v-if="showConfirmDeleteProduct" @closeModal="showConfirmDeleteProduct = false" @confirmDelete="deleteConfirmed" :loading="loadingDelete"/>
    </card>
</template>
<script>
import Card from '../../../common/components/cards/Card.vue';
import QuickView from './quick-view/QuickView.vue';
import IconEye from '../../../common/svg/iconEye.vue';
import ButtonGroupAction from './buttons/ButtonGroupAction.vue';
import ImageProduct from '@/modules/products/components/Image.vue';
// import StarRating from './rating/StarRating.vue';
import { productMixin } from '../helpers/mixin/product-mixin'
import Ribbon from './Ribbon.vue';
import ProductQuantityInput from './ProductQuantityInput.vue';
import ConfirmDeleteProduct from './modals/ConfirmDeleteProduct.vue';
// import { AlgoliaService } from '../services/algolia-service';

// const service = new AlgoliaService()

export default {
    name: 'CardProduct',
    data () {
        return {
            activeIndex: 0,
            productNew: [],
            starSize: 18,
            imageSize: '60%',
            quickView: {value: false, product: null},
            marginResponsive: false,
            url: '',
            showActions: false,
            tempImg: false,
            showConfirmDeleteProduct: false,
            loadingDelete: false,
            cartClick: false,
        }
    },
    components: {
        Card,
        IconEye,
        QuickView,
        // StarRating,
        ButtonGroupAction,
        ImageProduct,
        Ribbon,
        ProductQuantityInput,
        ConfirmDeleteProduct,
    },
    mixins: [productMixin],
    props: {
        product: {
            type: [Object, Number],
            required: true
        },
        disabledIncrement: {
            type: Boolean,
            default: false
        },
        skeleton:{
            type: Boolean,
            default: true
        },
        offer:{
            type: Boolean,
            default: false
        },
        grid:{
            type: Boolean,
        },
    },
    computed:{
        linkProduct(){
            if(!this.grid){
                return { name: 'product-detail', params: { id: this.product._id }}
            } else {
                return ''
            }
        }
    },
    methods:{
        showButtons(state){
            if(state){
                this.showActions = true
            }else{
                this.showActions = false
            }
        },
        imgReady(){
            this.tempImg = true
            console.log("boy");
        },
        async deleteConfirmed() {
            const product = {
                cod_articulo: this.product.codarticulo,
                quantity: 0, 
            }
            try {
                this.loadingDelete = true;
                await this.$store.dispatch("cart/removeProduct", product);
            } catch (error) {
                console.log(error);
            } finally{
                this.loadingDelete = false;
                // console.log(JSON.stringify(this.getProducts.length));
                if(this.mobile)
                window.AddToCartEvent.postMessage(JSON.stringify(this.getProducts.length));
            }
            this.showConfirmDeleteProduct = false;
        },
        showRatingFunction(boolean){
            this.showRating = boolean;
        },
        showQuickView({product, value}){
            if(this.grid){
                if (this.isCart) {
                this.$emit('showQuickView', {product, value})
                return;
            }
            this.quickView.product = product;
            this.quickView.value = value;
            }
        },
        shouldRenderItem(index) {
            return index === this.activeIndex || index === this.activeIndex - 1 || index === this.activeIndex + 1;
        },
        onSlideChange(ref) {
            this.activeIndex = this.$refs[ref].$swiper.activeIndex;
        },
    },
}
</script>
<style lang="scss" scoped>

    /**.
    * * Important style for letters
    */
    .icon-favourite {
        transition: var(--transition-1);
    }
    .icon-favourite:hover {
        transform: scale(0.93);
        fill: var(--main-red);
    }
    .appear-from-bottom {
        animation: 400ms cubic-bezier(.46,.89,.03,.97) slide-up;
        animation-fill-mode: both;
        @keyframes slide-up {
            from {
                opacity: 0;
                transform: translateY(25px);
            }
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    .product-price{
        margin-top: 5px;
        margin-bottom: 5px !important;
    }
    .hide{
        display: none;
    }
    .show{
        display: block;
    }
    .card-product {
        position: relative;
        border-radius: 14px;

        margin: 0px;
        padding: 15px;
        padding-bottom: 18px;
        box-shadow: 2px 3px 15px 0px #0e1e3d24;
        width: 100%;

        &:hover {        
            border: 1px solid var(--gray-50);
            box-shadow: none;

            .img-product-details {
                transform: scale(1.16);
            }
            .star-rating{
                transform: translateY(8px);
            }
        }
        .product-slider-card-content {
            // padding-top: 8px;
            @media (min-width: $md) {
                height: 280px;
            }
            @media (min-width: $lg) {
                height: 270px;
            }
            .star-rating{
                transition: $transition-1;
            }
            .img-product-details {
                height: 180px !important;
                @media (min-width: $lg) {
                    height: 150px !important;
                }
                width: 150px;
                
                transition: all 300ms ease-out;
                border-radius: 10px;
                
                overflow: hidden;
            }

            .product-desc-grid {
                margin-top: 15px;
                min-height: 20px;
                display: -webkit-box !important;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: normal !important;
            }
            .product-desc {
                margin-top: 5px;
                min-height: 20px;
                display: -webkit-box !important;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: normal !important;
            }
            .product-price{
                margin-bottom: 0px;
            }
        }

        transition: var(--transition-1);
    }
    .card-product:hover .img-product{
        transform: scale(1.1);
    }
    .img-product-details{
        border-radius: 7px;
        overflow: hidden;
        width: 150px;
        height: 110px;
    }
    .favorite-active {
        fill: var(--light-main-red);
    }
    .ribbon::v-deep > .ribbon-wrapper{
        left: -17px;
        top: 5px;
    }
    .quantity-input-container {
        animation: 400ms slide-up;
        animation-fill-mode: both;
        @keyframes slide-up {
            from {
                opacity: 0;
                transform: translateY(25px);
            }
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
.image__wrapper::v-deep > img {
    width: 100% !important;
    height: 100%;
    }
.container-img-product:hover .image__wrapper::v-deep > img {
    transform: scale(1.1);
}
.container-img-product {
    position: relative;
    width: 164px;
    height: 164px;
    overflow: hidden;
    border-radius: 8px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.container-img-product:hover {
    overflow: hidden;
}
.container-img-product:hover .icon-eye{
    display: flex !important;
}
.special-price{
        text-decoration: line-through;
    }
.icon-eye {
    right: 62px;
    margin-top: -88px;
    background: var(--main-red);
    height: 40px;
    width: 40px;
    padding: 10px;
    position: absolute;
    z-index: 3;
    border-radius: 50%;
    animation: show-eye 0.3s;
    cursor: pointer;
}
    @media (max-width: 475px) {
        .icon-eye {
            right: 49px;
            margin-top: -126px;
            background: var(--main-red);
            height: 40px;
            width: 40px;
            padding: 10px;
            position: absolute;
            z-index: 3;
            border-radius: 50%;
            animation: show-eye 0.3s;
            cursor: pointer;
        }
    }
@keyframes show-mask {
    from {
        opacity: 0;
    }to {
        opacity: 1;
    }
}
@keyframes show-eye {
    from {
        opacity: 0;
        transform: translateY(20px);
    }to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@media (max-width: 475px) {
    .container-img-product{
        width: 140px;
        height: 140px;
    }
}

a:hover .defaultColor, .main-link:hover .defaultColor{
    fill: white !important;
}

.product-description {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    color: var(--dark-main-navy) !important;
}
.swiper-container::v-deep .swiper-wrapper .swiper-slide-active::before{
    content: '';
    display: none;
    z-index: 2;
    background-color: #0e1e3d24;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    -webkit-animation: show-mask-data-v-2c065102 0.3s;
    animation: show-mask-data-v-2c065102 0.3s;
}
.swiper-container::v-deep .swiper-wrapper:hover .swiper-slide-active::before{
    display: block;
}
</style>

