
export default {
    loading: state => state.loading,
    notify: state => state.notify,
    shortNotify: state => state.shortNotify,
    isPageDisable: (state) => state.configurations.general.web_page.active,
    configurations: (state)=> state.configurations,
    disableModal: (state)=> state.disableModal,
    mobile: (state) => state.mobile,
    error: (state) => state.error,
}
